import React, { useEffect, useRef, useState } from "react";
import styles from './Network.module.css';
import messageIcon from '../../../../../../Assets/Logo/message.svg';
import profilePlaceholder from '../../../../../../Assets/Images/profile_avatar.png';
import plusIcon from '../../../../../../Assets/Logo/add-icon-red.svg';
import slideClose from '../../../../../../Assets/Logo/slide-close-icon.svg';
import threeDot from '../../../../../../Assets/Logo/three-dot-red-icon.svg';
import blockIcon from "../../../../../../Assets/Logo/block-icon.svg";
import disconnectIcon from "../../../../../../Assets/Logo/disconnect-icon.svg";
import reportIcon from "../../../../../../Assets/Logo/report-icon.svg";
import turnModeIcon from "../../../../../../Assets/Logo/turn_into_mode-icon.svg";
import removeIcon from "../../../../../../Assets/Logo/crose-whiteMini.svg";
import ConnectionService from "../../../../../../Services/ConnectionService";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChatWindow from "../../../../../GlobleShared/Chat/ChatWindow";
import useLocalStorage from "../../../../../GlobleShared/CustomHooks/useLocalStorage";
import useDebounce from "../../../../../GlobleShared/CustomHooks/useDebounce";

const Network = () => {
  const [activeTab, setActiveTab] = useState('Connections');
  const [connections, setConnections] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLsLoading] = useState(false);
  const initialized = useRef(false)
  const [chatWindows, setChatWindows] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const refDiv = useRef(null)
  const navigate = useNavigate()
  const [userDetail] = useLocalStorage('userDetail');
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [profilePic, setProfilePic] = useState();
  const [connectionIdvalue, setconnectionIdvalue] = useState();
  const [statusId, setStatusId] = useState();
  const [btnTextName, setbtnTextName] = useState();
  const [confirmationText, setConfirmationText] = useState('');






  const [profiles, setProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);

  const [searchTerm, setsearchTerm] = useState("");

  const [receiverId, setReceiverId] = useState();



  const viewMore =()=>{
    navigate('/member/connections')
  }
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getConnection(page);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOpenChat = (profile) => {
    setChatWindows((prevChats) => {
      if (!prevChats.some((chat) => chat.userConnectionId === profile.userConnectionId)) {
        return [...prevChats, { ...profile, isMinimized: false }];
      }
      return prevChats;
    });
  };
  
  const handleCloseChat = (id) => {
    setChatWindows((prevChats) => prevChats.filter((chat) => chat.userConnectionId !== id));
  };
  
  const handleMinimizeChat = (id) => {
    setChatWindows((prevChats) => prevChats.map((chat) => (chat?.userConnectionId === id ? { ...chat, isMinimized: !chat.isMinimized } : chat)));
  };

  const getConnection = (pageValue) => {
    let data = {
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: ""
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res => {
      const updatedConnections = res?.list.map(connection => ({
        ...connection,
        profileImage: connection?.profileImage ? blobUrl + connection.profileImage : ""
      }));
      setConnections(updatedConnections);
      setTotalCount(res?.totalCount);
      setLsLoading(false);
    }).catch(error => {
      setLsLoading(false);
    })
  }

  const getSuggestionList = (pageNum) => {
    let data = {
      limit: limit,
      page: pageNum,
      paginated: paginated,
      searchTerm: "",
    };
    setLsLoading(true)
    ConnectionService.getSuggestions(data)
      .then((res) => {
        const updatedSuggestions = res?.list.map(suggestion => ({
          ...suggestion,
          profileImage: suggestion?.profileImage ? blobUrl + suggestion.profileImage : ""
        }));
        setSuggestions(updatedSuggestions);
        setTotalCount(res?.totalCount)
        setLsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const getInvitationList = (pageNum) => {
    let data = {
      limit: limit,
      page: pageNum,
      paginated: paginated,
      searchTerm: "",
    };
    setLsLoading(true)
    ConnectionService.getInvitationList(data)
      .then((res) => {
        const updatedInvitations = res?.list.map(invitation => ({
          ...invitation,
          profileImage: invitation?.profileImage ? blobUrl + invitation.profileImage : ""
        }));
        setInvitations(updatedInvitations);
        setTotalCount(res?.totalCount);
        setLsLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const getData = (tabName) => {
    setActiveTab(tabName);
    if (tabName === 'Connections') {
      getConnection(page);
    } else if (tabName === 'Suggestions') {
      getSuggestionList(page);
    } else if (tabName === 'Invitations') {
      getInvitationList(page);
    }
  };

  const handleTogglePopup = (id) => {
    setActivePopup((prevId) => (prevId === id ? null : id));
  };

  const handleHidePopup = () => {
    setActivePopup(null);
  };

  const showModalhandler = (value, status, btnTextName) => {
    setModalIsVisible(true);
    setconnectionIdvalue(value);
    setStatusId(status)
    setbtnTextName(btnTextName)
    if (status === 4) {
      setConfirmationText('Are you sure, you want to report the selected contact?')
    }

    if (status === 5) {
      setConfirmationText('Are you sure, you want to block the selected contact?')
    }

    if (status === 2) {
      setConfirmationText('Are you sure, you want to unblock the selected contact?')
    }

    if (status === 6) {
      setConfirmationText('Are you sure, you want to remove the selected contact?')
    }
  }

  const hideModalhandler = (value) => {
    setModalIsVisible(false);
  }
  const handleClickOutside = (event) => {
    if (refDiv.current && !refDiv.current.contains(event.target)) {
      handleHidePopup();
    }
  };

  const statusUpdate = () => {
    let successMsg;
    let data = {
      connectionId: connectionIdvalue,
      connectionStatusId: statusId
    };
    setLsLoading(true);
    ConnectionService.updateConnection(data).then(res => {
      setModalIsVisible(false);
      setLsLoading(false);
      if (res?.message.includes("[User]")) {
        successMsg = res?.message.replace("[User]", btnTextName);
      } else if (res?.message && statusId === 2) {
        successMsg = `You have unblocked ${btnTextName}. They can now interact with you again!`;
      } else if (res?.message && statusId === 4) {
        successMsg = res?.message; // Assuming the backend returns the report success message here
      }
      toast.success(successMsg);
      handleHidePopup();
      setConnections(prevConnections => prevConnections.map(connection => {
        if (connection.userConnectionId === connectionIdvalue) {
          if (statusId === 6 || statusId === 4) {
            return null; // Mark for removal
          } else {
            return { ...connection, connectionStatus: statusId === 2 ? "Active" : "Block Contact" };
          }
        }
        return connection;
      }).filter(connection => connection !== null));
      getConnection(page);
    }).catch(error => {
      setLsLoading(false);
      toast.error(error?.message);
    });
  };



  // suggestinos and invitations logics

  const updateSuggestionStatus = (receiverIdValue, statusId) => {
    let successMsg;
    let data = {
      requesterId: receiverIdValue,
      connectionStatusId: statusId,
    };
    setLsLoading(true)
    ConnectionService.addConnection(data)
      .then((res) => {
        // refreshInvitationLIst(page);
        setLsLoading(false);
        if(res?.message && statusId === 1){
          successMsg = "Connection request sent!";
        }
        if(res?.message && statusId === 3){
          successMsg = "Unable to send a connection request. Please try again.";
        }
        toast.success(successMsg);
        getSuggestionList(page);
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  };


  const updateInvitationStatus = (connectionIdValue, statusId) => {
    let successMsg;
    let data = {
      connectionId: connectionIdValue,
      connectionStatusId: statusId,
    };
    setLsLoading(true)
    ConnectionService.updateConnection(data)
      .then((res) => {
        // refreshInvitationLIst(page);
        setLsLoading(false);
        if(res?.message && statusId === 2){
          successMsg = "You are now connected!";
        }
        if(res?.message && statusId === 3){
          successMsg = "Invitation rejected.";
        }
        toast.success(successMsg);
        getInvitationList(page);
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  };

  const handleAction = (receiverId, action) => {
    setReceiverId(receiverId);
    setModalAction(action);
    setConfirmationText(`Are you sure you want to ${action.toLowerCase()} with the selected contact?`);
    setShowModal(true);
  };

  const handleConfirmation = () => {
    if (modalAction === "Connect") {
      updateSuggestionStatus(receiverId, 1);


    } else if (modalAction === "Dismiss") {
      updateInvitationStatus(receiverId, 3);
    }

    else if (modalAction === "Accept") {
      updateInvitationStatus(receiverId, 2);
    }
    
    setShowModal(false);
  };

  const closeModalHandler = () => {
    setShowModal(false);
    setConfirmationText("");
    setModalAction("");
  }

  return (
    <div className={styles.network}>
      <div className={styles["network-header"]}>
        <div className={styles.heading}>Network</div>
        {/* <div onClick={viewMore} className={styles.viewMore}>View More...</div> */}
        <div>
          {/* <img src={slideClose} alt="close" /> */}
        </div>
      </div>

      <div className={styles['network-tabs']}>
        <button
          className={`${styles.tabButton} ${activeTab === 'Connections' ? styles.activeTab : ''}`}
          onClick={() => getData('Connections')} 
        >
          Connections
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'Suggestions' ? styles.activeTab : ''}`}
          onClick={() => getData('Suggestions')} 
        >
          Suggestions
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'Invitations' ? styles.activeTab : ''}`}
          onClick={() => getData('Invitations')} 
        >
          Invitations
        </button>
      </div>

      { activeTab === 'Connections' && (
        <div className={styles["connections-list"]}>
        {connections.map((connection, index) => (
          <div key={index} className={styles["connection-card"]}>
            <div className={styles["connection-section-1"]}>
  <img
    src={connection.profileImage ? connection.profileImage : profilePlaceholder}
    alt="Profile"
    className={`${styles["profile-image"]} ${connection.connectionStatus === "Block Contact" ? styles["disconnectIsTrue"] : ""}`}
  />
  <div className={styles["connection-info"]}>
    <div title={connection?.aboutUs} className={styles["user-message"]}>
      {connection?.aboutUs || "NA"}
    </div>
    <div className={styles["menu-dots"]} onClick={() => handleTogglePopup(connection.userConnectionId)}>
      <img src={threeDot} alt="more" />
      {activePopup === connection.userConnectionId && (
        <>
          <div ref={refDiv} className={styles.popup}>
            <div className={styles.popupOption} onClick={() => { showModalhandler(connection.userConnectionId, 6, 'Remove') }}>
              <img src={disconnectIcon} alt="Disconnect" />Disconnect
            </div>
            {(connection.connectionStatus === "Block Contact") ? (
              <div className={styles.popupOption} onClick={() => { showModalhandler(connection.userConnectionId, 2, 'Unblock') }}>
                <img src={blockIcon} alt="Unblock" />Unblock
              </div>
            ) : (
              <div className={styles.popupOption} onClick={() => { showModalhandler(connection.userConnectionId, 5, 'Block') }}>
                <img src={blockIcon} alt="Block" />Block
              </div>
            )}
            <div className={styles.popupOption} onClick={() => { showModalhandler(connection.userConnectionId, 4, 'Report') }}>
              <img src={reportIcon} alt="Report" />Report
            </div>
          </div>
        </>
      )}
    </div>
  </div>
</div>

            <div className={styles["connection-section-2"]}>
              <div className={styles["user-name"]}>
                {connection?.displayName || "NA"}
              </div>
              <img
                src={messageIcon}
                alt="Message"
                className={styles["message-icon"]}
                onClick={() => handleOpenChat(connection)}
              />
            </div>
          </div>
        ))}
      </div>
      )}

      {activeTab === 'Suggestions' && (
        <div className={styles["suggestions-list"]}>
          {suggestions.map((suggestion, index) => (
            <div key={index} className={styles["suggestion-card"]}>
              <div className={styles["suggestion-section-1"]}>
                <div>
                  <img
                    src={suggestion.profileImage ? suggestion.profileImage : profilePlaceholder}
                    alt="Profile"
                    className={styles["profile-image"]}
                  />
                </div>
                <div>
                  <div className={styles["user-name"]}>
                    {suggestion?.displayName || "NA"}
                  </div>
                  <div title={suggestion?.aboutUs} className={styles["user-message"]}>
                    {suggestion?.aboutUs || "NA"}
                  </div>
                </div>
              </div>

              <div className={styles["suggestion-section-2"]}>
                <button onClick={() => handleAction(suggestion.senderUserId, "Connect")} className={styles["invite-btn"]}>Invite</button>
                <div className={styles["decline-btn"]}>Dismiss</div>
              </div>
            </div>
          ))}
        </div>
      )}

      { activeTab === 'Invitations' && (
        <div className={styles["suggestions-list"]}>
          {invitations.map((invitation, index) => (
            <div key={index} className={styles["suggestion-card"]}>
              <div className={styles["suggestion-section-1"]}>
                <div>
                  <img
                    src={invitation.profileImage ? invitation.profileImage : profilePlaceholder}
                    alt="Profile"
                    className={styles["profile-image"]}
                  />
                </div>
                <div>
                  <div className={styles["user-name"]}>
                    {invitation?.displayName || "NA"}
                  </div>
                  <div title={invitation?.aboutUs} className={styles["user-message"]}>
                    {invitation?.aboutUs || "NA"}
                  </div>
                </div>
              </div>

              <div className={styles["suggestion-section-2"]}>
                <button onClick={() => handleAction(invitation.userConnectionId, "Accept")} className={styles["accept-btn"]}>Accept</button>
                <div onClick={() => handleAction(invitation.userConnectionId, "Dismiss")} className={styles["decline-btn"]}>Decline</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {isLoading && (
        <Loader></Loader>
      )}

<div className={styles.chatContainer}>
  <div className={`${styles.forChatScreen} row`}>
    <div className="col-md-3"></div>
    {chatWindows.slice(0, 2).map((chat, index) => (
      <div
        key={chat?.userConnectionId}
        className={`col-md-4`}>
        <ChatWindow
          id={chat?.userConnectionId}
          name={chat?.displayName}
          profile={chat?.profileImage}
          onClose={() => handleCloseChat(chat?.userConnectionId)}
          onMinimize={() => handleMinimizeChat(chat?.userConnectionId)}
          isMinimized={chat.isMinimized}
        />
      </div>
    ))}
    <div className="col-md-1">
      {chatWindows.map((chat) => (
        <div key={chat?.userConnectionId}>
          <img
            className={styles.profilePicBottom}
            src={chat?.profileImage}
            alt=""
          />
        </div>
      ))}
    </div>
  </div>
</div>

      {modalIsVisible && (
        <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible}
          getConfirmation={statusUpdate}>
        </ConfirmationModal>
      )}

{showModal && <ConfirmationModal
        confirmationText={confirmationText}
        onShow={handleAction}
        onClose={closeModalHandler}
        getConfirmation={handleConfirmation}
        btnText={modalAction}
      />}
    </div>
  );
};

export default Network;