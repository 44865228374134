import React from "react";
import classes from './Marketing.module.css'
import brochure from "../../../../../Assets/Images/msm-brochure.jpg";

function Marketing() {
  return (
    <>
      {/* <div className={classes['marketing_section']}>
        <div className={classes['section_border']}>
          <p className={classes['heading_text']}>Marketing</p>
        </div>
      </div> */}
      <img className={classes['brochure']} src={brochure} alt="brochure" />
    </>
  );
}
export default Marketing;
