import React, { useState, useRef, useEffect }  from 'react';
import classes from "./FeeSection.module.css";
import { Table } from 'react-bootstrap';
import ApplicationService from '../../../../../../Services/ApplicationServices';
import { toast } from 'react-toastify';
import Loader from '../../../../../GlobleShared/Loader/Loader';
import SearchModal from '../../../Search/SearchModal/SearchModal';
import FeePaymentModal from '../../FeePaymentModal/FeePaymentModal';
import ToastSuccessModal from '../../../../../GlobleShared/Modal/ToastSuccessModal/ToastSuccessModal';
import UploadRecipt from './UploadRecipt/UploadRecipt';
import ConfirmationModal from '../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal';
import viewIcon from '../../../../../../Assets/Logo/documetnView.svg'

const FeeSection = ({ applicationData, feeStatus, feeSuccessToastHandler }) => {
  const initial = useRef(null);
  const [depositFeeData, setDepositFeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalSize, setModalSize] = useState('');
  const [getfeeDetails, setGetfeeDetails] = useState();
  const [modeOfPaymentList, setModeOfPaymentList] = useState([]);
  const [applicationFeeData, setApplicationFeeData] = useState();
  const [spantranFeeData, setSpantranFeeData] = useState();
  const [tutionFeeData, setTutionFeeData] = useState();
  const [degreeEvaluationFeeData, setDegreeEvaluationFeeData] = useState();
  const [paymentSuccessModalIsVisible, setPaymentSuccessModalIsVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openUploadRecipt, setOpenUploadRecipt] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateFeeData, setUpdateFeeData] = useState('');
  const spanTranFee = JSON.parse(process.env.REACT_APP_SPANTRAN_FEE);
  const tableData = {
    columns: ["Deposit Date", "Type", "Fee Amount", "Basis", "Mode of Payment", "Intake", "Receipt type"],
  };
  const [confrimModalShow, setConfrimModalShow]=useState(false)
  const [confirmationText, setConfirmationText]=useState('')
  const [depositId, setDepositId]=useState('')
  const blobUrl= process.env.REACT_APP_URL_PATH

  useEffect(() => {
    const fetchFeeDataAndDeposits = async () => {
      if (applicationData) {
        await getAllFees(applicationData);
      }
    };
  
    if (!initial.current) {
      initial.current = true;
      fetchFeeDataAndDeposits();
    }
  }, [applicationData]);
  
  useEffect(() => {
    if (
      (applicationFeeData !== undefined ||
      tutionFeeData !== undefined ||
      spantranFeeData !== undefined ||
      degreeEvaluationFeeData !== undefined) &&
      applicationData?.genInfo?.ApplicationId
    ) {
      getDepositedFeeList(applicationData.genInfo.ApplicationId);
    }
  }, [applicationFeeData, tutionFeeData, spantranFeeData, degreeEvaluationFeeData, applicationData]);

  const getAllFees = async (appData) => {
    const data = {
      ApplicationId: appData?.genInfo?.ApplicationId,
      IntakeId: appData?.programInfo?.IntekId,
      Nationality: appData?.genInfo?.Citizenship
    };
    setIsLoading(true);
  
    try {
      const res = await ApplicationService.forApplication(data);
      await getAllFeeDetails(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };
  
  const getAllFeeDetails = (feeData) => {
    if (feeData) {
      return Promise.all([
        new Promise((resolve) => {
          const applicationFee = feeData.find(fee => fee.FeeType === "Application Fee");
          if (applicationFee) setApplicationFeeData(applicationFee);
          resolve();
        }),
        new Promise((resolve) => {
          const spantranFee = feeData.find(fee => fee.FeeType === "Span Tran Fee");
          if (spantranFee) setSpantranFeeData(spantranFee);
          resolve();
        }),
        new Promise((resolve) => {
          const tutionFee = feeData.find(fee => fee.FeeType === "Tuition Fee");
          if (tutionFee) setTutionFeeData(tutionFee);
          resolve();
        }),
        new Promise((resolve) => {
          const degreeEvaluationFee = feeData.find(fee => fee.FeeType === "Degree Evaluation Fee");
          if (degreeEvaluationFee) setDegreeEvaluationFeeData(degreeEvaluationFee);
          resolve();
        }),
      ]);
    }
  };

  const getDepositedFeeList = (applicationId) => {
    setIsLoading(true);
    ApplicationService.getDepositedFee(applicationId)
      .then((res) => {
        getApplicationFeeDetails(res, applicationData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  }

  const openFeeModal = (data) => {
    setGetfeeDetails(data);
    setModalSize('md');
    setOpenModal(true)
  }

  const hidefeeModalhandler = () => {
    getDepositedFeeList(applicationData.genInfo.ApplicationId);
    setOpenModal(false)
    setModalSize('');
  }

  const getApplicationFeeDetails = (responseData) => {

    let allFeeDataList = responseData;

    allFeeDataList.map((item) => {
      if(item.FeeType.toLowerCase() == "span tran fee") {
        item.NewFeeDeposit = spanTranFee.currency + ' ' + item?.FeeDeposit;
      }
      else {
        item.NewFeeDeposit = applicationData?.programInfo?.InstCurrency + ' ' + item?.FeeDeposit;
      }
    });

    let applicationFeeList = responseData.filter((fee) => {
      return fee.FeeType.toLowerCase() == "application fee";
    });

    let spantranFeeList = responseData.filter((fee) => {
      return fee.FeeType.toLowerCase() == "span tran fee";
    });

    let tutionFeeList = responseData.filter((fee) => {
      return fee.FeeType.toLowerCase() == "tuition fee";
    });

    let degreeEvaluationFeeList = responseData.filter((fee) => {
      return fee.FeeType.toLowerCase() == "degree evaluation fee";
    });

    if (applicationFeeList.length == 0){
      let applicationFeeInfo = applicationFeeData;
      if (applicationFeeInfo) {
        if (applicationData?.programInfo?.InstCountry === 101 && applicationData?.genInfo?.MailingCountryName === "India") {
          setModeOfPaymentList(['BillDesk', 'Razorpay']);
        }
        else{
          applicationFeeInfo['modeofPayment'] = 'Stripe';
        }
        applicationFeeInfo.FeeDeposit = applicationFeeInfo.FeeAmount !== '' ? applicationFeeInfo.FeeAmount : applicationFeeInfo.FullAmount;
        applicationFeeInfo.DateofDeposite = new Date().toLocaleDateString('en-US');
        applicationFeeInfo.currency = applicationData?.programInfo?.InstCurrency || 'NA';
        applicationFeeInfo.ApplicationId = applicationData?.genInfo?.ApplicationId ? applicationData?.genInfo?.ApplicationId : '';
        if (applicationFeeInfo.FeeDeposit == 0) {
          applicationFeeInfo.RecTypeName = 'Completed'
        }
        if (!applicationFeeInfo.RecTypeName) {
          applicationFeeInfo.RecTypeName = 'Inprogress'
        }
        if (applicationFeeInfo.FeeDeposit != 0) {
          applicationFeeInfo.NewFeeDeposit = applicationData?.programInfo?.InstCurrency + ' ' + applicationFeeInfo.FeeDeposit;
        }
        allFeeDataList.unshift(applicationFeeInfo);
      }
    }

    if (tutionFeeList.length == 0){
      let tutionFeeInfo = tutionFeeData;
      if (tutionFeeInfo) {
        if (applicationData?.programInfo?.InstCountry === 101 && applicationData?.genInfo?.MailingCountryName === "India") {
          setModeOfPaymentList(['BillDesk', 'Razorpay']);
        }
        else{
          tutionFeeInfo['modeofPayment'] = 'Stripe';
        }
        tutionFeeInfo.FeeDeposit = tutionFeeInfo.FeeAmount !== '' ? tutionFeeInfo.FeeAmount : tutionFeeInfo.FullAmount;
        tutionFeeInfo.DateofDeposite = new Date().toLocaleDateString('en-US');
        tutionFeeInfo.currency = applicationData?.programInfo?.InstCurrency || 'NA';
        tutionFeeInfo.ApplicationId = applicationData?.genInfo?.ApplicationId ? applicationData?.genInfo?.ApplicationId : '';
        if (tutionFeeInfo.FeeDeposit == 0) {
          tutionFeeInfo.RecTypeName = 'Completed'
        }
        if (!tutionFeeInfo.RecTypeName) {
          tutionFeeInfo.RecTypeName = 'Inprogress'
        }
        if (tutionFeeInfo.FeeDeposit != 0) {
          tutionFeeInfo.NewFeeDeposit = applicationData?.programInfo?.InstCurrency + ' ' + tutionFeeInfo.FeeDeposit;
        }
        allFeeDataList.unshift(tutionFeeInfo);
      }
    }

    if (spantranFeeList.length == 0){
      let spantranFeeInfo = spantranFeeData;
      if (spantranFeeInfo) {
        if (applicationData?.programInfo?.InstCountry === 101 && applicationData?.genInfo?.MailingCountryName === "India") {
          setModeOfPaymentList(['BillDesk', 'Razorpay']);
        }
        else{
          spantranFeeInfo['modeofPayment'] = 'Stripe';
        }
        spantranFeeInfo.FeeDeposit = spantranFeeInfo.FeeAmount !== '' ? spantranFeeInfo.FeeAmount : spantranFeeInfo.FullAmount;
        spantranFeeInfo.DateofDeposite = new Date().toLocaleDateString('en-US');
        spantranFeeInfo.currency = applicationData?.programInfo?.InstCurrency || 'NA';
        spantranFeeInfo.ApplicationId = applicationData?.genInfo?.ApplicationId ? applicationData?.genInfo?.ApplicationId : '';
        if (spantranFeeInfo.FeeDeposit == 0) {
          spantranFeeInfo.RecTypeName = 'Completed'
        }
        if (!spantranFeeInfo.RecTypeName) {
          spantranFeeInfo.RecTypeName = 'Inprogress'
        }
        if (spantranFeeInfo.FeeDeposit != 0) {
          spantranFeeInfo.NewFeeDeposit = spanTranFee.currency + ' ' + spantranFeeInfo.FeeDeposit;
        }
        allFeeDataList.unshift(spantranFeeInfo);
      }
    }

    if (degreeEvaluationFeeList.length == 0){
      let degreeEvaluationInfo = degreeEvaluationFeeData;
      if (degreeEvaluationInfo) {
        if (applicationData?.programInfo?.InstCountry === 101 && applicationData?.genInfo?.MailingCountryName === "India") {
          setModeOfPaymentList(['BillDesk', 'Razorpay']);
        }
        else{
          degreeEvaluationInfo['modeofPayment'] = 'Stripe';
        }
        degreeEvaluationInfo.FeeDeposit = degreeEvaluationInfo.FeeAmount !== '' ? degreeEvaluationInfo.FeeAmount : degreeEvaluationInfo.FullAmount;
        degreeEvaluationInfo.DateofDeposite = new Date().toLocaleDateString('en-US');
        degreeEvaluationInfo.currency = applicationData?.programInfo?.InstCurrency || 'NA';
        degreeEvaluationInfo.ApplicationId = applicationData?.genInfo?.ApplicationId ? applicationData?.genInfo?.ApplicationId : '';
        if (degreeEvaluationInfo.FeeDeposit == 0) {
          degreeEvaluationInfo.RecTypeName = 'Completed'
        }
        if (!degreeEvaluationInfo.RecTypeName) {
          degreeEvaluationInfo.RecTypeName = 'Inprogress'
        }
        if (degreeEvaluationInfo.FeeDeposit != 0) {
          degreeEvaluationInfo.NewFeeDeposit = applicationData?.programInfo?.InstCurrency + ' ' + degreeEvaluationInfo.FeeDeposit;
        }
        allFeeDataList.unshift(degreeEvaluationInfo);
      }
    }

    setDepositFeeData(allFeeDataList);
  };

  const submitDocuments = (isDocumentORFeePending, modalType) => {
    if (isDocumentORFeePending && modalType === "Fees") {
      setOpenModal(false);
      setModalSize('sm');
      setSuccessMessage("Your payment was a success!");
      setPaymentSuccessModalIsVisible(true);
    }
  }

  const closefeePaymentModal = () => {
    setPaymentSuccessModalIsVisible(false);
    feeSuccessToastHandler();
  }

  const closeUploadRecipt = (value) => {
    setOpenUploadRecipt(false)
    if (value) {
      getDepositedFeeList(applicationData?.genInfo?.ApplicationId)
    }
  }

  const uploadReciptData = (value, data) => {
    if(data.FeeType ==='Span Tran Fee' || data.FeeType === 'Spantran Additional Fee' || data.FeeType === 'Degree Evaluation Fee'){
      return
    }else if(data.InvoiceId === 0){
      setIsUpdate(value)
      setUpdateFeeData(data)
      if(data.RecTypeName !== "Completed" && data.RecTypeName !== "Inprogress"){
        setOpenUploadRecipt(true)
      }
        
    }  else{
      return
    }
  }

  const addReciptData = (value) => {
      setIsUpdate(value)
      setOpenUploadRecipt(true)
  }


  const openConfirmation=(id)=>{
    setDepositId(id)
    setConfrimModalShow(true)
    setConfirmationText('Are you sure, you want delete uploaded reciept?')

  }
  const getConfirmation=()=>{
    setIsLoading(true)
    ApplicationService.deleteRecipt(depositId).then(res=>{
      toast.success(res?.message)
      setIsLoading(false)
      setConfrimModalShow(false)
      getDepositedFeeList(applicationData?.genInfo.ApplicationId)
    }).catch(error=>{
      toast.error(error?.message)
      setIsLoading(false)
    })

  }
  const onClose=()=>{
  setConfrimModalShow(false)
  }

  return (
    <>
      {openModal && (
        <SearchModal onShow={openModal} size={modalSize}>
          <FeePaymentModal onClose={hidefeeModalhandler} feeDetails={getfeeDetails} onSubmitPendingDocuments={submitDocuments} getModeofPayments={modeOfPaymentList}
            studentDetail={applicationData?.genInfo} />
        </SearchModal>
      )}
      {paymentSuccessModalIsVisible && (
        <ToastSuccessModal onShow={paymentSuccessModalIsVisible} size={modalSize} closeSuccessModal={closefeePaymentModal} successMessage={successMessage} />
      )}

      {openUploadRecipt && (
        <UploadRecipt dataList={depositFeeData} isUpdate={isUpdate} updateFeeData={updateFeeData} applicationId={applicationData?.genInfo?.ApplicationId} applicationData={applicationData} onShow={openUploadRecipt} intake={applicationData?.programInfo?.IntekId} country={applicationData?.genInfo?.Citizenship} onClose={closeUploadRecipt}></UploadRecipt>
      )}

{confrimModalShow && (
       <ConfirmationModal onShow={confrimModalShow} onClose={onClose}  btnText={"Yes"} 
       confirmationText={confirmationText} getConfirmation={getConfirmation}></ConfirmationModal>
)}

      <div className={classes.container}>
        <Table responsive className={classes.customTable}>
          <thead>
            <tr>
              {tableData.columns.map((column, index) => (
                <th key={index}>
                  <div className={classes.thAndIconContainer}>
                    <span className={classes.columnText}>{column}</span>
                  </div>
                </th>
              ))}
              <th>Refund</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {depositFeeData?.length>0 && depositFeeData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td onClick={() => { uploadReciptData(true, row) }}>{row?.DepositDate}</td>
                <td onClick={() => { uploadReciptData(true, row) }}>{row?.FeeType}</td>
                <td onClick={() => { uploadReciptData(true, row) }}>{row?.NewFeeDeposit}</td>
                <td onClick={() => { uploadReciptData(true, row) }}>{row?.FeeBasis}</td>
                <td onClick={() => { uploadReciptData(true, row) }}>{row?.PaymentMode}</td>
                <td onClick={() => { uploadReciptData(true, row) }}>{row?.Intake}</td>
                <td onClick={() => { uploadReciptData(true, row) }}>{row?.RecTypeName}</td>
                <td>
                  <span className={classes.actionBtn} >
                    <input disabled={!row?.isRefundable} type='checkbox' />
                  </span>
                </td>
                <td>
                  {(row.FeeType == 'Application Fee' || row.FeeType == 'Tuition Fee') && applicationData?.programInfo?.PartnerTypeId == 2 && row.RecTypeName !== "Completed" && row.FeeDeposit !== 0 && !row?.DepositDate && (
                    <button onClick={() => { openFeeModal(row) }} className='primary-button'>Make Payment</button>
                  )}
                  {(row.FeeType == 'Span Tran Fee' || row.FeeType == 'Degree Evaluation Fee') && row.RecTypeName !== "Completed" && row.FeeDeposit !== 0 && (
                    <button onClick={() => { openFeeModal(row) }} className='primary-button'>Make Payment</button>
                  )}

                  {(row.RecTypeName !== "Completed" && row.RecTypeName !== "Inprogress") && row.DepositId && (
                    <>
                    {row?.ReceiptPath &&  (
                     <a title='View' href={blobUrl + row?.ReceiptPath} target='blank_' >
                     <img className={classes['view-icon']} src={viewIcon} alt='' ></img>
                    </a>
                    )}

                    <button title='Delete' onClick={() => { openConfirmation(row?.DepositId) }} className={classes['cross-btn']}>X</button>
                    </>
                  )}

                </td>
              </tr>
            ))}

            {depositFeeData?.length===0 && (
              <tr >
                <td colSpan={9}><div className={classes['no-data']}>No Data Available</div> </td>
              </tr>
            )

            }
          </tbody>
        </Table>
        <div className='text-end mt-4'>
          <button onClick={() => { addReciptData(false) }} className='primary-button'>Add Recipt</button>
        </div>
      </div>

      {isLoading && (
        <Loader></Loader>
      )}

    </>

  );
};

export default FeeSection;
