import React, { useState } from 'react';
import classes from './GroupDiscussions.module.css';
import Share from "../../../../../Assets/Logo/share-icon.svg";
import Comment from "../../../../../Assets/Logo/comment.svg";
import GroupService from '../../../../../Services/GroupService';
import { toast } from 'react-toastify';
import CommentSection from './Comments';
import Loader from '../../../../GlobleShared/Loader/Loader';
import ReactQuill from "react-quill";
import SearchModal from '../../Search/SearchModal/SearchModal';
import Form from 'react-bootstrap/Form';
import croseIcon from '../../../../../Assets/Logo/crossred.svg';
import ModelForMore from '../../../../Common/Posts/ModelForMore';
import { useBehaviorSubject } from '../../../../GlobleShared/BehaviorSubject/BehaviorSubject';
import PostServices from '../../../../../Services/PostServices';
const GroupDiscussions = ({ discussions, postDashboardData, onAddDiscussion, moderatorPostFlag}) => {
  const [selectedDiscussionID, setSelectedDiscussionID] = useState(null);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [replyText, setReplyText] = useState("");  
  const [parentCommentID, setParentCommentID] = useState(0);
  const [replyingToclose, setReplyingToclose] = useState(null);
  const [addDiscussion, setAddDiscussion] = useState();
  const [title, setTitle] = useState('');
  const [detailDesc, setDetailDesc] = useState('');
  const [modelOpenForMore, setModelOpenForMore] = useState('');
  const [forReportModal, setForReportModal] = useState();
  const {groupType, setGroupType, pauseFlag}=useBehaviorSubject()
  const [commentId, setCommentId]=useState()
  const handleCloseModal = () => {
    setModelOpenForMore(''); 
  };

  const reportPost=(id)=>{
    let data={
      optType:3,
      sourceId:commentId,
      reportReasonId:id
    }
    setIsLoading(true)
    GroupService.reportPost(data).then(res=>{
      setIsLoading(false)
      toast.success(res?.message)
      setModelOpenForMore(''); 
      fetchComments(forReportModal)

    }).catch(error=>{
      setIsLoading(false)
      toast.error(error?.message)
    })
  }
  const actionConfirm=(id)=>{
    reportPost(id)
  }
  const handleOpenModal= (actionName, id, discussionID) =>{
    setForReportModal(discussionID);
    setModelOpenForMore(actionName)
    setCommentId(id)
  }
  const fetchComments = (DiscussionID) => {
    setIsLoading(true);
    GroupService.getDiscussionComments(DiscussionID)
      .then((res) => {
        setComments(res);
        setIsLoading(false);
        if(!moderatorPostFlag){
          onAddDiscussion();
        }
       
        toast.success(res?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('Failed to get Comments. Please try again.');
      });
  };

  const handleCommentClick = (discussionID) => {
    if (selectedDiscussionID === discussionID) {
      setShowComments(!showComments);
      handleCloseModal();
    } else {
      fetchComments(discussionID);
      setSelectedDiscussionID(discussionID);
      setShowComments(true);
      handleCloseModal();
    }
  };

  const handleAddComment = (DiscussionID, e) => {
    e.preventDefault();
    
    // Use replyText for replies, newComment for main comments
    const commentText = parentCommentID === 0 ? newComment : replyText;
    if (!commentText) {
      toast.error("Comment cannot be empty.");
      return;
    }

    const data = {
      Comments: commentText,
      DiscussionID: DiscussionID,
      ParentCommentID: parentCommentID,
    };

    addCommentAPI(data);
  };

  const addCommentAPI = (data) => {
    setIsLoading(true);

    GroupService.addDiscussionComments(data)
      .then((res) => {
        setNewComment('');    // Clear main comment input
        setReplyText('');      // Clear reply input
        setParentCommentID(0); // Reset ParentCommentID after adding comment
        setReplyingToclose(null);
        toast.success(res?.message);
        setComments(res);

        fetchComments(data.DiscussionID); // Refresh comments from server
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('Failed to add comment. Please try again.');
      });
  };

  const changeHandlerTitle = (value)=>{ 
    const sanitizedValue = value.trimStart();
    setTitle(sanitizedValue);
  }


  const handleAddDiscussion = () => {
    debugger
    if (!title || !detailDesc) {
      toast.error("Title and description cannot be empty.");
      return;
    }
    setIsLoading(true);

    const payload = {
      Title: title,
      DetailDesc: detailDesc,
      GroupID: postDashboardData.GroupID,
    };

    GroupService.addDiscussion(payload)
      .then((res) => {
        toast.success("Discussion added successfully.");
        setTitle('');       
        setDetailDesc('');  
        onAddDiscussion();
        setAddDiscussion(false);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Failed to add discussion. Please try again.");
        setIsLoading(false);
      });
  };
  return (
    <>
      {addDiscussion&&
      <SearchModal
      onShow={addDiscussion}
      size={'lg'}>
          <div className={classes['add-discussion-container']}>
            <div className={classes['croseIconContainer']}>
            <div className={classes['titleContainer']}><span>Add Discussion</span></div>
            <div onClick={()=>setAddDiscussion(false)}><img src={croseIcon} className={classes['croseIcon']}/></div>
            </div>
            <div className={classes['']}>
           <Form>
             <Form.Group >
               <Form.Label><span className={classes['titleText']}>Title</span></Form.Label>
             </Form.Group>
               <input type="text" value={title} onChange={(e)=>{changeHandlerTitle(e.target.value)}} className={`${classes['titleInputFelid']} mb-3`} />
           </Form>
            </div>
            <div className={classes['']}>  
              <Form>
                <Form.Group>
                <Form.Label><span className={classes['titleText']}>Description</span></Form.Label>
                <ReactQuill
                  value={detailDesc}
                  onChange={setDetailDesc}
                modules={{
                  toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, 
                    ],
                    ['clean']
                  ],
                }}
                formats={[
                  'header', 'font', 'size',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image', 'video'
                ]}
                placeholder="Create a discussion..."
              />
                </Form.Group>
                </Form>
             </div>
             <div className={classes['submitButton']}>
                <button type="button" class="primary-button" onClick={handleAddDiscussion}>Add</button>
            </div>
          </div>
      </SearchModal>}
      {(!groupType && !moderatorPostFlag && !pauseFlag) && (
        <div className={classes['add-discussion-button']}><button className="primary-button" onClick={()=>setAddDiscussion(true)}><span className='pe-2'>+</span>Add Discussion</button></div>
      )}
      
      {discussions?.length > 0 ? (
        <div className={classes["discussion-container"]}>
          {discussions.map((post) => (
            <div key={post.DiscussionID} className={classes["post-item"]}>
              {(modelOpenForMore && forReportModal=== post.DiscussionID) && (
                    <ModelForMore 
                    title={modelOpenForMore}
                    onClose={handleCloseModal}
                    confirm={actionConfirm}
                    ></ModelForMore>
                )}
              <h3 className={classes["post-title"]}>{post.Title}</h3>
              <div className={classes["post-description"]}
                    dangerouslySetInnerHTML={{ __html: post.DetailDesc }}>
              </div>
              <div className={classes["post-footer"]}>
                <span className={classes["post-comments"]} >
                <svg className={classes['pointer']} onClick={() => handleCommentClick(post.DiscussionID)} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2 0H2.8C1.2558 0 0 1.2706 0 2.833V19.831C0 21.3934 1.2558 22.6641 2.8 22.6641H7V28L15.7878 22.6641H25.2C26.7442 22.6641 28 21.3934 28 19.831V2.833C28 1.2706 26.7442 0 25.2 0ZM25.2 19.831H15.0122L9.8 22.9941V19.831H2.8V2.833H25.2V19.831Z" fill="#363636"/>
</svg>

                  {/* <img className={classes['pointer']} onClick={() => handleCommentClick(post.DiscussionID)} src={Comment} alt="" /> */}
                  {post.DiscussionCommentCnt}
                </span>
                {/* <span className={classes["post-share"]}>
                  <img className={classes['pointer']} src={Share} alt="" /> {"0"}
                </span> */}
              </div>
              {showComments && selectedDiscussionID === post.DiscussionID && (
                <CommentSection moderatorPostFlag={moderatorPostFlag}
                  handleOpenModal={handleOpenModal}
                  groupId={post.GroupID}
                  commentsData={comments}
                  discussionID={post.DiscussionID}
                  newComment={newComment}
                  setNewComment={setNewComment}
                  replyText={replyText}
                  setReplyText={setReplyText}
                  handleAddComment={handleAddComment}
                  handleAddReply={setParentCommentID}
                  setReplyingToclose={setReplyingToclose} 
                  handleHidePopup={fetchComments}
                />
              )}
              {isLoading && <Loader />}
            </div>
          ))}
        </div>
      ) : (
        <div className={classes["discussion-container"]}>
          <div className={`${classes["post-item"]} text-center`}>
            <h3 className={classes["post-title"]}>No Data Found</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default GroupDiscussions;
