import axiosInstance from "./Interceptor";

const ApplicationService = {
  getApplicationList: async () => {
    try {
      const response = await axiosInstance.get('/Application/list');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getApplicationDetails: async (applicationId) => {
    try {
      const response = await axiosInstance.get('/Application/'+applicationId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },


  addApplication: async (data) => {
    try {
      const response = await axiosInstance.post('/Application/Add', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getAllApplicationData: async (data) => {
    try {
      const response = await axiosInstance.post('/Application/ListDetailed', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  getAllApplicationOnline: async (data) => {
    try {
      const response = await axiosInstance.post('/Application/OnlineListDetailed', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  updateApplication: async (data) => {
    try {
      const response = await axiosInstance.put('/Application/update', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  deleteApplication: async (applicationId) => {
    try {
      const response = await axiosInstance.post('/Application/delete?ApplicationId='+applicationId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },


  getProgramList: async (data) => {
    try {
      const response = await axiosInstance.post('/Program/ForApplicationForDDLV1', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
  getProgramDetails: async (programId) => {
    try {
      const response = await axiosInstance.get('/Program/GetDetail?ProgramId='+programId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getProgramIntake: async (programId, applicationId, countryId) => {
    try {
      const response = await axiosInstance.get('/ProgramIntek/PromIntakeForApplication?ProgramId='+programId+ '&ApplicationId='+applicationId+ '&CountryId=' +countryId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getProgramMode: async (programId) => {
    try {
      const response = await axiosInstance.get('/Program/Mode?ProgramId='+programId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getProgramSpecialization: async (programId) => {
    try {
      const response = await axiosInstance.get('/Program/Specialization?ProgramId='+programId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },



  
  getInstituteList: async (studentId, programTypeID) => {
    try {
      const response = await axiosInstance.get('/Institute/ForApplicationV1?StudentId='+studentId+'&ProgramTypeId='+programTypeID);;
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getInstituteDetails: async (instituteId) => {
    try {
      const response = await axiosInstance.get('/Application/institution/'+instituteId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  

  getCountryList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/Country');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  getProvince: async (countryId) => {
    try {
      const response = await axiosInstance.get('/Misc/Province?Country='+countryId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getCity: async (provinceId) => {
    try {
      const response = await axiosInstance.get('/Misc/City?Province='+provinceId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getCountryCode: async () => {
    try {
      const response = await axiosInstance.get('/Misc/CountryCodeDDL');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getLanguageList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/Language');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getSubjectList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/Subject');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getDegreeList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/DegreeDDL');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getStreamList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/StreamForDDL');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },


  getRelationList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/RelationDDL');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  getReportReasonList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/Report-Reason');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  getAssignedCounsellor: async (StudentId,InstituteId) => {
    try {
      const response = await axiosInstance.get(`/Application/B2CCounsellorForApplication?StudentId=${StudentId}&InstituteId=${InstituteId}`);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getStudentData: async (studentId, programId) => {
    try {
      const response = await axiosInstance.get('/Application/GetStudent?ProgramId='+programId+ '&StudentId='+studentId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getStudentDataBySection: async (studentId) => {
    try {
      const response = await axiosInstance.get('/Student/getSectionWise?StudentId='+studentId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getApplicationByStudentId: async (studentId) => {
    try {
      const response = await axiosInstance.get('/Application/ListByStudent?StudentId='+studentId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

//
  getEducationLevelasync: async () => {
    try {
      const response = await axiosInstance.get('/Misc/EduLevels');
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },






  getEduLevelForDDLByApplication: async (data) => {
    try {
      const response = await axiosInstance.get(`/Student/StudentSchool/EduLevelForDDLByApplication?ParentType=${data.ParentType}&ParentId=${data.ParentId}&SchoolId=${data.SchoolId}`);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getSchoolDetailList: async (data) => {
    try {
      const response = await axiosInstance.get(`/Student/StudentSchool/List?ParentType=${data.ParentType}&ParentId=${data.ParentId}`);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
  getSchoolDetail: async (SchoolId) => {
    try {
      const response = await axiosInstance.get('/Student/StudentSchool/Get?SchoolId='+SchoolId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  deleteSchoolDetail: async (SchoolId) => {
    try {
      const response = await axiosInstance.delete('/Student/StudentSchool/Delete?SchoolId='+SchoolId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  addSchoolDetail: async (data) => {
    try {
      const response = await axiosInstance.post('/Student/StudentSchool/Add', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  updateSchoolDetail: async (data) => {
    try {
      const response = await axiosInstance.post('/Student/StudentSchool/Update', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },






  getGradingSchemeAsync: async (countryId) => {
    try {
      const response = await axiosInstance.get('/Misc/GradeSchemesByCountry?CountryId='+countryId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  countryDetailsByInstitutionId: async (institutionId) => {
    try {
      const response = await axiosInstance.get('/Institute/CountryDetail?InstitutionId='+institutionId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },


  programbackup: async (IntakeId) => {
    try {
      const response = await axiosInstance.get('/Application/ProgramForBackup_V1?intakeId='+IntakeId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  CheckInstitutionIntakeExists: async (data) => {
    try {
      const response = await axiosInstance.post('/Application/CheckInstitutionIntakeExists', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

 getCampusList: async (intakeId) => {
    try {
      const response = await axiosInstance.get('/ProgramIntek/CampusList?IntakeId='+intakeId);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },


  
 forApplication: async (data) => {
  try {
    const response = await axiosInstance.post('/Application/ProgramFee/ForApplication', data);
    return response.data;
  } catch (error) {
    throw error.response.data
  }
},

getProgramDocumentList: async (ApplicationId, ProgramId, countryId) => {
  try {
    const response = await axiosInstance.get('/Application/ProgramDocument/ListForApplication?ApplicationId=' + ApplicationId + '&ProgramId=' + ProgramId + '&CountryId=' + countryId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getProgramParentDocumentList: async (StudentId) => {
  try {
    const response = await axiosInstance.get('/Application/StudentDocument/ListForApplicationCreation?StudentId=' + StudentId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getStudentDetail: async (StudentId) => {
  try {
    const response = await axiosInstance.post('/Student/View?StudentId='+ StudentId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getUpdateGenInfo: async (data) => {
  try {
    const response = await axiosInstance.put('/Student/UpdateGenInfo', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},


udateEducation: async (data) => {
  try {
    const response = await axiosInstance.put('/Student/UpdateEduInfo', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

udateAppEducation: async (data) => {
  try {
    const response = await axiosInstance.put('/Application/UpdateEduInfo', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

udateTestScore: async (data) => {
  try {
    const response = await axiosInstance.put('/Student/UpdateTestScore', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

udateApplicationTestScore: async (data) => {
  try {
    const response = await axiosInstance.put('/Application/UpdateTestScore', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},


preferenceAdd: async (data) => {
  try {
    const response = await axiosInstance.post('/Application/PreferenceAdd', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

viewV1Application: async (applicationId) => {
  try {
    const response = await axiosInstance.get('/Application/ViewV1?ApplicationId=' + applicationId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

feeAndDocumentStatus: async (applicationId) => {
  try {
    const response = await axiosInstance.get('/Application/GetFeeAndDocumentStatus?ApplicationId=' + applicationId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

pendingDocumentsCount: async (applicationId) => {
  try {
    const response = await axiosInstance.get('/Application/DocumentIsPending?ApplicationId=' + applicationId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

submitApplication: async (applicationId) => {
  try {
    const response = await axiosInstance.post('/Application/Submit?ApplicationId=' + applicationId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},


getRetrieveDeleteApplication: async (type) => {
  try {
    const response = await axiosInstance.get('/Application/Retrieve/Application?Keyword='+type);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

retrieveApplication: async (data) => {
  try {
    const response = await axiosInstance.post('Application/Retrieve/retrieve', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},



getDeferralRequestList: async (type) => {
  try {
    const response = await axiosInstance.get('/DeferralRequest/List?Keyword='+type);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getRefundList: async (data) => {
  try {
    const response = await axiosInstance.post('/refund/ListV1',data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},
getCreditCardData: async (applicationId) => {
  try {
    const response = await axiosInstance.get('/Application/ApplicationCC/Get?ApplicationId=' + applicationId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

addCreditCardData: async (data) => {
  try {
    const response = await axiosInstance.post('/Application/ApplicationCC/Add', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getListForDeferApplication: async (id) => {
  try {
    const response = await axiosInstance.get('/ProgramIntek/ListForDeferApplication?ApplicationId='+id);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

addDeferApplication: async (data) => {
  try {
    const response = await axiosInstance.post('/Application/DeferralRequest/Add', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

GetSectionWiseWithPreference: async (applicationId) => {
  try {
    const response = await axiosInstance.get('/Application/GetSectionWiseWithPreference?ApplicationId=' + applicationId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

addActivity: async (data) => {
  try {
    const response = await axiosInstance.post('/Activity/Add', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getActivity: async (data) => {
  try {
    const response = await axiosInstance.post('/Activity/List', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getCouponList: async (data) => {
  try {
    const response = await axiosInstance.post('/Coupon/CouponAvailableList', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

getDepositedFee: async (applicationId) => {
  try {
    const response = await axiosInstance.get('/ApplicationFee/List?ApplicationId=' + applicationId);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

addFeeRecipt: async (data) => {
  try {
    const response = await axiosInstance.post('/ApplicationFee/Add', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

deleteRecipt: async (id) => {
  try {
    const response = await axiosInstance.post('ApplicationFee/Delete?DepositId='+id);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},

updateFeeRecipt: async (data) => {
  try {
    const response = await axiosInstance.put('/ApplicationFee/Update', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},


updateProgramInfo: async (data) => {
  try {
    const response = await axiosInstance.put('/Application/UpdateProgramInfo', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
},


}
export default ApplicationService;