import React, { useEffect, useRef, useState } from "react";
import classes from "./SubComponents.module.css";
import blockIcon from "../../.../../../../../../Assets/Logo/block-icon.svg";
import kingIcon from "../../.../../../../../../Assets/Logo/king.svg";
import secureIcon from "../../.../../../../../../Assets/Logo/secure.svg";
import turnModeIcon from "../../.../../../../../../Assets/Logo/turn_into_mode-icon.svg";
import disconnectIcon from "../../.../../../../../../Assets/Logo/disconnect-icon.svg";
import reportIcon from "../../.../../../../../../Assets/Logo/report-icon.svg";
import messageIcon from "../../.../../../../../../Assets/Logo/message-icon.svg";
import moreIcon from "../../.../../../../../../Assets/Logo/more-icon.svg";
import userPic from "../../.../../../../../../Assets/Images/profile_avatar.png";
import ConnectionService from "../../../../../../Services/ConnectionService";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChatWindow from "../../../../../GlobleShared/Chat/ChatWindow";
import useLocalStorage from "../../../../../GlobleShared/CustomHooks/useLocalStorage";
import useDebounce from "../../../../../GlobleShared/CustomHooks/useDebounce";

const ProfileCardInner = ({
  hidePopupBox,
  connectionId,
  imgSrc,
  name,
  description,
  isPopupVisible,
  onTogglePopup,
  onHidePopup,
  disconnectIsTrue,
  status,
  groupMembersFlag,
  onOpenChat,
  receiverUserId,
  senderUserId,
  refId,
  connectionUserId,
  connectionStatus
}) => {

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [connectionIdvalue, setconnectionIdvalue] = useState();
  const [statusId, setStatusId] = useState();
  const [btnTextName, setbtnTextName] = useState();
  const [confirmationText, setConfirmationText] = useState('')
  const [isLoading, setLsLoading] = useState(false);
  const refDiv = useRef(null)
  const navigate = useNavigate()
  const [userDetail] = useLocalStorage('userDetail');
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [profilePhoto, setProfilePhoto] = useState();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if(imgSrc){
        const getProfilePicPath = blobUrl + imgSrc;
        setProfilePhoto(getProfilePicPath);
      }
    }
  }, [imgSrc]);

  const getConfirme = () => {
    statusUpdate()
  }
  const showModalhandler = (value, status, name) => {
    setModalIsVisible(true);
    setconnectionIdvalue(value);
    setStatusId(status)
    setbtnTextName(name)
    if (status === 4) {
      setConfirmationText('Are you sure, you want to report the content of the selected contact?')
    }

    if (status === 5) {
      setConfirmationText('Are you sure, you want to block the content of the selected contact?')
    }

    if (status === 2) {
      setConfirmationText('Are you sure, you want to unblock the content of the selected contact?')
    }

    if (status === 6) {
      setConfirmationText('Are you sure, you want to remove the selected contact?')
    }

  }
  const hideModalhandler = (value) => {
    setModalIsVisible(false);
  }

  const statusUpdate = () => {
    let successMsg;
    let data = {
      connectionId: connectionIdvalue,
      connectionStatusId: statusId
    };
    setLsLoading(true);
    ConnectionService.updateConnection(data).then(res => {
      setModalIsVisible(false);
      setLsLoading(false);
      if (res?.message.includes("[User]")) {
        successMsg = res?.message.replace("[User]", name);
      } else if (res?.message && statusId === 2) {
        successMsg = `You have unblocked ${name}. They can now interact with you again!`;
      } else if (res?.message && statusId === 4) {
        successMsg = res?.message; // Assuming the backend returns the report success message here
      }
      toast.success(successMsg);
      onHidePopup();
    }).catch(error => {
      setLsLoading(false);
      toast.error(error?.message);
    });
  };

  const viewProfileSection = () => {
    // window.open('/member/profileView', '_blank');
    const userId = userDetail.user_id;
    let profileUrl = ``;
    if (userId === senderUserId) {
      // profileUrl = `/member/profileView/${receiverUserId}`;
      profileUrl = `/member/profileView?userId=${receiverUserId}&studentId=${refId}`;
    }
    else {
      // profileUrl = `/member/profileView/${senderUserId}`;
      profileUrl = `/member/profileView?userId=${senderUserId}&studentId=${refId}`;
    }
    window.open(profileUrl, '_blank');
  }

  const handleClickOutside = (event) => {
    if (refDiv) {
      if (refDiv.current && !refDiv.current.contains(event.target)) {
        hidePopupBox()
      }
    }
  }
  document.addEventListener('mousedown', handleClickOutside);
  return (
    <div className={classes.card}>
      <div className={classes.card_content}>
        <div className={(connectionStatus === "Block Contact") ? classes.card_coverImage : classes[""]}>
          <img onClick={viewProfileSection}
            src={profilePhoto ? profilePhoto : userPic}
            alt=""
            className={
              disconnectIsTrue
                ? classes.disconnectIsTrue + " " + classes.profileImage
                : classes.profileImage
            }
          />
        </div>
        <div className={classes.card_info}>
          {connectionUserId && (
            <div className={classes["connectionUser-name"]}>{name}
              {groupMembersFlag && (<span><img src={status} alt="" /></span>)}
            </div>
          )}
          {!connectionUserId && (
           <span onClick={viewProfileSection} className={classes.name}>{name}
              {groupMembersFlag && (<span><img src={status} alt="" /></span>)}
            </span>
          )}
          <div className={classes.description} title={description}>{description}</div>
        </div>
      </div>
      {!connectionUserId && (
        <div className={classes.buttons}>
          <div className={classes['menu-icon']} onClick={onTogglePopup}>
            <img
              className={classes.moreBtn}
              src={moreIcon}
              alt="More options"
            />
            {isPopupVisible && (
              <>
                <div ref={refDiv} className={classes.popup}>
                  <div className={classes.popupOption} onClick={() => { showModalhandler(connectionId, 6, 'Remove') }}>
                    <img src={disconnectIcon} alt="Disconnect" />
                    {groupMembersFlag ? (<>Disconnect</>) : (<>Disconnect</>)}
                  </div>
                  {(connectionStatus === "Block Contact") ? (
                    <div className={classes.popupOption} onClick={() => { showModalhandler(connectionId, 2, 'Unblock') }}>
                      <img src={blockIcon} alt="Unblock" />Unblock
                    </div>
                  ) :
                    (
                      <div className={classes.popupOption} onClick={() => { showModalhandler(connectionId, 5, 'Block') }}>
                        <img src={blockIcon} alt="Block" />Block
                      </div>
                    )
                  }
                  <div className={classes.popupOption} onClick={() => { showModalhandler(connectionId, 4, 'Report') }}>
                    <img src={reportIcon} alt="Report" />Report
                  </div>
                  {groupMembersFlag && (
                    <div className={classes.popupOption} onClick={onHidePopup}>
                      <img src={turnModeIcon} alt="Turn into Mod" />Turn into Mod
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div>
            <img onClick={onOpenChat}
              className={classes.messageBtn}
              src={messageIcon}
              alt="Send message"
            />
          </div>
        </div>
      )}

      {modalIsVisible && (
        <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible}
          getConfirmation={getConfirme}>
        </ConfirmationModal>
      )}
      {isLoading && (
        <Loader></Loader>
      )}
    </div>
  );
};

const SubConnections = ({groupMembersFlag, studentId, searchInput, isSearch, setIsSearch}) => {
  const [profiles, setProfiles] = useState([]);

  const [activePopup, setActivePopup] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLsLoading] = useState(false);
  const initialized = useRef(false)
  const [chatWindows, setChatWindows] = useState([]);
  const debouncedSearchInput = useDebounce(searchInput, 300);

  const handleTogglePopup = (id) => {
    setActivePopup((prevId) => (prevId === id ? null : id));
  };


//   // filtered data
//   const filteredData = profiles.filter(item =>
//     item.name.toLowerCase().includes(searchInput.toLowerCase())
// );

  const handleHidePopup = () => {
    refreshConnection(page,limit)
  };


  const getConnection=(pageValue)=>{
    let data = {}
    if(studentId){
      data={
        limit: limit,
        page: pageValue,
        paginated: paginated,
        searchTerm: "",
        studentId: studentId,
      }
    }
    else{
      data={
        limit: limit,
        page: pageValue,
        paginated: paginated,
        searchTerm: ""
      }
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...profiles,...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }


  const refreshConnection=(pageNum,limit, searchInput)=>{
    let data = {}
    if (studentId) {
      data = {
        limit: pageNum * limit,
        page: 1,
        paginated: paginated,
        searchTerm: "",
        studentId: studentId,
      }
    }
    else {
      data = {
        limit: pageNum * limit,
        page: 1,
        paginated: paginated,
        searchTerm: searchInput
      }
    }

    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getConnection(page);
    }

    if ((debouncedSearchInput !== null) && debouncedSearchInput.length >= 3) {
      refreshConnection(page, limit, debouncedSearchInput)
    } 
    else if ((debouncedSearchInput !== null) && (debouncedSearchInput.length <= 2 && isSearch)) {
      refreshConnection(page, limit, "");
      setIsSearch(false);
    }
  }, [debouncedSearchInput]);

  const getdataReload=()=>{
    refreshConnection(page,limit)
  }
  const viewMore=()=>{
    getConnection(page+1)
    setPage(page+1)

  }

  const hidePopup=()=>{
    setActivePopup()
  }

  const handleOpenChat = (profile) => {
    setChatWindows((prevChats) => {
      if (!prevChats.some((chat) => chat.userConnectionId === profile.userConnectionId)) {
        return [...prevChats, { ...profile, isMinimized: false }];
      }
      return prevChats;
    });
  };
  const handleCloseChat = (id) => {
    setChatWindows((prevChats) => prevChats.filter((chat) => chat.userConnectionId !== id));
  };

  const handleMinimizeChat = (id) => {
    setChatWindows((prevChats) => prevChats.map((chat) => (chat?.userConnectionId === id ? { ...chat, isMinimized: !chat.isMinimized } : chat)));
  };

  return (
    <div >
      {profiles.length>0 && (
        <div className={groupMembersFlag ? classes.groupMembersContainer : studentId ? classes["userConnection-profile-container"] : classes.container}>
            {profiles.map((profile, index) => (
              <ProfileCardInner
              key={profile?.userConnectionId}
              connectionId={profile?.userConnectionId}
              backgroundImage={profile?.profileBGImage}
              imgSrc={profile?.profileImage}
              name={profile?.displayName}
              description={profile?.aboutUs}
              connections={profile?.connections}
              connectionStatus={profile?.connectionStatus}
              isPopupVisible={activePopup === profile?.userConnectionId}
              onTogglePopup={() => handleTogglePopup(profile?.userConnectionId)}
              onHidePopup={handleHidePopup}
              onOpenChat={() => handleOpenChat(profile)}
              disconnectIsTrue={false}
              index={index}
              groupMembersFlag= {groupMembersFlag}
              getConfirmationValue={getdataReload}
              hidePopupBox={hidePopup}
              receiverUserId={profile?.receiverUserId}
              senderUserId={profile?.senderUserId}
              refId={profile?.studentId}
              connectionUserId={studentId}
              />
            ))}
              <div className={classes.chatContainer}>
          <div className={`${classes.forChatScreen} row`}>
            <div className="col-md-3"></div>
            {chatWindows.slice(0, 2).map((chat, index) => (
              <div
                key={chat?.userConnectionId}
                className={`col-md-4`}>
                <ChatWindow
                  id={chat?.userConnectionId}
                  name={chat?.displayName}
                  profile={chat?.profileImage}
                  onClose={() => handleCloseChat(chat?.userConnectionId)}
                  onMinimize={() => handleMinimizeChat(chat?.userConnectionId)}
                  isMinimized={chat.isMinimized}
                />
              </div>
            ))}
            <div className="col-md-1">
              {chatWindows.map((chat) => (
                <div key={chat?.userConnectionId}>
                  <img
                    className={classes.profilePicBottom}
                    src={chat?.profileImage}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
           </div>
      )}
            <div>
                 {(totalCount!==profiles?.length && profiles.length>0 && !isSearch) && (
                <div className="text-center mt-2 mb-2">
                <button onClick={viewMore} className={classes['view-more']}>View more</button>
                  </div>
              )}
            </div>
      {profiles.length===0 && (
        <div className={classes['no-data']}>
          <p> No Connection Found</p>
          </div>
      ) }

        {isLoading && (
        <Loader></Loader>
      )}
     
    </div>
  );
};
export default SubConnections;
