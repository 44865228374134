import axiosInstance from "./Interceptor";

const ProgramSearchService = {
  getEducationLevel: async (instCountryId, programTypeId) => {
    try {
      const response = await axiosInstance.get(`/search/educationLevel/${instCountryId}/${programTypeId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getAllInstitutes: async () => {
    try {
      const response = await axiosInstance.get('/search/institute');
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getInstituteCountry: async (isScholarships) => {
    try {
      const response = await axiosInstance.get(`/search/instituteCountry/${isScholarships}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getInstitutionInfo: async (searchCriteria) => {
    try {
      const response = await axiosInstance.post('/search/institution', searchCriteria);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getProgramInfo: async (searchCriteria) => {
    try {
      const response = await axiosInstance.post('/search/program', searchCriteria);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getProgramDiscipline: async (instCountryId, programTypeId, edulevelId) => {
    try {
      const response = await axiosInstance.get(`/search/programDesipline/${instCountryId}/${programTypeId}/${edulevelId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getProgramType: async (instCountryId) => {
    try {
      const response = await axiosInstance.get(`/search/programType/${instCountryId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  getScholarshipInfo: async (scholarshipSearchRequest) => {
    try {
      const response = await axiosInstance.post('/search/scholarshipInfo', scholarshipSearchRequest);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },


  getApplicationProgramDetails: async (programId) => {
    try {
      const response = await axiosInstance.get('/Program/GetDetail?ProgramId=' + programId);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },


  getProgramListByInstAndEduLevel: async (data) => {
    try {
      const response = await axiosInstance.post('/Program/ListByInstAndEduLevel',data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },



  searchProgramForAgentByFilterNew: async (payload) => {
    try {
      const response = await axiosInstance.post('/Reports/SearchProgramForAgentByFilterNew',payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  getprogramsearch: async (payload) => {
    try {
      const response = await axiosInstance.post('/Misc/InstPageListParam1V3',payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  getInstitutionDetails: async (instCountryId) => {
    try {
        const response = await axiosInstance.post('/search/InstitutionDDL', { InstCountry: instCountryId });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
},
};

export default ProgramSearchService;