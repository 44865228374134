import React, { useState, useRef,  } from 'react';
import classes from "./UploadApplicationDocument.module.css"
import { Button, Form,  } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import corssIcon from "../../../../Assets/Logo/crossred.svg";
import corssIcon1 from "../../../../Assets/Logo/red-2-cross-icon.svg";
import uploadArrow from "../../../../Assets/Logo/uploadArrow.svg";
import infoICon from "../../../../Assets/Logo/info-iconBlack.svg";
import { toast } from 'react-toastify';
import CommonServices from '../../../../Services/CommonServices';
import SearchModal from '../../../Pages/Home/Search/SearchModal/SearchModal';
import Loader from '../../Loader/Loader';

const UploadApplicationDoucment = ({data, onShow, onClose, getConfirmation}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDocumentName, setSelectedDocumentName] = useState(''); // State for selected document name
  const fileInputRef = useRef(null); 
  const [filePath, setFilePath] = useState();
  const [fileName, setFileName] = useState(data?.DocPath);
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading]=useState(false)
  const blobUrl=process.env.REACT_APP_URL_PATH


  const handleFileChange = (event) => {
    const fileData=event.target.files[0]
     setFile(event.target.files[0]) ;
    if(fileData.type ==='image/png' || fileData.type ==='image/jpg' ||  fileData.type ==='image/jpeg' || fileData.type ==='application/pdf'){
      setFileName(fileData?.name);
      fileInputRef.current.value = null;
    }else{
      toast.error('Supported file format is :JPG, JPEG, PNG and PDF');
      fileInputRef.current.value = null;
      return;
    }

  };

 const uploadFile=()=>{
  const formData = new FormData();
  formData.append("file", file);

  setIsLoading(true)
    CommonServices.uploadFile(formData).then((res) => {
      setFilePath(res?.fileLocation);
      setIsLoading(false)
      getConfirmation(res?.fileLocation)
      fileInputRef.current.value = ''  
      setFileName('')
      setFile(null);


    }).catch(error=>{
      setIsLoading(false)
      toast.error(error?.message)
    });
  }


  const clearSelectedFile = () => {
    setFileName('')
    setFile(null);
    fileInputRef.current.value = ''
  };

  const handleUploadBoxClick = () => {
    fileInputRef.current.click();
  };
  const close=()=>{
    setFileName('')
    setFile(null);
    fileInputRef.current.value = ''
    onClose()
  }


  return (
    <div className={classes.container}> 
      <SearchModal
        onShow={onShow}
        size="lg"
      >  
        <div className={classes.ModelContainer}>
          <div className={`${classes.headerContainer}`}>
          <div className={classes.title}>Upload Document</div>
            <img
              title="Close"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={close}
            />
          </div>
          <div className="container">
            
            <Form.Select aria-label="Default select example" disabled>
              <option>{data?.DocumentName}</option>
            </Form.Select>
            <div>
              <div className='d-flex justify-content-center'>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <div className={classes.uploadBoxContainer}
                  onClick={handleUploadBoxClick}>
                  <img src={uploadArrow} alt="" />
                  <span className='ps-2'>
                   Choose a file or drag it here.
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center m-2">
                {fileName && (
                  <div className='d-flex align-items-center'>
                    {fileName}
                    <img
                    title='Remove'
                      src={corssIcon1}
                      alt="Remove"
                      className='ms-2'
                      style={{ cursor: 'pointer' }}
                      onClick={clearSelectedFile}
                    />
                  </div>
                )}
              </div>
              <div>
                <Alert key={`warning`} variant={`warning`}>
                  <div><img src={infoICon} className="me-1" /><span>Please use document name as a file name. </span></div>
                  <ul className="ms-2">
                    <li>Acceptable file formats: JPG, JPEG, PNG, and PDF.</li>
                    <li>File size must be less than 5 MB.</li>
                  </ul>
                </Alert>
              </div>
            </div>
          </div>
          <div className={classes.btnContainer}>
            <Button disabled={!file}
              className={`${classes.btnUpload}`}
              onClick={uploadFile}>
              Upload
            </Button>
          </div>
        </div> 
      </SearchModal>
      {isLoading && (
        <Loader></Loader>
      )}
      
    </div>
  )
}

export default UploadApplicationDoucment;