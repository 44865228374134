import React, { useState } from "react";
import classes from "./Profile.module.css";
import profileIcon from "../../../../../../Assets/Logo/profile-icon.svg";
import StudentServices from "../../../../../../Services/StudentServices";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";

const ProfileModel = ({ onProfileImageUpdated, onClose }) => {
  const [tempBackgroundImage, setTempBackgroundImage] = useState(null);
  const [tempProfileImage, setTempProfileImage] = useState(null);
  const [backgroundImageFile, setBackgroundImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const MAX_FILE_SIZE = 10 * 1024 * 1024; 

const allowedExtensions = ['.png', '.jpg', '.jpeg'];
  const handleBackgroundImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const filename = file.name;
      const extension = '.' + filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();  
      if (!allowedExtensions.includes(extension)) {
        toast.error('Supported Formats: JPG, JPEG, and PNG.');
        event.target.value = '';
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        toast.error("File size exceeds 10 MB. Please upload a smaller file.");
        event.target.value = "";
        return; // Prevent further execution
    }
      const imageUrl = URL.createObjectURL(file);
      setTempBackgroundImage(imageUrl);
      setBackgroundImageFile(file);
    }
  };

  const handleProfileImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const filename = file.name;
      // const extension = '.' + filename.split('.').pop().toLowerCase();
      const extension = '.' + filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();  
      // Check the file extension
      if (!allowedExtensions.includes(extension)) {
        toast.error('Supported Formats: JPG, JPEG, and PNG.');
        event.target.value = '';
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        toast.error("File size exceeds 10 MB. Please upload a smaller file.");
        event.target.value = "";
        return; // Prevent further execution
    }
      const imageUrl = URL.createObjectURL(file);
      setTempProfileImage(imageUrl);
      setProfileImageFile(file);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    
    try {
      // Validate background image file size
    if (backgroundImageFile && backgroundImageFile.size > MAX_FILE_SIZE) {
      toast.error("Background image file size exceeds 10 MB. Please upload a smaller file.");
      setIsLoading(false);
      return;
    }

    // Validate profile image file size
    if (profileImageFile && profileImageFile.size > MAX_FILE_SIZE) {
      toast.error("Profile image file size exceeds 10 MB. Please upload a smaller file.");
      setIsLoading(false);
      return;
    }
      if (backgroundImageFile) {
        const uploadResponse = await StudentServices.userService(
          backgroundImageFile
        );
        const data = {
          imagePath: uploadResponse.fileLocation,
          isBgImage: 1,
        };
        await StudentServices.addOrUpdateProfilePic(data);
        onProfileImageUpdated(uploadResponse.fileLocation);
        toast.success("Background image updated successfully!");
        if (!profileImageFile) {
          onClose();
        }
      }
      if (profileImageFile) {
        const uploadResponse = await StudentServices.userService(
          profileImageFile
        );
        const data = {
          imagePath: uploadResponse.fileLocation,
          isBgImage: 0,
        };
        await StudentServices.addOrUpdateProfilePic(data);
        onProfileImageUpdated(uploadResponse.fileLocation);
        toast.success("Profile image updated successfully!");
        onClose();
      }
    } catch (error) {
      console.error(
        "Error updating profile picture or background image:",
        error
      );
      toast.error(
        error?.message || "Error updating profile picture or background image"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={classes.container}>
        <div>
                <div
          className={classes.backgroundImgContainer}
          style={{
            backgroundImage: `url(${tempBackgroundImage || ""})`,
            cursor: tempBackgroundImage ? "pointer" : "default"
          }}
          onClick={() => {
            if (tempBackgroundImage) {
              document.getElementById("backgroundImageInput").click();
            }
          }}
        >
            <div></div>
            <div className={classes.backgroundImgMiniContainer}>
              <span>
              <div className={classes["tooltip-container"]}>

                {!tempBackgroundImage && (
                  <img
                    src={profileIcon}
                    alt=""
                    onClick={() =>
                      document.getElementById("backgroundImageInput").click()
                    }
                    className={classes.backgroundImgMiniContainerImage}
                  />
                )}
                {/* <span className={classes["tooltip"]}>upload image</span> */}
                </div>
                <input
                  type="file"
                  id="backgroundImageInput"
                  style={{ display: "none" }}
                  onChange={handleBackgroundImageUpload}
                />
              </span>
              {!tempBackgroundImage && (
                <div className={classes.textSizeAndColor}>Background</div>
              )}
            </div>
            <div></div>
          </div>
          <div className="mt-4">
            <div className={classes.profileImgContainer}>
              <div className={classes.profileImgDesign}>
                <div></div>
                <div className={classes.circleICon}>
                  <span>
                  <div className={classes["tooltip-container"]}>
                    
                    <img
                      src={tempProfileImage ? tempProfileImage : profileIcon}
                      alt=""
                      onClick={() =>
                        document.getElementById("profileImageInput").click()
                      }
                      className={
                        tempProfileImage
                          ? classes.tempProfileImage
                          : classes.profileIcon
                      }
                    />
                    {/* <span className={classes["tooltip"]}>upload image</span> */}
                    </div>

                    <input
                      type="file"
                      id="profileImageInput"
                      style={{ display: "none" }}
                      onChange={handleProfileImageUpload}
                    />
                  </span>
                  {!tempProfileImage && (
                    <div className={classes.textSizeAndColor}>Profile</div>
                  )}
                </div>
                <div></div>
              </div>
            </div>
            <center>
              <div className={classes.extraTextSizeAndColor}>
                Upload your profile and background image
              </div>
            </center>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          <div>
            <button
              className={classes.buttonSubmit + " " + "primary-button"}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileModel;