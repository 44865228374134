import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import classes from "./UploadRecipt.module.css";
import { toast } from "react-toastify";
import SearchModal from "../../../../Search/SearchModal/SearchModal";
import corssIcon from "../../../../../../../Assets/Logo/crossred.svg";
import corssIconRed from "../../../../../../../Assets/Logo/crossred.svg";
import Loader from "../../../../../../GlobleShared/Loader/Loader";
import CommonServices from "../../../../../../../Services/CommonServices";
import ApplicationService from "../../../../../../../Services/ApplicationServices";
import UploadDocument from "../../../../../../GlobleShared/UploadDocument/UploadDocument";
import CreditCardModal from "../../CreditCardSection/CreditCardModal/CreditCardModal";
const modeOfPaymentMaster = [
    {
      title: "Application Fee Waiver",
      value: "Application fee waiver",
      partnerTypeId: 1,
    },
    { title: "Bank Transfer", value: "Bank Transfer", partnerTypeId: 1 },
    { title: "Credit Card", value: "Credit Card", partnerTypeId: 1 },
    {
      title: "Direct in College",
      value: "Direct in College",
      partnerTypeId: 1,
    },
    { title: "EPBC", value: "EPBC", partnerTypeId: 1 },
    { title: "Flywire Receipt", value: "FlywireReceipt", partnerTypeId: 1 },
    { title: "Flywire", value: "Flywire", partnerTypeId: 2 },
    { title: "Forex", value: "Forex", partnerTypeId: 1 },
    { title: "Live Wire", value: "Live Wire", partnerTypeId: 1 },
    { title: "Paymytuition", value: "Paymytuition", partnerTypeId: 1 },
    { title: "PAYPAL", value: "PAYPAL", partnerTypeId: 1 },
    { title: "Simple Pay", value: "Simple Pay", partnerTypeId: 1 },
    { title: "Stripe", value: "Stripe", partnerTypeId: 2 },
    { title: "Voucher Code", value: "Voucher Code", partnerTypeId: 1 },
    { title: "Western Union", value: "Western Union", partnerTypeId: 1 }
]

const UploadRecipt = ({ applicationId, dataList, applicationData, onClose, onShow, intake, country, isUpdate, updateFeeData }) => {
  const initial = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date();
  const minDate = currentDate.toISOString().split("T")[0];
  const [reciptList, setReciptList] = useState([]);
  const [feeTypeList, setFeeTypeList] = useState([]);
  const [feeAmount, setFeeAmount]=useState('')
  const [openModal, setOpenModal]=useState(false)
  const receiptTypeName= updateFeeData?.RecTypeName? updateFeeData?.RecTypeName:"";
  const [makeDisableComplete, setMakeDisableComplete] = useState(false)
  const [makeDisable, setMakeDisable]=useState(false)
  const [modeOfPay, setModeOfPay]=useState(false)
  const [modeOfMaymentList, setModeOfMaymentList]=useState(modeOfPaymentMaster)

  const [formData, setFormData] = useState({
    DepositId: 0,
    ApplicationId: applicationId,
    IntakeId: intake,
    Nationality: country,
    SemesterNo: "",
    FeeDeposit: "",
    DepositDate: minDate,
    ReceiptPath: "",
    FeeType: "",
    FeeBasis: "",
    ReceiptType: "",
    PaymentMode: "",
  });


  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if(event.target.name==='FeeType'){
      const fee=  feeTypeList.filter(item=>item.FeeType===event.target.value)
        setFormData((prevData) => ({
            ...prevData,
            FeeDeposit: fee[0]?.FullAmount,
            FeeBasis: fee[0]?.FeeBasis,
          }));
    }

    validateForm({ ...formData, [name]: value });
  };

  const getReciptList = () => {
    CommonServices.getRecipt().then((res) => {
      setReciptList(res);
      if(isUpdate){
        const data =res.filter(item=>item?.RecTypeName===updateFeeData?.RecTypeName)
        editMode(data[0]?.RecTypeId)
      }
    });
  };

  const getFeeType = () => {
    let data = {
      IntakeId: intake,
      Nationality: country,
      ApplicationId: applicationId,
    };
    setIsLoading(true);
    ApplicationService.forApplication(data).then((res) => {
      setIsLoading(false);
      if(isUpdate){
        setFeeTypeList(res);
        res.map(item=>{
          if(item.FeeType === 'Span Tran Fee'){
            setMakeDisable(true) ;
        }
        if(item.FeeType === 'Tuition Fee'){
         
          setModeOfPay(true);
        }
        })
        setMakeDisableComplete(receiptTypeName==="Completed")

      }

      if(!isUpdate){
        const list = res.filter(item => {
          return !dataList.some(data => item?.FeeType === data.FeeType);
        });
        setFeeTypeList(list)
      }
 
    }).catch(error=>{
      setIsLoading(false);
    });
  };

  const validateForm = (formDataValue) => {
    const errors = {};
    const { FeeType, PaymentMode, DepositDate, ReceiptType } = formDataValue;

    if (!DepositDate) errors.DepositDate = "Deposit date is required.";
    if (!PaymentMode) errors.PaymentMode = "Payment mode is required.";
    if (!FeeType) errors.FeeType = "Fee type is required.";
      if (!ReceiptType) errors.ReceiptType = "Receipt type is required.";
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const addData = () => {
    if (!validateForm(formData)) {
      return;
    }

    setIsLoading(true);
    ApplicationService.addFeeRecipt(formData)
        .then((res) => {
            setIsLoading(false);
            toast.success('Fee details added successfully.');
            onClose(true)
        })
        .catch((error) => {
            setIsLoading(false);
            toast.error(error?.messages);
        });
  };

  const updateData = () => {
    if (!validateForm(formData)) {
      return;
    }

    setIsLoading(true);
    ApplicationService.updateFeeRecipt(formData)
        .then((res) => {
            setIsLoading(false);
            toast.success('Fee details updated successfully.');
            onClose(true)
        })
        .catch((error) => {
            setIsLoading(false);
            toast.error(error?.messages);
        });
  };

  const uploadFile=(file)=>{
    setFormData((prevData) => ({
        ...prevData,
        ReceiptPath: file,

      }));
  }
  const close = () => {
    onClose();
  };

  const editMode=(recType)=>{
    
    setFormData({
        DepositId: updateFeeData?.DepositId,
        DepositDate:updateFeeData?.DepositDate,
        FeeDeposit: updateFeeData?.FeeDeposit,
        SemesterNo: updateFeeData?.SemesterNo,
        InvoiceId: updateFeeData?.InvoiceId,
        ReceiptPath: updateFeeData?.ReceiptPath,
        FeeType: updateFeeData?.FeeType,
        PaymentMode: updateFeeData?.PaymentMode,
        ReceiptType: recType,
        FeeBasis:updateFeeData?.FeeBasis,
        UploadDate: '',
        ApplicationId: applicationId
    })
  }

  const closeModal =()=>{
    setOpenModal(false)

  }

  const opemCreditCard=(value)=>{
    if(value==='Credit Card'){
      setOpenModal(true)
    }
    
  }

  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      getFeeType();
      getReciptList();
      if(Number(applicationData?.programInfo?.InstCountry) === 101 && (applicationData?.genInfo?.MaillingCountryName === "India")){
        modeOfPaymentMaster.splice(2, 0, { title: "BillDesk", value: "BillDesk", partnerTypeId: 2 })
        modeOfPaymentMaster.splice(11, 0, { title: "Razorpay", value: "Razorpay", partnerTypeId: 2 })
        setModeOfMaymentList(modeOfPaymentMaster)
      }
    }
  }, []);


  return (
    <>
     {openModal && (
      <CreditCardModal  applicationId={applicationId} onShow={openModal} onClose={closeModal}></CreditCardModal>
     )}
      <SearchModal onShow={onShow} size="lg">
        <div className={classes.ModelContainer}>
          <div className={`${classes.headerContainer}`}>
            <div className={classes["header"]}>Fee Details</div>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIconRed}
              alt=""
              onClick={close}
            />
          </div>
          <div className={classes['div-heigth']}>
          <div className="row">
            {/* Card Type */}
            <div className="col-md-6 form-group">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>
                  Fee Type <span className={classes["required"]}>*</span>
                </Form.Label>
                <Form.Select
                disabled={isUpdate || makeDisable || ((formData.PaymentMode==='Stripe'||formData.PaymentMode==='BillDesk'||formData.PaymentMode==='Razorpay') && makeDisableComplete )}
                  className={`form-control`}
                  aria-label="Fee Type"
                  name="FeeType"
                  value={formData?.FeeType}
                  onChange={handleChange}
                 
                >
                  <option disabled value="">
                    Select Fee Type
                  </option>
                  {feeTypeList.map((item) => (
                    <option value={item?.FeeType}>{item?.FeeType}</option>
                  ))}
                </Form.Select>
                {formErrors.FeeType && (
                  <div className="text-danger">{formErrors.FeeType}</div>
                )}
              </Form.Group>
            </div>

            {/* Card Number */}
            <div className="col-md-6 form-group">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>
                  Payment Mode <span className={classes["required"]}>*</span>
                </Form.Label>
                <Form.Select
                disabled={ makeDisable || modeOfPay || ((formData.PaymentMode==='Stripe'|| formData.PaymentMode=='BillDesk' || formData.PaymentMode=='Razorpay') && makeDisableComplete )}
                  placeholder="Please enter payment mode"
                  className={`form-control`}
                  aria-label="Payment Mode"
                  name="PaymentMode"
                  value={formData?.PaymentMode} 
                  onChange={(e)=>{opemCreditCard(e.target.value);handleChange(e)}}
                 
                >
                  <option disabled value="">
                    Select Payment Mode
                  </option>
                  {modeOfMaymentList.map((item) => (
                    <option value={item?.value}>{item?.title}</option>
                  ))}

                </Form.Select>
                {formErrors.PaymentMode && (
                  <div className="text-danger">{formErrors.PaymentMode}</div>
                )}
              </Form.Group>
            </div>
            <div className="col-md-6 form-group">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>Fee Amount</Form.Label>
                <input disabled
                  placeholder="Please enter fee amount"
                  className={`form-control`}
                  type="text"
                  aria-label="Fee Amount"
                  name="FeeDeposit"
                  value={formData?.FeeDeposit}
                  onChange={handleChange}
                 
                />
              </Form.Group>
            </div>

            <div className="col-md-6 form-group">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>
                  Deposit Date<span className={classes["required"]}>*</span>
                </Form.Label>
                <input disabled={formData.PaymentMode==='Stripe' || formData.PaymentMode==='BillDesk' || formData.PaymentMode==='Razorpay' || makeDisable}
                  max={minDate}
                  placeholder="Please enter deposit date"
                  className={`form-control`}
                  type="date"
                  aria-label="Deposit Date"
                  name="DepositDate"
                  value={formData?.DepositDate}
                  onChange={handleChange}
                 
                />
                {formErrors.DepositDate && (
                  <div className="text-danger">{formErrors.DepositDate}</div>
                )}
              </Form.Group>
            </div>

            <div className="col-md-6 form-group">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>Fee Basis</Form.Label>
                <input disabled
                  placeholder="Please enter Fee Basis"
                  className={`form-control`}
                  type="text"
                  aria-label="Fee Basis"
                  name="FeeBasis"
                  value={formData.FeeBasis}
                  onChange={handleChange}
                  
                />
              </Form.Group>
            </div>
     
            
            <div className="col-md-6 form-group">
       <Form.Group
       className="mb-3"
       controlId="exampleForm.ControlInput5"
     >
       <Form.Label>Receipt Type <span className={classes["required"]}>*</span></Form.Label>
       <Form.Select
         placeholder="Please enter receipt type"
         className={`form-control`}
         type="text"
         aria-label="Receipt Type"
         name="ReceiptType"
         value={formData?.ReceiptType}
         onChange={handleChange}
         
       >
         <option disabled value="">
           Select Recipt Type
         </option>
         {reciptList.map((item) => (
           <option value={item?.RecTypeId}>{item?.RecTypeName}</option>
         ))}
       </Form.Select>
       {formErrors.ReceiptType && (
         <div className="text-danger">{formErrors.ReceiptType}</div>
       )}
     </Form.Group>
          </div>

          </div>
          {!makeDisable &&(
            <UploadDocument filePathUrl={updateFeeData?.ReceiptPath} btnText="Upload File" uploadFile={uploadFile}> </UploadDocument>
          )}
         
          </div>
          <div className="text-center">
            {isUpdate && (
            <button onClick={updateData} className="primary-button">
            Update
            </button>
            )}
              {!isUpdate && (
            <button onClick={addData} className="primary-button">
            Save Details
            </button>
            )}

          </div>
        </div>
      </SearchModal>

      {isLoading && <Loader />}
    </>
  );
};

export default UploadRecipt;
