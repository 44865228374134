import { Link, useNavigate } from "react-router-dom";
import classes from "./SignIn.module.css"
import ApiService from "../../../../Services/APIService";
import { useBehaviorSubject } from "../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import { useState,useEffect} from "react";
import { toast } from "react-toastify";
import loginFace from "../../../../Assets/Images/signin-page-img.png";
import unionLogo from "../../../../Assets/Images/msm-unify-logo.svg";
import google from "../../../../Assets/Logo/google-icon.svg";
import facebook from "../../../../Assets/Logo/facebook-icon.svg";
import apple from "../../../../Assets/Logo/apple-icon.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import counseling from '../../../../Assets/Images/Free-counseling.png';
import countryCourse from '../../../../Assets/Images/Country-course.png';
import standardized from '../../../../Assets/Images/Standardized.png';
import application from '../../../../Assets/Images/Application.png';

const SignIn = () => {
  const [isLoading, setisLoading] = useState(false);
  const { setisLoginUser } = useBehaviorSubject();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  //  state for userDetails
  const [form, setForm] = useState({
    email: "",
    "ipAddress": "0.0.0.0",
    "loginType": 1,
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;

    // Initialize sanitizedValue
    let sanitizedValue = value;

    // Sanitize email only if it's the email field
    if (name === 'email' && value) {
      sanitizedValue = value.replace(/\s/g, ''); // Remove spaces from email
    }

    setForm((prevForm) => ({
      ...prevForm,
      [name]: sanitizedValue,
    }));

    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(name, sanitizedValue);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }
  };

  const validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
      case "email":
        if (!value) errorMsg = "Please enter email";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter a valid email";
        break;
      case "password":
        if (!value) errorMsg = "Please enter password";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const validateForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      const msg = validateField(key, form[key]);
      if (msg) errorObj[key] = msg;
    });
    return errorObj;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setisLoading(true);
      ApiService.signInV1(form)
        .then((res) => {
          localStorage.setItem("token", res?.token);
          localStorage.setItem("userDetail", JSON.stringify(res?.internalUserResponse));
          setisLoginUser(true);
          toast.success(res?.message);
          setisLoading(false);
          navigate("/member/dashboard");
        })
        .catch((error) => {
          setisLoading(false);
          toast.error(error?.message);
        });
    }
    console.log("Data:", form);
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <>
      {/* <div className={classes["main-container"]}> */}
      {/* <div className={classes["logo-align"]}><img className={classes["login-image"]} src={unionLogo} alt='' /></div> */}
      <div className={classes["login-section"]}>
        <div className={`${classes.row_hieght} row`}>
          <div className={`${classes.col_img} col-md-7`}>
            {/* <img className={classes["left-img"]} src={leftImg} alt="" /> */}
            <div className={classes["left-block-header"]}>
              {/* <div className={classes["heading"]}>Sign up</div> */}
              <div className="mt-3"><img className={classes["login-image"]} src={unionLogo} alt='' /></div>
            

              <div className={classes["sub-heading"]}>Welcome to MSM Pursuit, your ultimate study-abroad platform!</div>
              <div className={classes["description"]}>
                Sign up as a student and embark on your educational journey like never before.
                Whether you dream of studying in the US, UK, Canada, or anywhere else in the world,
                MSM Pursuit is here to make your study abroad aspirations a reality.
                Sign up now and take the first step towards your future success!
              </div>
            </div>
            <div className={classes["left-block-cards"]}>
              <div className={classes["cards"]}>
                <div><img src={counseling} alt="" /></div>
                <div className={classes["card-detail-text"]}>Free counseling - In-person/Online</div>
              </div>
              <div className={classes["cards"]}>
                <div><img src={countryCourse} alt="" /></div>
                <div className={classes["card-detail-text"]}>Country, course & university selection</div>
              </div>
              <div className={classes["cards"]}>
                <div><img src={standardized} alt="" /></div>
                <div className={classes["card-detail-text"]}>Standardized tests preparation</div>
              </div>
              <div className={classes["cards"]}>
                <div><img src={application} alt="" /></div>
                <div className={classes["card-detail-text"]}>Application & admission assistance</div>
              </div>
            </div>
          </div>
          <div className={`${classes['col-color']} col-md-5 p-0`}>
            <div className={classes["section-2"]}>
              <div className={classes["login-card"]}>
                {/* <div className={classes["section-2_heading"]}>
                  <img className={classes["login-image"]} src={unionLogo} alt='' />
                </div> */}
                <div className={classes["section-2_form"]}>
                  <form>
                    <div className={classes["login-form-block"]}>

                      <div className={classes["block-1"]}>
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className={`${classes.label} form-label`}
                          >
                            Email
                          </label>
                          <input
                            placeholder="Type Email Here..."
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            name="email"
                            value={form.email}
                            className="form-control"
                            type="text"
                            required
                          />
                          {formErrors.email && <span className="error-alert">{formErrors.email}</span>}
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputPassword1"
                            className={`${classes.label} form-label`}
                          >
                            Password
                          </label>
                          <div className={classes["input-with-icon"]}>
                            <input
                              placeholder="Type Password Here..."
                              onChange={changeHandler}
                              onBlur={changeHandler}
                              name="password"
                              value={form.password}
                              className="form-control"
                              type={passwordType}
                              required
                            />
                            {form.password && (
                              <div className={classes["icon-container"]}>
                                <span className="eyeicon" onClick={viewPass}>
                                  <i className={`fa ${visible ? "fa-eye" : "fa-eye-slash"}`}
                                  />
                                </span>
                              </div>
                            )}
                          </div>
                          {formErrors.password && (
                            <span className="error-alert">{formErrors.password}</span>
                          )}
                          <div className={classes["forgot-password-text"]}>
                            <Link to="/forgot-password">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                        <div className={classes["login-form-btn"]}>
                          {/* <button onClick={handleSubmit} className={classes["login-btn"]}>Login</button>
                          <button className={classes["login-guest-btn"]}>Continue as guest</button> */}
                          <button disabled={isLoading} onClick={handleSubmit} className="primary-button">LOGIN</button>
                          <button className="secondary-button">Continue as guest</button>
                        </div>
                      </div>

                      <div className={classes["block-2"]}>
                        <div className={classes["other-sign-in-option-text"]}>Or sign in with:</div>
                        <button className={classes["login-google-btn"]}>
                          <img className={classes["img"]} src={google} alt='' />
                          <div className="">Sign in with Google</div>

                        </button>
                        <button className={classes["login-facebook-btn"]}>
                          <img className={classes["img"]} src={facebook} alt='' />
                          <div>Sign in with Facebook</div>

                        </button>
                        <button className={classes["login-apple-btn"]}>
                          <img className={classes["img"]} src={apple} alt='' />
                          <div>Sign in with Apple</div>

                        </button>
                      </div>

                      <div className={classes["block-3"]}>
                        <Link to="/sign-up">
                          Sign up
                        </Link>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{isLoading && <Loader />}</div>
      </div>
      {/* </div> */}




      {/* <div className="box-aglin">
        <div className="login-box">
          <div className="heading-align">
            <h1>Welcome</h1>
          </div>
          <div>
            <form className="form-group">
              <div className="margin-div">
                <h4 className="lable-name">Email</h4>
                <div>
                  <input
                    onChange={changeHandler}
                    onBlur={changeHandler}
                    name="email"
                    value={form.email}
                    className="form-control"
                    type="text"
                    required
                  />
                  {formErrors.email && <span>{formErrors.email}</span>}
                </div>
              </div>
              <div className="">
                <h4 className="lable-name">Password</h4>
                <div className="password-icon-postion input-with-icon">
                  <input
                    onChange={changeHandler}
                    onBlur={changeHandler}
                    name="password"
                    value={form.password}
                    className="form-control"
                    type={passwordType}
                    required
                  />
                  {form.password && (
                    <div className="icon-container">
                      <span className="eyeicon" onClick={viewPass}>
                        <i
                          className={`fa ${visible ? "fa-eye" : "fa-eye-slash"
                            }`}
                        />
                      </span>
                    </div>
                  )}
                </div>
                {formErrors.password && (
                  <span>{formErrors.password}</span>
                )}
              </div>
              <div className="forgot-section mt-2 margin-div">
                <Link to="/forgot-password">
                  <span className="forgot-text">Forgot Password?</span>
                </Link>
              </div>
              <div className="margin-div">
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="submit-btn"
                >
                  Sign in
                </button>
              </div>
              <div className="text-center margin-div">
                <h4>
                  <span className="dont-have">Don't an have account?</span>
                  <span className="sign-up-text">
                    <Link to="/sign-up"> Sign Up</Link>
                  </span>
                </h4>
              </div>
              <div className="other-option margin-div">
                <div className="text-div">
                  <span>OR CONTINUE WITH</span>
                </div>
                <div className="border-line"></div>
              </div>
              <div className="margin-div">
                <button type="button" className="submit-btn">
                  <i className="fab fa-google fab-color"></i>&nbsp; Sign
                  in with Google
                </button>
              </div>

              <div className="term-condition">
                <span>By continue, you are agree to our </span>
                <span>
                  <a href="">Term of Services</a>
                </span>
                <span> and </span>
                <span>
                  <a href="">Privacy Policy</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      {/* <div>{isLoading && <Loader />}</div> */}
    </>
  )
}

export default SignIn