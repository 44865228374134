import axiosInstance from "./Interceptor";
const CommonServices = {
  uploadFile: async (data,config) => {
    try {
      const response = await axiosInstance.post('/common/upload-file/v1', data,config);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  uploadPassport: async (data) => {
    try {
      const response = await axiosInstance.post('/common/upload-file/v2', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  tagList: async () => {
    try {
      const response = await axiosInstance.get('/Misc/Tag',);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getRecipt: async () => {
    try {
      const response = await axiosInstance.get('Misc/ReceiptTypes',);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  getGovtType: async () => {
    try {
      const response = await axiosInstance.get('Misc/GovernmentIdTypeGET',);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },


}
export default CommonServices;