import classes from './ToastSuccessModal.module.css';
import successTick from '../../../../Assets/Logo/success-tick-icon.svg';
import cross from '../../../../Assets/Logo/crossred.svg';
import { Modal } from 'react-bootstrap';

function ToastSuccessModal({ onShow, size, closeSuccessModal, successMessage }) {
    return(
        <Modal
        show={onShow}
        size={size}
        centered
        className={classes["success-modal-block"]}
    >
        <Modal.Header className={classes["success-modal-header"]}>
                <div><img className={classes["cross-icon"]} src={cross} alt="cross" onClick={closeSuccessModal} /></div>
        </Modal.Header>
        <Modal.Body className={classes["success-modal-body"]}>
                <div className={classes["success-modal-body-icon"]}>
                    <img className={classes["tick-icon"]} src={successTick} alt="Success Tick" />
                </div>
                <div className={classes["success-modal-hint"]}>{successMessage}</div>
        </Modal.Body>
        <Modal.Footer className={classes["success-modal-footer"]}>
            <button className={`${classes["close-button"]} primary-button`} onClick={closeSuccessModal}>Close</button>
        </Modal.Footer>
    </Modal>
    )
}

export default ToastSuccessModal;