import React from "react";
import classes from "./InstitutesCard.module.css";
import location from "../../../Assets/Logo/location-red-icon.svg";
import universityLogo from "../../../Assets/Logo/dougl-icon.svg";
import { useNavigate } from "react-router-dom";

const InstitutesCard = ({ institute,selectedCountryId }) => {
  const bloburl = process.env.REACT_APP_LOGO_PATH;
  const navigate = useNavigate();
  const handleInstituteClick = () => {
    const instituteDetailUrl = `/member/institute-details/${institute.institutionId}?selectedCountryId=${selectedCountryId}`;
    window.open(instituteDetailUrl, '_blank');
};
  function stripHtmlTags(str) {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  }
  return (
    <>
      <div className={classes["card-section"]}>
        <div className={classes["section-1"]}>
          <div className={classes["card-header-block-1"]} style={{ backgroundColor: institute.backgroundColor || "N/A" }}>
            {/* <div className={classes["card-rank"]}>
              {" "}
              <span className={classes["rank-text"]}>Ranked: #{institute.ranked || "N/A"}</span>
            </div> */}
            <div className={classes["card-header"]}>
              <div className={classes["university-icon"]}> <img
                src={institute?.instLogoPath ? bloburl + institute?.instLogoPath : universityLogo}
                alt="Profile"
              /></div>
            </div>
          </div>
          <div className={classes["card-header-block-2"]}>
              <div className={classes["university-detail"]}>
                <div title={institute.institutionName} onClick={handleInstituteClick} className={classes["university-name"]}>{institute.institutionName || "N/A"}</div>
                <div className={classes["university-address"]}> <img src={location} alt="location tag" />{'  '}{institute.cityName || "N/A"}</div>
                {/* <div className={classes["university-address"]}>{institute.location || "N/A"}</div> */}
              </div>
            {/* <div title={institute.account} className={classes["program-name"]}>{institute.account || "N/A"}</div> */}
            <div title={stripHtmlTags(institute?.instAbout)} className={classes["program-detail-text"]} dangerouslySetInnerHTML={{ __html: stripHtmlTags(institute?.instAbout) || 'N/A' }}></div>
          </div>
        </div>
        <div className={classes["section-2"]}>
          <div className={classes["program-detail-section-1"]}>
            {/* <div>
              <div className={classes["intake-header"]}>Intake</div>
              <div className={classes["detail"]}>{institute?.intake?.startDate || "N/A"}</div>
              <div className={classes["detail"]}>{institute?.intake?.endDate || "N/A"}</div>
            </div> */}
            {/* <div className={classes["detail_UI"]}>{institute?.intake?.duration || "N/A"}</div> */}
            {/* <div className={classes["detail_UI"]}>{institute.degree || "N/A"}</div> */}
            <div className={classes["detail"]}>
             <span className={classes["institute-label"]}>Fees Range:</span>&nbsp;
               <span className={classes["institute-amount"]}> {institute.instCurrency} {institute.minAmount} - {institute.maxAmount}</span>
            </div>
              <div className={classes["know-more"]}>
                                            <span onClick={handleInstituteClick}>Explore Courses</span>
                                            </div>
          </div>
          <div className={classes["program-detail-section-2"]}>
            {/* <div>
              <div className={classes["header"]}>Application Fee</div>
              <div className={classes["detail"]}>{institute.instCurrency || "N/A"}</div>
            </div>
            <div>
              <div className={classes["header"]}>People like You </div>
              <div className={classes["detail"]}>{institute.graduates || "N/A"}</div>
            </div>
            <div>
              <div className={classes["header"]}>Likelihood of Graduation</div>
              <div className={classes["detail"]}>{institute.graduationLikelihood || "N/A"}</div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default InstitutesCard;