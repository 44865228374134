import classes from "./ComparePrograms.module.css";
import crossIcon from "../../../Assets/Logo/red-cross-icon.svg";
import { Form } from "react-bootstrap";
import universityLogo from "../../../Assets/Logo/dougl-icon.svg";
import newUniversityLogo from "../../../Assets/Logo/university-icon.svg";
import likedIcon from "../../../Assets/Logo/liked-heart.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../GlobleShared/CustomHooks/useLocalStorage";

function ComparePrograms({selectedProgramTypeId, onClose, ProgramList }) {
    const [showCompareProgramsFlag, setshowCompareProgramsFlag] = useState();
    const [programIdList, setprogramIdList] = useState([]);
    const [compareProgramsList, setcompareProgramsList] = useState([]);
    const [arrayLength, setarrayLength] = useState(0);
    const navigate=useNavigate()
    const [userDetail] = useLocalStorage('userDetail');
    const studentId=userDetail?.refId

    const isDisabled = programIdList.length >= 5;
    const bloburl = process.env.REACT_APP_LOGO_PATH;

    // const programs = [
    //     {
    //         id: 1,
    //         university: 'Douglas College',
    //         course: 'Computer Science',
    //         liked: true,
    //         rank: '#1',
    //         programLevel: '2 year Diploma',
    //         tuition: '$13,980 CAD',
    //         costOfLiving: '$20,635 CAD',
    //         programLength: '2 year',
    //         intake: 'Sep. 2024',
    //         minEduLevel: 'High School Grad.',
    //         minGPA: '60.0%',
    //         IELTTS: '6.5',
    //         TOEFL: '83.0',
    //         PTE: '56.0',
    //         Duolingo: '110'
    //     },
    //     {
    //         id: 2,
    //         university: 'BCIT',
    //         course: 'Computer Science',
    //         liked: true,
    //         rank: '#2',
    //         programLevel: '3 year Diploma',
    //         tuition: '$15,980 CAD',
    //         costOfLiving: '$20,635 CAD',
    //         programLength: '2 year',
    //         intake: 'Jan. 2025',
    //         minEduLevel: 'High School Grad.',
    //         minGPA: '60.0%',
    //         IELTTS: '6.5',
    //         TOEFL: '83.0',
    //         PTE: '56.0',
    //         Duolingo: '110'
    //     },
    //     {
    //         id: 3,
    //         university: 'Simon Frasier University',
    //         course: 'Computer Science',
    //         liked: true,
    //         rank: '#4',
    //         programLevel: '2 year Diploma',
    //         tuition: '$15,440 CAD',
    //         costOfLiving: '$20,635 CAD',
    //         programLength: '3 year',
    //         intake: 'Sep. 2024',
    //         minEduLevel: 'High School Grad.',
    //         minGPA: '60.0%',
    //         IELTTS: '6.5',
    //         TOEFL: '83.0',
    //         PTE: '56.0',
    //         Duolingo: '110'
    //     },
    //     {
    //         id: 4,
    //         university: 'Alexander College',
    //         course: 'Computer Science',
    //         liked: true,
    //         rank: '#2',
    //         programLevel: '3 year Diploma',
    //         tuition: '$15,980 CAD',
    //         costOfLiving: '$20,635 CAD',
    //         programLength: '2 year',
    //         intake: 'Jan. 2025',
    //         minEduLevel: 'High School Grad.',
    //         minGPA: '60.0%',
    //         IELTTS: '6.5',
    //         TOEFL: '83.0',
    //         PTE: '56.0',
    //         Duolingo: '110'
    //     },
    //     {
    //         id: 5,
    //         university: 'Acadia College',
    //         course: 'Computer Science',
    //         liked: true,
    //         rank: '#2',
    //         programLevel: '3 year Diploma',
    //         tuition: '$15,980 CAD',
    //         costOfLiving: '$20,635 CAD',
    //         programLength: '2 year',
    //         intake: 'Jan. 2025',
    //         minEduLevel: 'High School Grad.',
    //         minGPA: '60.0%',
    //         IELTTS: '6.5',
    //         TOEFL: '83.0',
    //         PTE: '56.0',
    //         Duolingo: '110'
    //     },
    //     {
    //         id: 6,
    //         university: 'Okanagan College',
    //         course: 'Computer Science',
    //         liked: true,
    //         rank: '#2',
    //         programLevel: '3 year Diploma',
    //         tuition: '$15,980 CAD',
    //         costOfLiving: '$20,635 CAD',
    //         programLength: '2 year',
    //         intake: 'Jan. 2025',
    //         minEduLevel: 'High School Grad.',
    //         minGPA: '60.0%',
    //         IELTTS: '6.5',
    //         TOEFL: '83.0',
    //         PTE: '56.0',
    //         Duolingo: '110'
    //     },
    //     {
    //         id: 7,
    //         university: 'Okanagan College',
    //         course: 'Computer Science',
    //         liked: true,
    //         rank: '#2',
    //         programLevel: '3 year Diploma',
    //         tuition: '$15,980 CAD',
    //         costOfLiving: '$20,635 CAD',
    //         programLength: '2 year',
    //         intake: 'Jan. 2025',
    //         minEduLevel: 'High School Grad.',
    //         minGPA: '60.0%',
    //         IELTS: '6.5',
    //         TOEFL: '83.0',
    //         PTE: '56.0',
    //         Duolingo: '110'
    //     },
    // ];

    function getCheckBoxHandler(event) {
        const { id, checked } = event.target;

        setprogramIdList(prevState => {
            if (checked) {
                return [...prevState, Number(id)];
            }
            else {
                return prevState.filter(item => item !== Number(id));
            }
        });
    }

    const showCompareProgramsHandler = () => {
        const selectedPrograms = ProgramList.filter(program => programIdList.includes(program.ProgramId));
        setcompareProgramsList(selectedPrograms);
        setarrayLength(compareProgramsList.length);
        setshowCompareProgramsFlag(true);
    }


    const getProgramData=(programData)=>{
        const id = Number(selectedProgramTypeId)===1?1:2
        navigate('/member/application/add/'+id+'?studentId=' +studentId +'&programId='+ programData?.ProgramId )

    }

    return (
        <>
            <div className={classes["main-container"]}>

                {/* Compare Program Header Section starts here */}
                <div className={classes["compare-program-header"]}>
                    <div className={classes["title"]}>{!showCompareProgramsFlag ? 'Select up to 5 options' : 'Program Compare'}</div>
                    <div className={classes["cross-icon-div"]} onClick={onClose}><img className={classes["cross-icon"]} src={crossIcon} alt="cross" /></div>
                </div>

                {/* Compare Program Main Body Section starts here */}
                {!showCompareProgramsFlag && (
                    <div className={classes["compare-program-body-section-1"]}>
                        <div className={classes["compare-section"]}>
                            <div className={classes["section-1"]}>
                                <div className={classes["count-hint"]}>{programIdList.length}/5 selected</div>
                                <div className={classes["count-hint"]}>Click heart to deselect</div>
                            </div>
                            <div className={`${classes["section-2"]} custom-scrollbar`}>
                                <Form>
                                    <div className={classes["compare-program-block"]}>
                                        {ProgramList.map((program) => (
                                            <div className={classes["compare-program-tile"]} key={program.ProgramId}>
                                                <div className="compare-program-checkbox">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={program.ProgramId}
                                                        checked={programIdList.includes(program.ProgramId)}
                                                        disabled={!programIdList.includes(program.ProgramId) && isDisabled}
                                                        onChange={getCheckBoxHandler}
                                                    />
                                                </div>
                                                <div className={classes["program-tiles"]}>
                                                    <div className={classes["unversity-details"]}>
                                                        <div className={classes["university-icon"]}>
                                                            <img src={program?.InstLogoPath ? bloburl + program.InstLogoPath : newUniversityLogo} alt="university" />
                                                            
                                                            </div>
                                                        <div>
                                                            <div className={classes["unversity-name"]}>{program.Instname}</div>
                                                            <div className={classes["unversity-address"]}>New Westminster,</div>
                                                            <div className={classes["unversity-address"]}>British Columbia, {program.InstCountryName}</div>
                                                        </div>
                                                    </div>
                                                    <div className={classes["program-name"]}>{program.ProgramName}</div>
                                                    <div className={classes["rank-section"]}>
                                                        <div className={classes["program-rank"]}>Ranked: #1</div>
                                                        <div className={classes["liked-icon"]}><img src={likedIcon} alt="liked" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className={classes["buttons-section"]}>
                            <div>
                                <button onClick={onClose} className={`${classes["cancel-btn"]} secondary-button`}>Cancel</button>
                            </div>
                            <div>
                                <button className={`${classes["compare-btn"]} primary-button`} onClick={() => showCompareProgramsHandler()}>Compare</button>
                            </div>
                        </div>
                    </div>
                )}

                {showCompareProgramsFlag && (
                    <div className={classes["compare-program-body-section-2"]}>
                        <table className={classes["compare-table"]}>
                            <thead>
                                <tr>
                                    <th className={arrayLength == 1 ? 'col-md-3' : arrayLength == 2 ? 'col-md-4' : arrayLength == 3 ? 'col-md-3' : arrayLength == 4 ? 'col-md-4' : 'col-md-2'}></th>
                                    {compareProgramsList.map(program => (
                                        <th key={program.ProgramId} className={arrayLength == 1 ? 'col-md-9' : arrayLength == 2 ? 'col-md-4' : arrayLength == 3 ? 'col-md-3' : 'col-md-2'}>
                                            <img className={classes["compare-table-image"]}  src={program?.InstLogoPath ? bloburl + program.InstLogoPath : newUniversityLogo} alt={program.Instname} />
                                            <h3>{program.Instname}</h3>
                                            <p>{program.ProgramName}</p>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Liked</td>
                                    {compareProgramsList.map(program => (
                                        // <td key={program.ProgramId}>{program.liked ? '❤️' : '💔'}</td>
                                        <td key={program.ProgramId}>❤️</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Rank</td>
                                    {compareProgramsList.map(program => (
                                        // <td key={program.ProgramId}>{program.rank}</td>
                                        <td key={program.ProgramId}>#1</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Program Level</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>{program.DurationTime}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Tuition</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Cost of Living</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Program Length</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>{program.DurationTime}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Intake</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Min. Edu. Level Completed</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Min. GPA</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>IELTS</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>TOEFL</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>PTE</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Duolingo</td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>NA</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td></td>
                                    {compareProgramsList.map(program => (
                                        <td key={program.ProgramId}>
                                            <div className={classes["button-section"]}>
                                                <div><button onClick={()=>{getProgramData(program)}} className={`${classes["apply-btn"]} secondary-button`}>Apply</button></div>
                                                {/* <div><a className={classes["see-more"]} href="#">See More...</a></div> */}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

            </div>
        </>
    )
}

export default ComparePrograms;