import React, {useState, useEffect} from "react";
import classes from "./Institutes.module.css";
import downarrow from "../../../../../Assets/Logo/ic-down-arrow 3.svg";
import uparrow from "../../../../../Assets/Logo/ic-top-arrow.svg";
import companyLogoOne from "../../../../../Assets/Logo/comp-logo-1.svg";
import nodata from "../../../../../Assets/Logo/no-data1.svg";
import companyLogoTwo from "../../../../../Assets/Logo/comp-logo-2.svg";
import crossIconWhite from "../../../../../Assets/Logo/crose-whiteMini.svg";
import Accordion from "react-bootstrap/Accordion";
import { Form } from "react-bootstrap";
import InstitutesCard from "../../../../Common/InstitutesCard/InstitutesCard";
const Institutes = ({institutes,selectedCountryId, resetFlag, updateInsFilters,fetchInstituteData, isFlagInstitute}) => {

  const [selectedDuration, setSelectedDuration] = useState([]);
  const [selectedProgramtype, setSelectedProgramtype] = useState([]);
  const [selectedExamAccepted, setSelectedExamAccepted] = useState([]); 
  const [selectedEducationLevel, setSelectedEducationLevel] = useState([]); 
  const [searchInput, setSearchInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const filtersData = institutes?.filters?.[0]|| [];
  const [activeKey, setActiveKey] = useState(["0", "1", "2", "3", "4","5"]); // Initial Open States set

  const [filterAction, setFilterAction] = useState(null); // 'apply' or 'clear'
  const [instituteData, setInstituteData]=useState([])

  const clearFilters = () => {
  setSelectedDisciplines([]);
  setSelectedDuration([]);
  setSelectedProgramtype([]);
  setSelectedExamAccepted([]);
  setSelectedEducationLevel([]);
  setFilterAction('clear'); // Set filter action to 'clear'
  updateInsFilters({
    selectedDisciplines: [],
    selectedDuration: [],
    selectedProgramtype: [],
    selectedExamAccepted: [],
    selectedEducationLevel: [],
  });
};
const applyFilters = () => {
  setFilterAction('apply'); // Set filter action to 'apply'
  updateInsFilters({
    selectedDisciplines,
    selectedDuration,
    selectedProgramtype,
    selectedExamAccepted,
    selectedEducationLevel,
  });
};

useEffect(()=>{
  if(isFlagInstitute && filtersData){
    setSelectedDisciplines([]);
    setSelectedDuration([]);
    setSelectedProgramtype([]);
    setSelectedExamAccepted([]);
    setSelectedEducationLevel([]);
    setInstituteData(filtersData);
  }
  
}, [isFlagInstitute, filtersData])


useEffect(() => {
  if (filterAction === 'apply') {
    fetchInstituteData(1, false); // Apply filters and fetch data
  } else if (filterAction === 'clear') {
    fetchInstituteData(1, true); // Reset to page 1
  }
  setFilterAction(null); // Reset filter action
}, [selectedDisciplines, selectedDuration, selectedProgramtype, selectedExamAccepted, selectedEducationLevel, filterAction]);
  

  const handleDisciplineChange = (event) => {
    const discipline = event.target.value;
    setSelectedDisciplines((prev) =>
      event.target.checked
        ? [...prev, discipline]
        : prev.filter((d) => d !== discipline)
    );
  };

 

  const removeDiscipline = (discipline) => {
    setSelectedDisciplines((prev) =>
      prev.filter((d) => d !== discipline)
    );
  };

  const handleDurationChange = (durationCategoryId) => {
    setSelectedDuration((prev) =>
      prev.includes(durationCategoryId)
        ? prev.filter((id) => id !== durationCategoryId)
        : [...prev, durationCategoryId] 
    );
  };

  const handleProgramTypeChange = (programTypeId) => {
    setSelectedProgramtype((prev) =>
      prev.includes(programTypeId)
        ? prev.filter((id) => id !== programTypeId)
        : [...prev, programTypeId] 
    );
  };
  const handleAccordionSelect = (eventKey) => {
    setActiveKey(prevActiveKey => {
        if (prevActiveKey.includes(eventKey)) {
            return prevActiveKey.filter(key => key !== eventKey);
        } else {
            return [...prevActiveKey, eventKey];
        }
    });
};

   const handleExamAcceptedChange = (examTypeId) => {
    setSelectedExamAccepted((prev) =>
      prev.includes(examTypeId)
        ? prev.filter((id) => id !== examTypeId)
        : [...prev, examTypeId]
    );
  };

  
  const handleEducationLevelChange = (eduLevelId) => {
    setSelectedEducationLevel((prev) =>
      prev.includes(eduLevelId)
        ? prev.filter((id) => id !== eduLevelId)
        : [...prev, eduLevelId]
    );
  };


  const filteredDisciplines = instituteData.Discipline?.filter((discipline) =>
    discipline.DisciplineName.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
    }
  };
  
  

  // useEffect(() => {
  //     updateInsFilters({
  //       selectedDisciplines,
  //       selectedDuration,
  //       selectedProgramtype,
  //       selectedExamAccepted,
  //       selectedEducationLevel,
  //     });
  // }, [selectedDisciplines, selectedDuration, selectedProgramtype, selectedExamAccepted, selectedEducationLevel]);
  return (
    <div className="row">
     {/* {resetFlag && (
      <div className="col-md-3 col-lg-2">
           {(instituteData.Discipline?.length > 0 || instituteData.Duration?.length > 0) && (
        <Accordion
          className="filter-section"
          activeKey={activeKey} onSelect={handleAccordionSelect}>
         
             <Accordion.Item eventKey="5">
                                        <Accordion.Header className={classes["custom-accordion-header"]}>
                                          <div className={classes["accordiankey"]}>
                                        <span>Filters</span> */}
                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('5') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                        {/* </div>
                                        </Accordion.Header>
                                        <Accordion.Body className={classes["comman-filters"]}>
                                        <div>
  <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
    e.target.style.textDecoration = 'underline';
    e.target.style.color = '#E4151B '
    e.target.style.cursor = 'pointer';
  }} onMouseOut={(e) => {
    e.target.style.textDecoration = 'none';
    e.target.style.color = 'black';
  }} onClick={applyFilters}>Apply filter</span>
</div>
<div>
  <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
    e.target.style.textDecoration = 'underline';
    e.target.style.color = '#E4151B ';
    e.target.style.cursor = 'pointer';
  }} onMouseOut={(e) => {
    e.target.style.textDecoration = 'none';
    e.target.style.color = 'black';
  }}  onClick={clearFilters}>Clear filter</span>
</div>
                                        </Accordion.Body>
                                    </Accordion.Item>
            {instituteData.Discipline?.length > 0 && (
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => setIsOpen(!isOpen)}>
            <div className={classes["accordiankey"]}>
                                        <span>Discipline</span> */}
                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('5') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                        {/* </div>
            </Accordion.Header> */}
            {/* {!isOpen && (
            <input
                  type="text"
                  className={classes.searchInput}
                  placeholder="Search a Discipline..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
            )} */}
            {/* <Accordion.Body>
              <Form> */}
              {/* <div style={{position: "fixed", zIndex: "1000"}}> */}
              {/* {!isOpen && (
            <input
                  className={classes.searchInput}
                  placeholder="Search a Discipline..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={handleKeyPress}
                  style={{
                    borderRadius: "12px",
                    margin: "10px 0px 10px 3px",
                    border: "1px solid #F7A6A4",
                    height: "40px",
                    width: "100%"
                  }}
                />
                
              )} */}
              {/* </div> */}
                {/* {filteredDisciplines?.map((discipline, index) => (
                  <Form.Check
                    type="checkbox"
                    id={`discipline-${index}`}
                    key={index}
                    value={discipline.DisciplineName}
                    title={discipline.DisciplineName}
                    label={discipline.DisciplineName}
                    checked={selectedDisciplines.includes(discipline.DisciplineName)}
                    onChange={handleDisciplineChange}
                  />
                ))}
              </Form>
            </Accordion.Body>
          </Accordion.Item>
            )}
{filtersData.Duration?.length > 0 && (
          <Accordion.Item eventKey="1">
              <Accordion.Header>
              <div className={classes["accordiankey"]}>
                                        <span>Duration</span> */}
                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('1') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                        {/* </div>
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                  {instituteData.Duration?.map((duration) => (
                    <Form.Check
                      type="checkbox"
                      id={`duration-${duration.durationCategoryId}`}
                      key={duration.durationCategoryId}
                      value={duration.durationCategoryId}
                      title={duration.durationCategory}
                      label={duration.durationCategory}
                      checked={selectedDuration.includes(duration.durationCategoryId)}
                      onChange={() => handleDurationChange(duration.durationCategoryId)}
                    />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
)}

{instituteData?.Education?.length > 0 && (
            <Accordion.Item eventKey="2">
              <Accordion.Header>
              <div className={classes["accordiankey"]}>
                                        <span>Level of Education</span> */}
                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('5') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                        {/* </div>
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                  {instituteData?.Education?.map((eduLevel) => (
                    <Form.Check
                      type="checkbox"
                      id={`education-${eduLevel.eduLevelId}`}
                      key={eduLevel.eduLevelId}
                      value={eduLevel.eduLevelId}
                      label={eduLevel.eduLevel}
                      title={eduLevel.eduLevel}
                      checked={selectedEducationLevel.includes(eduLevel.eduLevelId)}
                      onChange={() => handleEducationLevelChange(eduLevel.eduLevelId)}
                    />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
)} */}

{/* {instituteData?.Req?.length > 0 && (
            <Accordion.Item eventKey="3">
              <Accordion.Header>
              <div className={classes["accordiankey"]}>
                                        <span>Exam Accepted</span> */}
                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('3') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                        {/* </div>
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                  {instituteData?.Req?.map((exam) => (
                    <Form.Check
                      type="checkbox"
                      id={`exam-${exam.CountryId}`}
                      key={exam.CountryId}
                      value={exam.CountryId}
                      label={exam.ProgramTypeID}
                      title={exam.ProgramTypeID}
                      checked={selectedExamAccepted.includes(exam.CountryId)}
                      onChange={() => handleExamAcceptedChange(exam.CountryId)}
                    />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>

                )}
{instituteData?.Program?.length > 0 && (
          <Accordion.Item eventKey="4">
            <Accordion.Header>
            <div className={classes["accordiankey"]}>
                                        <span>Course Type</span> */}
                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('4') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                        {/* </div>
            </Accordion.Header>
            <Accordion.Body>
              <Form>
                 {instituteData.Program?.map((program, index) => (
                  <Form.Check
                   type="checkbox"
                   id={`programType-${program.programTypeId}`}
                    key={program.programTypeId}
                    value={program.programTypeId}
                    label={program.programType}
                    title={program.programType}
                    checked={selectedProgramtype.includes(program.programTypeId)}
                    onChange={()=>{handleProgramTypeChange(program.programTypeId)}}
                  />
                ))}
              </Form>
            </Accordion.Body>
          </Accordion.Item>
)}
        </Accordion>
                    )}
      </div>
       )} */}
            <div className="col-md-11 col-lg-12">
                <div className="d-flex flex-wrap">
                {selectedDisciplines.map((discipline, index) => (
            <button
              key={index}
              className={classes.NameBudge}
              onClick={() => removeDiscipline(discipline)}
            >
              {discipline}
              <span>
                <img src={crossIconWhite} alt="Remove tag" />
              </span>
            </button>
          ))}
                </div>
                {institutes?.institution?.length > 0 ? (
                <div className={`${classes.container}`}>
                    {institutes?.institution?.map((institute) => (
                        <InstitutesCard key={institute.institutionId} institute={institute} selectedCountryId={selectedCountryId}/>
                    ))}
                </div>
                ) : resetFlag && (
                  <div className={classes["NOdata"]}>
                      <div>
                      <img className={classes["noimg"]}src={nodata} alt='nodata' />
                      </div>
                      <div className={classes['line']}>
                      </div>
                      <div className={classes['nospace']}>
                      <span className={classes["no"]}>No data found</span> 
                      <span className={classes['moment']}> at the moment.</span>
                      </div>
                      </div>
              )}
            </div>
      </div>
  );
};

export default Institutes;
