import classes from './FeePaymentModal.module.css';
import cross from '../../../../../Assets/Logo/crose-white.svg';
import msmLogo from '../../../../../Assets/Logo/Union.svg';
import corssIconRed from "../../../../../Assets/Logo/crossred.svg";
import { Form } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import PaymentService from '../../../../../Services/PaymentService';
import { toast } from 'react-toastify';
import Loader from '../../../../GlobleShared/Loader/Loader';

const FeePaymentModal = ({ onClose, feeDetails, onSubmitPendingDocuments, getModeofPayments, studentDetail = null, PartnerTypeId
    , InstName, InstCountryShortName, InstitutionId
}) => {

    const [formattedDate, setFormattedDate] = useState('');
    const [internalDate, setInternalDate] = useState('');
    const [flywireData, setFlywireData] = useState({ DestinationFlywireId: null });
    const [targetInstituteDetails, setTargetInstituteDetails] = useState({});
    const [showTargetDetails, setShowTargetDetails] = useState(false);
    const [possibleInstituteList, setPossibleInstituteList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [flagdisablebtn, setFlagdisablebtn] = useState(false)
    const initial = useRef(null);
    const stripeKey = process.env.REACT_APP_STRIPE_KEY;
    let handler;

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
            console.log("feeDetails: ", feeDetails);
        }

        if (feeDetails.DateofDeposite) {
            const initialDate = convertToInputDateFormat(feeDetails.DateofDeposite);
            setInternalDate(initialDate);
            setFormattedDate(feeDetails.DateofDeposite);
            setFeePaymentForm((prev) => ({ ...prev, paymentDate: initialDate }));
        }
    }, [feeDetails.DateofDeposite]);

    const formatDateToMMDDYYYY = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const convertToInputDateFormat = (mmddyyyyDate) => {
        const [month, day, year] = mmddyyyyDate.split('/');
        return `${year}-${month}-${day}`;
    };

    const handleDateChange = (event) => {
        const inputDate = event.target.value;
        const formattedDate = formatDateToMMDDYYYY(inputDate);
        setFormattedDate(formattedDate);
        setInternalDate(inputDate);
        setFeePaymentForm((prev) => ({ ...prev, paymentDate: inputDate }));
    };

    const initialFormState = {
        feeType: feeDetails.FeeType ? feeDetails.FeeType : "",
        modeofPayment: feeDetails.modeofPayment ? feeDetails.modeofPayment : "",
        currency: feeDetails.currency ? feeDetails.currency : "",
        feeAmount: feeDetails.FeeAmount ? feeDetails.FeeAmount : "",
        paymentDate: feeDetails.DateofDeposite ? convertToInputDateFormat(feeDetails.DateofDeposite) : "",
        feeBasis: feeDetails.FeeBasis ? feeDetails.FeeBasis : "",
    };
    const [feePaymentForm, setFeePaymentForm] = useState(initialFormState);

    const paymentHandler = () => {
        handler = window.StripeCheckout.configure({
            key: stripeKey,
            image: msmLogo,
            locale: 'auto',
            source: async (source) => {
                try {
                    const response = await payStripeLegacy(
                        feeDetails?.FeeAmount,
                        feeDetails?.currency,
                        source.id,
                        feeDetails?.FeeType,
                        feeDetails?.ApplicationId
                    );

                    if (response.message === 'succeeded') {
                        onSubmitPendingDocuments(true, "Fees");

                    } else {
                        toast.error('Payment failed due to an error');
                    }
                } catch (error) {
                    console.error('Payment failed:', error);
                    toast.error('Payment failed due to an error');
                }
            },
        });
    }

    const payStripeLegacy = async (amount, currency, sourceId, feeType, applicationId) => {
        setIsLoading(true);
        try {
            let data = {
                Amount: amount * 100,
                ApplicationId: applicationId,
                Currency: currency,
                FeeType: feeType,
                Source: sourceId
            };

            const response = await PaymentService.getPaymentByStripe(data);
            return response;
        } catch (error) {
            console.error("Payment failed: ", error);
        } finally {
            setIsLoading(false);
            setFeePaymentForm(initialFormState);
            onClose();
        }
    };

    const makePayment = (event) => {
        if (handler) {
            handler.open({
                name: 'MSM',
                description: feeDetails?.FeeType,
                email: 'support@msquaremedia.com'
            });
        } else {
            paymentHandler();
            handler.open({
                name: 'MSM',
                description: feeDetails?.FeeType,
                email: 'support@msquaremedia.com'
            });
        }
        event.preventDefault();
    };

    const onPaymentModeChange = () => {
        setFlywireData({ DestinationFlywireId: null });
        let targetInstituteDetails = {};
        targetInstituteDetails.Name = "";
        targetInstituteDetails.Address = "";
        setTargetInstituteDetails(targetInstituteDetails);
        if (feeDetails.modeofPayment == 'Flywire') {
            setShowTargetDetails(true);
            getPossibleInstitue();
        }
        else {
            setShowTargetDetails(false);
        }
    }

    const getPossibleInstitue = async () => {
        if ((PartnerTypeId == 2 || InstitutionId == 35 || InstitutionId == 618 || InstitutionId == 54 || InstitutionId == 33 || InstitutionId == 51) && this.InstName && this.InstCountryShortName && this.feeDetails.FeeType == 'Span Tran Fee') {
            let payload = [
                {
                    "externalId": "EF789",
                    "name": InstName,
                    "country": InstCountryShortName
                }
            ];
            try {
                //loading spinner
                setIsLoading(true);
                const res = await PaymentService.getPossibleInstitute(payload);
                //loading spinner
                setIsLoading(false);
                let possibleInstituteList = res[0].possibleMatches;
                if (possibleInstituteList && possibleInstituteList?.length == 0) {
                    toast.pop("error", "This Institution is not supported by Flywire, please try other mode of payment.");
                }
                setPossibleInstituteList(possibleInstituteList);
            } catch (error) {
                setIsLoading(false);
                //loading spinner
            }
        } else {
            setPossibleInstituteList([]);
        }
    }

    const triggerFlyWire = async () => {
        if (flywireData.DestinationFlywireId) {
            studentDetail.LastName = studentDetail.LastName != "" ? studentDetail.LastName : ".";
            let payload = {
                "flywirepay": {
                    "req1": {
                        "subject": {
                            "type": "student",
                            "firstName": studentDetail.FirstName,
                            "lastName": studentDetail.LastName,
                            "email": studentDetail.EmailId,
                            "country": studentDetail.CountryName.toLowerCase(),
                            "studentId": studentDetail.StudentId.toString()
                        },
                        "payment": {
                            "destinationId": this.flywireData.DestinationFlywireId,
                            "amount": this.discountedAmount * studentDetail.FlyWireUnit
                        }
                    },
                    "req2": {
                        "ApplicationId": feeDetails.ApplicationId,
                        "FeeType": feeDetails.FeeType
                    }
                }
            };
            //this.flagdisablebtn = true;
            //this.loading = true;
            setFlagdisablebtn(true)
            setIsLoading(true);
            try {
                const res = await PaymentService.payWithFlywire(payload);
                if (res) {
                    var newwindow = window.open(res.toString(), 'payment', 'width=700,height=500,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=450 top=150');
                    if (newwindow) {
                        newwindow.focus();
                        //this.loading = false;
                        setIsLoading(false);
                    }
                    var timer = setInterval(() => {
                        if (newwindow.closed) {
                            clearInterval(timer);
                            toast.pop("success", "Transaction initiated.");
                            //this.flagdisablebtn = false;
                            setFlagdisablebtn(false)
                            logTuitionFeePaid();
                            //this.matDialogRef.close(true);
                        }
                    }, 1000);
                }
            } catch (err) {
                //this.flagdisablebtn = false;
                setFlagdisablebtn(false)
                setIsLoading(false);
                toast.pop("error", "Payment failed.");
            }
        }
    }

    const logTuitionFeePaid = async () => {
        //code to log tuition fee paid activity
    }

    const closeHandler = () => {
        setFeePaymentForm(initialFormState);
        onClose();
    }

    const handleFormChange = (field, value) => {
        setFeePaymentForm(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    return (
        <>
            <div className={classes["fee-modal-block"]}>
                <div className={classes["fee-modal-header"]}>
                    <div className={classes["heading"]}>Fee Details</div>
                    <div><img className={classes["cross-icon"]} src={corssIconRed} alt="cross" onClick={closeHandler} /></div>
                </div>
                <div className={classes["fee-modal-body"]}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">Fee Type</Form.Label>
                            <Form.Control type="text" placeholder="Fee Type" disabled={feePaymentForm.feeType} value={feePaymentForm.feeType} onChange={(e) => handleFormChange('feeType', e.target.value)} />
                        </Form.Group>

                        {getModeofPayments.length === 0 && (
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledTextInput">Mode of Payment</Form.Label>
                                <Form.Control type="text" disabled={feePaymentForm.modeofPayment} value={feePaymentForm.modeofPayment} />
                            </Form.Group>
                        )}
                        {getModeofPayments.length > 0 && (
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="disabledSelect">Mode of Payment</Form.Label>
                                <Form.Select value={feePaymentForm.modeofPayment} onChange={(e) => handleFormChange('modeofPayment', e.target.value)}>
                                    <option disabled value="">Mode of payment</option>
                                    {getModeofPayments && getModeofPayments.map((paymentType, index) => (
                                        <option key={index} value={paymentType}>
                                            {paymentType}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}

                        <Form.Group className={`${classes["amount-field"]} mb-3`}>
                            <Form.Label htmlFor="disabledTextInput">Amount</Form.Label>
                            <Form.Control type="text" placeholder="Amount" disabled={feePaymentForm.feeAmount} value={feePaymentForm.feeAmount} onChange={(e) => handleFormChange('feeAmount', e.target.value)} className={classes["amount"]} />
                            <div className={classes["currency"]}>{feePaymentForm.currency}</div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="dateOfDeposit">Date of Deposit</Form.Label>
                            {feeDetails.DateofDeposite ? (
                                <Form.Control
                                    type="text"
                                    value={formattedDate}
                                    onChange={handleDateChange}
                                    disabled
                                />
                            ) : (
                                <Form.Control
                                    type="date"
                                    value={internalDate}
                                    onChange={handleDateChange}
                                />
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">Payment Schedule</Form.Label>
                            <Form.Control type="text" placeholder="Payment Schedule" disabled={feePaymentForm.feeBasis} value={feePaymentForm.feeBasis} onChange={(e) => handleFormChange('feeBasis', e.target.value)} />
                        </Form.Group>
                        <div className={classes["make-payment-button-block"]}><button type="submit" className={`${classes["payment-btn"]} primary-button`} onClick={(event) => makePayment(event)}>Make Payment</button></div>
                    </Form>
                </div>
            </div>
            {isLoading && (
                <Loader></Loader>
            )}
        </>
    )
}

export default FeePaymentModal;