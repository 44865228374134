import React, { useState } from "react";
import classes from "./Connections.module.css";
import SubConnections from "./subConnections/SubConnections";
import Suggestion from "./subConnections/Suggestion";
import searchIcon from "../../.../../../../Assets/Logo/search-icon.svg";
import SentComponents from "./subConnections/SentComponent";
import InvitationsComponent from "./subConnections/InvitationsComponent";
import brochure from "../../../../Assets/Images/msm-brochure.jpg";
import { CloseButton } from "react-bootstrap";

const ConnectionsComponents = () => {
  const [tab, setTab] = useState("Connections");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);

  const onChangeTab = (tab) => {
    if(tab === "Connections"){
      setTab(tab);
    }

    else if(tab === "Suggestions"){
      setTab(tab);
    }
    
    else if(tab === "Invitations"){
      setTab(tab);
    }

    else if(tab === "Sent"){
      setTab(tab);
    }
    setSearchQuery("");
    setSearchFlag(false);
  }

  const onSearch = (event) => {
    let searchString = event.target.value.trim();

    if (searchString === "") {
      setSearchQuery("");
    }
    else {
      setSearchQuery(searchString);
      if (searchString.length > 2) {
        setSearchFlag(true);
      }
    }
  };

  const clearSearchData = () => {
    const emptyEvent = { target: { value: "" } };
    setSearchQuery("");
    onSearch(emptyEvent);
  }

  return (
    <div className={classes["container-fluid"]}>

      <div className={classes["container"]}>
        <div className={classes["headerMange"]}>
          <div className={classes["header-left-part"]}>
            <span className={classes["connectionsText"]}>Connections</span>
          </div>
          <div className={classes["header-right-part"]}>
            <div className={`${classes["search-bar"]} form-group`}>
              <input
                type="text"
                placeholder="Search Users"
                className="form-control"
                value={searchQuery}
                onChange={(event) => onSearch(event)}
              />
              {searchQuery && (
                <CloseButton className={classes["close-button"]} onClick={clearSearchData} />
              )}
              {!searchQuery && (
                <img src={searchIcon} className={classes["search-icon"]} />
              )}
            </div>
            {/* <div>
            {tab === "Connections" && <span className={classes["searchWithFilters"]}>Search with filters</span>}
            </div> */}
          </div>
        </div>

        <div className={classes["brokenLine"]}></div>
        <div className={classes["user_navigation"]}>
          <button
            className={tab === "Connections" ? classes["active_button"] : ""}
            onClick={() => onChangeTab("Connections")}>
            Connections
          </button>
          <button
            className={tab === "Suggestions" ? classes["active_button"] : ""}
            onClick={() => onChangeTab("Suggestions")}>
            Suggestions
          </button>
          <button
            className={tab === "Invitations" ? classes["active_button"] : ""}
            onClick={() => onChangeTab("Invitations")}>
            Invitations
          </button>
          <button
            className={tab === "Sent" ? classes["active_button"] : ""}
            onClick={() => onChangeTab("Sent")}>
            Sent
          </button>
        </div>
      </div>


     <div className="row">
      <div className="col-md-9">
        {tab === "Connections" && <SubConnections searchInput={searchQuery} isSearch={searchFlag} setIsSearch={setSearchFlag} />}
        {tab === "Suggestions" && <Suggestion searchText={searchQuery} isSearchFlag={searchFlag} setIsSearchFlag={setSearchFlag} />}
        {tab === "Invitations" && <InvitationsComponent searchText={searchQuery} isSearchFlag={searchFlag} setIsSearchFlag={setSearchFlag}/>}
        {tab === "Sent" && <SentComponents searchText={searchQuery} isSearchFlag={searchFlag} setIsSearchFlag={setSearchFlag} />}
      </div>
      <div className="col-md-3">
      <img className={classes['brochure']} src={brochure} alt="brochure" />
      </div>
     </div>
    </div>
  );
};

export default ConnectionsComponents;
