import { useEffect, useState } from "react";
import classes from "./NewResetPassword.module.css";
import leftImg from "../../../../Assets/Images/signin-page-img.png";
import union from "../../../../Assets/Images/msm-unify-logo.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../../Services/APIService";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { useBehaviorSubject } from "../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import counseling from '../../../../Assets/Images/Free-counseling.png';
import countryCourse from '../../../../Assets/Images/Country-course.png';
import standardized from '../../../../Assets/Images/Standardized.png';
import application from '../../../../Assets/Images/Application.png';
const indianOtpCheck = process.env.REACT_APP_INDIA_OPT_CHECK;

const inputStyle = {
  width: "50px",
  height: "50px",
  margin: "5px 10px 5px 0px",
  fontSize: "24px",
  borderRadius: 12,
  border: "2px solid #363636",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
};

const invalidOtpStyle = {
  width: "50px",
  height: "50px",
  margin: "5px 10px 5px 0px",
  fontSize: "24px",
  borderRadius: 12,
  border: "2px solid #E82529",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
  color: "#E82529",
};

const NewResetPassword = () => {
  const { logout } = useBehaviorSubject();

  const [otpDetails, setOtpDetails] = useState(null);
  const [otpEmailDetails, setOtpEmailDetails] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [tokenStatus, settokenStatus] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("email");
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(30);
  const [otpCount, setOptCount] = useState();
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [otpSubmitted, setOtpSubmitted] = useState(false);
  const [otpVerificationPossible, setOtpVerificationPossible] = useState(false);
  const [passwordValidationErrors, setPasswordValidationErrors] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    uppercase: false,
  });
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [otpType, setOtpType] = useState("email");

  const [userDetailData , setUserDetailData] = useState(0)

  const [form, setForm] = useState({
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [touchedFields, setTouchedFields] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const { userId, token } = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   // tokenValidation();
  // }, []);

  // const tokenValidation = () => {
  //   setisLoading(true);
  //   ApiService.checkToken(token)
  //     .then((res) => {
  //       if (res?.valid) {
  //         settokenStatus(true);
  //       }
  //       setisLoading(false);
  //     })
  //     .catch((error) => {
  //       settokenStatus(false);
  //       setisLoading(false);
  //     });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedOption(e.target.value);
    if (value === "email") {
      setOtpType("email"); // Store email if email is selected
    } else if (value === "phone") {
      setOtpType("phone"); // Store phone number if phone is selected
    }
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const viewConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };
  const [formErrors, setFormErrors] = useState({
    email: null,
    confirmEmail: null,
    phoneNumber: null,
    firstName: null,
    lastName: null,
    newPassword: null,
    confirmPassword: null,
  });

  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    const trimmedValue = value.trim(); // Trim the input value
  
    switch (name) {
      case "email":
        if (!trimmedValue) errorMsg = "Please enter email.";
        else if (/\s/.test(value))
          errorMsg = "Spaces are not allowed in the email field";
        // else if (refValue && trimmedValue !== refValue.trim()) errorMsg = "Email and confirm email do not match";
        break;
      case "confirmEmail":
        if (!trimmedValue) errorMsg = "Please enter confirm email";
        else if (/\s/.test(value))
          errorMsg = "Spaces are not allowed in the confirm email field";
        else if (refValue && trimmedValue !== refValue.trim())
          errorMsg = "Email and confirm email do not match";
        break;
      case "phoneNumber":
        if (!trimmedValue) errorMsg = "Please enter phone number";
        else if (
          trimmedValue.replace(/\D/g, "").length < 10 ||
          trimmedValue.replace(/\D/g, "").length > 10
        )
          errorMsg = "Phone number must be 10 digits";
        break;
        case "newPassword":
          if (!value) errorMsg = "Please enter New Password.";
          else {
            const passwordErrors = validatePassword(value);
            
            if (Object.values(passwordErrors).includes(false)) {
              errorMsg = "";
            }
          }
          break;
        case "confirmPassword":
          if (!value) errorMsg = "Please enter confirm password.";
          else if (refValue && value !== refValue)
            errorMsg = "Password and confirm password do not match";
          break;
      default:
        break;
    }
    return errorMsg;
  };

  const changeHandler = (e) => {
    // debugger;
    const { name, value } = e.target;
  
    // Sanitize input based on field type
    const sanitizedValue =
      name === "email" || name === "confirmEmail"
        ? value.replace(/\s/g, "") // Remove spaces for email fields
        : name === "phoneNumber"
        ? value.replace(/\D/g, "") // Keep only digits for phone number
        : name === "firstName" || name === "lastName"
      ? value.replace(/[^a-zA-Z]/g, "") // Keep only alphabetic characters for first and last name
      : value;
    // Update form state
    setForm((prevForm) => ({
      ...prevForm,
      [name]: sanitizedValue,
    }));
    setTouchedFields((prevTouchedFields) => ({
    ...prevTouchedFields,
    [name]: true,
  }));
    // Real-time validation for relevant fields
    if (
      [
        "email",
        "confirmEmail",
        "phoneNumber",
        "newPassword",
        "confirmPassword",
      ].includes(name)
    ) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: validateField(
          name,
          sanitizedValue,
          name === "confirmEmail" ? form.email : form.confirmEmail,
          name === "confirmPassword" ? form.newPassword : form.confirmPassword
        ),
      }
    
    ));
    }
      // Always validate email and confirm email against each other
  if (name === "email" || name === "confirmEmail") {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      confirmEmail: validateField("confirmEmail", name === "confirmEmail" ? sanitizedValue : form.confirmEmail, name === "email" ? sanitizedValue : form.email),
    }));
  }
  // Validate new password and confirm password fields
  if (name === "newPassword" || name === "confirmPassword") {
    const passwordErrors = validatePassword(
      name === "newPassword" ? sanitizedValue : form.newPassword
    );
    setPasswordValidationErrors(passwordErrors);

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      newPassword: validateField(
        "newPassword",
        name === "newPassword" ? sanitizedValue : form.newPassword,
        name === "newPassword" ? form.confirmPassword : sanitizedValue
      ),
      confirmPassword: validateField(
        "confirmPassword",
        name === "confirmPassword" ? sanitizedValue : form.confirmPassword,
        name === "confirmPassword" ? form.newPassword : sanitizedValue
      ),
    }));
  }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  // for getting mobile otp details
  const sendAuthOtp = (number) => {
    // debugger
    setisLoading(true);
    ApiService.getMobOtp(number)
      .then((res) => {
        toast.success(res?.message)
        setOtpDetails(res.data);
        setisLoading(false);
        // setVerified(false);

        countdown();
        setOtp("");
        setInvalidOtp(false);
        otpValidation();
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  };

  const otpValidation = () => {
    ApiService.optValidation(form.email)
      .then((res) => {
       
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };
  const otpValidationCount = () => {
    ApiService.optValidationCount(form.email)
      .then((res) => {
       console.log("resCount",res)
       setOptCount(res)

      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  // for validate mobile otp details
  const validateAuthOtp = (session, otp) => {
    setisLoading(true);
    ApiService.validateMobOtp(session, otp)
      .then((res) => {
        setisLoading(false);
        setCurrentStep(5);
        setOtp("");
        setInvalidOtp(false);
        //toast.success(res?.message); // Display backend message in toast
        toast.success("OTP validated successfully.");
      })
      .catch((error) => {
        setisLoading(false);
        setInvalidOtp(true);
        toast.error(error?.Details);
        //toast.error("OTP validated successfully.");
      });
  };

  // for getting email otp details
  const sendEmailAuthOtp = (email) => {
    setisLoading(true);
    const data = {
      source: email,
      sourceType: "resetPassword",
    };
    ApiService.getEmailOtp(data)
      .then((res) => {
        setOtpEmailDetails(res);
        //toast.success(res?.message)
        setisLoading(false);
        // setVerified(false);

        countdown();
        setOtp("");
        setInvalidOtp(false);
        otpValidation();
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  };

  // for validating email otp
  const validateEmailAuthOtp = (session, otp) => {
    const data = {
      sessionId: session,
      authCode: otp,
    };
    setisLoading(true);
    ApiService.validateEmailOtp(data)
      .then((res) => {
        setisLoading(false);
        setCurrentStep(5);
        setOtp("");
        setInvalidOtp(false);
        //toast.success(res?.message); // Display backend message in toast
        toast.success("OTP validated successfully.");
      })
      .catch((error) => {
        setisLoading(false);
        setInvalidOtp(true);
        toast.error(error?.message);
      });
  };

  // const sendEmailAuthOtp = async (email) => {
  //   setisLoading(true);
  //   try {
  //     const response = await ApiService.getEmailOtp({ email });
  //     setOtpEmailDetails(response.data);
  //     toast.success("OTP sent successfully.");
  //   } catch (error) {
  //     toast.error("Failed to send OTP.");
  //   } finally {
  //     setisLoading(false);
  //   }
  // };

  // const validateAuthOtp = async (session, otp) => {
  //   setisLoading(true);
  //   try {
  //     const response = await ApiService.validateMobOtp(session, otp);
  //     setOtpVerificationPossible(true);
  //     toast.success("OTP verified successfully.");
  //   } catch (error) {
  //     toast.error("Invalid OTP.");
  //   } finally {
  //     setisLoading(false);
  //   }
  // };

  // const validateEmailAuthOtp = async (session, otp) => {
  //   setisLoading(true);
  //   try {
  //     const response = await ApiService.validateEmailOtp({ session, otp });
  //     setOtpVerificationPossible(true);
  //     toast.success("OTP verified successfully.");
  //   } catch (error) {
  //     toast.error("Invalid OTP.");
  //   } finally {
  //     setisLoading(false);
  //   }
  // };

  const handleNext = async (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      if (!form.email || !form.confirmEmail || !form.phoneNumber) {
        toast.error("Please fill in all fields.");
        return;
      }
      setisLoading(true);
      try {
        const userDetails = await ApiService.getUserDetails({
          email: form.email,
          mobileNumber: form.phoneNumber,
        });

        setUserDetailData(userDetails);

        setForm({
          ...form,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
        });
        setCurrentStep(2);
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setisLoading(false);
      }
    } 
    
    
    else if (currentStep === 2) {
      if(indianOtpCheck){
        if (userDetailData?.mobileNoCountryCode === "91") {
            setOtpVerificationPossible(true);
        }else{
            setOtpVerificationPossible(false);
        }
        }else{
           setOtpVerificationPossible(true);
        }
        

      if (!form.firstName || !form.lastName) {
        toast.error("Please fill in all fields.");
        return;
      }
      setCurrentStep(3);
    } else if (currentStep === 3) {
      if (form.email || form.phoneNumber) {
        otpValidationCount()
        if(otpCount>=4){
          toast.error("Limit exceeded, Try again after 24 hrs")
          setisLoading(false);
          return;
        }
        else{
        if (otpType === "phone") {
          setOtp("");
          sendAuthOtp(form.phoneNumber);
        } else if (otpType === "email") {
          setOtp("");
          sendEmailAuthOtp(form.email);
        }
        countdown();
        setCurrentStep(4);
      }
      }
    } else if (currentStep === 4 && otp.length === 6) {
      if (otpType === "phone") {
        validateAuthOtp(otpDetails?.Details, otp);
      } else if (otpType === "email") {
        validateEmailAuthOtp(otpEmailDetails?.response, otp);
      }
    } else if (currentStep === 5) {
      if (!form.newPassword || !form.confirmPassword) {
        toast.error("Please fill in all fields.");
        return;
      }
      if (form.newPassword !== form.confirmPassword) {
        toast.error("Passwords do not match.");
        return;
      }
      // const validationErrors = validatePassword(form.newPassword);
      // if (Object.keys(validationErrors).length > 0) {
      //   setPasswordValidationErrors(validationErrors);
      //   toast.error("");
      //   return;
      // }
      setisLoading(true);
      try {
        const response = await ApiService.resetPassword({
          password: form.newPassword,
          resetToken: "",
          userId: userDetailData?.userId,
        });
        //toast.success(response?.message);
        toast.success("Password reset successful! You can now log in with your new password.");
        if (response.status === 200 || response.message === "Password reset successful! You can now log in with your new password.") {
          logout(); // Call logout function if password update is successful
        }
        navigate("/sign-in");
        if (response.message === "Password updated successfully.") {
          logout(); // Call logout function if password update is successful
        }

      } catch (error) {
        toast.error(error?.message);
      } finally {
        setisLoading(false);
      }
    }
  };
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate("/forgot-password");
    }
  };

  const countdown = () => {
    let intervalId;
    let timeLeft = 30;
    clearInterval(intervalId);
    setTimeLeft(timeLeft);
    intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const resendOtp = () => {
    otpValidationCount()
    if(otpCount>=4){
      toast.error("Limit exceeded, Try again after 24 hrs")
      setisLoading(false);
      return;
    }
    else{
    setOtp("");
    countdown();
    setInvalidOtp(false);

    if (otpType === "phone") {
      sendAuthOtp(form.phoneNumber);
    } else if (otpType === "email") {
      sendEmailAuthOtp(form.email);
    }
  }
  };

  const getOtp = (otp) => {
    setInvalidOtp(false);
    setOtp(otp);
  };

  const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    const maskedLocalPart = localPart[0] + "*".repeat(localPart.length - 1);
    return `${maskedLocalPart}@${domain}`;
  };

  const validatePassword = (password) => {
    const errors = {};
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
  
    errors.minLength = password.length >= minLength;
    errors.specialChar = specialCharRegex.test(password);
    errors.number = numberRegex.test(password);
    errors.uppercase = uppercaseRegex.test(password);
  
    return errors;
  };

  return (
    <div className={classes["main-container"]}>
      <div className={`${classes.row_hieght} row`}>
        <div className={`${classes.col_img} col-md-7`}>
          {/* <img className={classes["left-img"]} src={leftImg} alt="" /> */}
          <div className={classes["left-block-header"]}>
            {/* <div className={classes["heading"]}>Sign up</div> */}
            <div><img src={union} alt="" /></div>
            <div className={classes["sub-heading"]}>Welcome to MSM Pursuit, your ultimate study-abroad platform!</div>
            <div className={classes["description"]}>
              Sign up as a student and embark on your educational journey like never before.
              Whether you dream of studying in the US, UK, Canada, or anywhere else in the world,
              MSM Pursuit is here to make your study abroad aspirations a reality.
              Sign up now and take the first step towards your future success!
            </div>
          </div>
          <div className={classes["left-block-cards"]}>
            <div className={classes["cards"]}>
              <div><img src={counseling} alt="" /></div>
              <div className={classes["card-detail-text"]}>Free counseling - In-person/Online</div>
            </div>
            <div className={classes["cards"]}>
              <div><img src={countryCourse} alt="" /></div>
              <div className={classes["card-detail-text"]}>Country, course & university selection</div>
            </div>
            <div className={classes["cards"]}>
              <div><img src={standardized} alt="" /></div>
              <div className={classes["card-detail-text"]}>Standardized tests preparation</div>
            </div>
            <div className={classes["cards"]}>
              <div><img src={application} alt="" /></div>
              <div className={classes["card-detail-text"]}>Application & admission assistance</div>
            </div>
          </div>
        </div>
        <div className={`${classes.right_col} col-md-5`}>
          <div className={classes["sign_up-box"]}>
            {/* <div className={classes.header}>
              <span>
                <img src={union} alt="" />
              </span>
            </div> */}
            <span className={classes.header}>Reset Password</span>
            {/* {tokenStatus && ( */}
            <form>
              <div className={classes["form-section"]}>
                <div className={classes["form-content"]}>
                  {currentStep === 1 && (
                    <>
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className={`${classes.label} form-label`}
                        >
                          Email
                        </label>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          onKeyDown={handleKeyDown}
                          type="email"
                          placeholder="Type Email Here..."
                          name="email"
                          value={form.email}
                          className="form-control"
                          id="email"
                        />
                        {formErrors.email && (
                          <span className={classes.error}>
                            {formErrors.email}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="confirmEmail"
                          className={`${classes.label} form-label`}
                        >
                          Confirm Email
                        </label>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          onKeyDown={handleKeyDown}
                          type="email"
                          placeholder="Re-type Email Here..."
                          name="confirmEmail"
                          value={form.confirmEmail}
                          className="form-control"
                          id="confirmEmail"
                        />
                        {formErrors.confirmEmail && (
                          <span className={classes.error}>
                            {formErrors.confirmEmail}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="phoneNumber"
                          className={`${classes.label} form-label`}
                        >
                          Phone Number
                        </label>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          type="tel"
                          placeholder="Type Phone Number Here..."
                          name="phoneNumber"
                          value={form.phoneNumber}
                          className="form-control"
                          id="phoneNumber"
                          pattern="[0-9]*"
                        />
                        {formErrors.phoneNumber && (
                          <span className={classes.error}>
                            {formErrors.phoneNumber}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                      <div className="form-group">
                        <label
                          htmlFor="firstName"
                          className={`${classes.label} form-label`}
                        >
                          First Name
                        </label>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          onKeyDown={handleKeyDown}
                          type="text"
                          placeholder="Type First Name Here..."
                          name="firstName"
                          value={form.firstName}
                          className="form-control"
                          id="firstName"
                          required
                        />
                        {formErrors.firstName && (
                          <span className={classes.error}>
                            {formErrors.firstName}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="lastName"
                          className={`${classes.label} form-label`}
                        >
                          Last Name
                        </label>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          onKeyDown={handleKeyDown}
                          type="text"
                          placeholder="Type Last Name Here..."
                          name="lastName"
                          value={form.lastName}
                          className="form-control"
                          id="lastName"
                          required
                        />
                        {formErrors.lastName && (
                          <span className={classes.error}>
                            {formErrors.lastName}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  {currentStep === 3 && (
                    <>
                      <div className={classes["authorization-container"]}>
                        <div>
                          <p className={classes["heading"]}>
                            Account Authorization:
                          </p>
                        </div>
                        <div className={classes["authorization-content"]}>
                          <div className={classes["authorization-option"]}>
                            <input
                              className={`${classes.radioInput}`}
                              type="radio"
                              id="email"
                              name="authorization"
                              value="email"
                              checked={selectedOption === "email"}
                              onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="email">
                              <span className="spanemail">Email : </span>
                              <span>{maskEmail(form.email)}</span>
                            </label>
                          </div>
                          <div className={classes["authorization-option"]}>
                            <input
                              className={`${classes.radioInput}`}
                              type="radio"
                              id="phone"
                              name="authorization"
                              value="phone"
                              checked={selectedOption === "phone"}
                              onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="phone">
                              <span>Phone : </span>
                              <span className="form-group">
                                <input
                                  type="tel"
                                  className="form-control"
                                  name="phoneNumber"
                                  placeholder="Type phone number here..."
                                  value={form.phoneNumber}
                                  onChange={changeHandler}
                                  disabled={true}
                                />
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className={classes["verify-message"]}>
                          We will send you a code to verify your account and to
                          keep it secure.
                        </p>
                      </div>
                    </>
                  )}
                  {currentStep === 4 && (
                    <>
                      <div className={classes["otp_section"]}>
                        <div>
                          <p className={classes["heading"]}>
                            Account Authorization:
                          </p>
                        </div>
                        <div className={classes["otp_input"]}>
                          <OTPInput
                            inputStyle={
                              invalidOtp ? invalidOtpStyle : inputStyle
                            }
                            value={otp}
                            onChange={getOtp}
                            numInputs={6}
                            // renderSeparator={(index) => (
                            //   <span>{index === 2 ?<span className={classes["dash"]}>-</span> : null}</span>
                            // )}
                            renderInput={(props) => (
                              <input
                                className={classes["otp_Input"]}
                                {...props}
                              />
                            )}
                          />
                        </div>
                        <div>
                          {invalidOtp && (
                            <p className={classes["timer"]}>
                              Wrong code. Please try again
                            </p>
                          )}
                        </div>
                        <div>
                          {timeLeft > 0 ? (
                            <p className={classes["timer"]}>
                              {timeLeft} seconds
                            </p>
                          ) : null}
                        </div>
                        <div class="mt-4">
                          <button
                            onClick={resendOtp}
                            type="button"
                            disabled={timeLeft !== 0}
                            className={`${classes.primary_button} primary-button`}
                          >
                            Resend Code
                          </button>
                        </div>
                        <div>
                          <p
                            onClick={handleBack}
                            className={classes["change_auth"]}
                          >
                            Change Authentication Device
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {currentStep === 5 && (
                    <>
                      <div className="form-group">
                        <label
                          htmlFor="newPassword"
                          className={`${classes.label} form-label`}
                        >
                          Password
                        </label>
                        <div className={classes["input-with-icon"]}>
                          <input
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            onKeyDown={handleKeyDown}
                            type={passwordType}
                            placeholder="Type New Password Here..."
                            name="newPassword"
                            value={form.newPassword}
                            className="form-control"
                            id="newPassword"
                          />
                          {form.newPassword && (
                            <div className={classes["icon-container"]}>
                              <span
                                className={classes["eyeicon"]}
                                onClick={viewPass}
                              >
                                <i
                                  className={`fa ${visible ? "fa-eye" : "fa-eye-slash"
                                    }`}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        {touchedFields.newPassword && formErrors.newPassword && (
                          <span className={classes.error}>
                            {formErrors.newPassword}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="confirmPassword"
                          className={`${classes.label} form-label`}
                        >
                          Confirm Password
                        </label>
                        <div className={classes["input-with-icon"]}>
                          <input
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            onKeyDown={handleKeyDown}
                            type={confirmPasswordType}
                            placeholder="Re-type Password Here..."
                            name="confirmPassword"
                            value={form.confirmPassword}
                            className="form-control"
                            id="confirmPassword"
                          />
                          {form.confirmPassword && (
                            <div className={classes["icon-container"]}>
                              <span
                                className={classes["eyeicon"]}
                                onClick={viewConfirmPass}
                              >
                                <i
                                  className={`fa ${visibleConfirm ? "fa-eye" : "fa-eye-slash"
                                    }`}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        {touchedFields.confirmPassword && formErrors.confirmPassword && (
                          <span className={classes.error}>
                            {formErrors.confirmPassword}
                          </span>
                        )}
                      </div>
                      <div className={classes["password-validation"]}>
                        <p className={classes["validation-heading"]}>
                          Password must contain:
                        </p>
                        <ul className={classes["validation-item"]}>
                          {!form.newPassword ? (
                            <li>
                              <span>At least 8 characters</span>
                            </li>
                          ) : (
                            <li style={{ color: passwordValidationErrors.minLength ? "green" : "red" }}>
                              <div className="row">
                                <span className="col-9">At least 8 characters</span>
                                <span className="col-3">
                                  <i className={`fa ${passwordValidationErrors.minLength ? "fa-check" : "fa-close"}`} />
                                </span>
                              </div>
                            </li>
                          )}
                          {!form.newPassword ? (
                            <li>
                              <span>At least 1 special character</span>
                            </li>
                          ) : (
                            <li style={{ color: passwordValidationErrors.specialChar ? "green" : "red" }}>
                              <div className="row">
                                <span className="col-9">At least 1 special character</span>
                                <span className="col-3">
                                  <i className={`fa ${passwordValidationErrors.specialChar ? "fa-check" : "fa-close"}`} />
                                </span>
                              </div>
                            </li>)}
                          {!form.newPassword ? (
                            <li>
                              <span>At least 1 number</span>
                            </li>
                          ) : (
                            <li style={{ color: passwordValidationErrors.number ? "green" : "red" }}>
                              <div className="row">
                                <span className="col-9">At least 1 number</span>
                                <span className="col-3">
                                  <i className={`fa ${passwordValidationErrors.number ? "fa-check" : "fa-close"}`} />
                                </span>
                              </div>
                            </li>)}
                          {!form.newPassword ? (
                            <li>
                              <span>At least 1 uppercase letter</span>
                            </li>
                          ) : (
                            <li style={{ color: passwordValidationErrors.uppercase ? "green" : "red" }}>
                              <div className="row">
                                <span className="col-9">At least 1 uppercase letter</span>
                                <span className="col-3">
                                  <i className={`fa ${passwordValidationErrors.uppercase ? "fa-check" : "fa-close"}`} />
                                </span>
                              </div>
                            </li>)}
                        </ul>
                      </div>
                    </>
                  )}
                  <div className="mt-2">
                    <button
                      disabled={
                        (currentStep === 1 &&
                          (!form.email ||
                            !form.confirmEmail ||
                            !form.phoneNumber ||
                            form.email !== form.confirmEmail ||
                            Object.keys(formErrors).some(
                              (key) => formErrors[key]
                            ))) ||
                        (currentStep === 2 &&
                          (!form.firstName ||
                            !form.lastName ||
                            Object.keys(formErrors).some(
                              (key) => formErrors[key]
                            ))) ||
                        (currentStep === 4 && otp.length < 6) ||
                        (currentStep === 5 &&
                          (!form.newPassword ||
                            !form.confirmPassword ||
                            form.newPassword !== form.confirmPassword ||
                            Object.keys(formErrors).some(
                              (key) => formErrors[key]
                            ))) ||
                        isLoading
                      }
                      className={`${classes.primary_button} primary-button`}
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    {currentStep > 1 && (
                      <button
                        type="button"  // Ensure the button type is "button"
                        className={`${classes.secondary_button} secondary-button mt-2`}
                        onClick={handleBack}
                      >
                        Back
                      </button>
                    )}
                    <div className="mt-4"></div>
                  </div>
                </div>
              </div>
            </form>
            {/* )} */}
            {/* {!tokenStatus && (
              <div className={classes["token-invalid"]}>
                <p>Invalid or expired token.</p>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  );
};

export default NewResetPassword;
