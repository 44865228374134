import React, { useEffect, useState } from "react";
import classes from "./CreateGroup.module.css";
import searchIcon from "../../../../../Assets/Logo/search-icon-2.svg";
import dumyImageIcon from "../../../../../Assets/Logo/dummy-image-icon.svg";
import nameInfoImage from "../../../../../Assets/Images/name-info-image.png";
import GroupService from "../../../../../Services/GroupService";
import Loader from "../../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";
import ConnectionService from "../../../../../Services/ConnectionService";
import userPic from "../../../../../Assets/Images/profile_avatar.png";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import { Form } from "react-bootstrap";

const CreateGroup = ({ onGroupCreationSuccess, isEditGroup , postDashboardData}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [invites, setInvites] = useState([]);
  const [moderators, setModerators] = useState([]);
  const [image, setImage] = useState(null);
  const [tempProfileImage, setTempProfileImage] = useState(null);
  const [accessLevel, setAccessLevel] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [moderatorSearchInput, setModeratorSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginated, setPaginated] = useState(true);
  const [invitesList, setInvitesList] = useState([]);
  const [moderatorsList, setModeratorsList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [userDetail] = useLocalStorage('userDetail');
  const studentId = userDetail.user_id;
  const [prevAccessLevel, setPrevAccessLevel] = useState(null);
  const fileExtention = ["image/png", "image/jpg", "image/jpeg"]
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedItemsMod, setSelectedItemsMod] = useState({});
  const {groupType, setGroupType, setPauseFlag, setUpdatePostDatata, setGroupIdGlobal}=useBehaviorSubject()


  // useEffect(() => {
  //   getGroupConnectionList("0");
  // }, []);
  useEffect(() => {
    if (isEditGroup && postDashboardData) {
      setGroupName(postDashboardData?.GroupName || "");
      setDescription(postDashboardData?.GroupDesc || "");
      setAccessLevel(Number(postDashboardData?.GroupType) || 0);
      setGroupId(postDashboardData?.GroupID || 0);
      setTempProfileImage(blobUrl + postDashboardData?.GroupImage || null);
      setInvites(postDashboardData?.invites || []);
      setModerators(postDashboardData?.moderators || []);
    }
  }, [isEditGroup, postDashboardData]);
  const validateField = (fieldName, value) => {
    const errors = { ...formErrors };

    switch (fieldName) {
      case "groupName":
        if (!value) {
          errors.groupName = "Group name is required.";
        } else {
          errors.groupName = "";
        }
        break;

      case "description":
        if (!value) {
          errors.description = "Description is required.";
        } else {
          errors.description = "";
        }
        break;

      case "image":
        // Check if image is uploaded (either via `image` or `tempProfileImage`)
        if (!value && !tempProfileImage) {
          errors.image = "Group image is required.";
        } else {
          errors.image = "";
        }
        break;

      case "accessLevel":
        if (!value) {
          errors.accessLevel = "Group type is required.";
        } else {
          errors.accessLevel = "";
        }
        break;

      default:
        break;
    }

    setFormErrors(errors);
  };

  const handleAccessLevelChange = (newAccessLevel) => {
    setSelectedItemsMod('')
    setSelectedItems('')
    // Reset lists if switching from Public to Private or vice versa
    if (prevAccessLevel !== null && prevAccessLevel !== newAccessLevel) {
      setInvitesList([]);
      setModeratorsList([]);
    }
  
    setAccessLevel(newAccessLevel);
    setPrevAccessLevel(newAccessLevel);
  
    if (newAccessLevel === 1) {
      getGroupConnectionListPublic();
    } else{
      getGroupConnectionList(); // Define this function for private-specific logic
    }
  };



  const getGroupConnectionListPublic = (type = 0, searchTerm = "", pageValue = 1) => {
    let data = {
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: searchTerm,
      studentId: studentId
    }
    setIsLoading(true)
    GroupService.getConnectionList(data).then(res => {
      if (type === "1") {
        setInvitesList([...res?.list] || []);
      } else if (type === "2") {
        setModeratorsList([...res?.list] || []);
      } else {
        setInvitesList([...res?.list] || []);
        setModeratorsList([...res?.list] || []);
      }
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
    })
  }
  const getGroupConnectionList = (type = 0, searchTerm = "", pageValue = 1) => {
    let data = {
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: searchTerm,
      studentId: studentId
    }
    setIsLoading(true)
    ConnectionService.getConnectionList(data).then(res => {
      if (type === "1") {
        setInvitesList([...res?.list] || []);
      } else if (type === "2") {
        setModeratorsList([...res?.list] || []);
      } else {
        setInvitesList([...res?.list] || []);
        setModeratorsList([...res?.list] || []);
      }
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
    })
  }

  // Function to upload the file
  const uploadFile = async () => {
    try {
      if (image) {
        const uploadResponse = await GroupService.groupFileService(image); // Upload file
        const fileLocation = uploadResponse?.fileLocation;
        // setTempProfileImage(fileLocation);
        return fileLocation;
      }
      return null;
    } catch (error) {
      toast.error(error?.message);
      return null;
    }
  };

  // Function to add group details
  const addGroupDetail = (fileLocation) => {
    const data = {
      GroupName: groupName,
      GroupDescription: description,
      GroupImage: fileLocation,
      GroupType: accessLevel,
      GroupID:groupId?groupId: 0,
    }
    setIsLoading(true);
    const groupAction = isEditGroup ? GroupService.updateGroup : GroupService.addGroup;
    groupAction(data).then((res) => {
        setIsLoading(false);
        if(res?.Message==='Group name already exists.'){
          toast.error(res?.Message)
          return 
        }
        toast.success(res?.message || "Group created successfully");
        if(!isEditGroup){
          addGroupRequest(res?.GroupID);
          setGroupId(res?.GroupID)
        }
        else{
          onGroupCreationSuccess();
          // setTempProfileImage(null);
        }
        setGroupIdGlobal(res?.GroupID)
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('Group name already exists.');
      });
  };

  //function to add group
  const addGroupRequest = (groupId) => {
    const allUserIDs = new Set([...invites, ...moderators]);


    // Create the ReceiverUserList by checking if each user is a moderator
    const receiverUserList = Array.from(allUserIDs).map((userID) => {
      const isModerator = moderators.includes(userID) ? 1 : 0;
      return {
        "IsModerator": isModerator,
        "ReceiverUserID": userID
      };
    });

    const dataPrivate = {
      "GroupID": groupId,
      "ReceiverUserList": receiverUserList
    };
    const dataPublic = Array.from(receiverUserList).map(data => ({
      "GroupID": groupId,
      "UserGroupRole": data.IsModerator === 1 ? 2 : 3,
      "UserID": data.ReceiverUserID,
    }));

    setIsLoading(true);
    const apiCall  = (accessLevel === 2 || accessLevel === 3) ? GroupService.addCreateGroupRequestForPrivate(dataPrivate) : accessLevel === 1 ? GroupService.addCreateGroupRequestForPublic(dataPublic) : '';
    apiCall.then((res) => {
        onGroupCreationSuccess();
        setIsLoading(false);
        if(receiverUserList?.length>0){
          toast.success(res?.message || "Group request added successfully");
        }
       
        // setTempProfileImage(null);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('Failed to add group request. Please try again.');
        console.log(error);
      });
  };

// Handle form submission for two functions: upload file and add group
const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate all fields before submission
  validateField("groupName", groupName);
  validateField("description", description);
  validateField("accessLevel", accessLevel);
  if (!isEditGroup || (isEditGroup && image)) {
    validateField("image", image || tempProfileImage);
  }


  if (Object.values(formErrors).some((error) => error)) {
    return;
  }

  setIsLoading(true);
  let fileLocation = postDashboardData?.GroupImage;

  if (image) {
    fileLocation = await uploadFile();
  }

  addGroupDetail(fileLocation);
};

const handleCancel = () =>{
  onGroupCreationSuccess();
}
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if(fileExtention.includes(file.type)){
      if(file.size < 10 * 1024 * 1024){
      const imageUrl = URL.createObjectURL(file);
      setImage(file); // Store the file for uploading
      setTempProfileImage(imageUrl); // Optional: preview the image
      validateField("image", file);
      }else{
        toast.error("File size must be less than 10 MB.");
      }
        }
        else {
          toast.error("Supported file format is JPG, JPEG, PNG, and PDF.");
        }
    }
  };

  const handleCheckboxChange = (event, profile, type) => {
    let userID=''
    if(profile.receiverUserId || profile.senderUserId){
      userID= profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId
    }else{
     userID = profile?.UserID
    }
   
    
    if (type === "invite") {
      if (event.target.checked) {
        setInvites((prev) => [...prev, userID]);
      } else {
        setInvites((prev) => prev.filter((id) => id !== userID));
      }
    } else if (type === "moderator") {
      if (event.target.checked) {
        setModerators((prev) => [...prev, userID]);
      } else {
        setModerators((prev) => prev.filter((id) => id !== userID));
      }
    }
  };

  const handleCheckboxChange1 = (id) => {
    setSelectedItems(prevSelected => {
      const newSelected = { ...prevSelected };
      if (newSelected[id]) {
        delete newSelected[id]; // Unselect the checkbox
      } else {
        newSelected[id] = true; // Select the checkbox
      }
      return newSelected;
    });
  };

  const handleCheckboxChangeMod = (id) => {
    setSelectedItemsMod(prevSelected => {
      const newSelected = { ...prevSelected };
      if (newSelected[id]) {
        delete newSelected[id]; // Unselect the checkbox
      } else {
        newSelected[id] = true; // Select the checkbox
      }
      return newSelected;
    });
  };

  const handleSearch = (type, value) => {
    if (accessLevel === 1) {
      getGroupConnectionListPublic(type, value);
    } else if(accessLevel===2){
      getGroupConnectionList(type, value);
    }else{
      getGroupConnectionList(type, value);
    }
  };

  
  const handleChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.trimStart();
    setSearchInput(sanitizedValue);
    if(sanitizedValue.length > 2){
    handleSearch("1", sanitizedValue);
    }
  };
  

  const handleModeratorSearchChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.trimStart();
    setModeratorSearchInput(sanitizedValue);
    if(sanitizedValue.length > 2){
    handleSearch("2", sanitizedValue); 
    }
  };



  return (
    <>
      <div className={` ${classes["main_create_group_container"]}`}>
        <div className={`row ${classes["create-group-form"]}`}>
          <div className={`${classes["create_group"]} ${isEditGroup ? "col-md-12" : "col-md-12"} ${isEditGroup ? "col-lg-8" : "col-lg-6"}`}>
            <div className={`${classes["form-section"]} form-group`}>
              <div className={`${classes["group-name-section"]} form-group`}>
                <div className={classes["heading"]}>Group Name</div>
                <div>
                <input
                  type="text"
                  className="form-control"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  onBlur={() => validateField("groupName", groupName)}
                  placeholder="Group Name"
                  required
                />
                {formErrors.groupName && (
                  <span className={classes.error}>
                    {formErrors.groupName}
                  </span>
                )}
                </div>
                <div className={classes["group-type"]}>
                  <div className={classes["radio-option"]}>
                    <input
                      type="radio"
                      name="groupType"
                      value={1}
                      checked={accessLevel === 1}
                      onChange={() => handleAccessLevelChange(1)}
                      onBlur={() => validateField("accessLevel", accessLevel)}
                    />
                    Public
                  </div>
                  <div className={classes["radio-option"]}>
                    <input
                      type="radio"
                      name="groupType"
                      value={2}
                      checked={accessLevel === 2}
                      onChange={() => handleAccessLevelChange(2)}
                      onBlur={() => validateField("accessLevel", accessLevel)}
                    />
                    Private
                  </div>
                  <div className={classes["radio-option"]}>
                    <input
                      type="radio"
                      name="groupType"
                      value={3}
                      checked={accessLevel === 3}
                      onChange={() => handleAccessLevelChange(3)}
                      onBlur={() => validateField("accessLevel", accessLevel)}
                    />
                    Exclusive
                  </div>
                  {formErrors.accessLevel && (
                    <span className={classes.error}>
                      {formErrors.accessLevel}
                    </span>
                  )}
                </div>
              </div>
            </div>

          {  !isEditGroup && <>
            <div className={`${classes["form-section"]}`}>
              <div className={classes["invite-section"]}>
                <div className={`${classes["heading"]} mt-2`}>Invite</div>

                <div className={`${classes["search_input_content"]} form-group mb-3`}>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={`${classes["search-input"]} form-control`}
                    value={searchInput}
                    onChange={handleChange}
                  />
                  <img
                    src={searchIcon}
                    alt="Search"
                    className={`${classes["search-icon"]}`}
                  />
                </div>
                <div className={`${classes["list-content"]}`}>
                  <ul className={classes["invite-list"]}>
                    {invitesList.map((profile) => (
                      <>
                      {profile.UserID!==studentId && (
                      <li key={profile.senderUserId|| profile.UserID} className={classes["name-item"]}>
                      <div className={classes["name-info"]}>
                        <img
                          src={profile.profileImage ? (blobUrl + profile.profileImage) : profile.ProfileImage? (blobUrl+ profile.ProfileImage) : userPic}
                          alt={profile.displayName ||profile.DisplayName}
                          className={classes["name-image"]}
                        />
                        <span>{profile.displayName||profile.DisplayName}</span>
                      </div>
                      <div className={classes["checkbox-container"]}>
                        <Form.Check
                          className={classes["checkbox-input"]}
                          type="checkbox"
                          checked={selectedItems[profile?.UserID?profile?.UserID: profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId] || false}
                          onChange={(event) => {handleCheckboxChange(event, profile, "invite"); handleCheckboxChange1(profile?.UserID?profile?.UserID:profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId)}}
                        >
                          </Form.Check>
                      </div>
                     
                    </li>
                      ) }

                      </>))}
                  </ul>
                </div>
              </div>
            </div>
            <div className={classes["form-section"]}>
              <div className={classes["invite-section"]}>
                <div className={`${classes["heading"]} mt-2`}>Moderators</div>
                <div className={`${classes["search_input_content"]} form-group mb-3`}>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={`${classes["search-input"]} form-control`}
                    value={moderatorSearchInput}
                    onChange={handleModeratorSearchChange}
                  />
                  <img
                    src={searchIcon}
                    alt="Search"
                    className={`${classes["search-icon"]}`}
                  />
                </div>
                <div className={`${classes["list-content"]}`}>
                  <ul className={classes["moderator-list"]}>
                    {moderatorsList.map((profile) => (
                      <li key={profile.senderUserId ||profile.UserID} className={classes["name-item"]}>
                        <div className={classes["name-info"]}>
                          <img
                            src={profile.profileImage ? (blobUrl + profile.profileImage) : profile.ProfileImage? (blobUrl+ profile.ProfileImage) : userPic}
                            alt={profile.displayName||profile.DisplayName}
                            className={classes["name-image"]}
                          />
                          <span>{profile.displayName||profile.DisplayName}</span>
                        </div>
                        <div className={classes["checkbox-container"]}>
                        <Form.Check
                            className={classes["checkbox-input"]}
                            type="checkbox"
                            checked={selectedItemsMod[profile?.UserID?profile?.UserID: profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId] || false}
                            onChange={(event) => {handleCheckboxChange(event, profile, "moderator"); handleCheckboxChangeMod(profile?.UserID?profile?.UserID: profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId)}}
                          >
                        </Form.Check>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            </>}
           </div>

          <div className={`${classes["create_group"]} ${isEditGroup ? "col-md-12" : "col-md-12"}  ${isEditGroup ? "col-lg-8" : "col-lg-6"}`}>
            <div className={`${classes["form-section"]}`}>
              <div className={`${classes["image-upload-section"]}`}>
                <div className={classes["heading"]}>Image</div>

                <div className={`${classes["image-upload"]}`}>
                  {(!tempProfileImage || !isEditGroup) && (
                    <div className={classes["over-lay-upload"]}>
                      <p className={classes["hover-upload"]}>
                     {!tempProfileImage &&   
                        <img
                          className={classes["damy-image"]}
                          src={dumyImageIcon}
                          alt=""
                        />}{" "}
                      </p>
                      <input
                        type="file"
                        title="Select file"
                        onChange={handleFileChange}
                        className={classes["image-upload-input"]}
                      />
                    </div>
                  )}

                  {tempProfileImage && (<>
                    <input
                    type="file"
                    title="Select file"
                    onChange={handleFileChange}
                    id="backgroundImageInput"
                    style={{ display: "none" }}
                    className={classes["image-upload-input"]}
                  />
                    <img
                      className={classes["real-image"]}
                      src={tempProfileImage}
                      alt="Uploaded"
                      onClick={() => {
                        const backgroundImageInput = document.getElementById("backgroundImageInput");
                        
                        if (backgroundImageInput) {
                          backgroundImageInput.click();
                        }
                      }}
                    />
                        </>
                  )}
              {formErrors.image && (
                  <span className={classes.error}>
                    {formErrors.image}
                  </span>
                )}
                </div>
  
              </div>
            </div>

            <div
              className={`${classes["form-section"]} ${classes["description-section"]}`}
            >
              <div className={classes["heading"]}>Description</div>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() => validateField("description", description)}
                placeholder="Description..."
                required
                maxLength={1000}
              />
              {formErrors.description && (
                <span className={classes.error}>
                  {formErrors.description}
                </span>
              )}
            </div>

          <div className="row">
            <div className="col-md-6 mb-3">
            <button
              type="submit"
              className={`${classes["create-group-button"]}`}
              onClick={handleSubmit}
              disabled={
                !groupName ||
                !description ||
                !tempProfileImage ||
                !accessLevel
              }
            >
             {isEditGroup ? "Update Group" : "Create Group"}
            </button>
            </div>
            <div className="col-md-6">
            <button
              type="cancel"
              className={`${classes["create-group-cancel-button"]}`}
              onClick={handleCancel}
            >
             Cancel
            </button>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <Loader></Loader>
        )}
      </div>
    </>
  );
};


export default CreateGroup;
