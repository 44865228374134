import { Modal } from 'react-bootstrap';
import classes from './UserModal.module.css';
import corssIcon from "../../../../Assets/Logo/crossred.svg";
import shareIcon from '../../../../Assets/Logo/share-blue-icon.svg';
import likeIcon from '../../../../Assets/Logo/liked.svg';
import postPic from "../../../../Assets/Images/profile_avatar.png";
import { useEffect, useState } from 'react';

const UserModal = (props) => {
  const blobURL = process.env.REACT_APP_URL_PATH;
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const totalCount = props?.data?.length || 0;
    setCount(totalCount);

    const userList = props?.data?.filter(item => item?.Username) || [];
    const groupList = props?.data?.filter(item => item?.GroupName) || [];

    setUsers(userList);
    setGroups(groupList);
  }, [props?.data]);

    // useEffect(()=>{
    //     if(props?.data?.length<1000){
    //         setCount(props?.data?.length)
    //     }
        
    //     if(props?.data?.length>=1000){
    //         const dataCount=props?.data?.length/1000
    //         setCount(dataCount.toFixed(2) +'K')
    //     }

    // }, [])

  return (
    <>
      <div>
        <Modal show={props.onShow}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Body>
            <div className={classes['header-icon']}>
              <div>
                <img className={classes['share-icon']} src={props?.text === 'share' ? shareIcon : props?.text === 'like' ? likeIcon : ''} alt=''></img>
                <span className={classes['count-number']}>{count}</span>
              </div>
              <div className={classes['cross-icon-position']}>
                <img title="Close" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={props.onClose}></img>
              </div>
            </div>

            <div className={classes['modal-body-data']}>
              {users.length > 0 && (
                <>
                  <div className={classes["name"]}>Users</div>
                  {users.map(user => (
                    <div className={classes['user-section']} key={user?.Username}>
                      <div>
                        <img className={classes['img-size']} src={user?.ProfilePic ? blobURL + user?.ProfilePic : postPic} alt=''></img>
                      </div>
                      <div>
                        <p className={classes['user-name']}>{user?.Username}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {groups.length > 0 && (
                <>
                  <div className={classes["name"]}>Groups</div>
                  {groups.map(group => (
                    <div className={classes['user-section']} key={group?.GroupName}>
                      <div>
                        <img className={classes['img-size']} src={group?.GroupImage ? blobURL + group?.GroupImage : postPic} alt=''></img>
                      </div>
                      <div>
                        <p className={classes['user-name']} title={group?.GroupName}>{group?.GroupName}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default UserModal;