import classes from './Programs.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; 
import nodata from '../../../../../Assets/Logo/no-data1.svg';
import calendar from '../../../../../Assets/Logo/calender-icon.svg';
import universityLogo from "../../../../../Assets/Logo/dougl-icon.svg";
import sideClose from "../../../../../Assets/Logo/sideclose-2.svg";
import { useState,useEffect } from 'react';
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import downarrow from "../../../../../Assets/Logo/ic-down-arrow 3.svg";
import uparrow from '../../../../../Assets/Logo/ic-top-arrow.svg';
import SearchModal from '../SearchModal/SearchModal';
import dropdownpart3 from '../../../../../Assets/Logo/dropdownpart3.svg';
import ProgramsModalBody from './ProgramsModalBody/ProgramsModalBody';

const Programs = ({ programs = [], filters = [], fees = [],courseTypes = [], onTuitionFeeChange, onProgramTypeChange, onDurationChange, selectedProgramTypeIds, selectedDurationIdss, selectedCountryId, resetFlag,fetchProgramInfo,isFlagProgram }) => {
    const [modalIsVisible, setModalIsVisible] = useState();
    const [programData, setProgramData] = useState({});
    const [modalSize, setModalSize] = useState('');
    const [activeKey, setActiveKey] = useState(['0', '1', '2', '3']); // Initial Open States set
  const [userDetail] = useLocalStorage('userDetail');
    const studentId=userDetail?.refId
    const navigate = useNavigate();
    const [selectedDurationIds, setSelectedDurationIds] = useState([]);
    const [selectedProgramTypeId, setSelectedProgramTypeId] = useState('');
    const bloburl = process.env.REACT_APP_LOGO_PATH;
    const [selectedTuitionFees, setSelectedTuitionFees] = useState([]);
    const [applyFilterColor, setApplyFilterColor] = useState('#363636');
    const [filterAction, setFilterAction] = useState(null); // 'apply' or 'clear'
    const handleAccordionSelect = (eventKey) => {
        setActiveKey(prevActiveKey => {
            if (prevActiveKey.includes(eventKey)) {
                return prevActiveKey.filter(key => key !== eventKey);
            } else {
                return [...prevActiveKey, eventKey];
            }
        });
    };
    const handleApplyClick = (data) => {
        const programTypeId = selectedProgramTypeIds === "1" ? 1 : 2;
        navigate("/member/application/add/" + programTypeId + "?studentId=" + studentId + '&programId=' + data?.ProgramId);
    };
    function hideModalHandler() {
        setModalIsVisible(false);
    }
    const clearFilters = () => {
        setSelectedTuitionFees([]);
        // setSelectedProgramTypeId('');
        setSelectedDurationIds([]);
        onTuitionFeeChange([]);
        // onProgramTypeChange('');
        onDurationChange([]);
        setFilterAction('clear');
        setApplyFilterColor('#363636'); // Change color to black when cleared
    };
    const applyFilters = () => {
        setFilterAction('apply');
        setApplyFilterColor('#E4151B'); // Change color to red when applied
    };
    useEffect(() => {
        if (filterAction === 'apply') {
            fetchProgramInfo(1, false); // Apply filters and fetch data
        } else if (filterAction === 'clear') {
            fetchProgramInfo(1, true); // Reset to page 1
        }
        setFilterAction(null); // Reset filter action
    }, [selectedTuitionFees, selectedProgramTypeId, selectedDurationIds, filterAction]);



    useEffect(()=>{
    
        if(isFlagProgram){
            setSelectedTuitionFees([]);
            setSelectedDurationIds([]);
        }

    },[isFlagProgram])

    function stripHtmlTags(str) {
        if (!str) return '';
        return str.replace(/<\/?[^>]+(>|$)/g, "");
      }
    
    const truncateText = (text, maxLength) => {
        if (!text) return 'N/A';
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
      };

    function showModalHandler(value, size) {
        setModalIsVisible(true);
        setProgramData(value);
        setModalSize(size);
    }
    const handleProgramTypeSelect = (programTypeId) => {
        const updatedSelectedProgramTypeId = selectedProgramTypeIds === programTypeId ? '' : programTypeId;
        setSelectedProgramTypeId(updatedSelectedProgramTypeId);
        onProgramTypeChange(updatedSelectedProgramTypeId);
    };

    const handleDurationChange = (durationId) => {
        const updatedSelectedDurationIds = selectedDurationIds.includes(durationId)
            ? selectedDurationIds.filter(id => id !== durationId)
            : [...selectedDurationIds, durationId];
        setSelectedDurationIds(updatedSelectedDurationIds);
        onDurationChange(updatedSelectedDurationIds); // Updated to pass array
    };
    const limitCharacters = (text, limit = 20) => {
        if (!text) return 'N/A';
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    };
    const handleTuitionFeeChange = (feeValue) => {
        const updatedSelectedTuitionFees = selectedTuitionFees.includes(feeValue)
            ? selectedTuitionFees.filter(value => value !== feeValue)
            : [...selectedTuitionFees, feeValue];
        setSelectedTuitionFees(updatedSelectedTuitionFees);
        onTuitionFeeChange(updatedSelectedTuitionFees); // Pass the selected fees
    };
    const institutionId = programs.length > 0 ? programs[0].InstitutionId : null;
    const handleProgramClick = (programId) => {
        const programDetailUrl = `/member/programs/detailPage/${programId}`;
        window.open(programDetailUrl, '_blank');
      };

    if (!selectedCountryId) return null;

    return (
        <>
            {modalIsVisible && (
                <SearchModal onShow={showModalHandler} size={modalSize}>
                    <ProgramsModalBody onClose={hideModalHandler} program={programData} />
                </SearchModal>
            )}

            <div>
                <div className="row">
                    {/* Secondary Filters */}
                    {/* {resetFlag && (
                        <div className="col-md-3 col-lg-2">
                            <div>
                            {(fees.length > 0 || filters.Duration?.length > 0) && (  
                                <Accordion className="filter-section" activeKey={activeKey} onSelect={handleAccordionSelect}>
                               
                                <Accordion.Item eventKey="3">
                                        <Accordion.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}className={classes["custom-accordion-header"]}>
                                        <div className={classes["accordiankey"]}>
                                        <div className={classes["newh"]}>
                                        <span>Filters</span>
                                            </div>
                                            <div className={classes["newimg"]}> */}
                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('3') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                            {/* </div>
                                        </div>
                    </Accordion.Header>
                                        <Accordion.Body className={classes["comman-filters"]}>
                                        <div>
                                        <span style={{ textDecoration: 'none', color: applyFilterColor }} onMouseOver={(e) => {
                                            e.target.style.textDecoration = 'underline';
                                            e.target.style.color = '#E4151B '
                                            e.target.style.cursor = 'pointer';
                                        }} onMouseOut={(e) => {
                                            e.target.style.textDecoration = 'none';
                                            e.target.style.color = applyFilterColor;
                                        }} onClick={applyFilters}>Apply filter</span>
                                        </div>
                                        <div>
                                        <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
                                            e.target.style.textDecoration = 'underline';
                                            e.target.style.color = '#E4151B ';
                                            e.target.style.cursor = 'pointer';
                                        }} onMouseOut={(e) => {
                                            e.target.style.textDecoration = 'none';
                                            e.target.style.color = 'black';
                                        }}  onClick={clearFilters}>Clear filter</span>
                                        </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {fees.length > 0 && (
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        <div className={classes["accordiankey"]}>
                                        <span>Tuition</span> */}
                                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('3') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                       {/* </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {fees.map(fee => (
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`tutionFee-${fee.srNo}`}
                                                    label={fee.feeCaption}
                                                    title={fee.feeCaption}
                                                    key={fee.srNo}
                                                    checked={selectedTuitionFees.includes(fee.feeValue)}
                                                    onChange={() => handleTuitionFeeChange(fee.feeValue)}
                                                />
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    )}
                                    {filters?.length > 0 && (
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        <div className={classes["accordiankey"]}>

                                        <span>Duration</span> */}
                                        {/* <img src={Array.isArray(activeKey) && activeKey.includes('3') ? downarrow : uparrow} alt="Accordion dropdown icon" style={{ width: '24px', height: '24px' }} /> */}
                                        {/* </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Form>
                                                {filters.map((item) => (
                                                    item.SrNo && item.DurationCaption && (
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`duration-${item.SrNo}`}
                                                            label={item.DurationCaption}
                                                            title={item.DurationCaption}
                                                            key={item.SrNo}
                                                            checked={selectedDurationIds.includes(item.SrNo)}
                                                            onChange={() => handleDurationChange(item.SrNo)}
                                                        />
                                                    )
                                                ))}
                                            </Form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    )}
                                </Accordion>
                                )}
                            </div>
                        </div>
                    )} */}


                    {/* Main Content */}
                    <div className="col-md-11 col-lg-12">
                    {programs.length > 0 ? (
                        <div className={classes["program-cards"]}>
                            {programs.map((program) => (
                                // <div className={classes["card-section"]} onClick={() => showModalHandler(program, 'md')} key={program?.ProgramId}>
                                <div className={classes["card-section"]} key={program?.ProgramId}>
                                    <div className={classes["section-1"]}>
                                        <div className={classes["card-header-block-1"]}>
                                            {/* <div className={classes["card-rank"]}> <span className={classes["rank-text"]}>Ranked: {program?.rank || 'N/A'}</span></div> */}
                                            <div className={classes["card-header"]}>
                                                <div className={classes[""]}>
                                                    <img
                                                        src={program?.InstLogoPath ? bloburl + program.InstLogoPath : universityLogo}
                                                        alt="Profile"
                                                        className={classes["university-icon"]}
                                                    />
                                                </div>
                                                <div className={classes["university-detail"]}>
                                                    <div title={program?.Instname} className={classes["university-name"]}>{program?.Instname || 'N/A'}</div>
                                                    <div className={classes["university-address"]}>{program?.InstCountryName || 'N/A'}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes["card-header-block-2"]}>
                                        <div className={classes["detail_UI"]}>{program?.EduLevelName || 'N/A'}{' '}{program?.DurationTime || 'N/A'}</div>
                                        <div className={classes["bracket"]}>
                                        <div className={`row justify-content-between ${classes["detail"]}`}>
                                            <div title={program?.ProgramName} onClick={() => handleProgramClick(program?.ProgramId)} className={` col-8 ${classes["program-name"]} col-8-to-7`}>{program?.ProgramName || 'N/A'}</div>
                                        <div className={` col-3 ${classes["calender-data"]}`} ><img src={calendar} alt="calendar" style={{marginRight: '7px',height: '20px', width: '20px'}}/>{program?.DurationTime || 'N/A'}
                                        </div>
                                            </div>
                                            <div className={`row mt-3 justify-content-between ${classes["detail"]}`}>

                                            <div className={`col-8 ${classes["program-detail-text"]} col-8-to-7`}  title={stripHtmlTags(program?.Description) || 'N/A'}>
                                                <p dangerouslySetInnerHTML={{ __html:   limitCharacters(stripHtmlTags(program?.Description), 200) }}></p>

                                                {/* {program?.Description || 'N/A'} */}
                                                
                                            </div>
                                            <div className={`col-3  ${classes["Intake"]}`} >
                                                <div className={classes["intake-header"]}>Intake</div>
                                                {program.Intakes.length === 0 ? (
                                                    <div className={classes["intake-detail"]}>N/A</div>
                                                ) : (
                                                    [...new Set(program.Intakes.map(intake => intake.IntakeName))].map((intakeName, index) => (
                                                        <div key={index} className={classes["intake-detail"]}>{intakeName || 'N/A'}</div>
                                                    ))
                                                )}
                                            </div>
                                            </div>
                                            
                                        </div>
                                          
                                        </div>
                                    </div>
                                    <div className={classes["section-2"]}>
                                        <div className={classes["program-detail-section-1"]}>
                                            {/* <div>
                                                <div className={classes["intake-header"]}>Intake</div>
                                                {program.Intakes.length === 0 ? (
                                                    <div className={classes["detail"]}>N/A</div>
                                                ) : (
                                                    [...new Set(program.Intakes.map(intake => intake.IntakeName))].map((intakeName, index) => (
                                                        <div key={index} className={classes["detail"]}>{intakeName || 'N/A'}</div>
                                                    ))
                                                )}
                                            </div> */}
                                            <div className={`${classes["fee-detail"]}`}>
                                            {/* <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail_UI"]}>{program?.ApplicationFee || 'N/A'}</div> 
                                            </div> */}
                                             <div className=''>
                                            <div className={classes["header"]}>Application Fee</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.FeeDetail[0]?.FeeAmount || 'N/A'}
                                                </div>
                                            </div>
                                            <div className=''>

                                            <div className={classes["header"]}>Tuition Fees</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.FeeDetail[0]?.FeeAmount || 'N/A'}
                                                </div>
                                                            <div className={classes["new-fee"]}>
                                                                <span className={classes['other-fee']} data-tooltip="Other Fee">Other Fee</span>{'  '}
                                                                <img src={sideClose} alt='sideclose'></img>
                                                            </div>
                                            </div>
                                            <div className=''>

                                            <div className={classes["header"]}>Cost of Living</div>
                                            <div className={classes["detail_UI"]}><span style={{ color: '#E4151B' }}> 
                                                {program?.ProgramCurrency  || 'N/A'}</span>&nbsp;  
                                                {program?.FeeDetail[0]?.FeeAmount || 'N/A'}
                                                </div>
                                            </div>
                                            {/* <div className={classes["vertical-control"]}> */}
                                            {/* </div> */}
                                            {/* <div className={classes["vertical-control"]}>
                                            <div className={classes["header"]}>Cost of Living</div>
                                            <div className={classes["detail_UI"]}>{program?.Costofliving || 'N/A'}</div>
                                            </div> */}
                                            </div>
                                            <div className={classes["parent-button"]}>
                                                <div> 
                                                <button className={` mr-1 ${classes["know-btn"]}`}  onClick={() => handleProgramClick(program?.ProgramId)}>Know More</button>
                                                </div>
                                                <div>
                                                <button className={` ml-1 ${classes["create-btn"]}`} onClick={() => handleApplyClick(program)}>Create Application</button>
                                                </div>
                                            </div>
                                            </div>
                                        <div className={classes["program-detail-section-2"]}>
                                            {/* <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail"]}>{program?.ApplicationFee || 'N/A'}</div>
                                            </div> */}
                                            {/* <div>
                                                <div className={classes["header"]}>People like You</div>
                                                <div className={classes["detail"]}>{program?.PeopleLikeYou || 'N/A'}</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                                <div className={classes["detail"]}>{program?.LikelihoodOfGraduation ? `${program.LikelihoodOfGraduation}%` : 'N/A'}</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) :  resetFlag && (
                        <div className={classes["NOdata"]}>
                            <div>
                            <img className={classes["noimg"]}src={nodata} alt='nodata' />
                            </div>
                            <div className={classes['line']}>
                            </div>
                            <div className={classes['nospace']}>
                            <span className={classes["no"]}>No data found</span> 
                            <span className={classes['moment']}> at the moment.</span>
                            </div>
                            </div>
                    )}
                </div>
                </div>
            </div>
        </>
    )
}

export default Programs;