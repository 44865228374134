import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import classes from './Header.module.css';
import homeIC from "../../../Assets/Logo/ic-home.svg";
import networkIC from "../../../Assets/Logo/ic-network.svg";
import searchIC from "../../../Assets/Logo/ic-pro-search.svg";
import appIC from "../../../Assets/Logo/ic-application.svg";
import marketPlaceIC from "../../../Assets/Logo/ic-market-place.svg";
import jobsIC from "../../../Assets/Logo/ic-jobs.svg";
import exploreIC from "../../../Assets/Logo/ic-explore.svg";
import messageIC from "../../../Assets/Logo/ic-message.svg";
import hamburgerIC from "../../../Assets/Logo/ic-hamburger-n.svg";
import hamburgerRedIC from "../../../Assets/Logo/ic-hamburger-red.svg";
import homeRedIC from "../../../Assets/Logo/ic-home-red.svg";
import networkRedIC from "../../../Assets/Logo/ic-network-red.svg";
import searchRedIC from "../../../Assets/Logo/ic-pro-search-red.svg";
import appRedIC from "../../../Assets/Logo/ic-application-red.svg";
import marketPlaceRedIC from "../../../Assets/Logo/ic-market-place-red.svg";
import jobsRedIC from "../../../Assets/Logo/ic-jobs-red.svg";
import exploreRedIC from "../../../Assets/Logo/ic-explore-red.svg";
import messageRedIC from "../../../Assets/Logo/ic-message-red.svg";
import notificationIC from "../../../Assets/Logo/notification-icon.svg";
import profilePlaceholderIC from "../../../Assets/Images/profile_avatar.png";
import searchIcon from "../../../Assets/Logo/search-icon.svg";
import dropdownIcon from "../../../Assets/Logo/drop-down-ic.svg";
import { useBehaviorSubject } from '../../GlobleShared/BehaviorSubject/BehaviorSubject';
import { useInput } from '../../Pages/Home/Dashboard/DashboardSearch/GlobalSearch';
import Notifications from "../../Common/Notifications/Notifications";
import Logo from '../../../Assets/Images/msm-unify-logo.svg';

import Messages from '../Messages/Messages';
import useLocalStorage from '../../GlobleShared/CustomHooks/useLocalStorage';
import ProfileService from '../../../Services/ProfileService';
import Loader from '../../GlobleShared/Loader/Loader';
import { useProfile } from '../../Context/ProfileContext';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
const items = [
    'Programs',
    'Institute',
    'Peoples',
  ];

function Header() {
    const [profileDropDown, setprofileDropDown] = useState();
    const dropdownRef = useRef(null);
    const notificationDiv = useRef(null);
    const massageDiv = useRef(null)
    const navigate = useNavigate();
    const initial = useRef(null);
    const moreDropdownRef = useRef(null);

    const { isLoginUser, logout } = useBehaviorSubject();
    const inputdiv = useRef(null);
    const [inputFlag, setInputFlag] = useState(false);
    const [query, setQuery] = useState('');
    const { inputValue, setInputValue } = useInput();
    const [toolsBtnPopupOpen, setToolsBtnPopupOpen] = useState(false);
    const toolsBtnRef = useRef(null);
    const blobUrl = process.env.REACT_APP_URL_PATH;
    const { profileImage, setProfileImage } = useProfile();
    const [userDetail] = useLocalStorage('userDetail');
    const studentId = userDetail.refId;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const defaultProfileImage = profilePlaceholderIC;
    const [currentProfileImage, setCurrentProfileImage] = useState(defaultProfileImage);
    const [activeTab, setActiveTab] = useState("Home");
    const [moreOptions, setmoreOptions] = useState(false);
    const [deviceSizeFlag, setdeviceSizeFlag] = useState();
    const location = useLocation();

    const toggleDropdown = () => {
        setDropdownOpen(true);
    };
    const fetchProfileInfo = async () => {
        setIsLoading(true);
        try {
            const response = await ProfileService.getAboutProfileInfo(studentId);
            if (response && response.ProfileImgPath) {
                const profileImgUrl = blobUrl + response.ProfileImgPath;
                setProfileImage(profileImgUrl);
                setCurrentProfileImage(profileImgUrl);
                localStorage.setItem('userInfo', JSON.stringify(response)) // Update the current profile image
            }
        } catch (error) {
            console.error('Error fetching profile image:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
            fetchProfileInfo();
        }
    }, [setProfileImage]);

    const [massageFlag, setMassageFlag] = useState(false);

    const handleTabClick = (tabName, path) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setActiveTab(tabName);
        navigate(path);
    };

    const isActive = (path) => location.pathname === path;

    const toggleToolsBtnPopup = () => {
        setToolsBtnPopupOpen(true);
    }
    const viewProfileClick = () => {
        setprofileDropDown(false);
        navigate('/member/profile');
    }
    const setImputValue = (value) => {
        setQuery(value);
        setInputFlag(false)
        setInputValue(value);
        if (value.trim()) {
            navigate('/member/dashboard');
        }

    }
    const filteredItems = items.filter(item =>
        item.toLowerCase().includes(query.toLowerCase())
    );
    const getInputValue = (value) => {
        setQuery(value);
        setInputFlag(true)
        // setInputValue(value);
        if (value.trim()) {
            navigate('/member/dashboard');
        }

    }
    const showMassages = () => {
        if (!massageFlag) {
            setMassageFlag(true)
        }
        if (massageFlag) {
            setMassageFlag(false)
        }
    }
    const handleClickOutside = (event) => {
        if (inputdiv) {
            if (inputdiv.current && !inputdiv.current.contains(event.target)) {
                setInputFlag(false)
            }
        }
        if (notificationDiv) {
            if (notificationDiv.current && !notificationDiv.current.contains(event.target)) {
                setDropdownOpen(false)
            }
        }
        if (massageDiv) {
            if (massageDiv.current && !massageDiv.current.contains(event.target)) {
                setMassageFlag(false)
            }
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', onClickOutsideHandler);
        return () => {
            document.removeEventListener('mousedown', onClickOutsideHandler);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 1450) {
                setdeviceSizeFlag(true);
            }
            else {
                setdeviceSizeFlag(false);
            }
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function onProfileHandler() {
        setActiveTab("");
        setprofileDropDown(!profileDropDown);
    }

    function onClickOutsideHandler(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setprofileDropDown(false);
            setToolsBtnPopupOpen(false);
        }
    }

    function onClickOutsideHandler(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setprofileDropDown(false);
        }

        if (moreDropdownRef.current && !moreDropdownRef.current.contains(event.target)) {
            setmoreOptions(false);
        }
        setToolsBtnPopupOpen(false);
    }

    function onLogOutHandler() {
        setprofileDropDown(false);
        logout();
    }


    document.addEventListener('mousedown', handleClickOutside);

    const handleDropdownToggle = () => {
        setmoreOptions((prev) => !prev);
    };

    return (
        <div className={classes["msm-header"]}>
            <div className={classes["top-header-container"]}>
                <div className={classes["top-header-block"]}>
                    <div></div>
                    <div>
                        <div className={classes["search-bar"]}>
                            <div className={classes['div-width']}>
                                <input value={query} onChange={(e) => getInputValue(e.target.value)} type="text" placeholder="Platform Search" />
                                {inputFlag && filteredItems?.length > 0 && query?.length > 2 && (
                                    <div ref={inputdiv} className={classes['searchInputValue']}>
                                        {filteredItems.map((item, index) => (
                                            <div className={classes['dropdown-text']} key={index} onClick={() => { setImputValue(item) }}>{item}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <button type="submit"><img src={searchIcon} /></button>


                        </div>
                    </div>
                </div>
            </div>

            <div className={classes["header-container"]}>
                {isLoading && <Loader />}
                <div className={classes["header-block"]}>
                    <div className={classes["header-left"]}>
                        <img
                            onClick={() => handleTabClick("Home", "/member/dashboard")}
                            src={Logo}
                            className={classes['logo']}
                            alt=""
                        />
                    </div>

                    <div className={classes["header-center"]}>
                        <div className={classes["nav-icons"]}>
                            <div onClick={() => handleTabClick("Home", "/member/dashboard")} className={classes["nav-item"]}>
                                <div>
                                    <img
                                        src={isActive("/member/dashboard") ? homeRedIC : homeIC}
                                        className={classes["nav-item-logo"]}
                                    />
                                </div>
                                <div className={`${classes["nav-item-detail"]} ${isActive("/member/dashboard") ? classes["active"] : ""}`}>
                                    Home
                                </div>
                            </div>

                            <div onClick={() => handleTabClick("Network", "/member/connections")} className={classes["nav-item"]}>
                                <div>
                                    <img
                                        src={isActive("/member/connections") ? networkRedIC : networkIC}
                                        className={classes["nav-item-logo"]}
                                    />
                                </div>
                                <div className={`${classes["nav-item-detail"]} ${isActive("/member/connections") ? classes["active"] : ""}`}>
                                    Network
                                </div>
                            </div>

                            <div onClick={() => handleTabClick("Search", "/member/search")} className={classes["nav-item"]}>
                                <div>
                                    <img
                                        src={isActive("/member/search") ? searchRedIC : searchIC}
                                        className={classes["nav-item-logo"]}
                                    />
                                </div>
                                <div className={`${classes["nav-item-detail"]} ${isActive("/member/search") ? classes["active"] : ""}`}>
                                    Program Search
                                </div>
                            </div>

                            <div onClick={() => handleTabClick("Applications", "/member/application/list")} className={classes["nav-item"]}>
                                <div className={classes['blinktext']}>Apply Now</div>
                                <div>
                                    <img
                                        src={isActive("/member/application/list") ? appRedIC : appIC}
                                        className={classes["nav-item-logo"]}
                                    />
                                </div>
                                <div className={`${classes["nav-item-detail"]} ${isActive("/member/application/list") ? classes["active"] : ""}`}>
                                    Applications
                                </div>
                            </div>
                            
                            <div className={classes["nav-item"]}>
                                <div><img src={marketPlaceIC} className={classes["nav-item-logo"]} /></div>
                                <div className={classes["nav-item-detail"]}>Market Place</div>
                            </div>
                            {/* <div className={`${classes["nav-item"]}`}>
                            <div><img src={item7} className={classes["nav-item-logo"]} onClick={() => toggleToolsBtnPopup()} /></div>
                            <div className={classes["nav-item-detail"]} onClick={() => toggleToolsBtnPopup()}>Tools <img src={dropdownIcon} className={classes["drop-down-icon"]} /></div>
                            <div className={classes["nav-item-position-relative"]}>
                                {toolsBtnPopupOpen && (
                                    <div className={classes["ToolBtn"]} ref={dropdownRef}>
                                        <div className={classes["ToolBtnItem"]} onClick={() => navigate('/member/tools/education-calculator')}>Education Calculator</div>
                                        <div className={classes["brokenLine"]}></div>
                                        <div className={classes["ToolBtnItem"]} onClick={() => navigate('/member/tools/living-cost-calculator')}>Living Cost Calculator</div>
                                        <div className={classes["brokenLine"]}></div>
                                        <div className={classes["ToolBtnItem"]} onClick={() => navigate('/member/tools/currency-converter')}>Currency Converter</div>
                                        <div className={classes["brokenLine"]}></div>
                                        <div className={classes["ToolBtnItem"]} onClick={() => navigate('/member/tools/english-score-calculator')}>English Score Calculator</div>
                                    </div>
                                )}
                            </div>
                        </div> */}
                            {!deviceSizeFlag && (
                                <div className={classes["nav-item"]}>
                                    <div><img src={jobsIC} className={classes["nav-item-logo"]} /></div>
                                    <div className={classes["nav-item-detail"]}>Jobs</div>
                                </div>
                            )}
                            {!deviceSizeFlag && (
                                <div className={classes["nav-item"]}>
                                    <div><img src={messageIC} className={classes["nav-item-logo"]} /></div>
                                    <div className={classes["nav-item-detail"]}>Messages</div>
                                </div>
                            )}
                            {/* <div onClick={showMassages} className={classes["nav-item"]}  >
                                <div><img src={messageIC} className={classes["nav-item-logo"]} /></div>
                                <div className={classes["nav-item-detail"]}>Messages</div>
                                {massageFlag && (
                                    <div ref={massageDiv} className={classes['massage-position']}>
                                        <Messages></Messages>
                                    </div>
                                )}
                            </div> */}
                            {!deviceSizeFlag && (
                                <div className={classes["nav-item"]}>
                                    <div><img src={exploreIC} className={classes["nav-item-logo"]} /></div>
                                    <div className={classes["nav-item-detail"]}>Explore <img src={dropdownIcon} className={classes["drop-down-icon"]} /></div>
                                </div>
                            )}
                            {deviceSizeFlag && (
                                <div className={`${classes["nav-item"]} ${classes["hamburger"]}`} ref={moreDropdownRef} onClick={handleDropdownToggle}>
                                    <div><img src={exploreIC} className={classes["nav-item-logo"]} alt="Hamburger Icon" /></div>
                                    <div className={classes["nav-item-detail"]}>Explore <img src={dropdownIcon} className={classes["drop-down-icon"]} /></div>
                                    {moreOptions && (
                                        <div className={classes["dropdown-menu"]}>
                                            <div className={classes["dropdown-item"]}>
                                                <img src={jobsIC} className={classes["nav-item-logo"]} />
                                                <div className={classes["nav-item-detail"]}>Jobs</div>
                                            </div>
                                            {/* <div className={classes["dropdown-item"]}>
                                            <img src={marketPlaceIC} className={classes["nav-item-logo"]} />
                                            <div className={classes["nav-item-detail"]}>Marketplace</div>
                                        </div> */}
                                            <div className={classes["dropdown-item"]}>
                                                <img src={messageIC} className={classes["nav-item-logo"]} />
                                                <div className={classes["nav-item-detail"]}>Messages</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={classes["header-right"]}>
                        <div className={`${classes["nav-item"]} ${classes["additionalclass"]}`}>
                            <div onClick={toggleDropdown}><img src={notificationIC} className={classes["nav-item-logo"]} /></div>
                            {dropdownOpen && (
                                <div ref={notificationDiv} className={classes["dropdown-notify"]}>
                                    <Notifications />
                                    hello
                                </div>
                            )}

                        </div>
                        <div className={classes["user-details-divider"]}></div>
                        <div className={classes["Profile-drop-down"]} ref={dropdownRef}>
                            <div className={classes["dropdown-username"]}>{userDetail?.first_name}</div>
                            <img
                                src={currentProfileImage}
                                alt="Profile"
                                className={classes["profile-placeholder"]}
                                onClick={onProfileHandler}
                            />
                            {/* <div><img src={dropdownIcon} className={classes["drop-down-icon"]} onClick={onProfileHandler} /></div> */}
                            {profileDropDown && (
                                <div className={classes["dropDownProfile"]}>
                                    <ul className={classes["drop-down-items"]}>
                                        <li className={classes["open-dropdown"]}>
                                            <img className={classes["profile-placeholder"]} src={currentProfileImage} alt="Profile" />
                                            {/* <p className={classes["dropdown-username"]}>{userDetails?.first_name} {userDetails?.last_name}</p> */}
                                        </li>
                                        <li className={classes["dropdown-item-text"]} onClick={viewProfileClick}>View Profile</li>
                                        <li className={classes["dropdown-item-text"]} onClick={onLogOutHandler}>Logout</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
