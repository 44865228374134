import React, { useState } from 'react';
import classes from "./GroupsTabs_Info_Panel.module.css";
import iconEdit2 from "../../../../../Assets/Logo/iconEdit2.svg";
import { useBehaviorSubject } from '../../../../GlobleShared/BehaviorSubject/BehaviorSubject';


function GroupsTabs_Info_Panel({groupData, tab, setTab, name, access, members, handleInvite,toggleComponent}) {
  const {groupType, setGroupType, pauseFlag, groupIdGlobal, memberCount}=useBehaviorSubject()
  

  // const [tab, setTab] = useState("Post");
  return (
    <div>
        <div className={classes["group_tabs_container"]}>
          <div className={classes["group_info"]}>

            <div className={classes["info_section"]}>
                 
               <div className={classes["info_content"]}>
                 <div className={classes["info_title"]}>
                  <span>{name}</span> 
                  { (!groupType && !pauseFlag && groupData?.UserGroupRole==='1' && groupData?.UserGroupStatusId !== '3') && (
                   <img  className={classes["edit_icon"]} src={iconEdit2} alt="" onClick={() =>{toggleComponent('updateGroup');}}/>
                  )}
                 
                  </div>
                 <div className={classes["info_description"]}> {access} | {(memberCount && groupIdGlobal) ? memberCount : members} Members </div>
               </div>
              {(!groupType && !pauseFlag && groupData?.UserGroupStatusId !== '3' && groupData?.UserGroupStatusId !== '12'&& ((groupData?.GroupType==='1') || (groupData?.GroupType==='2' && (groupData?.UserGroupRole==='1' || groupData?.UserGroupRole==='2')) ||
               (groupData?.GroupType==='3' && groupData?.UserGroupRole==='1'))) && (
                <div  className={classes["info_content"]}>
                <button className={classes["invite_button"]} onClick={handleInvite}>+ Invite</button>
                </div>
               )}


            </div>

            <div className={classes["nav_buttons"]}>

            {(groupData?.UserGroupStatusId !== '3') && (groupData?.UserGroupStatusId !== '12')  && (
              <>
                <button
                  className={tab === "Post" ? classes["active_button"] : classes["nav_button"]}
                  onClick={() => setTab("Post")}
                >
                  Post
                </button>

                {((groupType && groupData?.GroupType==='1') || !groupType) && (
                                  <button
                                  className={tab === "Discussion" ? classes["active_button"] : classes["nav_button"]}
                                  onClick={() => setTab("Discussion")}
                                >
                                  Discussion
                                </button>
                ) }
              {((groupType && groupData?.GroupType==='1') || !groupType) && (
                <button
                  className={tab === "Members" ? classes["active_button"] : classes["nav_button"]}
                  onClick={() => setTab("Members")}
                >
                  Members
                </button>
              )}

                {((groupData?.UserGroupRole==='1' ||  groupData?.UserGroupRole==='2') && !groupType) && (
                <button
                className={tab === "Moderator" ? classes["active_button"] : classes["nav_button"]}
                onClick={() => setTab("Moderator")}
              >
                Moderator 
              </button>
                )}
              </>
            )}
              

              </div>
            </div>
            
        </div>
    </div>
  )
}

export default GroupsTabs_Info_Panel