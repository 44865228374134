import React, { useRef, useState,useEffect } from "react";
import Loader from "../../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import AIME from "../AIME/AIME"
import Select from 'react-select';
import ProgramSearchService from "../../../../../Services/ProgramSearchServices";
import ApiService from "../../../../../Services/APIService";
import Programs from "../Programs/Programs";
import compare from '../../../../../Assets/Logo/comparenew.svg'
import newallfilter from '../../../../../Assets/Logo/allfilter.svg'
import sort1 from '../../../../../Assets/Logo/sort1.svg'
import Institutes from "../Institutes/Institutes";
import Scholarship from "../Scholarship/ScholarShip";
import styles from './SearchPage.module.css';
import dropdown4 from '../../../../../Assets/Logo/dropdown4.svg'
import allfilterIcon from '../../../../../Assets/Logo/all-filter.svg'
import alphaBetIcon from '../../../../../Assets/Logo/alphabet-icon.svg'
import dropdownIcon from '../../../../../Assets/Logo/drop-down_icon.svg'
import searchIcon from '../../../../../Assets/Logo/search-icon3.svg'
import updownIcon from '../../../../../Assets/Logo/updownarrow.svg'
import whiteArrow from '../../../../../Assets/Logo/white-down-arrow.svg';
import locattionIcon from '../../../../../Assets/ALL_FIlter/blue-location-icon.svg'
import tutionIcon from '../../../../../Assets/ALL_FIlter/tution-icon.svg'
import courseIcon from '../../../../../Assets/ALL_FIlter/course-type-icon.svg'
import applicationIcon from '../../../../../Assets/ALL_FIlter/application-icon.svg'
import englishIcon from '../../../../../Assets/ALL_FIlter/engilsh-score-icon.svg'
import intakeIcon from '../../../../../Assets/ALL_FIlter/intake-icon.svg'
import schoolIcon from '../../../../../Assets/ALL_FIlter/school-icon.svg'
import gmtIcon from '../../../../../Assets/ALL_FIlter/gmt-icon.svg'

import backInitial from '../../../../../Assets/Logo/no-programme.jpg'
import internshipIcon from '../../../../../Assets/ALL_FIlter/internship-icon.svg'
import desiplineIcon from '../../../../../Assets/ALL_FIlter/decipline-icon.svg'
import durationIcon from '../../../../../Assets/ALL_FIlter/duration-icon.svg'
import SearchModal from "../SearchModal/SearchModal";
import ComparePrograms from "../../../../Common/ComparePrograms/ComparePrograms";
import Paginations from "../../../../Common/Pagination/Paginations";
import { tab } from "@testing-library/user-event/dist/tab";
// import Select from "react-select/base";

const sortAlphabet=['Alphabetical', 'Tution', 'Ranking', 'Likes', 'Institute', 'Program'];
const country=['India', 'Canada', 'Nepal', 'US', 'UK']



const SearchPage = () => {
    const initial = useRef(null);
    const [countryError, setCountryError] = useState('');
    const [courseTypeError, setCourseTypeError] = useState('');
    const shortDiv = useRef(null);
    const countryDiv = useRef(null);
    const inputdiv = useRef(null);
    const allfilterDiv = useRef(null);
    const [tabIndex, setTabIndex] = useState(2);
    const [noDataFlag, setNoDataFlag] = useState(false);
    const [shordropdown, setShordropdown] = useState(false);
    const [countryFlag, setcountryFlag] = useState(false);
    const [countryName, setCountryName] = useState("Country");
    const [query, setQuery] = useState('');
    const [inputFlag, setInputFlag] = useState(false);
    const [allFilterFlag, setAllFilterFlag] = useState(false);
    const [locationFlag, setlocationFlag] = useState(false);
    const [courseTypeFlag, setcourseTypeFlag] = useState(false);
    const [tutionFlag, settutionFlag] = useState(false);
    const [durationFlag, setdurationFlag] = useState(false);
    const [internshipFlag, setinternshipFlag] = useState(false);
    const [intakeFlag, setintakeFlag] = useState(false);
    const [applicatioFlag, setapplicatioFlag] = useState(false);
    const [greFlag, setgreFlag] = useState(false);
    const [schoolFlag, setschoolFlag] = useState(false);
    const [desipilineFlag, setdesipilineFlag] = useState(false);
    const [englishScorFlag, setenglishScorFlag] = useState(false);
    const [modalIsVisible, setModalIsVisible] = useState();
    const [modalSize, setModalSize] = useState('');
    const [programs, setPrograms] = useState([]);
    const [searchprograms, setSearchPrograms] = useState([]);
    const [institutesData, setInstitutesData] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [filters, setFilters] = useState([]);
    const [programCount, setProgramCount] = useState([]);
    const [fees, setFees] = useState([]);
    const [filteredItemss, setFilteredItems] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [items, setItems] = useState([
        'Apple',
        'Appple',
        'Banana',
        'Cherry',
        'Date',
        'Fig',
        'Grape',
        'Kiwi'
    ]);
    const [insdisciplines, setInsDisciplines] = useState([]);
const [educationLevels, setEducationLevels] = useState([]);
const [examAccepted, setExamAccepted] = useState([]);
const [programTypes, setProgramTypes] = useState([]);
const [selectedEnglishScores, setSelectedEnglishScores] = useState([]);
const [durations, setDurations] = useState([]);
const[InsselectedProgramTypeId, setInsselectedProgramTypeId]=useState('');
    const [selectedSortOption, setSelectedSortOption] = useState('');
    const [programItems, setProgramItems] = useState([]);
    const [instituteItems, setInstituteItems] = useState([]);
    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disciplines, setDisciplines] = useState([]);
    const [prodisciplines, setProDisciplines] = useState([]);
    const [eduLevels, setEduLevels] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState('');
    const [selectedProgramTypeId, setSelectedProgramTypeId] = useState('');
    const [scholarships, setScholarships] = useState([]);
    const [scholarshipData, setScholarshipData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [selectedDurationId, setSelectedDurationId] = useState('');
    const [resetFlag, setResetFlag] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [paginated, setPaginated] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [instituteNames, setInstituteNames] = useState([]);
    const [selectedTuitionFees, setSelectedTuitionFees] = useState([]);
    // const [selectedDisciplines, setSelectedDisciplines] = useState([]);
    const [selectedInsDisciplines, setSelectedInsDisciplines] = useState([]);
    const [selectedInsDuration, setSelectedInsDuration] = useState([]);
    const [selectedInsProgramtype, setSelectedInsProgramtype] = useState([]);
    const [selectedInsEducationLevel, setSelectedInsEducationLevel] = useState([]);
    const [selectedInsExamAccepted, setSelectedInsExamAccepted] = useState([]);
    const [selectedScholarAmounts, setSelectedScholarAmount] = useState([]);
    const [selectedScholarInstitutes, setSelectedScholarInstitutes] = useState([]);
    const [selectedScholarProgram, setSelectedScholarProgram] = useState([]);
    const [selectedScholarEdulevels, setSelectedScholarEdulevels] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltipAllFilters, setShowTooltipAllFilters] = useState(false);
  const [showTooltipSort, setShowTooltipSort] = useState(false);
  const [isFlagScholar, setIsFlagScholar] = useState(false);
  const [isFlagProgram, setIsFlagProgram] = useState(false);
  const [isFlagInstitute, setIsFlagInstitute] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
//   const [selectedTuitionFees, setSelectedTuitionFees] = useState([]);
const [selectedDurationIds, setSelectedDurationIds] = useState([]);
  const [isClearAllClicked, setIsClearAllClicked] = useState(false);
  const [amountFlag, setAmountFlag] = useState(false);
const [instituteFlag, setInstituteFlag] = useState(false);
const [programFlag, setProgramFlag] = useState(false);
  const [disciplineFlag, setDisciplineFlag] = useState(false);
const [educationFlag, setEducationFlag] = useState(false);
const [examFlag, setExamFlag] = useState(false);
const [filteredDisciplines, setFilteredDisciplines] = useState([]);
const [selectedDuration, setSelectedDuration] = useState([]);
const [selectedProgramtype, setSelectedProgramtype] = useState([]);
const [selectedExamAccepted, setSelectedExamAccepted] = useState([]); 
const [selectedEducationLevel, setSelectedEducationLevel] = useState([]); 
const [selectedDisciplines, setSelectedDisciplines] = useState([]);
const [searchInput, setSearchInput] = useState("");
const [isOpen, setIsOpen] = useState(false);
const [activeKey, setActiveKey] = useState(["0", "1", "2", "3", "4","5"]); // Initial Open States set
const [selectedAmounts, setSelectedAmount] = useState([]);
const [selectedInstitutes, setSelectedInstitutes] = useState([]);
const [selectedPrograms, setSelectedPrograms] = useState([]);
const sortOptions = {
    "A-Z": 1,
    "Z-A": 2,
    "Lowest": 3,
    "Highest": 4
};
const handleDisciplineChange = (disciplineId) => {
    const updatedSelectedDisciplines = selectedDisciplines.includes(disciplineId)
        ? selectedDisciplines.filter(id => id !== disciplineId)
        : [...selectedDisciplines, disciplineId];
    setSelectedDisciplines(updatedSelectedDisciplines);
};
const handleAmountChange = (amountType) => {
    const updatedSelectedAmounts = selectedScholarAmounts.includes(amountType)
        ? selectedScholarAmounts.filter(value => value !== amountType)
        : [...selectedScholarAmounts, amountType];
    setSelectedScholarAmount(updatedSelectedAmounts);
};
const InsHandleDeciplineChange = (disciplineName) => {
    const updatedSelectedInsDisciplines = selectedInsDisciplines.includes(disciplineName)
        ? selectedInsDisciplines.filter(name => name !== disciplineName)
        : [...selectedInsDisciplines, disciplineName];
    setSelectedInsDisciplines(updatedSelectedInsDisciplines);
};
const handleInstituteChange = (institutionId) => {
    const updatedSelectedInstitutes = selectedScholarInstitutes.includes(institutionId)
        ? selectedScholarInstitutes.filter(id => id !== institutionId)
        : [...selectedScholarInstitutes, institutionId];
    setSelectedScholarInstitutes(updatedSelectedInstitutes);
};

// useEffect(() => {
//     const updateLimit = () => {
//         if (window.innerWidth < 1301) {
//             setLimit(15);
//         } else {
//             setLimit(16);
//         }
//     };
//     updateLimit();
//     window.addEventListener('resize', updateLimit);
//     return () => window.removeEventListener('resize', updateLimit);
// }, []);

const schhandleProgramChange = (programId) => {
    const updatedSelectedPrograms = selectedScholarProgram.includes(programId)
        ? selectedScholarProgram.filter(id => id !== programId)
        : [...selectedScholarProgram, programId];
    setSelectedScholarProgram(updatedSelectedPrograms);
};
const showAmount = () => {
    setAmountFlag(!amountFlag);
};
const options = countryList.map((item) => ({
    value: item.InstCountryId,
    label: item.InstCountryName
}));

const showInstitute = () => {
    setInstituteFlag(!instituteFlag);
};

const showProgram = () => {
    setProgramFlag(!programFlag);
};
const handleProgramChange = (programId) => {
    const updatedSelectedPrograms = selectedScholarProgram.includes(programId)
        ? selectedScholarProgram.filter(id => id !== programId)
        : [...selectedScholarProgram, programId];
    setSelectedScholarProgram(updatedSelectedPrograms);
};
const InshandleDurationChange = (durationCategoryId) => {
    const updatedSelectedInsDuration = selectedInsDuration.includes(durationCategoryId)
        ? selectedInsDuration.filter(id => id !== durationCategoryId)
        : [...selectedInsDuration, durationCategoryId];
    setSelectedInsDuration(updatedSelectedInsDuration);
};

const handleProgramTypeChange1 = (programTypeId) => {
    const updatedSelectedProgramtype = selectedProgramtype.includes(programTypeId)
        ? selectedProgramtype.filter(id => id !== programTypeId)
        : [...selectedProgramtype, programTypeId];
    setSelectedProgramtype(updatedSelectedProgramtype);
};

const handleExamAcceptedChange = (examId) => {
    const updatedSelectedInsExamAccepted = selectedInsExamAccepted.includes(examId)
        ? selectedInsExamAccepted.filter(id => id !== examId)
        : [...selectedInsExamAccepted, examId];
    setSelectedInsExamAccepted(updatedSelectedInsExamAccepted);
};
const handleEducationLevelChange = (eduLevelId) => {
    const updatedSelectedInsEducationLevel = selectedInsEducationLevel.includes(eduLevelId)
        ? selectedInsEducationLevel.filter(id => id !== eduLevelId)
        : [...selectedInsEducationLevel, eduLevelId];
    setSelectedInsEducationLevel(updatedSelectedInsEducationLevel);
};
const handleEnglishScoreChange = (scoreId) => {
    const updatedSelectedEnglishScores = selectedEnglishScores.includes(scoreId)
        ? selectedEnglishScores.filter(id => id !== scoreId)
        : [...selectedEnglishScores, scoreId];
    setSelectedEnglishScores(updatedSelectedEnglishScores);
};
const handleAccordionSelect = (eventKey) => {
    setActiveKey(prevActiveKey => {
        if (prevActiveKey.includes(eventKey)) {
            return prevActiveKey.filter(key => key !== eventKey);
        } else {
            return [...prevActiveKey, eventKey];
        }
    });
};
const resetPayloadFields = () => {
    setSelectedDisciplines([]);
    setSelectedDurationIds([]);
    setSelectedEnglishScores([]);
    setSelectedTuitionFees([]);
    setSelectedInsDisciplines([]);
    setSelectedInsDuration([]);
    setSelectedInsProgramtype([]);
    setSelectedInsExamAccepted([]);
    setSelectedInsEducationLevel([]);
    setSelectedScholarAmount([]);
    setSelectedScholarInstitutes([]);
    setSelectedScholarProgram([]);
    setSelectedScholarEdulevels('');
    setQuery('');
};

const [instituteData, setInstituteData] = useState({
    Education: [],
    Req: [],
    Program: []
});
const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
    }
  };
const showDuration = () => {
    setdurationFlag(!durationFlag);
};

const showDiscipline = () => {
    setDisciplineFlag(!disciplineFlag);
};

const showEducationLevel = () => {
    setEducationFlag(!educationFlag);
};

const showExamAccepted = () => {
    setExamFlag(!examFlag);
};

const showProgramType = () => {
    setProgramFlag(!programFlag);
};



const clearAllSelections = () => {
    if (tabIndex === 2) {
        setSelectedDisciplines([]);
        setSelectedDurationIds([]);
        setProDisciplines([]);
        setSelectedEnglishScores([]);
        setSelectedTuitionFees([]);
    } else if (tabIndex === 3) {
        setSelectedInsDisciplines([]);
        setSelectedInsDuration([]);
        setSelectedProgramtype([]);
        setSelectedInsExamAccepted([]);
        setSelectedInsEducationLevel([]);
        setSelectedTuitionFees([]);
    } else if (tabIndex === 4) {
        setSelectedScholarAmount([]); // Corrected here
        setSelectedScholarInstitutes([]); // Corrected here
        setSelectedScholarProgram([]); 
    }
    setIsClearAllClicked(true);
    setSelectedSortOption('');
    setAllFilterFlag(false);
};

useEffect(() => {
    if (isClearAllClicked) {
        if (tabIndex === 2 && selectedDisciplines.length === 0 && selectedEnglishScores.length === 0 &&selectedTuitionFees.length === 0 && selectedDurationIds.length === 0) {
            fetchProgramInfo(1, true);
        } else if (tabIndex === 3 && selectedDisciplines.length === 0 && selectedTuitionFees.length === 0 && selectedDuration.length === 0 && selectedProgramtype.length === 0 && selectedExamAccepted.length === 0 && selectedEducationLevel.length === 0) {
            fetchInstituteData(1, true);
        } else if (tabIndex === 4 && selectedAmounts.length === 0 && selectedInstitutes.length === 0 && selectedScholarProgram.length === 0) {
            fetchScholarshipData(1, true);
        }
        setIsClearAllClicked(false); // Reset the flag
    }
}, [selectedDisciplines, selectedTuitionFees, selectedDurationIds,selectedEnglishScores, selectedDuration, selectedProgramtype, selectedExamAccepted, selectedEducationLevel, selectedAmounts, selectedInstitutes, selectedScholarProgram, isClearAllClicked]);
    // const handleTuitionFeeChange = (selectedFees) => {
    //     setSelectedTuitionFees(selectedFees);
    // };  
    const handleTuitionFeeChange = (feeValue) => {
        const updatedSelectedTuitionFees = selectedTuitionFees.includes(feeValue)
            ? selectedTuitionFees.filter(value => value !== feeValue)
            : [...selectedTuitionFees, feeValue];
        setSelectedTuitionFees(updatedSelectedTuitionFees);
    };
    const handleDurationChange = (durationId) => {
        const updatedSelectedDurationIds = selectedDurationIds.includes(durationId)
            ? selectedDurationIds.filter(id => id !== durationId)
            : [...selectedDurationIds, durationId];
        setSelectedDurationIds(updatedSelectedDurationIds);
    };
    const fetchsearchdata = () => {
        
        const payload = {
            "Nationality": selectedCountryId,
            "CategoryId": 0,
            "KeyWord": query,
            "ProgramCategoryID": selectedProgramTypeId,
        };
        setIsLoading(true);
    
        ProgramSearchService.getprogramsearch(payload)
            .then((res) => {
                if (res) {
                    
                    const programs = res.find(item => item.label === "Programs")?.data || [];
                    setPrograms(programs); // Set the programs
                    setFilteredPrograms(programs); 
                    setProgramItems(programs);
                }
                else {
                    setPrograms([]);
                    setProgramItems([]);
                    setFilteredPrograms([]);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };
      
      
    //   fetch country list
    const fetchCountryList = async (countryFlag) => {
        setIsLoading(true);
        ProgramSearchService.getInstituteCountry(countryFlag)
            .then((res) => {
                setCountryList(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
            fetchCountryList(false);
            //   fetchInstituteData();
        }
    }, []);
   
    useEffect(() => {
        if (tabIndex === 3 && selectedCountryId) {
            fetchInstitutionDetails(selectedCountryId);
        }
    }, [selectedCountryId, tabIndex]);

    const fetchProgramDiscipline = (instCountryId, programTypeId, edulevelId) => {
        setIsLoading(true);
        ProgramSearchService.getProgramDiscipline(instCountryId, programTypeId, edulevelId)
            .then((res) => {
                setDisciplines(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };


    const fetchEduLevels = (instCountryId, programTypeId) => {
        setIsLoading(true);
        ProgramSearchService.getEducationLevel(instCountryId, programTypeId)
            .then((res) => {
                setEduLevels(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };




    // get programs based on search


    // const fetchProgramInfo = (pageValue, flag) => {
    //     if (!selectedCountryId || !selectedProgramTypeId) {
    //         return;
    //     }
    //     setPage(pageValue)
    //     setIsFlagProgram(flag);
    //     if(flag){
    //         setSelectedDurationIds([]);
    //         setSelectedTuitionFees([]);
    //         setProDisciplines([]);
    //         setSelectedDisciplines([]);
    //         setSelectedEnglishScores([]);
    //     }
    //     const searchCriteria = {
    //         Descipline: selectedDisciplines.length > 0 ? selectedDisciplines: [],
    //         Duration: selectedDurationIds.length > 0 ?  selectedDurationIds.map(Number):[],
    //         EnglishId: selectedEnglishScores.length > 0 ? selectedEnglishScores : [],
    //         InstCountryId: Number(selectedCountryId),
    //         Keyword: query || '',
    //         LevelofEducationId: [],
    //         OffersDealsId: [],
    //         PageNumber: pageValue,
    //         ProgramTypeId: Number(selectedProgramTypeId),
    //         RowsPerPage: limit,
    //         SortOrder:  selectedSortOption,
    //         TuitionFee: selectedTuitionFees.length > 0 ?  selectedTuitionFees :[]
    //     };

        
    //     setIsLoading(true);
    //     ProgramSearchService.getProgramInfo(searchCriteria)
    //         .then((res) => {
    //             if (res) {
    //                 setIsSearching(true)
    //                 setPrograms(res);
    //                 setProgramData(res?.program);
    //                 if(flag){
    //                     setFilters(res.filters[0].Duration);
    //                     setFees(res.filters[0].Fee);
    //                     setRequirements(res.filters[0].Req);
    //                     setProDisciplines(res.filters[0].Discipline);
    //                 }

    //                 setTotalPages(Math.ceil((res.programCount[0].TotalRecordCount) / limit));
    //                 setResetFlag(true);
    //                 setIsLoading(false);
    //                 setNoDataFlag(res.program.length === 0); 
    //                 setAllFilterFlag(false);
    //             }
    //             else {
    //                 setPrograms([]);
    //                 setProgramData([]);
    //                 setFilters([]);
    //                 setProDisciplines([]);
    //                 setFees([]);
    //                 setTotalPages(0);
    //                 setNoDataFlag(true);
    //                 setResetFlag(false);
    //                 setAllFilterFlag(false);
    //             }
                  
    //         })
    //         .catch((error) => {
    //             setIsLoading(false);
    //             toast.error(error?.message);
    //         });
    // };

    // const handleDurationChange = (durationId) => {
    //     setSelectedDurationId(durationId);
    // };

    const fetchProgramInfo = (pageValue, flag, sortOrderId) => {
        if (!selectedCountryId || !selectedProgramTypeId) {
            return;
        }
        // setPage(pageValue);
        setIsFlagProgram(flag);
        // setPrograms([]); // Clear previous program data
        // setProgramData([]); 
        if (flag) {
            setSelectedDurationIds([]);
            setSelectedTuitionFees([]);
            setProDisciplines([]);
            setSelectedDisciplines([]);
            setSelectedEnglishScores([]);
        }
        const searchCriteria = {
            Descipline: selectedDisciplines.length > 0 ? selectedDisciplines : [],
            Duration: selectedDurationIds.length > 0 ? selectedDurationIds.map(Number) : [],
            EnglishId: selectedEnglishScores.length > 0 ? selectedEnglishScores : [],
            InstCountryId: Number(selectedCountryId),
            Keyword: query || '',
            LevelofEducationId: [],
            OffersDealsId: [],
            PageNumber: pageValue,
            ProgramTypeId: Number(selectedProgramTypeId),
            RowsPerPage: limit,
            SortOrder: sortOrderId || selectedSortOption,
            TuitionFee: selectedTuitionFees.length > 0 ? selectedTuitionFees : []
        };
    
        setIsLoading(true);
        ProgramSearchService.getProgramInfo(searchCriteria)
            .then((res) => {
                if (res) {
                    setIsSearching(true);
                    setPrograms(res);
                    setProgramData(res?.program);
                    if (flag) {
                        setFilters(res.filters[0].Duration);
                        setFees(res.filters[0].Fee);
                        setRequirements(res.filters[0].Req);
                        setProDisciplines(res.filters[0].Discipline);
                    }
    
                    setTotalPages(Math.ceil((res.programCount[0].TotalRecordCount) / limit));
                    setResetFlag(true);
                    setIsLoading(false);
                    setNoDataFlag(res.program.length === 0);
                    setAllFilterFlag(false);
                } else {
                    setPrograms([]);
                    setProgramData([]);
                    setFilters([]);
                    setProDisciplines([]);
                    setFees([]);
                    setTotalPages(0);
                    setNoDataFlag(true);
                    setResetFlag(false);
                    setAllFilterFlag(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };
    
    const fetchInstituteData = (pageValue, flag, sortOrderId) => {
        if (!selectedCountryId) {
            return;
        }
    
        setIsFlagInstitute(flag);
        if (flag) {
            setSelectedInsDisciplines([]);
            setSelectedInsDuration([]);
            setSelectedInsExamAccepted([]);
            setSelectedInsEducationLevel([]);
            setSelectedTuitionFees([]);
        }
        const data = {
            Descipline: selectedInsDisciplines,
            Duration: selectedInsDuration,
            EnglishId: selectedInsExamAccepted,
            InstCountry: Number(selectedCountryId),
            InstName: query || '',
            LevelofEducationId: selectedInsEducationLevel,
            OffersDealsId: [],
            PageNumber: pageValue,
            ProgramTypeId: InsselectedProgramTypeId,
            RowsPerPage: limit,
            SortOrder: sortOrderId || selectedSortOption,
            TuitionFee: selectedTuitionFees.length > 0 ? selectedTuitionFees : []
        };
    
        setIsLoading(true);
        ProgramSearchService.getInstitutionInfo(data)
            .then((res) => {
                if (res) {
                    setIsSearching(true);
                    setResetFlag(true);
                    setInstitutes(res);
                    if (flag) {
                        setInstitutesData(res?.institution);
                        setDisciplines(res.filters[0].Discipline);
                        setEducationLevels(res.filters[0].Education);
                        setExamAccepted(res.filters[0].Req);
                        setFees(res.filters[0].Fee);
                        setProgramTypes(res.filters[0].Program);
                        setDurations(res.filters[0].Duration);
                        setFilters(res.filters[0].Duration);
                        setAllFilterFlag(false);
                    }
    
                    setNoDataFlag(res.institution.length === 0);
                    setTotalPages(Math.ceil((res.InstituteCount[0].TotalRecordCount) / limit));
                } else {
                    setInstitutes([]);
                    setInstitutesData([]);
                    setDisciplines([]);
                    setEducationLevels([]);
                    setFees([]);
                    setNoDataFlag(true);
                    setExamAccepted([]);
                    setProgramTypes([]);
                    setDurations([]);
                    setTotalPages(0);
                    setResetFlag(false);
                    setNoDataFlag(true);
                    setAllFilterFlag(false);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };
    
    const fetchScholarshipData = (pageValue, flag, sortOrderId) => {
        if (!selectedCountryId) {
            return;
        }
    
        setIsFlagScholar(flag);
        if (flag) {
            setSelectedScholarAmount([]);
            setSelectedScholarInstitutes([]);
            setSelectedScholarProgram([]);
        }
        const scholarshipSearchRequest = {
            AmtType: selectedScholarAmounts,
            InstCountryId: selectedCountryId,
            InstitutionId: selectedScholarInstitutes,
            LevelofEducationId: selectedScholarEdulevels,
            PageNumber: pageValue,
            ProgramName: selectedScholarProgram,
            RowsPerPage: limit,
            SortOrder: sortOrderId || selectedSortOption
        };
    
        setIsLoading(true);
        ProgramSearchService.getScholarshipInfo(scholarshipSearchRequest)
            .then((response) => {
                if (response) {
                    setIsSearching(true);
                    setResetFlag(true);
                    setScholarships(response);
                    if (flag) {
                        setScholarshipData(response?.Institution);
                    }
    
                    setTotalPages(Math.ceil((response?.ScholarShipCount[0]?.TotalRecordCount) / limit));
                    setNoDataFlag(response.Institution.length === 0);
                    setAllFilterFlag(false);
                } else {
                    setScholarships([]);
                    setScholarshipData([]);
                    setTotalPages(0);
                    setNoDataFlag(true);
                    setAllFilterFlag(false);
                    setResetFlag(false);
                }
    
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };
    // const fetchInstituteData = (pageValue, flag) => {

    //     if (!selectedCountryId) {
    //         return;
    //     }

    //     setIsFlagInstitute(flag)
    //     if(flag){
    //         setSelectedInsDisciplines([]);
    //         setSelectedInsDuration([]);
    //         setSelectedInsExamAccepted([]);
    //         setSelectedInsEducationLevel([]);
    //         setSelectedTuitionFees([]);
    //     }
    //     const data = {
    //         Descipline: selectedInsDisciplines,
    //         Duration:  selectedInsDuration,
    //         EnglishId: selectedInsExamAccepted,
    //         InstCountry: Number(selectedCountryId),
    //         InstName: query || '',
    //         LevelofEducationId:  selectedInsEducationLevel,
    //         OffersDealsId: [],
    //         PageNumber: pageValue,
    //         ProgramTypeId: InsselectedProgramTypeId,
    //         RowsPerPage: limit,
    //         SortOrder:  selectedSortOption,
    //         TuitionFee: selectedTuitionFees.length > 0 ?  selectedTuitionFees :[]
    //     };


    //     setIsLoading(true)
    //     ProgramSearchService.getInstitutionInfo(data)
    //         .then((res) => {
    //             if (res) {
    //                 setIsSearching(true)
    //                 setResetFlag(true);
    //                 setInstitutes(res);
    //                 if(flag){
    //                     setInstitutesData(res?.institution);
    //                 setDisciplines(res.filters[0].Discipline);
    //                 setEducationLevels(res.filters[0].Education);
    //                 setExamAccepted(res.filters[0].Req);
    //                 setFees(res.filters[0].Fee);
    //                 setProgramTypes(res.filters[0].Program);
    //                 setDurations(res.filters[0].Duration);
    //                     setFilters(res.filters[0].Duration);
    //                     setAllFilterFlag(false);
    //                 }

    //                 // setTotalCount(res.InstituteCount);
    //                 setNoDataFlag(res.institution.length === 0); // Set noDataFlag
    //                 setTotalPages(Math.ceil((res.InstituteCount[0].TotalRecordCount) / limit));
    //             } else {
    //                 setInstitutes([]);
    //                 setInstitutesData([]);
    //                 setDisciplines([]);
    //                 setEducationLevels([]);
    //                 setFees([]);
    //                 setNoDataFlag(true);
    //                 setExamAccepted([]);
    //                 setProgramTypes([]);
    //                 setDurations([]);
    //                 setTotalPages(0);
    //                 setResetFlag(false);
    //                 setNoDataFlag(true);
    //                 setAllFilterFlag(false);
    //             }
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             setIsLoading(false);
    //             toast.error(error?.message);
    //         });
    // };
    // const fetchScholarshipData = (pageValue, flag) => {
    //     if (!selectedCountryId) {
    //         return;
    //     }
    
    //     setIsFlagScholar(flag);
    //     if(flag){
    //         setSelectedScholarAmount([]);
    //         setSelectedScholarInstitutes([]);
    //         setSelectedScholarProgram([]);
    //         // setSelectedScholarEdulevels('');
    //     }
    //     const scholarshipSearchRequest = {
    //         AmtType: selectedScholarAmounts, // Always include selected checkbox data
    //         InstCountryId: selectedCountryId,
    //         InstitutionId: selectedScholarInstitutes, // Always include selected checkbox data
    //         LevelofEducationId: selectedScholarEdulevels,
    //         PageNumber: pageValue,
    //         ProgramName: selectedScholarProgram, // Always include selected checkbox data
    //         RowsPerPage: limit,
    //         SortOrder:  selectedSortOption,
    //     };
    
    //     setIsLoading(true);
    //     ProgramSearchService.getScholarshipInfo(scholarshipSearchRequest)
    //         .then((response) => {
    //             if (response) {   
    //                 setIsSearching(true);         
    //                 setResetFlag(true);
    //                 setScholarships(response);
    //                 if (flag) {
    //                     setScholarshipData(response?.Institution);
    //                 }
                    
    //                 setTotalPages(Math.ceil((response?.ScholarShipCount[0]?.TotalRecordCount) / limit));
    //                 setNoDataFlag(response.Institution.length === 0); 
    //                 setAllFilterFlag(false);
    //             } else {
    //                 setScholarships([]);
    //                 setScholarshipData([]);
    //                 setTotalPages(0);
    //                 setNoDataFlag(true);
    //                 setAllFilterFlag(false);
    //                 setResetFlag(false);
    //             }
    
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             setIsLoading(false);
    //             toast.error(error?.message);
    //         });
    // };
    const fetchInstitutionDetails = () => {
        setIsLoading(true);
        ProgramSearchService.getInstitutionDetails(selectedCountryId)
            .then((res) => {
                if (res) {
                    const institutes = res.map(item => item.InstName).filter(Boolean);; 
                    setInstituteNames(institutes); // Set the institutes
                    setFilteredItems(institutes);  // Initialize filtered institutes
                } else {
                    setInstitutes([]);
                    setFilteredItems([]);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const updateInsFilters = (filters) => {
        setSelectedInsDisciplines(filters.selectedDisciplines);
        setSelectedInsDuration(filters.selectedDuration);
        setSelectedInsProgramtype(filters.selectedInsProgramtype);
        setSelectedInsExamAccepted(filters.selectedExamAccepted);
        setSelectedInsEducationLevel(filters.selectedEducationLevel);
    };

    const updateScholarFilters = (filters) => {
        setSelectedScholarInstitutes(filters.selectedInstitutes);
        setSelectedScholarAmount(filters.selectedAmounts);
        setSelectedScholarProgram(filters.selectedPrograms)
    };

    const handleEduLevelChange = (event) => {
        const levelId = parseInt(event.target.value);
        setSelectedScholarEdulevels(levelId);
    };


    const removeEduLevel = () => {
        // debugger
        setSelectedScholarEdulevels('');
    };


    const handleProgramTypeChange = (programTypeId) => {
        const updatedSelectedProgramTypeId = selectedProgramTypeId === programTypeId ? '' : programTypeId;
        setSelectedProgramTypeId(updatedSelectedProgramTypeId);
        resetPayloadFields();   // Reset all selected fields
        if (programTypeId !== '') {
            fetchProgramDiscipline(selectedCountryId, programTypeId, 0);
          }
    };
    
    useEffect(() => {
        if (selectedCountryId && selectedProgramTypeId) {
            fetchsearchdata();
        }
      }, [selectedCountryId, selectedProgramTypeId]);
    // main search function
    const handleSearch = () => {
        if (!selectedCountryId) {
            setCountryError('Country is required.');
          } else {
            setCountryError('');
          }
        if (tabIndex === 2 && !selectedProgramTypeId) {
            setCourseTypeError('Course type is required.');
            return;
          } else {
            setCourseTypeError('');
          }
          setInputFlag(false);
          setAllFilterFlag(false); 
          setSelectedSortOption(''); 
          setPrograms([]); // Clear previous program data
          setProgramData([]); 
        if (tabIndex === 2) {
            setcountryFlag(false);
            fetchProgramInfo(1, true);
        }
        else if (tabIndex === 3) {
            setcountryFlag(false);
            fetchInstituteData(1, true);
        } else if (tabIndex === 4) {
            setcountryFlag(false);
            fetchScholarshipData(1, true);
        }
        //  else {
        //     setcountryFlag(false);
        //     fetchProgramInfo();
        // }
    };

    //   const viewMore=()=>{
    //     if( tabIndex===2){
    //         fetchProgramInfo(page+1)
    //         setPage(page+1)
    //     }  
    //     if( tabIndex===3){
    //         fetchInstituteData(page+1);
    //         setPage(page+1)
    //     } 

    //     if( tabIndex===4){
    //         fetchScholarshipData(page+1);
    //         setPage(page+1)
    //     } 

    //   }



    const onPageChange = (newPage) => {
        setPage(newPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // Fetch data based on tab index and new page number
        if (tabIndex === 2) {
            fetchProgramInfo(newPage, false);
        } else if (tabIndex === 3) {
            fetchInstituteData(newPage, false);
        } else if (tabIndex === 4) {
            fetchScholarshipData(newPage, false);
        }
    };




    const fetchCourseTypes = (countryId) => {
        // debugger;
        setIsLoading(true);
        ProgramSearchService.getProgramType(countryId)
            .then((res) => {
                setCourseTypes(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };



    const showCountry = async (country) => {
        setCountryName(country.InstCountryName);
        setSelectedCountryId(country.InstCountryId);
        setSelectedScholarEdulevels('');
        setcountryFlag(false);
         setAllFilterFlag(false);
         setResetFlag(false);
        // if(tabIndex===4){
        //     setcountryFlag(true);
        // }
        setCountryError('');
        setSelectedProgramTypeId(''); // Reset selected program type
        setSelectedDurationId(''); // Reset selected duration
        resetPayloadFields();   // Reset all selected fields
        if (country.InstCountryId) {
            if (tabIndex === 2) {
                fetchCourseTypes(country.InstCountryId);
                // fetchProgramDiscipline(country.InstCountryId, 1, 48);

            }

            if (tabIndex === 4) {
                fetchEduLevels(country.InstCountryId, 0);
            }

        } else {
            console.error("Invalid country ID");
        }
    };


    //    index tab
    const getTabIndex = (index) => {
        setTabIndex(index);
        setIsSearching(false);
        setShordropdown(false);
        setResetFlag(false);
        setCountryName("Country");
        setSelectedCountryId("");
        setSelectedProgramTypeId("");
        setQuery("");
        setProgramData([]);
        setInstitutes([]);
        setScholarships([]);
        setPage(1)
        setCountryError('');
        setCourseTypeError('');
        setSelectedSortOption(''); 
        {shordropdown && (
    <div ref={shortDiv} className={styles['sortDropDown']}>
        <div
            onClick={() => {
                handleSortByTab("A-Z");
                setShordropdown(false);
            }}
            className={styles['dropDown-aligment']}
        >
            <div><img src={updownIcon} alt=""></img></div>
            <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 1 ? '#E4151B' : '#3C3C3C' }}> Alphabetical (A-Z)</div>
        </div>
        <div
            onClick={() => {
                handleSortByTab("Z-A");
                setShordropdown(false);
            }}
            className={styles['dropDown-aligment']}
        >
            <div><img src={updownIcon} alt=""></img></div>
            <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 2 ? '#E4151B' : '#3C3C3C' }}> Alphabetical (Z-A)</div>
        </div>

        {tabIndex === 2 && (
            <div
                onClick={() => {
                    handleSortByTab("Highest");
                    setShordropdown(false);
                }}
                className={styles['dropDown-aligment']}
            >
                <div><img src={updownIcon} alt=""></img></div>
                <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 4 ? '#E4151B' : '#3C3C3C' }}>Fee (High-Low)</div>
            </div>
        )}

        {tabIndex === 2 && (
            <div
                onClick={() => {
                    handleSortByTab("Lowest");
                    setShordropdown(false);
                }}
                className={styles['dropDown-aligment']}
            >
                <div><img src={updownIcon} alt=""></img></div>
                <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 3 ? '#E4151B' : '#3C3C3C' }}>Fee (Low-High)</div>
            </div>
        )}
    </div>
)}
        if (index === 2 || index === 3) {
            fetchCountryList(false);
        }
        else if (index === 4) {

            fetchCountryList(true);
        }
    }

    const showSortDrop = () => {
        if (!shordropdown) {
            setShordropdown(true)
        }
        else if (shordropdown) {
            setShordropdown(false)
        }

    }


    const showLocationFilter = () => {
        if (!locationFlag) {
            setlocationFlag(true)
        } else if (locationFlag) {
            setlocationFlag(false)
        }

    }
    const showCourseType = () => {
        if (countryName === 'Country') {
            toast.error("Please select a country first.");
            return;
        }
        if (!courseTypeFlag) {
            setcourseTypeFlag(true)
        } else if (courseTypeFlag) {
            setcourseTypeFlag(false)
        }

    }
    const showtution = () => {
        if (!tutionFlag) {
            settutionFlag(true)
        } else if (tutionFlag) {
            settutionFlag(false)
        }

    }
    const showduration = () => {
        if (!durationFlag) {
            setdurationFlag(true)
        } else if (durationFlag) {
            setdurationFlag(false)
        }

    }
    const showinternship = () => {
        if (!internshipFlag) {
            setinternshipFlag(true)
        } else if (internshipFlag) {
            setinternshipFlag(false)
        }

    }
    const handleSeePrograms = () => {
        setAllFilterFlag(false);
        if (tabIndex === 2) {
            fetchProgramInfo(1, false);
        } else if (tabIndex === 3) {
            fetchInstituteData(1, false);
        } else if (tabIndex === 4) {
            fetchScholarshipData(1, false);
        }
    };

    const showintake = () => {
        if (!intakeFlag) {
            setintakeFlag(true)
        } else if (intakeFlag) {
            setintakeFlag(false)
        }

    }
    const showapplication = () => {
        if (!applicatioFlag) {
            setapplicatioFlag(true)
        } else if (applicatioFlag) {
            setapplicatioFlag(false)
        }

    }
    const showgre = () => {
        if (!greFlag) {
            setgreFlag(true)
        } else if (greFlag) {
            setgreFlag(false)
        }

    }
    const showschool = () => {
        if (!schoolFlag) {
            setschoolFlag(true)
        } else if (schoolFlag) {
            setschoolFlag(false)
        }

    }
    const showenglish = () => {
        if (!englishScorFlag) {
            setenglishScorFlag(true)
        } else if (englishScorFlag) {
            setenglishScorFlag(false)
        }

    }
    const showDesipiline = () => {
        if (!desipilineFlag) {
            setdesipilineFlag(true)
        } else if (desipilineFlag) {
            setdesipilineFlag(false)
        }

    }

    const allFilterShow = () => {
        setAllFilterFlag(true)
    }
    const filteredItems = (tabIndex === 2 ? programItems : instituteNames).filter(
        (item) => typeof query === "string" && item.toLowerCase().includes(query.toLowerCase())
    );

    const handleClickOutside = (event) => {
        if (shortDiv) {
            if (shortDiv.current && !shortDiv.current.contains(event.target)) {
                setShordropdown(false)
            }
        }
        if (countryDiv) {
            if (countryDiv.current && !countryDiv.current.contains(event.target)) {
                setcountryFlag(false)
            }
        }
        if (inputdiv) {
            if (inputdiv.current && !inputdiv.current.contains(event.target)) {
                setInputFlag(false)
            }
        }
        if (allfilterDiv) {
            if (allfilterDiv.current && !allfilterDiv.current.contains(event.target)) {
                setAllFilterFlag(false)
            }
        }

    };

    const setImputValue = (value) => {
        setQuery(value);
        setInputFlag(false)


    }
    const getInputValue = (e) => {
        const value = e.target.value;
        setQuery(value);
        if (tabIndex === 3 && Array.isArray(instituteNames)) {
            setFilteredItems(
                instituteNames.filter(
                    institute => 
                        typeof institute === 'string' && 
                        institute.toLowerCase().includes(value.toLowerCase())
                )
            );
        } else if (tabIndex === 2 && Array.isArray(programItems)) {
            setFilteredPrograms(
                programItems.filter(
                    program => 
                        typeof program === 'string' && 
                        program.toLowerCase().includes(value.toLowerCase())
                )
            );
        }
        setInputFlag(true);
    };

    function hideModalhandler() {
        setModalIsVisible(false);
    }

    function showModalhandler(size) {
        setModalIsVisible(true);
        setModalSize(size);
    }


    const handleSort = (order, dataKey, key) => {
        const sortedData = [...dataKey];
    
        if (order === "A-Z") {
            sortedData.sort((a, b) => a[key].localeCompare(b[key]));
        } else if (order === "Z-A") {
            sortedData.sort((a, b) => b[key].localeCompare(a[key]));
        }
    
        return sortedData;
    };


    const handleSortByFeeAmount = (order, dataKey) => {
        const sortedData = [...dataKey];
    
        sortedData.sort((a, b) => {
            const feeA = a.FeeDetail[0]?.FeeAmount || 0;
            const feeB = b.FeeDetail[0]?.FeeAmount || 0;
            if (order === "Lowest") {
                return feeA - feeB; 
            } else if (order === "Highest") {
                return feeB - feeA;
            }
            return 0;
        });
    
        return sortedData;
    };
    
    


    // const handleSortByTab = (order) => {
    //     setSelectedSortOption(order); 
    //     if (tabIndex === 3) {
    //         setInstitutes((prevData) => ({
    //             ...prevData,
    //             institution: handleSort(order, prevData.institution, "institutionName"),
    //         }));
    //     } else if (tabIndex === 4) {
    //         setScholarships((prevData) => ({
    //             ...prevData,
    //             Institution: handleSort(order, prevData.Institution, "ProgramName"),
    //         }));
    //     }else if (tabIndex === 2) {
    //         if (order === "Lowest" || order === "Highest") {
    //             setProgramData((prevData) => handleSortByFeeAmount(order, prevData));
    //         } else {
    //             setProgramData((prevData) => handleSort(order, prevData, "ProgramName"));
    //         }
    //     }
    // };
    
    
    const handleSortByTab = (order) => {
        const sortOrderId = sortOptions[order];
        setSelectedSortOption(sortOrderId); 
        setPage(1);
        if (tabIndex === 2) {
            fetchProgramInfo(1, false, sortOrderId);
        } else if (tabIndex === 3) {
            fetchInstituteData(page, false, sortOrderId);
        } else if (tabIndex === 4) {
            fetchScholarshipData(page, false, sortOrderId);
        }
    };


    document.addEventListener('mousedown', handleClickOutside);
    return (
        <>
            {isLoading && <Loader />}
            {modalIsVisible && (
                <SearchModal onShow={showModalhandler} size={modalSize}>
                    <ComparePrograms selectedProgramTypeId={selectedProgramTypeId} onClose={hideModalhandler} ProgramList={programData} />
                </SearchModal>
            )}

            {allFilterFlag && (
                <div className={styles['all-filter-background']}>
                    <div ref={allfilterDiv} className={styles['all-filter-section']}>
                        {tabIndex === 2 && (
                        <>
                    <div className={styles['filter-sparate-border']}>
    <div className={styles['all-filter-content']} onClick={showduration}>
        <div className={styles['header-align']}> <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
        <div><img className={durationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
    </div>
    {durationFlag && (
        <div className={styles['dropdown-background']}>
            {filters.map((item) => (
                <div className={styles['checkbox-alignment']} key={item.SrNo}>
                    <Form.Check
                        type="checkbox"
                        id={`duration-${item.SrNo}`}
                        label={item.DurationCaption}
                        title={item.DurationCaption}
                        checked={selectedDurationIds.includes(item.SrNo)}
                        onChange={() => handleDurationChange(item.SrNo)}
                    />
                </div>
            ))}
        </div>
    )}
</div>
<div className={styles['filter-sparate-border']}>
    <div className={styles['all-filter-content']} onClick={showtution}>
        <div className={styles['header-align']}> <img src={tutionIcon} alt='' /> <div className={styles['filter-heading']}> Tuition</div></div>
        <div><img className={tutionFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
    </div>
    {tutionFlag && (
        <div className={styles['dropdown-background']}>
            {fees.map(fee => (
                <div className={styles['checkbox-alignment']} key={fee.srNo}>
                    <Form.Check
                        type="checkbox"
                        id={`tutionFee-${fee.srNo}`}
                        label={fee.feeCaption}
                        title={fee.feeCaption}
                        checked={selectedTuitionFees.includes(fee.feeValue)}
                        onChange={() => handleTuitionFeeChange(fee.feeValue)}
                    />
                </div>
            ))}
        </div>
    )}
</div>
                        {/* <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showLocationFilter}>
                                <div className={styles['header-align']}> <img src={locattionIcon} alt='' /> <div className={styles['filter-heading']}> Location</div></div>
                                <div><img className={locationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>

                            {locationFlag && (
                                
                                <div className={styles['dropdown-background']}>
                                    {countryList.map(item => (
                                        <div key={item.InstCountryId} className={styles['checkbox-alignment']}>
                                            
                                                        <Form.Check
                                                            type="checkbox"
                                                        />
                                            <div>{item.InstCountryName}</div>
                                        </div>
                                    ))}
                                </div>
                            )}


                        </div> */}
                        {/* <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showCourseType}>
                                <div className={styles['header-align']}>
                                    <img src={courseIcon} alt='' />
                                    <div className={styles['filter-heading']}> Course Type</div>
                                </div>
                                <div><img className={courseTypeFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {courseTypeFlag && courseTypes.length > 0 && (
                                <div className={styles['dropdown-background']}>
                                    {courseTypes.map((item) => (
                                        <div className={styles['checkbox-alignment']} key={item.ProgramTypeId}>
                                             <Form.Check type="checkbox"
                                                id={`courseType-${item.ProgramTypeId}`}
                                                name="courseType"
                                                checked={selectedProgramTypeId === item.ProgramTypeId}
                                                onChange={() => handleProgramTypeChange(item.ProgramTypeId)}
                                            />
                                            <div>{item.ProgramType}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                        {/* <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showDesipiline}>
                <div className={styles['header-align']}> <img src={desiplineIcon} alt='' /> <div className={styles['filter-heading']}>Discipline</div></div>
                    <div><img className={desipilineFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {desipilineFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div> */}
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showDesipiline}>
                                <div className={styles['header-align']}> <img src={desiplineIcon} alt='' /> <div className={styles['filter-heading']}>Discipline</div></div>
                                <div><img className={desipilineFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {desipilineFlag && (
                                <div className={styles['dropdown-background']}>
                                    {prodisciplines.map((item) => (
                                        <div className={styles['checkbox-alignment']} key={item.DisciplineId}>
                                             <Form.Check type="checkbox"
                                                checked={selectedDisciplines.includes(item.DisciplineName)}
                                                onChange={() => handleDisciplineChange(item.DisciplineName)}
                                            />
                                            <div>{item.DisciplineName}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {/* <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showtution}>
                                <div className={styles['header-align']}> <img src={tutionIcon} alt='' /> <div className={styles['filter-heading']}> Tuition</div></div>
                                <div><img className={tutionFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {tutionFlag && (
                                <div className={styles['dropdown-background']}>
                                    {fees.map(fee => (
                                        <div className={styles['checkbox-alignment']} key={fee.srNo}>
                                           <Form.Check type="checkbox"/>
                                            <div>{fee.feeCaption}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                        {/* <div className={styles['filter-sparate-border']}>
    <div className={styles['all-filter-content']} onClick={showtution}>
        <div className={styles['header-align']}> <img src={tutionIcon} alt='' /> <div className={styles['filter-heading']}> Tuition</div></div>
        <div><img className={tutionFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
    </div>
    {tutionFlag && (
        <div className={styles['dropdown-background']}>
            {fees.map(fee => (
                <div className={styles['checkbox-alignment']} key={fee.srNo}>
                    <Form.Check
                        type="checkbox"
                        id={`tutionFee-${fee.srNo}`}
                        label={fee.feeCaption}
                        title={fee.feeCaption}
                        checked={selectedTuitionFees.includes(fee.feeValue)}
                        onChange={() => handleTuitionFeeChange(fee.feeValue)}
                    />
                    <div>{fee.feeCaption}</div>
                </div>
            ))}
        </div>
    )}
</div> */}
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showintake}>
                                <div className={styles['header-align']}> <img src={intakeIcon} alt='' /> <div className={styles['filter-heading']}> Intake</div></div>
                                <div><img className={intakeFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {intakeFlag && (
                                <div className={styles['dropdown-background']}>
                                    {/* {country.map(items => (
                                        <div className={styles['checkbox-alignment']}>
                                            <Form.Check type="checkbox"></Form.Check>
                                            <div>{items}</div>
                                        </div>
                                    ))} */}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showschool}>
                                <div className={styles['header-align']}> <img src={schoolIcon} alt='' /> <div className={styles['filter-heading']}> Institutes</div></div>
                                <div><img className={schoolFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {schoolFlag && (
                                <div className={styles['dropdown-background']}>
                                    {/* {country.map(items => (
                                        <div className={styles['checkbox-alignment']}>
                                            <Form.Check type="checkbox"></Form.Check>
                                            <div>{items}</div>
                                        </div>
                                    ))} */}
                                </div>
                            )}
                        </div>
                        {/* <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showduration}>
                <div className={styles['header-align']}> <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
                    <div><img className={durationFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {durationFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div> */}
                {/* <div className={styles['filter-sparate-border']}>
    <div className={styles['all-filter-content']} onClick={showduration}>
        <div className={styles['header-align']}> <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
        <div><img className={durationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
    </div>
    {durationFlag && (
        <div className={styles['dropdown-background']}>
            {filters.map((item) => (
                <div className={styles['checkbox-alignment']} key={item.SrNo}>
                    <Form.Check
                        type="checkbox"
                        id={`duration-${item.SrNo}`}
                        label={item.DurationCaption}
                        title={item.DurationCaption}
                        checked={selectedDurationIds.includes(item.SrNo)}
                        onChange={() => handleDurationChange(item.SrNo)}
                    />
                    <div>{item.DurationCaption}</div>
                </div>
            ))}
        </div>
    )}
</div> */}
                        {/* <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showduration}>
                                <div className={styles['header-align']}> <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
                                <div><img className={durationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {durationFlag && (
                                <div className={styles['dropdown-background']}>
                                    {filters.map((item) => (
                                        <div className={styles['checkbox-alignment']} key={item.SrNo}>
                                            <Form.Check type="checkbox" />
                                            <div>{item.DurationCaption}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                        {/* <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showinternship}>
                                <div className={styles['header-align']}> <img src={internshipIcon} alt='' /> <div className={styles['filter-heading']}> Co-op and Internship</div></div>
                                <div><img className={internshipFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {internshipFlag && (
                                <div className={styles['dropdown-background']}>
                                    {country.map(items => (
                                        <div className={styles['checkbox-alignment']}>
                                            <Form.Check type="checkbox"></Form.Check>
                                            <div>{items}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                    <div className={styles['filter-sparate-border']}>
    <div className={styles['all-filter-content']} onClick={showenglish}>
        <div className={styles['header-align']}> <img src={englishIcon} alt='' /> <div className={styles['filter-heading']}>Exams Accepted</div></div>
        <div><img className={englishScorFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
    </div>
    {englishScorFlag && (
    <div className={styles['dropdown-background']}>
        {requirements.map(req => (
            <div className={styles['checkbox-alignment']} key={req.CountryId}>
                <Form.Check
                    type="checkbox"
                    id={`englishScore-${req.CountryId}`}
                    label={req.ProgramTypeID}
                    title={req.ProgramTypeID}
                    checked={selectedEnglishScores.includes(req.CountryId)}
                    onChange={() => handleEnglishScoreChange(req.CountryId)}
                />
            </div>
        ))}
    </div>
)}
</div>
                        {/* <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showgre}>
                                <div className={styles['header-align']}> <img src={gmtIcon} alt='' /> <div className={styles['filter-heading']}> GMAT/GRE Score</div></div>
                                <div><img className={greFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {greFlag && (
                                <div className={styles['dropdown-background']}>
                                    {requirements.map(req => (
                                        <div className={styles['checkbox-alignment']} key={req.ProgramTypeID}>
                                            <Form.Check type="checkbox"></Form.Check>
                                            <div>{req.ProgramTypeID}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                        {/* <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showapplication}>
                                <div className={styles['header-align']}> <img src={applicationIcon} alt='' /><div className={styles['filter-heading']}> Application Fee</div></div>
                                <div><img className={applicatioFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {applicatioFlag && (
                                <div className={styles['dropdown-background']}>
                                    {fees.map(fee => (
                                        <div className={styles['checkbox-alignment']} key={fee.srNo}>
                                            <Form.Check type="checkbox"></Form.Check>
                                            <div>{fee.feeCaption}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                        </>
                    )}
  {tabIndex === 3 && (
    <>
        <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']} onClick={showDiscipline}>
                <div className={styles['header-align']}> <img src={desiplineIcon} alt='' /> <div className={styles['filter-heading']}> Discipline</div></div>
                <div><img className={disciplineFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {disciplineFlag && (
                <div className={styles['dropdown-background']}>
                    {disciplines.map((discipline, index) => (
                        <div className={styles['checkbox-alignment']} key={index}>
                            <Form.Check
                                type="checkbox"
                                id={`discipline-${index}`}
                                value={discipline.DisciplineName}
                                title={discipline.DisciplineName}
                                label={discipline.DisciplineName}
                                checked={selectedInsDisciplines.includes(discipline.DisciplineName)}
                                onChange={() => InsHandleDeciplineChange(discipline.DisciplineName)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
        <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']} onClick={showEducationLevel}>
                <div className={styles['header-align']} >  <img src={schoolIcon} alt='' /> <div className={styles['filter-heading']}> Level of Education</div></div>
                <div><img className={educationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {educationFlag && (
                <div className={styles['dropdown-background']}>
                    {educationLevels.map((eduLevel) => (
                        <div className={styles['checkbox-alignment']} key={eduLevel.eduLevelId}>
                            <Form.Check
                                type="checkbox"
                                id={`education-${eduLevel.eduLevelId}`}
                                value={eduLevel.eduLevelId}
                                label={eduLevel.eduLevel}
                                title={eduLevel.eduLevel}
                                checked={selectedInsEducationLevel.includes(eduLevel.eduLevelId)}
                                onChange={() => handleEducationLevelChange(eduLevel.eduLevelId)}
                            />
                        </div>
                    ))}             
                </div>
            )}
        </div>
        <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']} onClick={showExamAccepted}>
                <div className={styles['header-align']}> <img src={englishIcon} alt='' /> <div className={styles['filter-heading']}>Exams Accepted</div></div>
                <div><img className={examFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {examFlag && (
                <div className={styles['dropdown-background']}>
                    {examAccepted.map((exam) => (
                        <div className={styles['checkbox-alignment']} key={exam.CountryId}>
                          <Form.Check
                                type="checkbox"
                                id={`exam-${exam.CountryId}`}
                                value={exam.CountryId}
                                label={exam.ProgramTypeID}
                                title={exam.ProgramTypeID}
                                checked={selectedInsExamAccepted.includes(exam.CountryId)}
                                onChange={() => handleExamAcceptedChange(exam.CountryId)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
        {/* <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']} onClick={showProgramType}>
                <div className={styles['header-align']}> <div className={styles['filter-heading']}> Course Type</div></div>
                <div><img className={programFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {programFlag && (
                <div className={styles['dropdown-background']}>
                    {programTypes.map((program, index) => (
                        <div className={styles['checkbox-alignment']} key={program.programTypeId}>
                            <Form.Check
                                type="checkbox"
                                id={`programType-${program.programTypeId}`}
                                value={program.programTypeId}
                                label={program.programType}
                                title={program.programType}
                                checked={selectedProgramtype.includes(program.programTypeId)}
                                onChange={() => handleProgramTypeChange1(program.programTypeId)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div> */}
            <div className={styles['filter-sparate-border']}>
    <div className={styles['all-filter-content']} onClick={showtution}>
        <div className={styles['header-align']}> <img src={tutionIcon} alt='' /> <div className={styles['filter-heading']}> Course Fee</div></div>
        <div><img className={tutionFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
    </div>
    {tutionFlag && (
        <div className={styles['dropdown-background']}>
            {fees.map(fee => (
                <div className={styles['checkbox-alignment']} key={fee.srNo}>
                    <Form.Check
                        type="checkbox"
                        id={`tutionFee-${fee.srNo}`}
                        label={fee.feeCaption}
                        title={fee.feeCaption}
                        checked={selectedTuitionFees.includes(fee.feeValue)}
                        onChange={() => handleTuitionFeeChange(fee.feeValue)}
                    />
                </div>
            ))}
        </div>
    )}
</div>

        <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']} onClick={showDuration}>
                <div className={styles['header-align']}>  <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
                <div><img className={durationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {durationFlag && (
                <div className={styles['dropdown-background']}>
                    {durations.map((duration) => (
                        <div className={styles['checkbox-alignment']} key={duration.durationCategoryId}>
                           <Form.Check
                                type="checkbox"
                                id={`duration-${duration.durationCategoryId}`}
                                value={duration.durationCategoryId}
                                title={duration.durationCategory}
                                label={duration.durationCategory}
                                checked={selectedInsDuration.includes(duration.durationCategoryId)}
                                onChange={() => InshandleDurationChange(duration.durationCategoryId)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    </>
)}
    {tabIndex === 4 && (
    <>
        <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']} onClick={showAmount}>
                <div className={styles['header-align']}> <img src={tutionIcon} alt='' />  <div className={styles['filter-heading']}> Type</div></div>
                <div><img className={amountFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {amountFlag && (
                <div className={styles['dropdown-background']}>
                    {scholarships?.Filters?.Amount?.map((item, index) => (
                        <div className={styles['checkbox-alignment']} key={item.AmountType}>
                            <Form.Check
                                type="checkbox"
                                id={`amount-${item.AmountType}`}
                                value={item.AmountType}
                                label={item.AmountTypeName}
                                title={item.AmountTypeName}
                                checked={selectedScholarAmounts.includes(item.AmountType)}
                                onChange={() => handleAmountChange(item.AmountType)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>

        <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']}  onClick={showInstitute}>
                <div className={styles['header-align']}>  <img src={schoolIcon} alt='' /> <div className={styles['filter-heading']}> Institute</div></div>
                <div><img className={instituteFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {instituteFlag && (
                <div className={styles['dropdown-background']}>
                    {scholarships?.Filters?.Institution?.map((item, index) => (
                        <div className={styles['checkbox-alignment']} key={item.InstitutionId}>
                            <Form.Check
                                type="checkbox"
                                id={`institute-${item.InstitutionId}`}
                                value={item.InstitutionId}
                                label={item.InstitutionName}
                                checked={selectedScholarInstitutes.includes(item.InstitutionId)}
                                onChange={() => handleInstituteChange(item.InstitutionId)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>

        <div className={styles['filter-sparate-border']}>
            <div className={styles['all-filter-content']} onClick={showProgram}>
                <div className={styles['header-align']}> <img src={desiplineIcon} alt='' /> <div className={styles['filter-heading']}> Programs</div></div>
                <div><img className={programFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
            </div>
            {programFlag && (
                <div className={styles['dropdown-background']}>
                    {scholarships?.Filters?.Program?.map((item, index) => (
                        <div className={styles['checkbox-alignment']} key={item.ProgramId}>
                            <Form.Check
                                type="checkbox"
                                id={`program-${item.ProgramId}`}
                                value={item.ProgramId}
                                label={item.ProgramName}
                                title={item.ProgramName}
                                checked={selectedScholarProgram.includes(item.ProgramId)}
                                onChange={() => handleProgramChange(item.ProgramId)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    </>
)}
                        <div>
                            <div className={styles['clear-btn-postion']}>
                                <button className={styles['clear-btn']} onClick={clearAllSelections}>Clear All</button>
                                <button className={styles['see-program-btn']} onClick={handleSeePrograms}>See Programs</button>
                            </div>
                        </div>
                    </div>

                </div>
            )}

            <div className={`${styles['filter_container']}`}>
                <div className={styles['tabs_container']}>
                    <div onClick={() => { getTabIndex(1) }} className={tabIndex === 1 ? `${styles['selected_tabs_value']}  col-md-3` : `${styles['tabs_value']} col-md-3`}>AIME</div>
                    <div onClick={() => { getTabIndex(2) }} className={tabIndex === 2 ? `${styles['selected_tabs_value']} col-md-3` : `${styles['tabs_value']} col-md-3`}>Programs</div>
                    <div onClick={() => { getTabIndex(3) }} className={tabIndex === 3 ? `${styles['selected_tabs_value']} col-md-3` : `${styles['tabs_value']} col-md-3`}>Institutes</div>
                    <div onClick={() => { getTabIndex(4) }} className={tabIndex === 4 ? `${styles['selected_tabs_value']}  col-md-3` : `${styles['tabs_value']} col-md-3`}>Scholarships</div>
                </div>
                {tabIndex !== 1 && (
                    <div className={`${styles['search_filter_container']}`}>
                        <div className="row mb-2 pb-3">
                            <div className="col-md-12 col-lg-9 mb-2">
                                <div className={styles['search-align']}>
                                       <div  className="flex-grow-1 form-group" style={{ width: '100%' }}>
                                       <select
                                                value={countryName}
                                                onChange={(e) => {
                                                    const selectedOption = countryList.find(
                                                        (item) => item.InstCountryName === e.target.value
                                                    );
                                                    if (selectedOption) {
                                                        showCountry(selectedOption);
                                                    }
                                                }}
                                                className={` ${styles['input-serch']} ${styles['custom-select']} form-select`}
                                            >
                                                {/* Placeholder Option */}
                                                <option value='' className={styles['custom-option']}>Country</option>


                                                {/* Options from the list */}
                                                {countryList.map((item) => (
                                                    <option key={item.InstCountryId} value={item.InstCountryName}>
                                                        {item.InstCountryName}
                                                    </option>
                                                ))}
                                            </select>
                                              {countryError && <div className={styles['error-message']}>{countryError}</div>}
                                    </div>
                                    {tabIndex === 4 && (
                                        <div className="flex-grow-1 form-group" style={{ width: '100%' }}>
                                            <select
                                                name="scholarship"
                                                placeholder="Level of Study"
                                                className={`${styles["input-serch"]} form-select`}
                                                value={selectedScholarEdulevels}
                                                onChange={handleEduLevelChange}
                                            >
                                                <option className={styles["option-default"]} value="">
                                                    Level of Study
                                                </option>

                                                {eduLevels.length > 0 &&
                                                    eduLevels.map((level) => (
                                                        <option key={level.EduLevelId} value={level.EduLevelId}>
                                                            {level.EduLevelName}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    )}

                                    {/* Conditionally render the select input for tabIndex 2 */}
                                    {tabIndex === 2 && (
                                        <div className="flex-grow-1 form-group position-relative" style={{ width: '100%' }} >
                                            <select
                                                name='courseType'
                                                value={selectedProgramTypeId}
                                                onChange={(e) => {
                                                    handleProgramTypeChange(e.target.value);
                                                    if (e.target.value === '') {
                                                      setCourseTypeError('Course type is required.'); // Show validation error if no course type is selected
                                                    } else {
                                                      setCourseTypeError(''); // Hide validation error when a course type is selected
                                                    }
                                                  }}
                                                  className={` ${styles['input-serch']} ${styles['custom-select']} form-select`}
                                                disabled={!selectedCountryId || isLoading}
                                            >
                                                <option value='' className={styles['custom-option']}>Select Course Type</option>
                                                {courseTypes.map((item) => (
                                                    <option key={item.ProgramTypeId} value={item.ProgramTypeId}>
                                                        {item.ProgramType}
                                                    </option>
                                                ))}
                                                </select>
                                                {courseTypeError && <div className={styles['error-message']}>{courseTypeError}</div>}
                                        </div>
                                    )}

                                    {/* Conditionally render search input for tabIndex 2 and 3 */}
                                    {(tabIndex === 2) && (
                                        <div className={`${styles['input-width']} flex-grow-1 form-group position-relative`} style={{ width: '100%' }}>
                                            <input
                                                value={query}
                                                onChange={(e) => {
                                                    getInputValue(e);
                                                  }}
                                                name={'disipline' }
                                                placeholder={'Search a Program'}
                                                type="text"
                                                // title={query}
                                                className={`${styles["input-serch"]} ${
                                                    tabIndex === 2
                                                        ? styles["input-search-width-270"]
                                                        : tabIndex === 3
                                                        ? styles["input-search-width-100"]
                                                        : ""
                                                }` }
                                               
                                            />
                                            {inputFlag && query.length > 2 && filteredPrograms.length > 0 && (
            <div ref={inputdiv} className={styles["searchInputValue"]}>
                {filteredPrograms.map((program, index) => (
                    <div
                        className={styles["dropdown-text"]}
                        key={index}
                        onClick={() => setImputValue(program)}
                        title={program}
                    >
                        {program}
                    </div>
                ))}
            </div>
        )}
                                            {/* { filteredItems.length > 0 && query.length > 2 && (
                                                <div ref={inputdiv} className={styles["searchInputValue"]}>
                                                    {filteredItems.map((item, index) => (
                                                        <div
                                                            className={styles["dropdown-text"]}
                                                            key={index}
                                                            onClick={() => setImputValue(item)}
                                                        >
                                                            {item}
                                                        </div>
                                                    ))}
                                                </div>
                                            )} */}
                                            {tabIndex !== 4 && (
                                                <img className={styles['search-icon']} src={searchIcon} alt='' />
                                            )}
                                        </div>
                                    )}

                                    {(tabIndex === 3) && (
                                        <div className={`${styles['input-width']} flex-grow-1 form-group position-relative`} style={{ width: '100%' }}>
                                            {/* <Select
                                                options={options}
                                                onInputChange={handleInputChange}
                                                onChange={handleSelectChange}
                                                placeholder="Search a Program"
                                                isClearable
                                                isDisabled={!selectedCountryId}
                                                filterOption={(option, inputValue) =>
                                                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                                className={styles["input-search"]}
                                            /> */}

                                            <input
                                                value={query}
                                                onChange={getInputValue}
                                                name={tabIndex === 2 ? 'program' : 'institute'}
                                                placeholder={tabIndex === 2 ? 'Search a Program' : 'Search an Institute'}
                                                type="text"
                                                // className={styles["input-serch"]}
                                                className={`${styles["input-serch"]} ${styles["input-search-width-100"] } `}
                                                
                                            />
                                            {inputFlag && filteredItems.length > 0 && query.length > 2 && (
                                                <div ref={inputdiv} className={styles["searchInputValue"]}>
                                                    {filteredItems.map((item, index) => (
                                                        <div
                                                            className={styles["dropdown-text"]}
                                                            key={index}
                                                            onClick={() => setImputValue(item)}
                                                            title={item}
                                                        >
                                                            {item}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {tabIndex !== 4 && (
                                                <img className={styles['search-icon']} src={searchIcon} alt='' />
                                            )}
                                        </div>
                                    )}

                                    <div>
                                        <button disabled={(tabIndex===2 && !selectedProgramTypeId) || !selectedCountryId} onClick={handleSearch} className={`${styles['primary-button']} primary-button `}>Search</button>
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-12 col-lg-3">
                                <div className={styles['search-align-filter']}>
                                    {tabIndex === 2 && resetFlag &&  programData.length > 0 && (
                                        <div 
                                        className={styles['tooltip-container']} title="Compare">
                                            <button className={styles['commom-btn']} onClick={() => showModalhandler('xl')} >
                                            <img src={compare} alt="Compare" />
                                            </button>
       
                                        </div>
                                    )}
                                    {/* {tabIndex === 2 && resetFlag && programData.length > 0 &&  (
                                        <div
                                        className={styles['tooltip-container']}
                                        title="All Filters"
                                       
                                        >
                                            <button onClick={allFilterShow} className={styles['commom-btn']} > <span><img className={styles['all-filters-icon']} src={newallfilter} alt='' /></span></button>
         
                                        </div>
                                    )} */}

                                             {(resetFlag || tabIndex === 3 && institutesData.length > 0 || tabIndex === 4 && scholarshipData.length > 0 || tabIndex === 2 && programData.length > 0) && resetFlag &&   (
                                              <>
                                               <div
            className={styles['tooltip-container']}
            title="All Filters"
        >
            <button onClick={allFilterShow} className={styles['commom-btn']} >
                <span><img className={styles['all-filters-icon']} src={newallfilter} alt='' /></span>
            </button>
        </div>
                                              <div 
                                                className={styles['tooltip-container']}
                                                title="Sort"
                                                >
                                                    <button
                                                        onClick={() => setShordropdown(!shordropdown)}
                                                        className={styles['commom-btn']}
                                                    >
                                                         <span><img src={sort1} alt="" /></span>
                                                    </button>
             
                                                    {/* {shordropdown && (
                                                        <div ref={shortDiv} className={styles['sortDropDown']}>
                                                            <div
                                                                onClick={() => {
                                                                    handleSortByTab("A-Z");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']}style={{ color: selectedSortOption === "A-Z" ? '#E4151B' : '#3C3C3C' }}> Alphabetical (A-Z)</div>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    handleSortByTab("Z-A");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']} style={{ color: selectedSortOption === "Z-A" ? '#E4151B' : '#3C3C3C' }}> Alphabetical (Z-A)</div>
                                                            </div>

                                                            {tabIndex === 2 && (
                                                                <div
                                                                onClick={() => {
                                                                    handleSortByTab("Highest");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']} style={{color: selectedSortOption === "Highest" ? '#E4151B' : '#3C3C3C'}}>Fee (High-Low)</div>
                                                            </div>
                                                            )}

                                                            {tabIndex === 2 && (
                                                                <div
                                                                onClick={() => {
                                                                    handleSortByTab("Lowest");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']} style={{ color: selectedSortOption === "Lowest" ? '#E4151B' : '#3C3C3C' }}>Fee (Low-High)</div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    )} */}
                                                   {shordropdown && (
    <div ref={shortDiv} className={styles['sortDropDown']}>
        <div
            onClick={() => {
                handleSortByTab("A-Z");
                setShordropdown(false);
            }}
            className={styles['dropDown-aligment']}
        >
            <div><img src={updownIcon} alt=""></img></div>
            <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 1 ? '#E4151B' : '#3C3C3C' }}> Alphabetical (A-Z)</div>
        </div>
        <div
            onClick={() => {
                handleSortByTab("Z-A");
                setShordropdown(false);
            }}
            className={styles['dropDown-aligment']}
        >
            <div><img src={updownIcon} alt=""></img></div>
            <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 2 ? '#E4151B' : '#3C3C3C' }}> Alphabetical (Z-A)</div>
        </div>

        {tabIndex === 2 && (
            <div
                onClick={() => {
                    handleSortByTab("Highest");
                    setShordropdown(false);
                }}
                className={styles['dropDown-aligment']}
            >
                <div><img src={updownIcon} alt=""></img></div>
                <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 4 ? '#E4151B' : '#3C3C3C' }}>Fee (High-Low)</div>
            </div>
        )}

        {tabIndex === 2 && (
            <div
                onClick={() => {
                    handleSortByTab("Lowest");
                    setShordropdown(false);
                }}
                className={styles['dropDown-aligment']}
            >
                <div><img src={updownIcon} alt=""></img></div>
                <div className={styles['dropdown-text']} style={{ color: selectedSortOption === 3 ? '#E4151B' : '#3C3C3C' }}>Fee (Low-High)</div>
            </div>
        )}
    </div>
)}
                                                </div>
                                                </>
                                            )}
                                        </div>

                            </div>

                        </div>

                    </div>
                )}

            </div>
            <div>

            { tabIndex !== 1 && !isSearching && (
    <div className={styles['initial-state']}>
        <img className={styles['initial-state-img']} src={backInitial} alt="Initial State" />
        <div>
            {tabIndex === 2 && (
                <>
                    <span className={styles['nblack']}>Please </span>
                    <span className={styles['bblack']}>Search </span>
                    <span className={styles['nblack']}> to view the desired Programs </span>
                </>
            )}
            {tabIndex === 3 && (
                <>
                    <span className={styles['nblack']}>Please </span>
                    <span className={styles['bblack']}>Search </span>
                    <span className={styles['nblack']}> to view the desired Institutes</span>
                </>
            )}
            {tabIndex === 4 && (
                <>
                    <span className={styles['nblack']}>Please </span>
                    <span className={styles['bblack']}>Search </span>
                    <span className={styles['nblack']}> to view the desired Scholarships </span>
                </>
            )}
        </div>
    </div>
)}

               <div>
                    {tabIndex === 1 && (
                        <AIME ></AIME>
                    )}

                </div>

                 {isSearching && (
                 <>
               
                <div>
                    
                    {tabIndex === 2 && selectedCountryId && (
                        <Programs
                            programs={programData}
                            filters={filters}
                            courseTypes={courseTypes}
                            fees={fees}
                            fetchProgramInfo={fetchProgramInfo}
                            onDurationChange={handleDurationChange}
                            onTuitionFeeChange={handleTuitionFeeChange}
                            onProgramTypeChange={handleProgramTypeChange}
                            selectedProgramTypeIds={selectedProgramTypeId}
                            selectedDurationIdss={selectedDurationId}
                            selectedCountryId={selectedCountryId}
                            resetFlag={resetFlag}
                            countryId={selectedCountryId} // Pass countryId as prop
                            institutionId={selectedScholarInstitutes}
                            isFlagProgram={isFlagProgram}
                        />
                    )}
                </div>
                <div>
                    {tabIndex === 3 && selectedCountryId && (
                        <Institutes isFlagInstitute={isFlagInstitute}  selectedCountryId={selectedCountryId}  institutes={institutes} resetFlag={resetFlag} updateInsFilters={updateInsFilters} Institutes fetchInstituteData={fetchInstituteData}/>
                    )}
                </div>
                <div>
                    {tabIndex === 4 && selectedCountryId && (
                        <Scholarship isFlag={isFlagScholar} setCountryId={selectedCountryId} scholarships={scholarships} resetFlag={resetFlag} updateScholarFilters={updateScholarFilters}
                            removeEduLevel={removeEduLevel}  selectedProgramTypeId={selectedProgramTypeId} selectedScholarEdulevels={selectedScholarEdulevels} eduLevels={eduLevels}
                            fetchScholarshipData={fetchScholarshipData} />
                    )}
                </div>


                {/* common for three tabs */}
                {/* <div>
                 {(totalCount!==programs?.program && programs?.program?.length>0) && (
                <div className="text-center mt-2 mb-2">
                <button onClick={viewMore} className={styles['view-more']}>View more</button>
                  </div>
              )}
            </div> */}


                <div>
                    {(resetFlag && (tabIndex === 2 || tabIndex === 3 || tabIndex === 4) && totalPages > 0) ? (
                        <Paginations currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                    ) : null}
                </div>
               </>
            )}
            </div>


        </>
    )

}

export default SearchPage;