import axios from "axios";
import { useNavigate } from "react-router-dom";

const AIUrl = process.env.REACT_APP_AI_API_KEY;
const axiosInstanceAI = axios.create({
  baseURL: AIUrl,
});

axiosInstanceAI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    throw error;
  }
);

axiosInstanceAI.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if(error?.response?.status ===401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload();
      return

    }
    throw error;
    
  }
);

export default axiosInstanceAI;