import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Table } from 'react-bootstrap';
import classes from "./Refunds.module.css";
import srName from "../../../../../Assets/Logo/srnum.svg";
import sortName from "../../../../../Assets/Logo/sort.svg";
import corssIcon from "../../../../../Assets/Logo/crossred.svg";
import SearchModal from "../../Search/SearchModal/SearchModal";
import ApplicationService from "../../../../../Services/ApplicationServices";
import ViewDetails from "../viewDetails/viewDetails";
import leftDropDown from "../../../../../Assets/Logo/back.svg";
import Loader from "../../../../GlobleShared/Loader/Loader";
const Refunds = () => {
  const columns = [
    { title: "Application ID", dataKey: "ApplicationId" },
    { title: "Student Name", dataKey:"FullName"},
    { title: "Program", dataKey:'ProgramName'},
    { title: "Institution Name", dataKey: "InstName" },
    { title: "Status", dataKey: "StatusName" },
  ];
  const allColumns = [
    { title: "Application ID", dataKey: "ApplicationId" },
    { title: "Name", dataKey: "FullName" },
    { title: "Intake", dataKey: "IntakeName" },
    { title: "Program", dataKey: "ProgramName" },
    { title: "Application Program Category", dataKey: "ProgramType" },
    { title: "Institution Name", dataKey: "InstName" },
    { title: "Status", dataKey: "StatusName" },
    { title: "Refund Status Date", dataKey: "RefundStatusDate" },
    { title: "Initiate Date", dataKey: "InitiateDate" },
    { title: "Submitted Date", dataKey: "SubmittedDate" },
    { title: "Approved Date", dataKey: "ApprovedDate" },
    { title: "Rejected Date", dataKey: "RejectedDate" },
    { title: "Received Date", dataKey: "ReceivedDate" },
    
    // { title: "Agent Name", dataKey: "AgentName" },
    // { title: "Student ID", dataKey: "StudentId" },
    // { title: "Student Email", dataKey: "StudentEmail" },
    // { title: "Refund Type", dataKey: "RefundType" },
    // response input all-felid 
    // { title: "Student Contact Number", dataKey: "StudentContactNumber" },
    // { title: "Refund Status", dataKey: "RefundStatus" },
    // { title: "Refund Remarks", dataKey: "RefundRemarks" },
    // { title: "Refund ID", dataKey: "RefundId" },
    // { title: "Refund Additional Remarks", dataKey: "RefundAdditionalremarks" },
    // { title: "Re-Submitted Date", dataKey: "ReSubmittedDate" },
    // { title: "Program Type ID", dataKey: "ProgramTypeID" },
    // { title: "Document Pending Date", dataKey: "DocPendingDate" }
  ];
  
  const responseData = [];
  const [deferralId, setDeferralId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedDeferral, setSelectedDeferral] = useState([]);
  const [isLoading, setIsLoading] =  useState(false);
  const initial=useRef(null)
  const getRefundList = () => {
    setIsLoading(true);
    let payload = {
    RefundType: deferralId ? deferralId : "All",
    keyword: "All"
  }
  ApplicationService.getRefundList(payload).then((res)=>{
    if(deferralId){
      setSelectedDeferral(res.length > 0 ? res[0] : tableData.find(row => row["ApplicationId"] === deferralId))
      setIsLoading(false);
    }else{
      setTableData(res.length > 0 ? res : responseData);
      setIsLoading(false);
    }
    setIsLoading(false);
  }).catch((error)=>{
    console.log('error: ', error);
    setIsLoading(false);
  })
}
useEffect(()=>{
  if(!initial.current){
    initial.current=true
    getRefundList();
  }
  
},[deferralId]);
  const [showModal, setShowModal] = useState(false);
  const handleViewRequest = (id) => {
     setIsLoading(true);
      setDeferralId(id);
     setIsLoading(false);
    // setShowModal(true);
  };
  const handleConfirm = () => {
    console.log("Refund confirmed for Application ID:", deferralId);
    setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <div className={classes.container}>
      {isLoading && <Loader/>}
      <div className={classes.heading}>MSM Refunds</div>
      {deferralId && 
      <div className="mt-3 mb-3" >
        <span className={classes.backButton} onClick={() => setDeferralId(null)}><img src={leftDropDown} alt="" /><span>Back</span></span>
      </div>}
      {!deferralId ? (
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column.title}</span>
                  {/* <img
                    src={index === 0 ? srName : sortName}
                    alt={index === 0 ? "Sr Name" : "Sort"}
                    className={classes.icon}
                  /> */}
                </div>
              </th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>

          {tableData?.length>0 && tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
             {columns.map((column, colIndex) => (
            <td key={colIndex}>
              {column.dataKey === "FullName" ? (
                row.FirstName || row.MiddleName || row.LastName ? (
                  `${row.FirstName} ${row.MiddleName || ""} ${row.LastName}`.trim()
                ) : (
                  "NA"
                )
              ) : (
               row[column.dataKey] ? row[column.dataKey] : "NA"
              )}
            </td>
          ))}
              <td>
                <span 
                  className={classes.actionBtn}
                  onClick={() => handleViewRequest(row.ApplicationId)}>
                  View Refund Document(s)
                </span>
              </td>
            </tr>
          ))}
          {tableData?.length===0 && (
            <tr>
              <td className="text-center" colSpan={6}>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </Table> 
      
  ) : (
    <ViewDetails
      columns={allColumns}
      selectedDeferral={selectedDeferral}
    />
  )}
     <SearchModal
        onShow={showModal}
        size="md"
        >  
        <div className={classes.ModelContainer}>
        <div className={`${classes.headerContainer}`}>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={handleClose}></img>
        </div>
          <div className={classes.title}>Are you sure you want to refund this application?</div>
        <div className={classes.btnContainer}>
          <Button
          className={`${classes.btnNo}`}
            onClick={handleClose}>
            No
          </Button>
          <Button
          className={`${classes.btnYes}`}
            onClick={handleConfirm}>
            Yes
          </Button>
        </div>
        </div> 
      </SearchModal>
</div>
);
};
   

export default Refunds;
