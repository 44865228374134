import React from "react";
import classes from "./Section.module.css";
import vectorLeftIcon from "../../../../../../Assets/Logo/red-side-close.svg";
import { Link } from "react-router-dom";
function LanguageComponent({ onBack }) {
  return (
    <div className={`${classes["main-container"]}`}>
      <div className={`${classes["language-main-container"]}`}>
        <div className="d-flex justify-content-between align-items-center">
          <div onClick={onBack} className={`${classes["prev-icon"]}`}>
            <img src={vectorLeftIcon}></img>
          </div>
        </div>
        <div className={classes["language-container-content"]}>
          <div>
            <div className={classes["language-title"]}>
              Language Preferences
            </div>
            <p className={classes["language-description"]}>
              Select your language to use
            </p>
          </div>
          <div className="form-group mb-2 pt-4 col-6">
            <input type="text" className="form-control" placeholder="English" disabled/>
          </div>
          <div>
            <span className={classes["language-info"]}>
              Let us know which language you're most comfortable using. you can
              change it back at any time.{" "}
              <Link to="/" className={`${classes["removeLinkDecoration"]}`}>
                <b style={{ color: "#213c92" }}>Learn more</b>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LanguageComponent;
