import React, {useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import classes from './CreditCardModal.module.css';

import { toast } from 'react-toastify';

import SearchModal from '../../../../Search/SearchModal/SearchModal';
import corssIcon from "../../../../../../../Assets/Logo/crossred.svg";
import ApplicationService from '../../../../../../../Services/ApplicationServices';
import Loader from '../../../../../../GlobleShared/Loader/Loader';
import { useBehaviorSubject } from '../../../../../../GlobleShared/BehaviorSubject/BehaviorSubject';

const CreditCardModal = ({onShow, onClose, applicationId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const currentDate = new Date();
    const minDate = currentDate.toISOString().split('T')[0];
    const {setCreditCardUpdate}=useBehaviorSubject()
    
    const [formData, setFormData] = useState({
        CCType: '',
        CCNo: '',
        CCCode: '',
        CCExpiryDate: '',
        CCName: '',
        CCAddress: '',
        ApplicationId: applicationId,
    });

    const [formErrors, setFormErrors] = useState({}); // State for error messages

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        validateForm({...formData,  [name]: value,})
    };


    const validateForm = (formDataValue) => {
        const errors = {};
        const { CCType, CCNo, CCCode, CCExpiryDate, CCName, CCAddress } = formDataValue;

        if (!CCType) errors.CCType = 'Card type is required.';
        if (!CCNo) errors.CCNo = 'Card number is required.';
        else if (CCNo.length < 12 || CCNo.length > 19) errors.CCNo = 'Card number must be between 12 and 19 digits.';
        
        if (!CCCode) errors.CCCode = 'CVV is required.';
        else if (CCCode.length !== 4) errors.CCCode = 'CVV must be 4 digits.';

        if (!CCExpiryDate) errors.CCExpiryDate = 'Expiry date is required.';
        if (!CCName) errors.CCName = 'Name of card holder is required.';
        if (!CCAddress) errors.CCAddress = 'Address is required.';

        setFormErrors(errors); 
        return Object.keys(errors).length === 0; 
    };

    const updateData = () => {
        if (!validateForm(formData)) {
            return;
        }

        setIsLoading(true);
        ApplicationService.addCreditCardData(formData)
            .then((res) => {
                setIsLoading(false);
                toast.success('Credit card details added successfully.');
                onClose()
                setCreditCardUpdate(true)
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.messages);
            });
    };

    const close = () => {
        onClose();
      };


    return (
        <>
        <SearchModal onShow={onShow} size="lg">
        <div className={classes.ModelContainer}>
          <div className={`${classes.headerContainer}`}>
            <div className={classes["header"]}>Credit Card Details</div>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={close}
            />
          </div>
            <div className={classes['mainContainer']}>
                <div className={classes['container']}>
                    <div className="row">
                        <div className="col-md-3 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Card Type <span className={classes['required']}>*</span></Form.Label>
                                    <Form.Select
                                        className={`form-control`}
                                        aria-label="Card Type"
                                        name="CCType"
                                        value={formData?.CCType}
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                    >
                                        <option disabled value=''>Select card type</option>
                                        <option value='AMERICAN EXPRESS'>AMERICAN EXPRESS</option>
                                        <option value='Master'>Master</option>
                                        <option value='Visa'>Visa</option>
                                    </Form.Select>
                                { (formErrors.CCType) && <div className="text-danger">{formErrors.CCType}</div>}
                            </Form.Group>
                        </div>

                        {/* Card Number */}
                        <div className="col-md-3 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Card Number <span className={classes['required']}>*</span></Form.Label>
                                <input
                                    placeholder='Please enter card number'
                                    className={`form-control`}
                                    type= 'text'
                                    aria-label="Card Number"
                                    name="CCNo"
                                    value={formData?.CCNo}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                { (formErrors.CCNo) && <div className="text-danger">{formErrors.CCNo}</div>}
                            </Form.Group>
                        </div>

                        {/* CVV */}
                        <div className="col-md-3 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>CVV <span className={classes['required']}>*</span></Form.Label>
                                <input
                
                                    placeholder='Please enter CVV'
                                    className={`form-control`}
                                    type= 'text'
                                    aria-label="CVV"
                                    name="CCCode"
                                    value={formData?.CCCode}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                {(formErrors.CCCode) && <div className="text-danger">{formErrors.CCCode}</div>}
                            </Form.Group>
                        </div>

                        {/* Expiry Date */}
                        <div className="col-md-3 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Expiry Date <span className={classes['required']}>*</span></Form.Label>
                                <input
                                    min={minDate}
                                    placeholder='Please enter expiry date'
                                    className={`form-control`}
                                    type='date' 
                                    aria-label="Expiry Date"
                                    name="CCExpiryDate"
                                    value={formData?.CCExpiryDate}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                { (formErrors.CCExpiryDate) && <div className="text-danger">{formErrors.CCExpiryDate}</div>}
                            </Form.Group>
                        </div>

                        {/* Name of Card Holder */}
                        <div className="col-md-12 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Name of Card Holder <span className={classes['required']}>*</span></Form.Label>
                                <input
                                    placeholder='Please enter name of card holder'
                                    className={`form-control`}
                                    type='text' 
                                    aria-label="Overall"
                                    name='CCName'
                                    value={formData.CCName}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                {(formErrors.CCName) && <div className="text-danger">{formErrors.CCName}</div>}
                            </Form.Group>
                        </div>

                        {/* Address */}
                        <div className="col-md-12 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Address <span className={classes['required']}>*</span></Form.Label>
                                <input
                                    placeholder='Please enter address'
                                    className={`form-control`}
                                    type='text' 
                                    aria-label="Overall"
                                    name='CCAddress'
                                    value={formData.CCAddress}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                {(formErrors.CCAddress) && <div className="text-danger">{formErrors.CCAddress}</div>}
                            </Form.Group>
                        </div>
                    </div>


                        <div className='text-center'>
                            <button onClick={updateData} className='primary-button'>Save Details</button>
                        </div>
                </div>
            </div>
            </div>
      </SearchModal>


            {isLoading && <Loader />}
        </>
    );
};

export default CreditCardModal;
