import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./ContactInfo.module.css";
import ApplicationService from "../../../../../../Services/ApplicationServices";
import { toast } from "react-toastify";
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

const ContactInfo = ({countryList, getProvinceListData, getCityListData, getValidation, contactInfo, getGenContectInfo, relationList }) => {
 
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [provinceListForMailing, setProvinceListForMailing] = useState([]);
          
  const [cityListForMailing, setCityListForMailing] = useState([]);

  const [ mailingAddressSame, setMailingAddressSame] =useState(false);
  const { validationFlag, setValidationFlag } = useBehaviorSubject();
  const [getValidationFlag, setGetValidationFlag]=useState(false)

  const [genInfo, setGenInfo] = useState({
    Addres: contactInfo?.Addres,
    Country:contactInfo?.Country,
    Province: contactInfo?.Province,
    City:contactInfo?.City,
    Pincode: contactInfo?.Pincode,
    MailingAddres: contactInfo?.MailingAddres,
    MailingCountry: Number(contactInfo?.MailingCountry),
    MailingProvince:Number(contactInfo?.MailingProvince),
    MailingCity: contactInfo?.MailingCity,
    MailingPincode: contactInfo?.MailingPincode,
    EmrgencyName: contactInfo?.EmrgencyName,
    EmergencyRelation: contactInfo?.EmergencyRelation,
    EmergencyEmail: contactInfo?.EmergencyEmail,
    EmergencyCellPhoneCountryCode: contactInfo?.EmergencyCellPhoneCountryCode,
    EmergencyCellPhone: contactInfo?.EmergencyCellPhone,
    EmergencyAddress: contactInfo?.EmergencyAddress,
    MailingAddressSame:contactInfo?.MailingAddressSame
  });
  const [formErrors, setFormErrors] = useState({
    Addres: null,
    Country:null,
    Province:null,
    City:null,
    Pincode: null,
    MailingAddres: null,
    MailingCountry: null,
    MailingProvince:null,
    MailingCity: null,
    MailingPincode: null,
    EmrgencyName: null,
    EmergencyRelation: null,
    EmergencyEmail: null,
    EmergencyCellPhoneCountryCode: null,
    EmergencyCellPhone: null,
  });
  
  const validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
     
     case "Country":
        if (!value) errorMsg = "Please select country";
        break;
     case "Addres":
        if (!value) errorMsg = "Please enter address";
        break;
     case "Pincode":
        if (!value) errorMsg = "Please enter pincode";
        break;
     case "City":
        if (!value) errorMsg = "Please select city";
        break;
     case "Province":
        if (!value) errorMsg = "Please select province";
        break;

     case "MailingAddres":
        if (!value) errorMsg = "Please enter address";
        break;
     case "MailingCountry":
        if (!value) errorMsg = "Please select country";
        break;  
     case "MailingProvince":
        if (!value) errorMsg = "Please select province";
        break; 
     case "MailingCity":
        if (!value) errorMsg = "Please select city";
        break; 
     case "MailingPincode":
        if (!value) errorMsg = "Please enter pincode";
        break; 
     case "EmrgencyName":
      value=value.trim()
        if (!value) errorMsg = "Please enter name";
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid name';
        break; 
     case "EmergencyRelation":
        if (!value) errorMsg = "Please enter relation";
        break;
     case "EmergencyEmail":
      value=value.trim()
        if (!value) errorMsg = "Please enter email";
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
        )
          errorMsg = 'Please enter a valid email';
        break;
     case "EmergencyCellPhoneCountryCode":
        if (!value) errorMsg = "Please enter country code";
        break;
     case "EmergencyCellPhone":
        if (!value) errorMsg = "Please enter phone number";
        if (!/^\d{10}$/.test(value)) errorMsg = "Please enter a valid 10-digit phone number";
        break;

     default:
        break;
        
    }
    return errorMsg;
  };






  const handleChange = (e) => {
    const { name, value } = e.target;
    setGenInfo({ ...genInfo, [name]: value });
    getGenContectInfo({ ...genInfo, [name]: value })

    const errorMsg = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }))
    getValidation((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }), 4)
  };



  const toggleMailingAddressSame = (e) => {
    if(e){
      getProvinceListForMailing(genInfo?.Country, true)
      getCityListForMailing(Number(genInfo?.Province, true))
      setGenInfo((prev) => ({
        ...prev,
        MailingAddres: prev.Addres,
        MailingCountry: prev.Country,
        MailingProvince: prev.Province,
        MailingCity:  prev.City,
        MailingPincode: prev.Pincode,
        MailingAddressSame:e
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        MailingProvince: null,
        MailingCity: null,
        MailingAddres: null,
        MailingCountry: null,
        MailingPincode: null,
      }))
      getValidation({...formErrors,
        MailingProvince: null,
        MailingCity: null,
        MailingAddres: null,
        MailingCountry: null,
        MailingPincode: null}, 4)

        getGenContectInfo((prev) => ({
          ...prev,
          MailingAddres: prev.Addres,
          MailingCountry: prev.Country,
          MailingProvince: prev.Province,
          MailingCity:  prev.City,
          MailingPincode: prev.Pincode,
          MailingAddressSame:e
        }))
    }else{
      setGenInfo((prev) => ({
        ...prev,
        MailingAddres: "",
        MailingCountry: 0,
        MailingProvince: 0,
        MailingCity:  "",
        MailingPincode: "",
       MailingAddressSame:e
      }));

      getGenContectInfo((prev) => ({
        ...prev,
        MailingAddres: "",
        MailingCountry: 0,
        MailingProvince: 0,
        MailingCity:  "",
        MailingPincode: "",
       MailingAddressSame:e
      }))


      setFormErrors((prevErrors) => ({
        ...prevErrors,  
        MailingProvince: 'Please select province',
        MailingCity: 'Please select city',
        MailingAddres: 'Please enter address',
        MailingCountry: 'Please select country',
        MailingPincode: 'Please enter pincode',
      }))
      getValidation((prevErrors) => ({
          ...prevErrors,  
          MailingProvince: 'Please select province',
          MailingCity: 'Please select city',
          MailingAddres: 'Please enter address',
          MailingCountry: 'Please select country',
          MailingPincode: 'Please enter pincode',
        }), 4)

    }
    setMailingAddressSame(e);
    // getGenContectInfo({ ...genInfo, 'MailingAddressSame': e })

  };

  const toggleMailingAddressSameValue = (e) => {
    if(e){
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        MailingProvince: null,
        MailingCity: null,
        MailingAddres: null,
        MailingCountry: null,
        MailingPincode: null,
      }))
      getValidation({...formErrors,
        MailingProvince: null,
        MailingCity: null,
        MailingAddres: null,
        MailingCountry: null,
        MailingPincode: null}, 4)
    }



    setMailingAddressSame(e);
    getGenContectInfo({ ...genInfo, 'MailingAddressSame': e })

  };

  const getProvinceList = (value, clearFlag) => {
    if(clearFlag){
      genInfo.Province="";
      genInfo.City="";
    }

    ApplicationService.getProvince(value)
      .then((res) => {
        setProvinceList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
      if(genInfo.MailingAddressSame){
        getProvinceListForMailing(value, clearFlag)
      }
  };

  const getCityList = (value, clearFlag) => {
    if(clearFlag){
      genInfo.City="";
    }
   
    ApplicationService.getCity(value)
      .then((res) => {
        setCityList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
      if(genInfo?.MailingAddressSame){
        if(value){
          getCityListForMailing(Number(value), clearFlag)
          setGenInfo((prev) => ({
            ...prev,
            MailingProvince: value,
          }));
        }
   
      }
  };

  const getProvinceListForMailing = (value, clearFlag) => {
    if(clearFlag){
      genInfo.MailingProvince="";
      genInfo.MailingCity="";
    }

    ApplicationService.getProvince(value)
      .then((res) => {
        setProvinceListForMailing(res);
        if(genInfo?.MailingAddressSame){
          if(genInfo.Province){
            getCityListForMailing(genInfo.Province, clearFlag)
          }
          
        }
       
      })
      .catch((error) => {
        toast.error(error?.message);
      });

  };

  const getCityListForMailing = (value, clearFlag) => {
    if(clearFlag){
      genInfo.MailingCity="";
    }
   
    ApplicationService.getCity(value)
      .then((res) => {
        setCityListForMailing(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getVaild=()=>{


    setFormErrors({...formErrors,
      Addres: genInfo?.Addres  ? null: 'Please enter address',
      Country:genInfo?.Country  ? null: 'Please enter country',
      Province:genInfo?.Province  ? null: 'Please select province',
      City:genInfo?.City  ? null: 'Please select city',
      Pincode: genInfo?.Pincode  ? null: 'Please enter pincode',
      MailingAddres: genInfo?.MailingAddres  ? null: 'Please enter address',
      MailingCountry: Number(genInfo?.MailingCountry) > 0  ? null: 'Please enter country',
      MailingProvince:Number(genInfo?.MailingProvince) > 0  ? null: 'Please select province',
      MailingCity: genInfo?.MailingCity  ? null: 'Please select city',
      MailingPincode: genInfo?.MailingPincode  ? null: 'Please enter pincode',
      EmrgencyName: genInfo?.EmrgencyName  ? null: 'Please enter name',
      EmergencyRelation: genInfo?.EmergencyRelation  ? null: 'Please select relation',
      EmergencyEmail: (genInfo?.EmergencyEmail && formErrors?.EmergencyEmail===null) ? null: 'Please enter email',
      EmergencyCellPhoneCountryCode: genInfo?.EmergencyCellPhoneCountryCode  ? null: 'Please enter country code',
      EmergencyCellPhone: (genInfo?.EmergencyCellPhone && formErrors?.EmergencyCellPhone===null) ? null: 'Please enter phone number',
     })
    getValidation({...formErrors,
      Addres: genInfo?.Addres  ? null: 'Please enter address',
      Country:genInfo?.Country  ? null: 'Please enter country',
      Province:genInfo?.Province  ? null: 'Please select province',
      City:genInfo?.City  ? null: 'Please select city',
      Pincode: genInfo?.Pincode  ? null: 'Please enter pincode',
      MailingAddres: genInfo?.MailingAddres  ? null: 'Please enter address',
      MailingCountry: Number(genInfo?.MailingCountry) > 0  ? null: 'Please enter country',
      MailingProvince:Number(genInfo?.MailingProvince) > 0  ? null: 'Please select province',
      MailingCity: genInfo?.MailingCity  ? null: 'Please select city',
      MailingPincode: genInfo?.MailingPincode  ? null: 'Please enter pincode',
      EmrgencyName: genInfo?.EmrgencyName  ? null: 'Please enter name',
      EmergencyRelation: genInfo?.EmergencyRelation  ? null: 'Please select relation',
      EmergencyEmail: (genInfo?.EmergencyEmail && formErrors?.EmergencyEmail===null) ? null: 'Please enter email',
      EmergencyCellPhoneCountryCode: genInfo?.EmergencyCellPhoneCountryCode  ? null: 'Please enter country code',
      EmergencyCellPhone: (genInfo?.EmergencyCellPhone && formErrors?.EmergencyCellPhone===null) ? null: 'Please enter phone number',
     }, 4)
  }

  useEffect(()=>{
    setMailingAddressSame(genInfo?.MailingAddressSame)
    getGenContectInfo(genInfo)
    if(genInfo.Country && genInfo.Country > 0){
      getProvinceList(genInfo.Country, false)
    }
    if(genInfo.Province && Number(genInfo.Province) > 0){
     getCityList(genInfo.Province, false)
    }

    if(!genInfo?.MailingAddressSame){
      if(genInfo?.MailingCountry && genInfo?.MailingCountry > 0){
        getProvinceListForMailing(genInfo?.MailingCountry, false)
      }
      if(genInfo.MailingProvince && Number(genInfo.MailingProvince) > 0){
        getCityListForMailing(genInfo.MailingProvince, false)
       }
    }
    // getValidation(formErrors, 4)

  }, [])

  useEffect(()=>{
    if(validationFlag){
      getVaild()
      setValidationFlag(false)
      setGetValidationFlag(true)
      toggleMailingAddressSameValue(genInfo?.MailingAddressSame)
    }
  },[validationFlag])

  return (
    <div className={styles["data-section"]}>
      <Form>
        <div className={styles["heading"]}>
          <p>Residential Address</p>
        </div>
        <div className={styles["address"]}>
          <div className="row">
            <div className="col-md-12 form-group">
              <Form.Group className="mb-3" controlId="Addres">
                <Form.Label> Address <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="Addres"
                  placeholder="Address"
                  value={genInfo.Addres}
                  onChange={handleChange} onBlur={handleChange}
                />
                 {formErrors.Addres && <div className={styles["errorText"]}>{formErrors.Addres}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="country">
                <Form.Label> Country <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select 
                  aria-label="Country"
                  name="Country"
                  value={genInfo.Country}
                  onChange={(e)=>{getProvinceList(e.target.value, true); handleChange(e)}} onBlur={handleChange}
                  required
                >
                  <option className={styles["option-default"]} value="">Select a country</option>
                  {countryList.map((country) => (
                    <option key={country.CountryId} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.Country && <div className={styles["errorText"]}>{formErrors.Country}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="provinceState">
                <Form.Label> Province/State <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select
                  name="Province"
                  aria-label="Province/State"
                  value={genInfo.Province}
                  onChange={(e)=>{getCityList(e.target.value, true); handleChange(e)}} onBlur={handleChange}
                  required
                  // disabled={!genInfo.Country}
                >
                  <option className={styles["option-default"]} value="">Select a province/state</option>
                  {provinceList.map((province) => (
                    <option
                      key={province.ProvinceId}
                      value={province.ProvinceId}
                    >
                      {province.ProvinceName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.Province && <div className={styles["errorText"]}>{formErrors.Province}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="cityTown">
                <Form.Label> City/Town <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select
                  name="City"
                  aria-label="City/Town"
                  value={genInfo.City}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                  <option  className={styles["option-default"]} value="">Select a city/town</option>
                  {cityList.map((city) => (
                    <option key={city.CityName} value={city.CityName}>
                      {city.CityName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.City && <div className={styles["errorText"]}>{formErrors.City}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="postalZipCode">
                <Form.Label> Postal/Zip Code <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="Pincode"
                  placeholder="Postal/Zip Code"
                  value={genInfo.Pincode}
                  onChange={handleChange} onBlur={handleChange}
                />
                {formErrors.Pincode && <div className={styles["errorText"]}>{formErrors.Pincode}</div>}
              </Form.Group>
            </div>
          </div>
        </div>

        <div className={styles["heading"]}>
          <p className="mt-2">Mailing Address</p>
          <div className="mt-2">
            <div className={styles["checkbox-container"]}>
              <Form className={styles["checkbox-form"]}>
                <Form.Check
                  className={styles["checkbox-input"]}
                  type="checkbox"
                  name="MailingAddressSame"
                  value={mailingAddressSame}
                  onChange={(e)=>{toggleMailingAddressSame(e.target.checked)}}
                  checked={mailingAddressSame}
                />
              </Form>
              {/* <input
                className={styles["checkbox-input"]}
                type="checkbox"
                name="MailingAddressSame"
                value={mailingAddressSame}
                onChange={(e)=>{toggleMailingAddressSame(e.target.checked)}}
                checked={mailingAddressSame}
              />  */}
              <div>Same as above</div>
            </div>
          </div>
        </div>
        <div className={styles["address"]}>
          <div className="row">
            <div className="col-md-12 form-group">
              <Form.Group className="mb-3" controlId="MailingAddres">
                <Form.Label> Address <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="MailingAddres"
                  placeholder="MailingAddres"
                  value={
                    mailingAddressSame
                      ? genInfo.Addres
                      : genInfo.MailingAddres
                  }
                  onChange={handleChange} onBlur={handleChange}
                  disabled={mailingAddressSame}
                />
                {formErrors.MailingAddres && <div className={styles["errorText"]}>{formErrors.MailingAddres}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MailingCountry">
                <Form.Label> Country <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select
                  name="MailingCountry"
                  value={
                    mailingAddressSame
                      ? genInfo.Country
                      : genInfo.MailingCountry
                  }
                  onChange={(e)=>{getProvinceListForMailing(e.target.value, true); handleChange(e)}}
                  onBlur={handleChange}
                  disabled={mailingAddressSame}
                  required
                >
                  <option className={styles["option-default"]} value="">Select a country</option>
                  {countryList.map((country) => (
                    <option key={country.CountryId} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.MailingCountry && <div className={styles["errorText"]}>{formErrors.MailingCountry}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MailingProvince">
                <Form.Label> Province/State <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select
                  name="MailingProvince"
                  value={
                    mailingAddressSame
                      ? genInfo.Province
                      : genInfo.MailingProvince
                  }
                  onChange={(e)=>{getCityListForMailing(e.target.value, true); handleChange(e)}}
                  onBlur={handleChange}
                  required
                  disabled={mailingAddressSame}
                >
                  <option className={styles["option-default"]} value="">Select a province/state</option>
                  {provinceListForMailing.map((province) => (
                    <option
                      key={province.ProvinceId}
                      value={province.ProvinceId}
                    >
                      {province.ProvinceName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.MailingProvince && <div className={styles["errorText"]}>{formErrors.MailingProvince}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MailingCity">
                <Form.Label> City/Town <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select
                  name="MailingCity"
                  value={
                    mailingAddressSame ? genInfo.City : genInfo.MailingCity
                  }
                  onChange={handleChange}
                  onBlur={handleChange}
                  disabled={mailingAddressSame}
                  required
                >
                  <option className={styles["option-default"]} value="">Select a city/town</option>
                  {cityListForMailing.map((city) => (
                    <option key={city.CityName} value={city.CityName}>
                      {city.CityName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.MailingCity && <div className={styles["errorText"]}>{formErrors.MailingCity}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MailingPincode">
                <Form.Label> Postal/Zip Code <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="MailingPincode"
                  placeholder="Postal/Zip Code"
                  value={
                    mailingAddressSame
                      ? genInfo.Pincode
                      : genInfo.MailingPincode
                  }
                  onChange={handleChange} onBlur={handleChange}
                  disabled={mailingAddressSame}
                />
                {formErrors.MailingPincode && <div className={styles["errorText"]}>{formErrors.MailingPincode}</div>}
              </Form.Group>
            </div>
          </div>
        </div>

        <div className={styles["heading"]}>
          <p className="mt-2">Emergency Contact</p>
        </div>
        <div className={styles["address"]}>
          <div className="row">
            <div className="col-md-4 form-group">
              <Form.Group className="mb-3" controlId="EmrgencyName">
                <Form.Label> Name <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="EmrgencyName"
                  placeholder="Name"
                  value={genInfo?.EmrgencyName}
                  onChange={handleChange} onBlur={handleChange}
                />
                  {formErrors.EmrgencyName && <div className={styles["errorText"]}>{formErrors.EmrgencyName}</div>}
              </Form.Group>
            </div>

            <div className="col-md-4 form-group">
              <Form.Group className="mb-3" controlId="EmergencyRelation">
                <Form.Label> Relation <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select
                  name="EmergencyRelation"
                  value={genInfo.EmergencyRelation}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                  <option value="">Select relation</option>
                  {relationList.map((relation) => (
                    <option key={relation.RelationId} value={relation.Relation}>
                      {relation.Relation}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.EmergencyRelation && <div className={styles["errorText"]}>{formErrors.EmergencyRelation}</div>}
              </Form.Group>
            </div>

            <div className="col-md-4 form-group">
              <Form.Group className="mb-3" controlId="EmergencyEmail">
                <Form.Label> Email <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="EmergencyEmail"
                  placeholder="Email"
                  value={genInfo.EmergencyEmail}
                  onChange={handleChange} onBlur={handleChange}
                />
                 {formErrors.EmergencyEmail && <div className={styles["errorText"]}>{formErrors.EmergencyEmail}</div>}
              </Form.Group>
            </div>

            <div className="col-md-6 form-group">
              <Form.Group
                className="mb-3"
                controlId="EmergencyCellPhoneCountryCode"
              >
                <Form.Label> Country Code <span className={styles['extrik']}>*</span></Form.Label>
                {/* <Form.Select aria-label="EmergencyCellPhoneCountryCode">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}

                <Form.Select
                  name="EmergencyCellPhoneCountryCode"
                  value={genInfo.EmergencyCellPhoneCountryCode}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                   <option  className={styles["option-default"]}></option>
                  <option  className={styles["option-default"]} value="">Select Country Code</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country.CountryCode}>
                      <span
                        className={`${country.flag}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country.CountryCode} ({country.CountryName})
                    </option>
                  ))}
                </Form.Select>
                {formErrors.EmergencyCellPhoneCountryCode && <div className={styles["errorText"]}>{formErrors.EmergencyCellPhoneCountryCode}</div>}
              </Form.Group>
            </div>

            <div className="col-md-6 form-group inputnumber-filed">
              <Form.Group className="mb-3" controlId="EmergencyCellPhone">
                <Form.Label> Phone Number <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Control
                  type="number"
                  name="EmergencyCellPhone"
                  placeholder="Phone Number"
                  min={0}
                  value={genInfo.EmergencyCellPhone}
                  onChange={handleChange} onBlur={handleChange}
                />
                 {formErrors.EmergencyCellPhone && <div className={styles["errorText"]}>{formErrors.EmergencyCellPhone}</div>}
              </Form.Group>
            </div>
            <div className="col-md-12 form-group">
              <Form.Group className="mb-3" controlId="EmergencyAddress">
                <Form.Label> Address</Form.Label>
                <Form.Control
                  type="text"
                  name="EmergencyAddress"
                  placeholder="Address"
                  value={genInfo.EmergencyAddress}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ContactInfo;
