import React, { useEffect, useRef, useState } from "react";
import classes from "./ProfileSection.module.css";
import avatarImage from "../../../Assets/Images/backgroung_profile_image.jpg";
import profile_picture from "../../../Assets/Images/profile_avatar.png";
import edit_profile from "../../../Assets/Logo/edit_profile.svg";
import Connections from "../../Pages/Home/User_Account/Connections/Connections";
import Posts from "../Posts/Posts";
import { useLocation } from "react-router-dom";
import ProfileService from "../../../Services/ProfileService";
import Loader from "../../GlobleShared/Loader/Loader";
import About from "../../Pages/Home/User_Account/About/About";
import { toast } from "react-toastify";

function ProfileSection() {
  const [tab, setTab] = useState("About");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const refId = queryParams.get('studentId');
  const [rowData, setRowData] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [profileImage, setProfileImage ] = useState('');
  const [connectionCount, setConnectionCount] = useState(0); 

  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [isLoading, setIsLoading] = useState(false);
  const initial = useRef(null);

  const getConnectionCount = (id) => {
    setIsLoading(true);
    ProfileService.getConnectionCount(id)
      .then((res) => {
        setConnectionCount(res);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
  const getAboutProfileInfo = () => {
    setIsLoading(true);
    ProfileService.getAboutProfileInfo(refId).then((res) => {
      setRowData(res);
      if (res) {
        if (res && res.ProfileBGImage) {
          setBackgroundImage(blobUrl + res.ProfileBGImage);
        } else {
          setBackgroundImage(avatarImage);
        }
        if (res && res.ProfileImgPath) {
          setProfileImage(blobUrl + res.ProfileImgPath);
        } else {
          setProfileImage(profile_picture);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log('error: ', error);
      setIsLoading(false);
    });
  }
  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      getAboutProfileInfo();
      getConnectionCount(userId);
    }
  }, []);
  return (
    <div className={`${classes["user_account"]}`}>
      {isLoading && (
        <Loader></Loader> 
      )}
      <div className={` ${classes["user_account_section"]}`}>
        <div className={classes["user_account_info"]}>
          <div className={classes["background_image"]}>
            <div className={classes["background_image_pic"]}>
              <img src={backgroundImage} alt="background-image " />
            </div>
            <div className={classes["profile_pic"]}>
              <span className={classes["user_profile_pic"]}>
                <img src={profileImage} alt="User Profile_Picture" />
              </span>
              {/* <span className={classes["edit_profile_icon"]}>
                <img src={edit_profile} alt="" />
              </span> */}
            </div>
          </div>
          <div className={`row ${classes["user_details"]}`}>
            <div className={`col-md-6 ${classes["user_personalDetails"]}`}>
             <p  className={`${classes["name"]}`}>
                {(rowData.FirstName && rowData.LastName) ? (rowData.FirstName + ' ' + rowData.LastName) : 'NA'}
                <span>
                {rowData.Gender === 'Female' ? ' (She/Her)' : rowData.Gender === 'Male' ? ' (He/His)' :  rowData.Gender === 'Others' ? ' (Others)' : " N/A"}
                </span>
              </p>
              <p className={`${classes["email"]}`}>{rowData.Email ? rowData.Email : "N/A"}</p>
            </div>

            <div className={`col-md-6 ${classes["user_highlights"]}`}>
             <p>{rowData.TagLine ? rowData.TagLine : " No Tagline"}</p>
              <p>Highlights</p>
            </div>
          </div>

          <div className={classes["user_connections"]}>{connectionCount} Connections</div>
          {/* <div>
            <button className={classes["send-req-btn"]}>Send Request</button>
          </div> */}

          {/* <div className={classes["section-line"]}>
            <hr />
          </div> */}

          <div className={classes["user_navigation"]}>
            <button
              className={tab === "About" ? classes["active_button"] : ""}
              onClick={() => setTab("About")}
            >
              About
            </button>
            <button
              className={tab === "Connections" ? classes["active_button"] : ""}
              onClick={() => setTab("Connections")}
            >
              Connections
            </button>
            <button
              className={tab === "Posts" ? classes["active_button"] : ""}
              onClick={() => setTab("Posts")}
            >
              Posts
            </button>
          </div>
        </div>
        {tab === "About" && (
          <div >
            <About connectedStudentId={refId} rowData={rowData}/>
          </div>
        )}

        {tab === "Posts" && (
          <div className={classes["posts_section"]}>
            <Posts connectedUserId={userId}/>
          </div>
        )}
        {/* {tab === "Settings" && (
          <div>
            <Settings />
          </div>
        )} */}
        {tab === "Connections" && (
          <div>
            <Connections connectedUserId={userId} />
          </div>
        )}
        {/* {tab === "Documents" && (
          <div>
            <Documents />
          </div>
        )}
        {tab === "Application" && (
          <div>
            <Applications />
          </div>
        )} */}
      
      </div>
    </div>
  );
}

export default ProfileSection;
