import React from 'react';
import classes from './Comments.module.css';
import Send from "../../../../../Assets/Logo/send-icon.svg";
import Mic from "../../../../../Assets/Logo/mic-icon.svg";
import Gif from "../../../../../Assets/Logo/gif-icon.svg";
import Picker from 'emoji-picker-react';
import { useState } from 'react';
import crossWhite from "../../../../../Assets/Logo/cross-icon.svg";

const CommentForm = ({ onSubmit, commentText, setCommentText, placeholder }) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };
  const onEmojiClick = (event, emojiObject) => {
    setCommentText(commentText + ' ' + event.emoji)
    // const quill = reactQuillRef.current.getEditor();
    // if (quill) {
    //   quill.focus();
    //   const selection = quill.getSelection(true); 
    //   const cursorPosition = selection ? selection.index : quill.getLength(); 
    //   quill.insertText(cursorPosition, event.emoji);
    //   quill.setSelection(cursorPosition + event?.emoji?.length);
    // }
    setIsEmojiPickerOpen(false);
  };
  return (
    <form onSubmit={onSubmit} className={classes["commentForm"]}>
      <div className={classes["inputContainer"]}>
        <textarea
          className={classes["commentInput"]}
          placeholder={placeholder || "Type here..."}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <div className={classes["iconContainer"]}>
          <button className={classes["sendButton"]} type="button">
          <svg className={classes["sendIcon"]} onClick={toggleEmojiPicker} width="32" height="15" viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.90909 0H11.6364V2.86897H2.90909V11.4759H8.72727V8.6069H5.81818V5.73793H11.6364V14.3448H0V0H2.90909ZM23.2727 0H32V2.86897H23.2727V5.73793H29.0909V8.6069H23.2727V14.3448H20.3636V0H23.2727ZM17.4545 0H14.5455V14.3448H17.4545V0Z" fill="#363636"/>
</svg>

            {/* <img  onClick={toggleEmojiPicker} src={Gif} className={classes["sendIcon"]} alt="GIF" /> */}
          </button>
          <button className={classes["sendButton"]} type="button">
          <svg className={classes["sendIcon"]} width="24" height="34" viewBox="0 0 24 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6774 14C22.6774 20.0752 17.8248 25 11.8387 25M11.8387 25C5.85265 25 1 20.0752 1 14M11.8387 25V33M11.8387 33H3.95601M11.8387 33H19.7214M11.8387 19C9.1178 19 6.91202 16.7614 6.91202 14V6C6.91202 3.23858 9.1178 1 11.8387 1C14.5596 1 16.7654 3.23858 16.7654 6V14C16.7654 16.7614 14.5596 19 11.8387 19Z" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          </button>
          <button type="submit" className={classes["sendButton"]}>
          <svg className={classes["sendIcon"]} width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2114 14.9332H4.35535M4.04763 16.3742L2.25405 21.8001C1.27172 24.7716 0.780555 26.2574 1.13304 27.1723C1.43912 27.967 2.09655 28.5692 2.90778 28.7987C3.84194 29.0626 5.25286 28.4196 8.07473 27.1337L26.1655 18.8895C28.9198 17.6342 30.297 17.0068 30.7226 16.135C31.0925 15.3775 31.0925 14.4886 30.7226 13.7312C30.297 12.8595 28.9198 12.2319 26.1655 10.9767L8.04353 2.71835C5.23016 1.43627 3.8235 0.795236 2.89028 1.05821C2.07981 1.28657 1.42245 1.88738 1.1153 2.68049C0.761598 3.59373 1.24752 5.07632 2.21937 8.04148L4.05112 13.6303C4.21802 14.1396 4.30149 14.3942 4.33442 14.6546C4.36366 14.8858 4.36336 15.1197 4.33355 15.3507C4.29993 15.6111 4.21584 15.8654 4.04763 16.3742Z" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          </button>
            {isEmojiPickerOpen && (
               <div className={classes["emoji_picker_wrapper"]}>
                 <Picker onEmojiClick={onEmojiClick} />
               </div>
             )}
        </div>
      </div>
    </form>
  );
};

export default CommentForm;
