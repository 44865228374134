import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import classes from './CreditCardSection.module.css';
import ApplicationService from '../../../../../../Services/ApplicationServices';
import { toast } from 'react-toastify';
import Loader from '../../../../../GlobleShared/Loader/Loader';
import { useBehaviorSubject } from '../../../../../GlobleShared/BehaviorSubject/BehaviorSubject';

const CreditCardSection = ({ applicationId, creditCardEdit, disableCreditCard }) => {
    const [creditCardData, setCreditCardData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const currentDate = new Date();
    const minDate = currentDate.toISOString().split('T')[0];
    const {creditCardUpdate, setCreditCardUpdate}=useBehaviorSubject()
    
    const [formData, setFormData] = useState({
        CCType: '',
        CCNo: '',
        CCCode: '',
        CCExpiryDate: '',
        CCName: '',
        CCAddress: '',
        ApplicationId: applicationId,
    });

    const [formErrors, setFormErrors] = useState({}); // State for error messages

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        validateForm({...formData,  [name]: value,})
    };

    const getCreditCardData = (id) => {
        ApplicationService.getCreditCardData(id)
            .then((res) => {
                setCreditCardData(res);
                setFormData({
                    CCType: res.CCType || '',
                    CCNo: res.CCNo || '',
                    CCCode: res.CCCode || '',
                    CCExpiryDate: res.CCExpiryDate || '',
                    CCName: res.CCName || '',
                    CCAddress: res.CCAddress || '',
                    ApplicationId: applicationId,
                });
            })
            .catch((error) => {
                
            });
    };

    const validateForm = (formDataValue) => {
        const errors = {};
        const { CCType, CCNo, CCCode, CCExpiryDate, CCName, CCAddress } = formDataValue;

        if (!CCType) errors.CCType = 'Card type is required.';
        if (!CCNo) errors.CCNo = 'Card number is required.';
        else if (CCNo.length < 12 || CCNo.length > 19) errors.CCNo = 'Card number must be between 12 and 19 digits.';
        
        if (!CCCode) errors.CCCode = 'CVV is required.';
        else if (CCCode.length !== 4) errors.CCCode = 'CVV must be 4 digits.';

        if (!CCExpiryDate) errors.CCExpiryDate = 'Expiry date is required.';
        if (!CCName) errors.CCName = 'Name of card holder is required.';
        if (!CCAddress) errors.CCAddress = 'Address is required.';

        setFormErrors(errors); 
        return Object.keys(errors).length === 0; 
    };

    const updateData = () => {
        if (!validateForm(formData)) {
            return;
        }

        setIsLoading(true);
        ApplicationService.addCreditCardData(formData)
            .then((res) => {
                setIsLoading(false);
                disableCreditCard();
                getCreditCardData(applicationId);
                toast.success('Credit card details added successfully.');
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.messages);
            });
    };

    const cancelData = () => {
        setFormData({
            CCType: creditCardData.CCType || '',
            CCNo: creditCardData.CCNo || '',
            CCCode: creditCardData.CCCode || '',
            CCExpiryDate: creditCardData.CCExpiryDate || '',
            CCName: creditCardData.CCName || '',
            CCAddress: creditCardData.CCAddress || '',
            ApplicationId: applicationId,
        });
        disableCreditCard();
    };

    useEffect(() => {
        if(creditCardUpdate){
            setCreditCardUpdate(false)
            getCreditCardData(applicationId);
        }
           
        
    }, [creditCardUpdate]);

    return (
        <>
            <div className={classes['mainContainer']}>
                <div className={classes['container']}>
                    <div className="row">
                        {/* Card Type */}
                        <div className="col-md-3 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Card Type {creditCardEdit?(<span className={classes['required']}>*</span>):('')}</Form.Label>
                                {!creditCardEdit && (
                                    <input
                                        disabled={!creditCardEdit}
                                        placeholder='Please select card type'
                                        className={`form-control`}
                                        type={creditCardEdit ? 'text' : 'password'}
                                        aria-label="Card Type"
                                      
                                        value={formData?.CCType}
                                        onChange={handleChange}
                                    />
                                )}
                                {creditCardEdit && (
                                    <Form.Select
                                        disabled={!creditCardEdit}
                                        className={`form-control`}
                                        aria-label="Card Type"
                                        name="CCType"
                                        value={formData?.CCType}
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                    >
                                        <option disabled value=''>Select card type</option>
                                        <option value='AMERICAN EXPRESS'>AMERICAN EXPRESS</option>
                                        <option value='Master'>Master</option>
                                        <option value='Visa'>Visa</option>
                                    </Form.Select>
                                )}
                                { (creditCardEdit && formErrors.CCType) && <div className="text-danger">{formErrors.CCType}</div>}
                            </Form.Group>
                        </div>

                        {/* Card Number */}
                        {creditCardEdit && (
                        <div className={`${classes['inputnumber-filed']} col-md-3 form-group`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                            <Form.Label>Card Number {creditCardEdit?(<span className={classes['required']}>*</span>):('')}</Form.Label>
                            <input
                                disabled={!creditCardEdit}
                                placeholder='Please enter card number'
                                className={`form-control`}
                                type='number'
                                aria-label="Card Number"
                                name="CCNo"
                                value={formData?.CCNo}
                                onChange={handleChange}
                                onBlur={handleChange}
                            />
                            { (creditCardEdit && formErrors.CCNo) && <div className="text-danger">{formErrors.CCNo}</div>}
                        </Form.Group>
                       </div>
                        )}
                        {!creditCardEdit && (
                        <div className={`${classes['inputnumber-filed']} col-md-3 form-group`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                            <Form.Label>Card Number {creditCardEdit?(<span className={classes['required']}>*</span>):('')}</Form.Label>
                            <input
                                disabled={!creditCardEdit}
                                placeholder='Please enter card number'
                                className={`form-control`}
                                type='password'
                                aria-label="Card Number"
                                name="CCNo"
                                value={formData?.CCNo}
                                onChange={handleChange}
                                onBlur={handleChange}
                            />
                            { (creditCardEdit && formErrors.CCNo) && <div className="text-danger">{formErrors.CCNo}</div>}
                        </Form.Group>
                       </div>
                        )}


                        {/* CVV */}
                        <div className={`${classes['inputnumber-filed']} col-md-3 form-group`}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>CVV {creditCardEdit?(<span className={classes['required']}>*</span>):('')}</Form.Label>
                                <input
                                    disabled={!creditCardEdit}
                                    placeholder='Please enter CVV'
                                    className={`form-control`}
                                    type={creditCardEdit ? 'number' : 'password'}
                                    aria-label="CVV"
                                    name="CCCode"
                                    value={formData?.CCCode}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                {(creditCardEdit && formErrors.CCCode) && <div className="text-danger">{formErrors.CCCode}</div>}
                            </Form.Group>
                        </div>

                        {/* Expiry Date */}
                        <div className="col-md-3 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Expiry Date {creditCardEdit?(<span className={classes['required']}>*</span>):('')}</Form.Label>
                                <input
                                    min={minDate}
                                    disabled={!creditCardEdit}
                                    placeholder='Please enter expiry date'
                                    className={`form-control`}
                                    type={creditCardEdit ? 'date' : 'password'}
                                    aria-label="Expiry Date"
                                    name="CCExpiryDate"
                                    value={formData?.CCExpiryDate}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                { (creditCardEdit && formErrors.CCExpiryDate) && <div className="text-danger">{formErrors.CCExpiryDate}</div>}
                            </Form.Group>
                        </div>

                        {/* Name of Card Holder */}
                        <div className="col-md-12 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Name of Card Holder {creditCardEdit?(<span className={classes['required']}>*</span>):('')}</Form.Label>
                                <input
                                    disabled={!creditCardEdit}
                                    placeholder='Please enter name of card holder'
                                    className={`form-control`}
                                    type={creditCardEdit ? 'text' : 'password'}
                                    aria-label="Overall"
                                    name='CCName'
                                    value={formData.CCName}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                {(creditCardEdit && formErrors.CCName) && <div className="text-danger">{formErrors.CCName}</div>}
                            </Form.Group>
                        </div>

                        {/* Address */}
                        <div className="col-md-12 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label>Address {creditCardEdit?(<span className={classes['required']}>*</span>):('')}</Form.Label>
                                <input
                                    disabled={!creditCardEdit}
                                    placeholder='Please enter address'
                                    className={`form-control`}
                                    type={creditCardEdit ? 'text' : 'password'}
                                    aria-label="Overall"
                                    name='CCAddress'
                                    value={formData.CCAddress}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                                {(creditCardEdit && formErrors.CCAddress) && <div className="text-danger">{formErrors.CCAddress}</div>}
                            </Form.Group>
                        </div>
                    </div>

                    {creditCardEdit && (
                        <div className='text-end'>
                            <button onClick={cancelData} className={classes['cancel-btn']}>Cancel</button>
                            <button onClick={updateData} className='primary-button'>Update</button>
                        </div>
                    )}
                </div>
            </div>

            {isLoading && <Loader />}
        </>
    );
};

export default CreditCardSection;
