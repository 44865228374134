import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";
import classes from "./PersonalInformation.module.css";
import documentDownloadArrow from "../../../../../../Assets/Logo/documentDownloadArrow.svg";
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import CommonServices from "../../../../../../Services/CommonServices";
import { toast } from "react-toastify";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import ApplicationService from "../../../../../../Services/ApplicationServices";

const PersonalInformation = ({ personalInfoData, formData, countryList, languageList , getValidation, programTypeId}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [personalData, setPersonalData] = useState(formData);
  const { validationFlag, setValidationFlag } = useBehaviorSubject();
  const [getValidationFlag, setGetValidationFlag]=useState(false)
  const [errorMsg, setErrorMsg]=useState('')
  const currentDate = new Date().toISOString().split('T')[0];
  const [isLoading, setIsLoading]=useState(false)
  const [govtTypeList, setGovtTypeList]=useState([])
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const initial=useRef(null)

  const [formErrors, setFormErrors] = useState({
    Salution: null,
    FirstName:null,
    LastName:null,
    Gender:null,
    MaritialStatus:null,
    DateOfBirth:null,
    Email:null,
    MobileNoCountryCode:null,
    MobileNo:null,
    Citizenship:null,
    PassportExpiryDate:null,
    PassportNo:null,
    GovernmentIdNumber:null,
    GovernmentIdType:null,
    Addres: null,
    Country:null,
    Province: null,
    City:null,
    Pincode: null,

  });
  
  const validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
     case "Salution":
        if (!value) errorMsg = "Please select salution";
        break;
     case "LastName":
      value=value.trim()
        if (!value) errorMsg = "Please enter last name";
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid last name';
        break;
     case "FirstName":
      value=value.trim()
        if (!value) errorMsg = "Please enter first name";
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid first name';
        break;
     case "Gender":
        if (!value) errorMsg = "Please select gender";
        break;
     case "MaritialStatus":
      if(programTypeId===1){
        if (!value) errorMsg = "Please select maritial status";
      }
        
        break;
     case "DateOfBirth":
        if (!value) errorMsg = "Please select date of birth";
        break;
 
     case "Email":
      value=value.trim()
        if (!value) errorMsg = "Please enter email";
         if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
        )
          errorMsg = 'Please enter a valid email';
        break;
     case "MobileNoCountryCode":
        if (!value) errorMsg = "Please select mobileNo country code";
        break;  
     case "MobileNo":
      value=value.trim()
        if (!value) errorMsg = "Please enter mobile number";
         if (!/^\d{10}$/.test(value)) errorMsg = "Please enter a valid 10-digit mobile number";
        break; 
     case "Citizenship":
        if (!value) errorMsg = "Please select citizenship";
        break; 
     case "PassportExpiryDate":
      if(programTypeId===1 || Number(personalData?.GovernmentIdType)===2){
        if(personalData?.PassportNo.toLowerCase()==='passport applied'){
          errorMsg = null;
        }else{
          if (!value) errorMsg = "Please select passport expiry date";
        }
      }
  
      break; 
     
     case "PassportNo":
      if(programTypeId===1){
       value=value.trim()
        if (!value) errorMsg = "Please enter passport number";
        if(value.toLowerCase()==='passport applied'){
          setFormErrors((pre)=>({...pre,
            PassportExpiryDate:null
          }))
        }
      }
      break; 
     case "GovernmentIdType":
        if(programTypeId===2){
          if (!value) errorMsg = "Please select government id type";
        }
        break; 
     case "GovernmentIdNumber":
          if(programTypeId===2){
           value=value.trim()
            if (!value) errorMsg = "Please enter government id number";
          }
          break; 
     default:
        break;
        
    }
    return errorMsg;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    personalInfoData({ ...personalData, [name]: value })
    setPersonalData({ ...personalData, [name]: value })
    const errorMsg = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));

    getValidation({...formErrors, [name]: errorMsg,}, 3)
    if(name==='Citizenship'){
     setErrorMsg('')
    }
    
  };

  const changeGovtId=(e)=>{
    personalInfoData((prev)=>({...prev, GovernmentIdNumber: '' }))
    setPersonalData((prev)=>({...prev, GovernmentIdNumber: '' }))
  }

  const spacehande = (e) => {
    const { name, value } = e.target;
    personalInfoData({ ...personalData, [name]: value.trim() })
    setPersonalData({ ...personalData, [name]: value.trim() })
    const errorMsg = validateField(name, value.trim());
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));

    getValidation({...formErrors, [name]: errorMsg,}, 3)

  };


  const convertToDateInputFormat = (dateString) => {
    if (!dateString) return '';
    
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  const handleFileChange = (event) => {
    setErrorMsg('')
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("file", file);
      setIsLoading(true)

         CommonServices.uploadPassport(formData).then(res=>{
        fileInputRef.current.value='';
        setIsLoading(false)
        if(res==='File extension is not valid'){
          toast.error(res)
          return
        }
        if(res?.result==='Bad image quality or file read error'){
          setErrorMsg(res?.result)
          return
        }
  
        const responseData = JSON.parse(res?.result);
         const date=convertToDateInputFormat(responseData?.DateOfExpiry)
         setPersonalData((pre)=>({...pre, 
          PassportNo: responseData?.PassportNumber,
          PassportExpiryDate:date}))
          console.log(date)
  
      }).catch(error=>{
        setIsLoading(false)
        toast.error(error?.message)
      })
    }
  };

  const handleUploadBoxClick = () => {
    fileInputRef.current.click();
  };

  const getvalid=()=>{
    setFormErrors({...formErrors, 
      Salution: personalData?.Salution ? null:'Please select salution',
      FirstName:personalData?.FirstName ? null:'Please enter first name',
      LastName:personalData?.LastName ? null:'Please enter last name',
      Gender:personalData?.Gender ? null:'Please select gender',
      MaritialStatus:programTypeId===1 ? (personalData?.MaritialStatus ? null:'Please select maritial status'):null,
      DateOfBirth:personalData?.DateOfBirth ? null:'Please select date of birth',
      Email:(personalData?.Email && formErrors?.Email===null) ? null:'Please enter email',
      MobileNoCountryCode:personalData?.MobileNoCountryCode ? null:'Please select mobileNo country code',
      MobileNo:(personalData?.MobileNo && formErrors?.MobileNo===null) ? null:'Please enter mobile number',
      Citizenship:personalData?.Citizenship ? null:'Please select citizenship',
      PassportExpiryDate:(programTypeId===1 || Number(personalData?.GovernmentIdType)===2)? ((personalData?.PassportExpiryDate || personalData?.PassportNo.toLowerCase()==='passport applied') ? null:'Please select passport expiry date'):null,
      PassportNo:programTypeId===1 ? (personalData?.PassportNo ? null:'Please enter passport number'):null,
      GovernmentIdNumber:programTypeId===2 ? (personalData?.GovernmentIdNumber ? null:'Please enter government id number'):null,
      GovernmentIdType:programTypeId===2 ? (personalData?.GovernmentIdType ? null:'Please select government id type'):null,
      Addres: programTypeId===2 ? (personalData?.Addres ? null:'Please enter address'):null,
      Country:programTypeId===2 ? (personalData?.Country ? null:'Please select country'):null,
      Province: programTypeId===2 ? (personalData?.Province ? null:'Please select province'):null,
      City:programTypeId===2 ? (personalData?.City ? null:'Please select city type'):null,
      Pincode: programTypeId===2 ? (personalData?.Pincode ? null:'Please enter pincode'):null,
    })
    
     getValidation({...formErrors, 
      Salution: personalData?.Salution ? null:'Please select salution',
      FirstName:personalData?.FirstName ? null:'Please enter first name',
      LastName:personalData?.LastName ? null:'Please enter last name',
      Gender:personalData?.Gender ? null:'Please select gender',
      MaritialStatus:programTypeId===1 ? (personalData?.MaritialStatus ? null:'Please select maritial status'):null,
      DateOfBirth:personalData?.DateOfBirth ? null:'Please select date of birth',
      Email:(personalData?.Email && formErrors?.Email===null) ? null:'Please enter email',
      MobileNoCountryCode:personalData?.MobileNoCountryCode ? null:'Please select mobileNo country code',
      MobileNo:(personalData?.MobileNo  && formErrors?.MobileNo===null)  ? null:'Please enter mobile number',
      Citizenship:personalData?.Citizenship ? null:'Please select citizenship',
      PassportExpiryDate:(programTypeId===1 || Number(personalData?.GovernmentIdType)===2)? ((personalData?.PassportExpiryDate || personalData?.PassportNo.toLowerCase()==='passport applied') ? null:'Please select passport expiry date'):null,
      PassportNo:programTypeId===1 ? (personalData?.PassportNo ? null:'Please enter passport number'):null,
      GovernmentIdNumber:programTypeId===2 ? (personalData?.GovernmentIdNumber ? null:'Please enter government id number'):null,
      GovernmentIdType:programTypeId===2 ? (personalData?.GovernmentIdType ? null:'Please select government id type'):null,
      Addres: programTypeId===2 ? (personalData?.Addres ? null:'Please enter address'):null,
      Country:programTypeId===2 ? (personalData?.Country ? null:'Please select country'):null,
      Province: programTypeId===2 ? (personalData?.Province ? null:'Please select province'):null,
      City:programTypeId===2 ? (personalData?.City ? null:'Please select city type'):null,
      Pincode: programTypeId===2 ? (personalData?.Pincode ? null:'Please enter pincode'):null,
     }, 3)
  }

  const getGovtTypeList=()=>{
    CommonServices.getGovtType().then(res=>{
      setGovtTypeList(res)

    }).catch(error=>{

    })
  }

  
  const getProvinceList = (value) => {
    ApplicationService.getProvince(value)
      .then((res) => {
        setProvinceList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getCityList = (value, clearFlag) => {
    ApplicationService.getCity(value)
      .then((res) => {
        setCityList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const selectTitle=(value)=>{
    if(value==='Mr.'){
      formData.Gender="Male"
    }
    else if(value==='Ms.' || value==='Mrs.'){
      formData.Gender="Female"
    }
   
    setPersonalData((pre)=>({...pre, 
      Gender:formData.Gender, 
      Salution:value}))
    personalInfoData((pre)=>({...pre, 
      Gender:formData.Gender, 
      Salution:value}))
  }

  useEffect(()=>{
    if(formData){
      formData.Salution=formData?.Gender==='Male'? 'Mr.' : formData?.Gender==='Female'?'Ms.':''
      setPersonalData(formData)
      personalInfoData(formData)
    }
    if(!initial.current){
      initial.current=true
      if(programTypeId===2){
        getGovtTypeList()
        if(personalData?.Country){
          getProvinceList(Number(personalData?.Country))
        }
        if(personalData?.Province){
          getCityList(Number(personalData?.Province))
        }
      }
    }
    // getValidation(formErrors, 3)

  }, [])


  useEffect(()=>{
    if(validationFlag){
      getvalid()
      setValidationFlag(false)
      setGetValidationFlag(true)
    }
  },[validationFlag])

  return (
    <>
      <div className={classes["mainContainer"]}>
        <div className={classes["container"]}>
          <div className="row">
            <div className="col-md-3 form-group">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Title <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Title"
                  name="Salution"
                  value={personalData?.Salution}
                  onChange={(e)=>{handleChange(e); selectTitle(e.target.value)}} onBlur={handleChange}
                >
                  <option value="">Select Tilte</option>
                  <option value="Mr."> Mr.</option>
                  <option value="Ms."> Ms.</option>
                  <option value="Mrs."> Mrs.</option>
                </Form.Select>
                {formErrors.Salution && <div className={classes["errorText"]}>{formErrors.Salution}</div>}
              </Form.Group>
            </div>
            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="FirstName">
                <Form.Label>
                  First Name <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="FirstName"
                  className="form-control"
                  value={personalData?.FirstName}
                  onChange={handleChange} onBlur={handleChange}
                />
                {formErrors.FirstName && <div className={classes["errorText"]}>{formErrors.FirstName}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MiddleName">
                <Form.Label>Middle Name</Form.Label>
                <input
                  type="text"
                  name="MiddleName"
                  className="form-control"
                  value={personalData?.MiddleName}
                  onChange={handleChange} 
                />
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="LastName">
                <Form.Label>
                  Last Name<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="LastName"
                  className="form-control"
                  value={personalData?.LastName}
                  onChange={handleChange} onBlur={handleChange}
                />
                 {formErrors.LastName && <div className={classes["errorText"]}>{formErrors.LastName}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Gender">
                <Form.Label>
                  Gender<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select disabled={personalData?.Gender}
                  name="Gender"
                  value={personalData?.Gender}
                  onChange={handleChange} onBlur={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Select>
                {formErrors.Gender && <div className={classes["errorText"]}>{formErrors.Gender}</div>}
              </Form.Group>
            </div>
         {programTypeId===1 && (
            <div className="col-md-3 form-group">
            <Form.Group className="mb-3" controlId="MaritialStatus">
              <Form.Label>
                Marital Status<span className={classes["mandotory"]}>*</span>
              </Form.Label>
              <Form.Select
                name="MaritialStatus"
                value={personalData?.MaritialStatus}
                onChange={handleChange} onBlur={handleChange}
              >
                <option value="">Select Status</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
                <option value="widowed">Widowed</option>
                <option value="separated">Separated</option>
                <option value="common-law">Common-law</option>
              </Form.Select>
              {formErrors.MaritialStatus && <div className={classes["errorText"]}>{formErrors.MaritialStatus}</div>}
            </Form.Group>
          </div>
         )}


            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="DateOfBirth">
                <Form.Label>
                  Date of Birth<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="date"
                  name="DateOfBirth"
                  className="form-control"
                  value={personalData?.DateOfBirth}
                  onChange={handleChange} onBlur={handleChange}
                />
                {formErrors.DateOfBirth && <div className={classes["errorText"]}>{formErrors.DateOfBirth}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Language">
                <Form.Label>First Language</Form.Label>
                <Form.Select
                  name="Language"
                  value={personalData?.Language}
                  onChange={handleChange} onBlur={handleChange}
                >
                  <option value="">Select Language</option>
                  {languageList.map((language, index) => (
                    <option key={index} value={language?.LanguageName}>
                      {language.LanguageName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Email">
                <Form.Label>
                  Email<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="Email"
                  name="Email"
                  className="form-control"
                  value={personalData?.Email}
                  onChange={handleChange} onBlur={handleChange}
                  required
                />
                {formErrors.Email && <div className={classes["errorText"]}>{formErrors.Email}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MobileNoCountryCode">
                <Form.Label>
                  Country Code<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="MobileNoCountryCode"
                  value={personalData?.MobileNoCountryCode}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                  <option value="">Select Country Code</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country?.CountryCode}>
                      <span
                        className={`${country?.flag}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country?.CountryCode} ({country?.CountryName})
                    </option>
                  ))}
                </Form.Select>
                {formErrors.MobileNoCountryCode && <div className={classes["errorText"]}>{formErrors.MobileNoCountryCode}</div>}
              </Form.Group>
            </div>

            <div className={`${classes['inputnumber-filed']} col-md-3 form-group`}>
              <Form.Group className="mb-3" controlId="MobileNo">
                <Form.Label>
                  Phone Number<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="number"
                  name="MobileNo"
                  className="form-control"
                  value={personalData?.MobileNo}
                  onChange={handleChange} onBlur={handleChange}
                  required
                  min={0}
                />
                {formErrors.MobileNo && <div className={classes["errorText"]}>{formErrors.MobileNo}</div>}
              </Form.Group>
            </div>
          </div>
        </div>
      {(Number(personalData?.Citizenship)===101 && Number(programTypeId)===1) && (
        <div
        className={`${classes.headingText} d-flex align-items-center mb-4 mt-4`}
      >
        <span>Passport Details</span>
        <span className={` ${classes.centerIcon} ${classes.uploadBtn} ms-2`}>
          <input
            type="file"
            id="upload-passport"
            className="d-none"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/png, image/jpeg"
            onChange={handleFileChange}
          />
          <span onClick={handleUploadBoxClick}>
            <img
              src={documentDownloadArrow}
              className={classes.actionICon}
              alt=""
            />
            <span className={`${classes.uploadPassport} ps-2`}>
              Upload Passport
            </span>
          </span>
        </span>
        {errorMsg && (
        <div className="d-flex justify-content-center m-2">
        <span className={classes['error-msg']}>({errorMsg})</span>
      </div>
        )}

      </div>
     )}

        <div className={classes["container"]}>
          <div className="row">
            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Citizenship">
                <Form.Label>
                  Country of Citizenship
                  <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="Citizenship"
                  value={personalData?.Citizenship}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                  <option value="">Select Citizenship</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country?.CountryId}>
                      <span
                        className={`fi fi-${country?.CShortName.toLowerCase()}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country?.CountryName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.Citizenship && <div className={classes["errorText"]}>{formErrors.Citizenship}</div>}
              </Form.Group>
            </div>
            {programTypeId===2 &&  (
              <>
                          <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="GovernmentIdType">
                <Form.Label>Government ID Type <span className={classes["mandotory"]}>*</span></Form.Label>
                <Form.Select
                  name="GovernmentIdType"
                  value={personalData?.GovernmentIdType}
                  onChange={(e)=>{handleChange(e); changeGovtId(e)}} onBlur={handleChange}
                >
                  <option value="">Select Government ID Type</option>
                  {govtTypeList.map((govt, index) => (
                    <option key={index} value={govt?.Id}>
                      {govt.GovernmentIdName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors?.GovernmentIdType && <div className={classes["errorText"]}>{formErrors?.GovernmentIdType}</div>}
              </Form.Group>
            </div>
            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="GovernmentIDNumber">
                <Form.Label>
                Government ID Number<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="GovernmentIdNumber"
                  className="form-control"
                  value={personalData?.GovernmentIdNumber}
                  onChange={handleChange} onBlur={handleChange}
                  required
                />
                {formErrors?.GovernmentIdNumber && <div className={classes["errorText"]}>{formErrors?.GovernmentIdNumber}</div>}
              </Form.Group>
            </div>
              </>
            )}

        {programTypeId===1 && (
            <div className="col-md-3 form-group">
            <Form.Group className="mb-3" controlId="PassportNo">
              <Form.Label>
                Passport Number<span className={classes["mandotory"]}>*</span>
              </Form.Label>
              <input
                type="text"
                name="PassportNo"
                className="form-control"
                value={personalData?.PassportNo}
                onChange={handleChange} onBlur={(e)=>{handleChange(e); spacehande(e)}}
                required
              />
              <div><span className={classes['hint-msg']}>Please write Passport number/Passport applied</span></div>
              {formErrors.PassportNo && <div className={classes["errorText"]}>{formErrors.PassportNo}</div>}
            </Form.Group>
          </div>
          )}

           {(programTypeId===1 || Number(personalData?.GovernmentIdType)===2)  && (
            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="PassportExpiryDate">
                <Form.Label>
                  Expiry Date<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="date"
                  name="PassportExpiryDate"
                  className="form-control"
                  min={currentDate}
                  disabled={personalData?.PassportNo.toLowerCase()==='passport applied'}
                  value={personalData?.PassportNo.toLowerCase()==='passport applied'?'':(personalData?.PassportExpiryDate ? personalData?.PassportExpiryDate.split('T')[0] : '')}
                  onChange={handleChange} onBlur={handleChange}
                  required
                />
                {formErrors.PassportExpiryDate && <div className={classes["errorText"]}>{formErrors.PassportExpiryDate}</div>}
              </Form.Group>
            </div>
           )}
          </div>
        </div>
      </div>
      {programTypeId===2 && (
        <>
             <div className={classes["heading"]}>
          <p className={classes['address-text']}>Mailing Address (Current residence address)</p>
        </div>
      <div className={classes["mainContainer"]}>
          <div className={classes["container"]}>

        <div className={classes["address"]}>
          <div className="row">
            <div className="col-md-12 form-group">
              <Form.Group className="mb-3" controlId="Addres">
                <Form.Label> Address <span className={classes['mandotory']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="Addres"
                  placeholder="Address"
                  value={personalData.Addres}
                  onChange={handleChange} onBlur={handleChange}
                />
                 {formErrors.Addres && <div className={classes["errorText"]}>{formErrors.Addres}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="country">
                <Form.Label> Country <span className={classes['mandotory']}>*</span></Form.Label>
                <Form.Select 
                  aria-label="Country"
                  name="Country"
                  value={personalData.Country}
                  onChange={(e)=>{getProvinceList(e.target.value); handleChange(e)}} onBlur={handleChange}
                  required
                >
                  <option className={classes["option-default"]} value="">Select a country</option>
                  {countryList.map((country) => (
                    <option key={country.CountryId} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.Country && <div className={classes["errorText"]}>{formErrors.Country}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="provinceState">
                <Form.Label> Province/State <span className={classes['mandotory']}>*</span></Form.Label>
                <Form.Select
                  name="Province"
                  aria-label="Province/State"
                  value={personalData.Province}
                  onChange={(e)=>{getCityList(e.target.value); handleChange(e)}} onBlur={handleChange}
                  required
                  // disabled={!genInfo.Country}
                >
                  <option className={classes["option-default"]} value="">Select a province/state</option>
                  {provinceList.map((province) => (
                    <option
                      key={province.ProvinceId}
                      value={province.ProvinceId}
                    >
                      {province.ProvinceName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.Province && <div className={classes["errorText"]}>{formErrors.Province}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="cityTown">
                <Form.Label> City/Town <span className={classes['mandotory']}>*</span></Form.Label>
                <Form.Select
                  name="City"
                  aria-label="City/Town"
                  value={personalData.City}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                  <option  className={classes["option-default"]} value="">Select a city/town</option>
                  {cityList.map((city) => (
                    <option key={city.CityName} value={city.CityName}>
                      {city.CityName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.City && <div className={classes["errorText"]}>{formErrors.City}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="postalZipCode">
                <Form.Label> Postal/Zip Code <span className={classes['mandotory']}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="Pincode"
                  placeholder="Postal/Zip Code"
                  value={personalData.Pincode}
                  onChange={handleChange} onBlur={handleChange}
                />
                {formErrors.Pincode && <div className={classes["errorText"]}>{formErrors.Pincode}</div>}
              </Form.Group>
            </div>
          </div>
        </div>
          </div>
          </div>
        </>
      )}
 
   
      {isLoading &&(
        <Loader></Loader>
      )}
      
    </>
  );
};

export default PersonalInformation;
