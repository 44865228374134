import React, {useRef, useState,useEffect} from "react";
import classes from "./InstituteDetails.module.css"; // Adjusted import
import program_details_image from "../../../../Assets/Images/program_details_image.png";
import heartIcon from "../../../../Assets/Logo/heartIcon.svg";
import Programs from "../../../Common/Programs/Programs";
import searchIcon from "../../../../Assets/Logo/search-icon3.svg";
import { useParams } from "react-router-dom";
import InstituteService from "../../../../Services/InstituteService";
import ProgramSearchService from "../../../../Services/ProgramSearchServices";
import Loader from "../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";

function InstituteDetails() {

  const initial = useRef(null);
  const { institutionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [institutesDetail, setInstitutesDetail] = useState({});
  const [searchQuery, setSearchQuery] = useState("")


  const bloburl = process.env.REACT_APP_LOGO_PATH;

  const [programsData, setProgramsData] = useState([]);
  

  const getInstitutionData = (id) => {
    setIsLoading(true);
    InstituteService.getInstitutionData(id)
    .then((res) => {
      setInstitutesDetail(res);
        setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      toast.error(error?.message);
    });
};

// for programs by insituteID

const getProgramListByInstAndEduLevel = (id) => {

  let data = {
    EduLevel: "",
    InstitutionId: id
  };

  setIsLoading(true);
  ProgramSearchService.getProgramListByInstAndEduLevel(data)
      .then((res) => {
        setProgramsData(res);
        console.log(programsData)
        setIsLoading(false);
      })
      .catch((error) => {
          setIsLoading(false);
          toast.error(error?.message);
      });
};




const filteredPrograms = programsData.filter((program) =>
  program?.ProgramName?.toLowerCase().includes(searchQuery.toLowerCase())
);



useEffect(() => {
  
  if (!initial.current) {
    initial.current = true;
  getInstitutionData(institutionId);
  getProgramListByInstAndEduLevel(institutionId);
  }

}, [institutionId]);




// dummiy data
const data = {
  profile: {
    name: "Douglas College",
    location: "New Westminster, British Columbia, Canada",
    imageSrc: program_details_image,
  },

  description: {
    paragraphs: [
      "Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program. Students will study a well-rounded curriculum that includes classes in accounting, communications, computing science, economics, finance and marketing.",
      "Graduates gain the skills to secure employment in fields including financial accounting, cost accounting and financial management. Graduates interested in furthering their accounting careers can transfer seamlessly into Douglas College's Bachelor of Business Administration in Accounting program. This diploma also satisfies the entrance requirements of the Chartered Professional Accountants (CPA) Advanced Certificate in Accounting and Finance (ACAF) program of studies and partially satisfies its requirements for admission into the CPA Professional Education Program (PEP).",
      "Students enrolled in this program may be eligible for a Co-operative Education designation. Co-operative Education involves alternating full-time academic and work terms. For information, contact the Co-operative Education Office.",
    ],
  },
};

const programs = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
];





  return (
    <div className={`${classes["instituteDetails_main_container"]} row`}>
      <div
        className={`${classes["instituteDetails_section"]} col-md-12`}
      >
        <div className={classes["instituteDetails_left_section"]}>
          <div className={classes["instituteDetails_section1"]}>
            <div className={classes["profileCard"]}>
            <div className={classes["card_coverImage"]}>
              <img 
                src={institutesDetail?.InstLogoPath ? bloburl+institutesDetail?.InstLogoPath : program_details_image} 
                alt="" 
              />
            </div>

              <div className={classes["card_info"]}>
                <div className={classes["name"]}>{institutesDetail?.InstName}</div>
                <div className={classes["description"]}>
                  {institutesDetail?.InstAddress1}, {institutesDetail?.InstProvince} {institutesDetail?.InstCountryName}
                </div>
              </div>
            </div>
          </div>

          <div className={classes["instituteDetails_section3"]}>
            <div className={`row ${classes["main-row"]}`}>
              <div className={`col-8 ${classes["customCol8"]}`}>
                <div className={classes["customCol8_content"]}></div>
              </div>
              <div className={`col-2 ${classes["customCol2"]}`}>
                <div className={classes["innerDiv"]}></div>
                <div className={classes["innerDiv"]}></div>
                <div className={`mb-0 ${classes["innerDiv"]}`}></div>
              </div>
              <div className={`col-2 ${classes["customCol2"]}`}>
                <div className={classes["innerDiv"]}></div>
                <div className={classes["innerDiv"]}></div>
                <div className={`mb-0 ${classes["innerDiv"]}`}></div>
              </div>
            </div>
          </div>

          <div className={classes["instituteDetails_section4"]}>
            <div className={classes["program_description_content"]}>
              <div className={classes["program_description"]}>
                {data?.description?.paragraphs?.map((para, index) => (
                  <p key={index}>{para}</p>
                ))}
                {institutesDetail.institutionDescription}
              </div>
            </div>
          </div>

          <div className={`row ${classes["instituteDetails_section5"]}`}>
            <div className={`col-md-12 col-lg-7 p-0 ${classes["program_description_content"]}`}>
              <div className={`${classes["search_input_content"]}`}>
              <input
                  type="text"
                  placeholder="Search for a program"
                  className={`${classes["search-input"]}`}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />

                <img
                  src={searchIcon}
                  alt="Search"
                  className={`${classes["search-icon"]}`}
                />
              </div>
              <button className={`${classes["search-button"]}`}>Search</button>
            </div>
            <div className="col-md-12 col-lg-5">

            </div>
          </div>

          {/*programs prefered sections /*/}
          <div className={classes["instituteDetails_section6"]}>
            <div className={classes["instituteDetails_Programs_content"]}>
              <div className={classes["program_title"]}>Programs Offered</div>
              <div className={classes["program-cards"]}>
              {filteredPrograms.length > 0 ? (
                  filteredPrograms.map((program) => (
                    <Programs key={program.ProgramId} program={program} institutesDetail={institutesDetail}/>
                  ))
                ) : (
                  <p>No programs found.</p>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${classes["instituteDetails_section"]} col-md-3 col-lg-3`}
      ></div>
       {isLoading && <Loader />}
    </div>
  );
}

export default InstituteDetails;
