import React, { useState } from "react";
import classes from './common.module.css';
import SentComponents from "../../User_Account/Connections/SubComponents/SentComponents";
import dropDown from "../../.../../../../../Assets/Logo/reddropdown.svg";
import { Dropdown } from "react-bootstrap";

const SentComponent = ({searchText, isSearchFlag, setIsSearchFlag}) => {

  const [selectedOption, setSelectedOption] = useState("1");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSort, setIsSort] = useState(false);

  const handleSelect = (eventKey) => {
    if(eventKey && selectedOption !== eventKey){
      setSelectedOption(eventKey);
      setIsSort(true);
    }
  };

  return (
    <div className="row mt-4">
      <div className={`${classes.containerForTab} col-md-12`}>
        <div className="d-flex justify-content-end mb-3">
        <div className={classes["sorting-drop-down-block"]}>
            <div className={classes["sorting-text"]}>Sort by:</div>
            <Dropdown
              onSelect={handleSelect}
              onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
              className="drop-down-icon"
            >
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className={`${classes["drop-down-toggle"]}`}
              >
                {(selectedOption && selectedOption === "1") ? 'Recently Added' : (selectedOption && selectedOption === "2") ? 'A to Z' : 'Z to A'}
                {isDropdownOpen ? (
                  <img src={dropDown} alt="" />
                ) : (
                  <img src={dropDown} alt="" />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className={classes["drp-down-items"]}>
                <Dropdown.Item eventKey="1">Recently Added</Dropdown.Item>
                <Dropdown.Item eventKey="2">A to Z</Dropdown.Item>
                <Dropdown.Item eventKey="3">Z to A</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <SentComponents searchInput={searchText} isSearch={isSearchFlag} setIsSearch={setIsSearchFlag} selectedSort={selectedOption} isSortingFlag={isSort} setSortingFlag={setIsSort} />
      </div>
    </div>
  );
};

export default SentComponent;
