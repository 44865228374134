import React, { useState } from "react";
import classes from "./Connections.module.css";
import SubConnections from "./SubComponents/SubConnections";
import Suggestions from "./SubComponents/Suggestions";
import Invitations from "./SubComponents/Invitations";
import SentComponents from "./SubComponents/SentComponents";
import { CloseButton } from "react-bootstrap";


function Connections({ connectedUserId }) {
  const [activeSection, setActiveSection] = useState("connections");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);

  const onChangeTab = (tab) => {
    if(tab === "connections"){
      setActiveSection(tab);
    }

    else if(tab === "Suggestions"){
      setActiveSection(tab);
    }
    
    else if(tab === "invitations"){
      setActiveSection(tab);
    }

    else if(tab === "sent"){
      setActiveSection(tab);
    }
    setSearchQuery("");
    setSearchFlag(false);
  }

  const onSearch = (event) => {
    let searchString = event.target.value.trim();

    if (searchString === "") {
      setSearchQuery("");
    }
    else {
      setSearchQuery(searchString);
      if (searchString.length > 2) {
        setSearchFlag(true);
      }
    }
  };

  const clearSearchData = () => {
    const emptyEvent = { target: { value: "" } };
    setSearchQuery("");
    onSearch(emptyEvent);
  }

  return (
    <>
      {connectedUserId && (
        <div className={`${classes["connection-container"]}`}>
          <div className={`${classes["connection-section"]}`}>
            <SubConnections studentId={connectedUserId} />
          </div>
        </div>
      )}
      {!connectedUserId && (
        <div className={`${classes["connection-container"]}`}>
          <div className={`${classes["connection-section"]}`}>
            <div className={`${classes["connection-left-section"]}`}>
              <div className={classes["connection-left-content"]}>
                <div
                  className={`${classes["alphaComp"]} ${activeSection === "connections" ? classes["activeMenuBar"] : ""}`}
                  onClick={() => onChangeTab("connections")}>
                  Connections
                </div>
                <div
                  className={`${classes["alphaComp"]}  ${activeSection === "Suggestions" ? classes["activeMenuBar"] : ""}`}
                  onClick={() => onChangeTab("Suggestions")}>
                  Suggestions
                </div>
                <div
                  className={`${classes["alphaComp"]}  ${activeSection === "invitations" ? classes["activeMenuBar"] : ""}`}
                  onClick={() => onChangeTab("invitations")}>
                  Invitations
                </div>
                <div
                  className={`${classes["alphaComp"]}  ${activeSection === "sent" ? classes["activeMenuBar"] : ""}`}
                  onClick={() => onChangeTab("sent")}>
                  Sent
                </div>
              </div>
            </div>
            
            <div className={`${classes["connection-right-section"]} `}>
              <div className={classes['search-bar-block']}>
                <div className={classes['search-bar']}>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={classes['input-serch']}
                    value={searchQuery}
                    onChange={(event) => onSearch(event)}
                  />
                  {searchQuery && (
                    <CloseButton className={classes["close-button"]} onClick={clearSearchData} />
                  )}
                </div>
              </div>
              {activeSection === "connections" && <SubConnections searchInput={searchQuery} isSearch={searchFlag} setIsSearch={setSearchFlag} />}
              {activeSection === "Suggestions" && <Suggestions searchInput={searchQuery} isSearch={searchFlag} setIsSearch={setSearchFlag} />}
              {activeSection === "invitations" && <Invitations searchInput={searchQuery} isSearch={searchFlag} setIsSearch={setSearchFlag} />}
              {activeSection === "sent" && <SentComponents searchInput={searchQuery} isSearch={searchFlag} setIsSearch={setSearchFlag} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Connections;