import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import styles from "./EducationSection.module.css";
import eduEdit from "../../../../../../Assets/Logo/eduEdit.svg"
import AddIcon from "../../../../../../Assets/Logo/Add-icon-logo.svg"
import eduDelete from "../../../../../../Assets/Logo/eduDelete.svg"
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import AddEducationModal from "./AddEducationModal/AddEducationModal";
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import ApplicationService from "../../../../../../Services/ApplicationServices";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";

const EducationSection = ({
  educationLevelData,
  countryList,
  schoolList,
  grdSchemeData,
  onEducationCountryChange,
  deleteSchoolDetail,
  studentId, 
  educationInfo,
  getEducationInfo, programTypeId,
  getSchoolDetailList, getValidation, editEducation, disableEditEducationDetail, applicationId, viewEducation
}) => {


  const initail=useRef(null)
  const [gradeSchemeDDL, setGradeSchemeDDL] = useState([]);
  const [addMoreEducationPopup, setAddMoreEducationPopup] = useState(false);
  const [dropDownGradingSchemeSelected, setDropDownGradingSchemeSelected] = useState(false);
  const GradeDropdownValuesArray = [1,2,14,19,21,22,25];
  const [confirmationRepostModal, setConfirmationRepostModal]=useState(false);
  const [schoolId, setSchoolId]=useState(0);

  const [updateEducationData, setUpdateEducationData]=useState(false);
  const { validationFlag, setValidationFlag } = useBehaviorSubject();
  const [getValidationFlag, setGetValidationFlag]=useState(false)
  const [tempData, setTemp]=useState(educationInfo)
  const [isLoading, setIsLoading]=useState(false)
  const [gradeHint, setGradeHint]=useState('')
  const [patturn, setPatturn]=useState('')
  

   const [eduInfo, setEduInfo] = useState({
    CountryOfEducation: "",
    HighestLevelOfEducation: "",
    GradingScheme: "",
    GradeAverage: "",
  });
  const [formErrors, setFormErrors] = useState({
    CountryOfEducation: null,
    HighestLevelOfEducation: null,
    GradingScheme: null,
    GradeAverage: null,
  });

  useEffect(()=>{
    if(!initail.current){
      initail.current=true
      setEduInfo(educationInfo);
      getEducationInfo(educationInfo)
      onEducationCountryChange(educationInfo?.CountryOfEducation);

      // getValidation(formErrors, 5)
    }

  }, [])

  useEffect(()=>{
    if(grdSchemeData){
      onGradeChange(educationInfo?.GradingScheme);
    }  
  }, [grdSchemeData])

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    if (name === 'GradingScheme') {
      onGradeChange(value);
    }
    setEduInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    getEducationInfo({...eduInfo, [name]:value})


    const errorMsg = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }))
    getValidation({...formErrors, [name]: errorMsg}, 5)

  };

  const resetAvrage=()=>{
    eduInfo.GradeAverage=''

    setEduInfo((prevInfo) => ({
      ...prevInfo,
      "GradeAverage": '',
    }));
    getEducationInfo({...eduInfo, "GradeAverage":''})


    const errorMsg = validateField("GradeAverage", '');
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      "GradeAverage": null,
    }))
    getValidation({...formErrors, "GradeAverage": errorMsg}, 5)
  }

  const handleInfoChangeBulr = (e) => {
    const { name, value } = e.target;
    setEduInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    getEducationInfo({...eduInfo, [name]:value})
   

    const errorMsg = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }))
    getValidation({...formErrors, [name]: errorMsg}, 5)

  };


  const onGradeChange = (gradingSchemeID) => {
    if (GradeDropdownValuesArray.includes(Number(gradingSchemeID))) {
      setDropDownGradingSchemeSelected(true);
    }
    else{
      setDropDownGradingSchemeSelected(false);
      return;
    }
    const selectedGrade = grdSchemeData.find((d) => d.GrdSchemeId === Number(gradingSchemeID)) || null;

    if (selectedGrade) {
      setGradingScheme(selectedGrade);
    }
  };

  const setGradingScheme = (selectedGrade) => {
    if (selectedGrade) {
      setDropDownGradingSchemeSelected(true);
        const hints = selectedGrade['InputHint'].replaceAll(" ","").split(",");
      setGradeSchemeDDL(hints);
    } else {
      setDropDownGradingSchemeSelected(false);
    }
  };


  const handleEducationCountryChange = (e) => {
    const { name, value } = e.target;
    eduInfo.GradingScheme = "";
    eduInfo.GradeAverage = "";
    onEducationCountryChange(e.target.value);
    
    const errorMsg = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }))
    getValidation({...formErrors, [name]: errorMsg}, 5)
  };




  //********************** */
  const addMoreEducation = () => {
    setAddMoreEducationPopup(true);
  };


 const showConfirmModalhandler = (id) => {
  setSchoolId(id);
  setConfirmationRepostModal(true);
};
const hideConfirmModalhandler = () => {
  setConfirmationRepostModal(false);
};


//  ******************** delete school data
const deleteSchoolData = ()=>{
  deleteSchoolDetail(schoolId)
  hideConfirmModalhandler();
}


// send school data
const [selectedSchoolData, setSelectedSchoolData] = useState({});
const handleEditClick = (school) => {
  setSelectedSchoolData(school); 
  setAddMoreEducationPopup(true);
  setUpdateEducationData(true); 
};

const handleClose = () => {
  setAddMoreEducationPopup(false);
  // setRows([]);
  // setEducationDetails("")
  setSelectedSchoolData({});
  setUpdateEducationData(false); 
  // setCountryInstitution("");
  // setEducationLevel("");
};


const refreshSchoolList=()=>{
  getSchoolDetailList();
}

const validateField = (name, value) => {

  let errorMsg = null;
  switch (name) {
   case "CountryOfEducation":
      if(!value)
        {
          errorMsg = "Please select country of education";
        } 
      break;
  case "HighestLevelOfEducation":
      if(!value) errorMsg = "Please select highest level of education";
      break;
   case "GradingScheme":
      if(!value) errorMsg = "Please select grading scheme";
      break;
   case "GradeAverage":
    if(dropDownGradingSchemeSelected){
      if(!value) errorMsg = "Please select grade average";
      break;
    }else{
      if(!value) errorMsg = "Please enter grade average";
      if (!new RegExp(patturn).test(value)) errorMsg = "Please enter valid score";
      break;
    }
     
   default:
      break;
  }
  return errorMsg;
};

const getVaild=(data)=>{

  setFormErrors({...formErrors,
    CountryOfEducation: ((data?.CountryOfEducation || Number(data?.CountryOfEducation!==0)) && formErrors?.CountryOfEducation===null) ? null :'Please select country of education',
    HighestLevelOfEducation: (data?.HighestLevelOfEducation && formErrors?.HighestLevelOfEducation===null)  ?  null :'Please select highest level of education',
    GradingScheme:(data?.GradingScheme && formErrors?.GradingScheme===null)  ? null :'Please select grading scheme',
    GradeAverage: (data?.GradeAverage && formErrors?.GradeAverage===null) ? null:'Please enter grade average',
   })
   getValidation({...formErrors,
    CountryOfEducation: (data?.CountryOfEducation && formErrors?.CountryOfEducation===null) ? null :'Please select country of education',
    HighestLevelOfEducation: (data?.HighestLevelOfEducation && formErrors?.HighestLevelOfEducation===null)  ?  null :'Please select highest level of education',
    GradingScheme:(data?.GradingScheme && formErrors?.GradingScheme===null)  ? null :'Please select grading scheme',
    GradeAverage: (data?.GradeAverage && formErrors?.GradeAverage===null) ? null:'Please enter grade average',
   }, 5)
}

const cancelData=()=>{
  disableEditEducationDetail()
  setEduInfo(tempData);
  getEducationInfo(tempData)
  onEducationCountryChange(tempData?.CountryOfEducation);
  onGradeChange(tempData?.GradingScheme);
  getVaild(tempData)
}
const updateData=()=>{
  if(eduInfo?.CountryOfEducation===0 || eduInfo?.HighestLevelOfEducation===0 || eduInfo?.GradingScheme===0 
    || !eduInfo?.GradeAverage
  ){
    getVaild(eduInfo)
    return 

  }
  let data={
    ApplicationId: applicationId,
    CountryOfEducation:Number(eduInfo?.CountryOfEducation),
    HighestLevelOfEducation: Number(eduInfo?.HighestLevelOfEducation),
    GradingScheme: Number(eduInfo?.GradingScheme),
    GradeAverage: eduInfo?.GradeAverage,
    GraduatedMostRecent:0,
    EduYearEnd:eduInfo?.EduYearEnd ? eduInfo?.EduYearEnd:"",
    EduYearStart: eduInfo?.EduYearStart ? eduInfo?.EduYearStart:"",
    StudentId: studentId
  }
  setIsLoading(true)
  ApplicationService.udateEducation(data).then(res=>{
    setIsLoading(false)
    toast.success(res)
    disableEditEducationDetail()

  }).catch(error=>{
    setIsLoading(false)
    toast.error(error?.message)
  })
}

useEffect(()=>{
  if(validationFlag){
    getVaild(eduInfo)
    setValidationFlag(false)
    setGetValidationFlag(true)
  }
},[validationFlag])


const getHintAndPettrn=(value)=>{
  grdSchemeData.filter(item=>{
    if(item?.GrdSchemeId===Number(value)){
      setGradeHint(item.InputHint)
      setPatturn(item?.InputPattern)
    }
  })
}

  return (
    <div className={styles["data-section"]}>
      {/*main education section only have only four fields */}
      <Form>
        <div className={styles["eduacation-data-section"]}>
          <div className="row">
            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="CountryOfEducation">
                <Form.Label>Country of Education <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select disabled={!editEducation}
                  name="CountryOfEducation"
                  aria-label="Country of Education"
                  onChange={(e) => {
                    handleEducationCountryChange(e);
                    handleInfoChange(e);
                  }} onBlur={handleInfoChange}
                  value={eduInfo?.CountryOfEducation}
                  required
                >
                  <option value='' className={styles["option-default"]}>
                    Select a country
                  </option>
                  {countryList.map((country) => (
                    <option key={country.CountryId} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.CountryOfEducation && <div className={styles["errorText"]}>{formErrors.CountryOfEducation}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="highestLevelOfEducation">
                <Form.Label>Highest Level of Education <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select disabled={!editEducation}
                  name="HighestLevelOfEducation"
                  aria-label="Highest Level of Education"
                  onChange={handleInfoChange} onBlur={handleInfoChange}
                  value={eduInfo?.HighestLevelOfEducation}
                  required
                >
                  <option className={styles["option-default"]} value="">
                    Select level of education
                  </option>
                  {educationLevelData.map((level) => (
                    <option key={level.EduLevelId} value={level.EduLevelId}>
                      {level.EduLevelName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.HighestLevelOfEducation && <div className={styles["errorText"]}>{formErrors.HighestLevelOfEducation}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="gradingScheme">
                <Form.Label>Grading Scheme <span className={styles['extrik']}>*</span></Form.Label>
                <Form.Select disabled={!editEducation}
                  name="GradingScheme"
                  aria-label="Grading Scheme"
                  onChange={(e)=>{handleInfoChange(e); resetAvrage(); getHintAndPettrn(e.target.value)}} onBlur={handleInfoChangeBulr}
                  value={eduInfo?.GradingScheme}
                  required
                >
                  <option className={styles["option-default"]} value="">
                    Select grading scheme
                  </option>
                  {grdSchemeData.map((scheme) => (
                    <option key={scheme.GrdSchemeId} value={scheme.GrdSchemeId}>
                      {scheme.GrdSchemeName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.GradingScheme && <div className={styles["errorText"]}>{formErrors.GradingScheme}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              {dropDownGradingSchemeSelected ? (
                <Form.Group controlId="gradeAverage">
                  <Form.Label>Grade Average <span className={styles['extrik']}>*</span></Form.Label>
                  <Form.Select disabled={!editEducation}
                    name="GradeAverage"
                    onChange={handleInfoChange} onBlur={handleInfoChange}
                    value={eduInfo?.GradeAverage}
                    required
                  >
                    <option className={styles["option-default"]} value="">
                      Select grade average
                    </option>
                    {gradeSchemeDDL.map((grade, index) => (
                      <option key={index} value={grade}>
                        {grade}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.GradeAverage && <div className={styles["errorText"]}>{formErrors.GradeAverage}</div>}
                </Form.Group>
              ) : (
                <Form.Group controlId="gradeAverageInput">
                  <Form.Label>Grade Average <span className={styles['extrik']}>*</span></Form.Label>
                  <Form.Control disabled={!editEducation}
                    type="text"
                    name="GradeAverage"
                    value={eduInfo?.GradeAverage}
                    placeholder="Grade Average"
                    onChange={(e) => handleInfoChange(e)} onBlur={handleInfoChange}
                    required
                  />
                  <p className={styles['hint']}>{gradeHint}</p>
                  {formErrors.GradeAverage && <div className={styles["errorText"]}>{formErrors.GradeAverage}</div>}
                </Form.Group>
              )}
            </div>
          </div>
        </div>
      </Form>

      {/* ***************************************************************** */}
    {programTypeId===1 && (
      <>

      <div className="text-end m-2">
        <button disabled={!editEducation} className={styles["add-more-btn"]} onClick={addMoreEducation}>
          <span><img src={AddIcon} alt="" /></span> Add More Education
        </button>
      </div>

      {/* school list section to show school list */}
      <div className={`${styles.formBackgroundEducationInfoMore} mt-4`}>
        <div className={`${styles.rowHeight} row mb-4`}>
          {schoolList?.length > 0 &&
            schoolList.map((school) => (
              <div className="col-lg-4 col-md-6 mb-4" key={school.SchoolId}>
                <div className={styles.educationCard}>
                  <div className={styles.educationCardHeader}>
                    <div
                      className={`${styles.textOverflow} ${styles.educationCardTitle}`}
                      title={school.NameOfInstitution}
                    >
                      {school.NameOfInstitution}
                    </div>
                    {editEducation && (
                    <div className={styles.actions}>
                    <button
                      className={styles.iconHeightUpdate}
                      title="Edit"
                      onClick={() => handleEditClick(school)}
                    >
                      <img src={eduEdit} alt="Edit" />
                    </button>
                    <button
                      className={styles.iconHeightDelete}
                      title="Delete"
                      onClick={() => showConfirmModalhandler(school.SchoolId)}
                    >
                      <img src={eduDelete} alt="Delete" />
                    </button>
                  </div>
                    )}

                  </div>
                  <div className={styles.mainWrapper}>
                    <div className={styles.contentArea}>
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>Level : </div>
                        <div title={school.LevelOfEducationName} className={styles.headingValue}>
                            {school.LevelOfEducationName}
                        </div>
                      </div>
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>
                          Language of Instruction :{" "}
                        </div>
                        <div className={styles.headingValue}>
                          {school.Language}
                        </div>
                      </div>
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>
                          Board of Education :{" "}
                        </div>
                        <div
                          className={`${styles.headingValue} ${styles.boardOfEducation}`}
                          title={school?.Addres}
                        >
                          {school?.Addres}
                        </div>
                      </div>
                      {/* {school.StreamName && ( */}
                      <div className={styles.valueWrapper}>
                        <div className={styles.headingLabel}>Stream : </div>
                        <div title={school.StreamName} className={styles.headingValue}>
                            {school.StreamName}
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                    <div className={styles.tableArea}>
                      <div className={`${styles.tableWrapper} edu_table`}>
                        <table
                          style={{ width: "100%" }}
                          className={styles.educationTable}
                        >
                          <thead>
                            <tr>
                              <th className={styles.tableHeading}>
                                Major Subject
                              </th>
                              <th className={styles.tableHeading}>Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            {school.Marks.map((item, index) => (
                              <tr key={index}>
                                <td className={styles.tableData}>
                                  {item.SubjectName}
                                </td>
                                <td className={styles.tableData}>
                                  {item.ObtainMarks}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      </>
    )}
      {/* ************************************************* */}

     

      {(editEducation && viewEducation) && (
                        <div className='text-end mt-4'>
                            <button onClick={cancelData} className={styles['cancel-btn']}>Cancel</button>
                            <button onClick={updateData} className='primary-button'>Update</button>
                        </div>
                    )}

                    {isLoading && (
                      <Loader></Loader>
                    )}

      {/* popup for education modal */}



   {addMoreEducationPopup && (
      <AddEducationModal addMoreEducationPopup={addMoreEducationPopup} updateFlag={updateEducationData} handleClose={handleClose} selectedSchoolData={selectedSchoolData} refreshSchoolList={refreshSchoolList}/>
      
       )}


      {confirmationRepostModal && (
          <ConfirmationModal onShow={showConfirmModalhandler} getConfirmation={deleteSchoolData} 
          onClose={hideConfirmModalhandler} btnText='Yes'  confirmationText='Are you sure, you want to remove the selected education detail ?'>
            
          </ConfirmationModal>
        )}


    </div>
    
  );
};

export default EducationSection;
