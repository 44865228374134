import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Programs from "../../../../Common/Programs/Programs";
import styles from "./SimilarPrograms.module.css";

function SimilarPrograms({ programs }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1400 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1400, min: 940 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 940, min: 500 },
      items: 2,
    },
    small: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  // Flatten the array of programs
  const flattenedPrograms = programs.reduce((acc, level) => {
    return acc.concat(level.programs);
  }, []);

  return (
    <div>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        infinite={true}
        keyBoardControl={true}
        stagePadding={"5"}
        itemClass={styles.carouselItem}
      >
        {flattenedPrograms.map((program) => (
          <div className={styles["main_card"]} key={program.ProgramId}>
            <Programs key={program.ProgramId} program={program} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default SimilarPrograms;