import React, { useEffect, useState } from "react";
import { Card, Table } from 'react-bootstrap';
import classes from "./DeferralRequests.module.css";
import srName from "../../../../../Assets/Logo/srnum.svg";
import sortName from "../../../../../Assets/Logo/sort.svg";
import leftDropDown from "../../../../../Assets/Logo/back.svg";
import ApplicationService from "../../../../../Services/ApplicationServices";
import ViewDetails from "../viewDetails/viewDetails";
import Loader from "../../../../GlobleShared/Loader/Loader";
const DeferralRequests = () => {
  const columns = [
    { title: "Application ID", dataKey: "ApplicationId" },
    { title: "Deferral Intake", dataKey: "IntakeName" },
    { title: "Deferral Date", dataKey: "AddStamp" },
    { title: "Name", dataKey: "Name" },
    { title: "Program", dataKey: "Program" },
    { title: "Application Program Category", dataKey: "ProgramType" },
    { title: "Institution", dataKey: "Institution" },
    { title: "Status", dataKey: "Status" },
  ];
  const columnsViewDetail = [
    { title: "Application ID", dataKey: "ApplicationId" },
    { title: "Deferral Intake", dataKey: "IntakeName" },
    { title: "Deferral Date", dataKey: "AddStamp" },
    { title: "Program", dataKey: "Program" },
    { title: "Student ID", dataKey: "StudentId" },
    { title: "Intake", dataKey: "CurrentIntake" },
    { title: "Added by User", dataKey: "AddUser" },
    { title: "Institution", dataKey: "Institution" },
    { title: "Student Name", dataKey: "Name" },
    { title: "Deferral Status", dataKey: "Status" },
    { title: "Application Program Category", dataKey: "ProgramType" }
];

  const [deferralId, setDeferralId] = useState(null);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] =  useState(false);
  const responseData = []
  const [selectedDeferral, setSelectedDeferral ] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 
    return `${day}/${month}/${year}`; 
  };
  const DeferralDetailGet = () =>{
    setIsLoading(true);
    const data = deferralId ? deferralId : 'All';
    ApplicationService.getDeferralRequestList(data).then((res)=>{
      if(deferralId){
        setSelectedDeferral(res.length > 0 ? res[0] :  rows.find(row => row["ApplicationId"] === deferralId))
        setIsLoading(false);
      }
      else{
        setRows(res);
        setIsLoading(false);
      }
    }).catch((error)=>{
      setIsLoading(false);
    })
  }
  useEffect(()=>{
    DeferralDetailGet();
  },[deferralId]);
  const handleViewRequest = (id) => {
    setIsLoading(true);
    setDeferralId(id);
    setIsLoading(false);
  };
  return (
    <div className={classes.container}>
      {isLoading && <Loader/>}
      <div className={classes.heading}>MSM Deferral Requests</div>
      {deferralId && 
      <div className="mt-3 mb-3" >
        <span onClick={() => setDeferralId(null)} className={classes.backButton}><img   src={leftDropDown} alt="" /> <span>Back</span></span>
       
      </div>}
      {deferralId === null ? (
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column.title}</span>
                  {/* <img
                    src={index === 0 ? srName : sortName}
                    alt={index === 0 ? "Sr Name" : "Sort"}
                    className={classes.icon}
                  /> */}
                </div>
              </th>
            ))}
            <th>Deferral Details</th>
          </tr>
        </thead>
        {rows?.length > 0 && (
        <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>{column.dataKey === 'IntakeDate' ? formatDate(row[column.dataKey]) : row[column.dataKey]}</td>
            ))}
            <td>
              <span 
                className={classes.actionBtn}
                onClick={() => handleViewRequest(row["ApplicationId"])}>
                View
              </span>
            </td>
          </tr>
        ))}
      </tbody>
        )}

       {rows?.length===0 && (
        <tbody>
          <tr>
            <td colSpan={10} className="text-center"> No Data Found</td>
          </tr>
        </tbody>
        
      )}

      </Table>
      ) : (
      <ViewDetails
      columns={columnsViewDetail}
      selectedDeferral={selectedDeferral}
      />
      )}
    </div>
  );
};

export default DeferralRequests;
