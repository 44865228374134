import React, {useState, useEffect,useRef} from "react";
import { useNavigate } from "react-router-dom";
import styles from './Groups.module.css';
import plusRed from '../../../../../../../src/Assets/Logo/add-icon-red.svg';
import profilePic1 from '../../../../../../../src/Assets/Images/profile_avatar.png';
import GroupService from "../../../../../../Services/GroupService";
import { toast } from "react-toastify";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import useLocalStorage from "../../../../../GlobleShared/CustomHooks/useLocalStorage";



const GroupItem = ({groupId, groupName, GroupImage, message, members, modalShow}) => {

  const blobUrl = process.env.REACT_APP_URL_PATH;

  return(

  <div className={styles.groupItem}>
    <div className={styles.groupIconContainer}>
    <img  src={GroupImage ? blobUrl +GroupImage : profilePic1} alt="member" className={styles["group-profile-pic"]} />
    <div className={styles.groupContent}>
      <div title={groupName} className={styles.groupName}>{groupName}</div>
      <div title={message}
        className={styles.groupMessage}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <div className={styles.groupMembers}>
       Members: {members}
      </div>
    </div>
    </div>
    
    <div className={styles.groupAction}>
      <button className={styles.addButton}>
        <img onClick={() => modalShow(groupId)} src={plusRed} alt="Add Group" className={styles.addButtonIcon} />
      </button>
    </div>
    
  </div>
);
}




const Groups = () => {

  const [isLoading, setIsLoading] = useState(false);
   const [groups, setGroups] = useState([])
   const [filteredGroups, setFilteredGroups] = useState([]);
   const initial = useRef(null);
   const navigate=useNavigate()
   const [modalIsVisible, setModalIsVisible] = useState(false);
  const [confirmationText , setConfirmationText]= useState('')
  const [btnTextName, setbtnTextName] = useState();
  const [groupIdvalue, setgroupIdvalue] = useState();
  const [userDetail] = useLocalStorage('userDetail');
  const studentId = userDetail?.user_id;




   const showModalhandler=()=>{
    setModalIsVisible(true);
    // setStatusId(status)
    setConfirmationText('Are you sure, you want to join the selected group?')
    setbtnTextName('Join');
  }

  const hideModalhandler=()=>{
    setModalIsVisible(false);
  }

  const getConfirm =()=>{
    joinSuggestionGroup();
    hideModalhandler()
  }

  const modalShow = (id)=>{
    setgroupIdvalue(id);
    showModalhandler();
  }


  const joinSuggestionGroup=()=>{
    let data = {
      "GroupID": groupIdvalue,
      "UserGroupRole": '3',
      "UserID": studentId
    }
    setIsLoading(true)
    GroupService.joinSuggestionGroup(data).then(res=>{
      setIsLoading(false)
      toast.success(res?.message)
      viewMore();
      setModalIsVisible(false);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message)
    })
  
  }


  const getSuggestionGroups = () => {
    setIsLoading(true);
    GroupService.getSuggestionGroup()
      .then((res) => {
        let filteredData = res?.filter(item => item?.UserGroupStatusId !== "2");
        setGroups(filteredData);
        // if (filteredData.length > 0) {
        //   handlePostDashboard(filteredData[0]);
        //   setSelectedGroupId(filteredData[0]?.GroupID || filteredData[0]?.groupId)
        //   setGroupIdGlobal(filteredData[0]?.GroupID || filteredData[0]?.groupId)
        //   setMemberCount(filteredData[0]?.MembersCount)
        // } else {
        //   handlePostDashboard(null);
        // }
        // setUpdatePostDatata(true)
        setIsLoading(false);
        toast.success(res?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Failed to get suggestion list. Please try again.");
      });
  };



  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      getSuggestionGroups(); 
  }
  }, []);

  const viewMore =()=>{
    navigate('/member/groups')
  }


  return (
    <div className={styles.groupsContainer}>
      <div className={styles.header}>
        <div className={styles.headerText}>Groups</div>
        <div onClick={viewMore} className={styles.viewMore}>View More...</div>
      </div>
      {groups?.slice(0, 3).map((group, index) => (
        <GroupItem
          key={group?.GroupID}
          groupId={group?.GroupID}
          groupName={group?.GroupName}
          message={group?.GroupDesc}
          members={group?.MembersCount}
          GroupImage={group?.GroupImage}
          modalShow={modalShow}
        />
      ))}
      <div>
      {isLoading && (
        <Loader></Loader>
      )}

     {modalIsVisible && (
        <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible}  getConfirmation={getConfirm} >
        </ConfirmationModal>
      )}
      </div>
      
    </div>
    
  );
};

export default Groups;
