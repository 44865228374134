import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Table } from 'react-bootstrap';
import classes from "./RetriveDeleteApplication.module.css";
import srName from "../../../../../Assets/Logo/srnum.svg";
import sortName from "../../../../../Assets/Logo/sort.svg";
import SearchModal from "../../Search/SearchModal/SearchModal";
import corssIcon from "../../../../../Assets/Logo/crossred.svg";
import ApplicationService from "../../../../../Services/ApplicationServices";
import Loader from "../../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";

const RetriveDeleteApplication = () => {
  const columns = [
    { title: "Application ID", dataKey: "ApplicationId" },
    { title: "Student Name", dataKey: "FullName" },
    { title: "Passport", dataKey: "PassportNo" },
    { title: "Program", dataKey: "ProgramName" },
    { title: "Institution", dataKey: "InstName" },
    { title: "Status", dataKey: "ApplicationStatus" },

  ];

  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] =  useState(false);
  const [retriveData, setRetriveData]=useState('')
  const initial=useRef(null)


  const getRetrieveDeleteData = () =>{
    setIsLoading(true);
    let type = "";
    ApplicationService.getRetrieveDeleteApplication(type).then((res)=>{
      setRowData(res)
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
    })
  }
  useEffect(()=>{
    if(!initial.current){
      initial.current=true
      getRetrieveDeleteData()
    }
    
  },[]);
  const [showModal, setShowModal] = useState(false);

  const handleViewRequest = (data) => {
    setRetriveData(data)
    setShowModal(true);
  };

  const handleConfirm = () => {
    setShowModal(false);
    applicationRetrive()
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const applicationRetrive=()=>{
    let data={
      deleteId:  retriveData?.DeleteId,
      id:  retriveData?.ApplicationId,
      key: "Application"
     
    }
    setIsLoading(true)
    ApplicationService.retrieveApplication(data).then(res=>{
      setIsLoading(false)
      if(res==='OK'){
        toast.success('Applcation retrive successfully.')
        getRetrieveDeleteData()
      } else{
        toast.error(res)
      }
    }).catch(error=>{
      setIsLoading(false)
      toast.error(error?.message)
    })
  }
  return (
    <div className={classes.container}>
      {isLoading && <Loader/>}
      <div className={classes.heading}>MSM Retrieve Deleted Applications</div>
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column.title}</span>
                  {/* <img
                    src={index === 0 ? srName : sortName}
                    alt={index === 0 ? "Sr Name" : "Sort"}
                    className={classes.icon}
                  /> */}
                </div>
              </th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        { rowData?.length> 0 && (
        <tbody>
        {rowData.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((column, colIndex) => (
            <td key={colIndex}>
              {column.dataKey === "FullName" ? (
                row.FirstName || row.MiddleName || row.LastName ? (
                  `${row.FirstName} ${row.MiddleName || ""} ${row.LastName}`.trim()
                ) : (
                  "NA"
                )
              ) : (
               row[column.dataKey] ? row[column.dataKey] : "NA"
              )}
            </td>
          ))}
          <td>
            {!row?.RestoreUserId && (
            <span
            className={classes.actionBtn}
            onClick={() => handleViewRequest(row)}
          >
            Retrieve
          </span>
            )}

          </td>
        </tr>
             ))}
        </tbody>
        )}
        {rowData?.length===0 && (
          <tbody>
            <tr>
              <td colSpan={7}>No Data Found</td>
            </tr>
          </tbody>
        )}

      </Table>
      <SearchModal
        onShow={showModal}
        size="md"
        >  
        <div className={classes.ModelContainer}>
        <div className={`${classes.headerContainer}`}>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={handleClose}></img>
        </div>
          <div className={classes.title}>Are you sure, you want to retrieve this application?</div>
        <div className={classes.btnContainer}>
        <Button
          className={`${classes.btnYes}`}
            onClick={handleConfirm}>
            Yes
          </Button>
          <div>
          <Button
          className={`${classes.btnNo}`}
            onClick={handleClose}>
            Cancel
          </Button>
          </div>
          <div>

          </div>
        </div>
        </div> 
      </SearchModal>
   </div>
 );
};

export default RetriveDeleteApplication;
