import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import classes from "./Sidebar.module.css";
import searchIcon from "../../.../../../../../Assets/Logo/search-icon-2.svg";
import crossIcon from "../../.../../../../../Assets/Logo/red-2-cross-icon.svg";
import groups_image from "../../.../../../../../Assets/Images/groups_image.png";
import GroupProfiles from "./GroupProfiles";
import GroupService from "../../../../../Services/GroupService";
import { toast } from "react-toastify";
import Loader from "../../../../GlobleShared/Loader/Loader";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import { CloseButton } from "react-bootstrap";

const Sidebar =forwardRef (({toggleComponent,handlePostDashboard},ref) => {
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [tab, setTab] = useState("your-groups");
  const [isLoading, setIsLoading] = useState(false);
   const [groups, setGroups] = useState([])
   const [searchTerm, setSearchTerm] = useState("");
   const [filteredGroups, setFilteredGroups] = useState([]);
   const initial = useRef(null);
   const [selectedGroupId, setSelectedGroupId] = useState("");
   const {groupType, setGroupType, setPauseFlag, setUpdatePostDatata, setGroupIdGlobal, 
    groupIdGlobal, setMemberCount, memberCount, setMemberCountTrue, memberCountTrue}=useBehaviorSubject()

    // Expose method to refresh group list via ref
    useImperativeHandle(ref, () => ({
      refreshGroupList: () => {
        addGroupList();
      }
    }));

  const onTabChange = (value, flag) =>{
    setGroups([]);
    setGroupType(false)
    setTab(value);
    if (value === "your-groups") {
      addGroupList(); 
    } else if (value === "invitations") {
      getInvitation();
    } else if (value === "requests") {
      setGroupType(flag)
      getRequest();
     
    }else if (value === "suggestions") {
      getSuggestionGroups();
      setGroupType(flag)
    }
  }
  useEffect(() => {
    if (!initial.current) {
      initial.current = true;
      addGroupList(); 
  }
  }, []);

  const getSuggestionGroups = () => {
    setIsLoading(true);
    GroupService.getSuggestionGroup()
      .then((res) => {
        let filteredData = res?.filter(item => item?.UserGroupStatusId !== "2");
        setGroups(filteredData);
        if (filteredData.length > 0) {
          handlePostDashboard(filteredData[0]);
          setSelectedGroupId(filteredData[0]?.GroupID || filteredData[0]?.groupId)
          setGroupIdGlobal(filteredData[0]?.GroupID || filteredData[0]?.groupId)
          setMemberCount(filteredData[0]?.MembersCount)
        } else {
          handlePostDashboard(null);
        }
        setUpdatePostDatata(true)
        setIsLoading(false);
        toast.success(res?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Failed to get suggestion list. Please try again.");
      });
  };
  
//yourGroups
  const addGroupList = () => {
     setIsLoading(true);
    GroupService.getUserGroupList()
      .then((res) => {
        let filteredData = res?.filter(item => item?.UserGroupStatusId !== "2");
        setGroups(filteredData);
        applyFilter(filteredData);
        if (filteredData.length > 0) {
          console.log(filteredData)
          handlePostDashboard(filteredData[0]); // Set first "your-group" data by default
          setSelectedGroupId(filteredData[0]?.GroupID || filteredData[0]?.groupId)
          setGroupIdGlobal(filteredData[0]?.GroupID || filteredData[0]?.groupId)
          setMemberCount(filteredData[0]?.MembersCount)
          if(filteredData[0]?.UserGroupRole!=='1' && (filteredData[0]?.StatusId==='5' || filteredData[0]?.StatusId==='10')){
            setPauseFlag(true)
          }
        }else{
          handlePostDashboard(null);
        }
         setUpdatePostDatata(true)
         setIsLoading(false);
         toast.success(res?.message);
      })
      .catch((error) => {
         setIsLoading(false);
        toast.error('Failed to get group list. Please try again.');
      });
  };

  const getInvitation = () => {
    setIsLoading(true);
   GroupService.getInvitations()
     .then((res) => {
      let filteredData = res?.filter(item => item?.UserGroupStatusId !== "2");
       setGroups(filteredData);
       applyFilter(filteredData);
       if (filteredData.length > 0) {
         handlePostDashboard(filteredData[0]);
         setSelectedGroupId(filteredData[0]?.GroupID || filteredData[0]?.groupId) 
         setGroupIdGlobal(filteredData[0]?.GroupID || filteredData[0]?.groupId)// Set first "your-group" data by default
         setMemberCount(filteredData[0]?.MembersCount)
       }else{
        handlePostDashboard(null);
       }
        setIsLoading(false);
        toast.success(res?.message);
     })
     .catch((error) => {
        setIsLoading(false);
       toast.error('Failed to get Invitation list. Please try again.');
     });
 };

 const getRequest = async() => {
  setIsLoading(true);
 await GroupService.getRequests()
   .then((res) => {
    let filteredData = res?.filter(item => item?.UserGroupStatusId !== "2");
    const formattedGroups = filteredData.map((item) => ({
      requestId:item.requestId,
      GroupID: item.groupId,
      GroupName: item.groupName,
      GroupImage:item.groupImage,
      receiverUserId:item.receiverUserId,
      senderUserId:item.senderUserId,
      approverStatus:item.approverStatus
    }));
     setGroups(formattedGroups);
     applyFilter(formattedGroups);
     if (formattedGroups.length > 0) {
      handlePostDashboard(formattedGroups[0]);
      setSelectedGroupId(formattedGroups[0]?.GroupID) // Set first "your-group" data by default
      setGroupIdGlobal(formattedGroups[0]?.GroupID)
     }else{
      handlePostDashboard(null);
     }
      setUpdatePostDatata(true)
      setIsLoading(false);
      toast.success(res?.message);
   })
   .catch((error) => {
      setIsLoading(false);
     toast.error(error?.message);
   });
};


  const handleGroupClick = (group) => {
    setSelectedGroupId(group.GroupID || group.groupId)
    setGroupIdGlobal(group.GroupID || group.groupId)
    setMemberCount(group?.MembersCount)
    if((group?.UserGroupRole!=='1' && group?.StatusId === '10') || (group?.UserGroupRole!=='1' && group?.StatusId === '5')){
      setPauseFlag(true)
    }else{
      setPauseFlag(false)
    }
    handlePostDashboard(group); 

  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    const sanitizedValue = term.trimStart();
    setSearchTerm(sanitizedValue);
    applyFilter(groups, sanitizedValue);
  };

  const handleSearchRemove = ()=>{
    setSearchTerm('')
    applyFilter(groups, '');
  }

  const applyFilter = (groupList = groups, term = searchTerm) => {
    if (term.trim() === "") {
      setFilteredGroups(groupList);
    } else {
      const lowercasedTerm = term.toLowerCase();
      const filtered = groupList.filter((group) => {
        const name = group.GroupName || group.groupName || "";
        return name.toLowerCase().includes(lowercasedTerm);
      });
      setFilteredGroups(filtered);
    }
  };
  useEffect(() => {
    applyFilter(groups, searchTerm); // Apply filter whenever groups change
  }, [groups]);

  useEffect(() => {
    if(memberCountTrue){
      setMemberCountTrue(false)
       filteredGroups.filter(item=>{
        if((item?.GroupID || item?.groupId)===selectedGroupId){
          item['MembersCount']=memberCount
        }
      })
      setFilteredGroups(filteredGroups)
    }

  
  }, [memberCountTrue]);


  return (
    <div className={classes["container"]}>
      <div className={classes["section-1"]}>
        <div>
          <span className={classes["beta-section-1"]}>Groups</span>
        </div>
        <div className={classes["beta-section-2"]}>
          <button onClick={() =>{setGroupType(false); setTab("your-groups"); toggleComponent('createGroup');}} className={classes["section-button"]}>Create Group</button>
        </div>
      </div>
      <div className={classes["section-2"]}>
        <div className={`${classes["search-bar"]} form-group`}>
          <input
            type="text"
            placeholder="Search Groups"
            value={searchTerm}
            className="form-control"
            onChange={(e)=>handleSearchChange(e)}
          />
          <div  className={classes['search-icon-section']}>

             {searchTerm?.length >0  && (<CloseButton className={classes["close-button"]} onClick={handleSearchRemove} />)}
          </div>
          <button type="submit">
            <img
              src={searchIcon}
              alt="Search"
              className={classes['search-icon']}
            />
            
          </button>
        </div>
      </div>
      <div className={classes["section-3"]}>
        <div className="row">
          <div className={`${classes["buttonRow"]} col-sm-3 col-md-6 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "your-groups" ? classes["activeButton"] : ""}`}
              onClick={() =>{ onTabChange("your-groups", false); toggleComponent('groupDashboard') }}>
              Your Groups
            </button>
          </div>
          <div className={`${classes["buttonRow"]} col-sm-3 col-md-6 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "suggestions" ? classes["activeButton"] : ""}`}
              onClick={() => {onTabChange("suggestions", true);  toggleComponent('groupDashboard') }}>
              Suggestions
            </button>
          </div>
          <div className={`${classes["buttonRow"]} col-sm-3 col-md-6 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "invitations" ? classes["activeButton"] : ""}`}
              onClick={() => {onTabChange("invitations", true);  toggleComponent('groupDashboard')}}>
              Invitations
            </button>
          </div>
          <div className={`${classes["buttonRow"]} col-sm-3 col-md-6 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "requests" ? classes["activeButton"] : ""}`}
              onClick={() => {onTabChange("requests", true);  toggleComponent('groupDashboard')}}>
              Requests
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader></Loader>
      ):
      (filteredGroups.length>0 ?(
      <div className={classes["section-4"]}>
     
          {filteredGroups.map((group, index) => (
            <>
            {group?.UserGroupStatusId!=="2" && (
             <div className={(group.GroupID || group.groupId)===selectedGroupId ? classes['red-back'] :''}>
             <GroupProfiles
            //  key={group.GroupID || group.groupId}
             index={index}
             groupLength={filteredGroups.length}
             id={group.GroupID || group.groupId}
             name={group.GroupName || group.groupName}
             MembersCount={(memberCount && groupIdGlobal === (group.GroupID || group.groupId)) ? memberCount: (group.MembersCount||"0")}
             UserGroupRole={group.UserGroupRole}
            //  update={"5 min ago"} 
            onTabChange={onTabChange}
             groupType={group.GroupType || ""}
             image={group.GroupImage ? `${blobUrl}${group.GroupImage}`: group.groupImage? `${blobUrl}${group.groupImage}`: groups_image}
             tab={tab}
             handleHidePopup={addGroupList}
             getInvitationlist={getInvitation}
             getSuggestionGroups={getSuggestionGroups}
             getRequestlist={getRequest}
             requestId={group.requestId||"0"}
             onClick={() => handleGroupClick(group)}
             groupData={group}
             selectedGroupIdValue={selectedGroupId}
           />
           </div>
            )}

       </>
         ))}
       </div>
        ): <div className={classes["nosection"]}><span>No Group Information Found</span></div>)}
     
    </div>
  );
});

export default Sidebar;
