import React, { useEffect, useRef, useState } from "react";
import styles from "./NewApplication.module.css";
import ProgramSection from "./ProgramSection/ProgramSection";
import PendingDocumentsSection from "./PendingDocumentsSection/PendingDocumentsSection";
import AssignedCounsellor from "./AssignedCounsellor/AssignedCounsellor";
import ContactInfo from "./ContactInfo/ContactInfo";
import EducationSection from "./EducationSection/EducationSection";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import TestScores from "./TestScores/TestScores";
import ApplicationService from "../../../../../Services/ApplicationServices";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../GlobleShared/Loader/Loader";
import StudentServices from "../../../../../Services/StudentServices";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import institutesImage from "../../../../../Assets/Images/institute-image.svg"



const NewApplication = () => {
  const [tabs, setTabs] = useState([]);
  const [programDetails, setProgramDetails] = useState();
  const [personalFormData , setPersonalFormData]= useState(null)
  const initial = useRef(null);
  const [userDetail] = useLocalStorage("userDetail");
  const [studentId, setStudentId] = useState(userDetail?.refId);
  const params = useParams();
  const programTypeId = Number(params.programTypeId);
  const [institionList, setInstitionList] = useState([]);
  const [programId, setProgramId] = useState(0);
  const [institution, setInstitution] = useState(null);
  const [studentData, setStudentData] = useState();
  const [programList, setProgramList] = useState([]);
  const [intakeList, setIntakeList] = useState([]);
  const [programMode, setProgramMode] = useState([]);
  const [programSpecialization, setProgramSpecialization] = useState([]);
  const [studentDataBySectionWise, setStudentDataBySectionWise] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [studentDetail, setStudentDetail] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [instituteId, setInstituteId] = useState();
  const [applicationId, setApplicationId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [intakeId, setIntakeID] = useState(0);
  const [feeList, setFeeList] = useState([]);
  const [ genContectInfoData,  setGenContectInfoData] = useState();
  const [ testScoreData,  setTestScoreData] = useState();
  const [isLoading, setIsLoading]=useState(false)
  const [campusList, setCampusList]=useState([])
  const [educationInfo,setEducationInfo]=useState()
  const [schoolData,setSchoolData]=useState()
  const [rowData, setRowData] = useState({});
  const navigate=useNavigate()
  const [editEducation, setEditEducation] = useState(true);
  const blobURL= process.env.REACT_APP_LOGO_PATH
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [provinceList, setProvinceList] = useState([]);

    const [cityList, setCityList] = useState([]);

  const [subjectList, setSubjectList] = useState([]);

  const [streamList, setStreamList] = useState([]);

  const [degreeList, setDegreeList] = useState([]);

  const [educationLevelListbyApllication, setEducationLevelListbyApllication] =  useState([]);


  const [educationLevelData, setEducationLevelData] = useState([]);
  const [grdSchemeData, setGrdSchemeData] = useState([]);

  const [modeId, setModeId]=useState()
  const [campusId, setCampusId]=useState()
  const [specializationId, setSpecializationId]=useState()
  const [residingId, setResidingId]=useState();
  const [personalInfoData, setPersonalInfoData]=useState()
  const [documentList, setDocumentList]=useState([]);
  const [pendingDocumentList, setPendingDocumentList]=useState([]);
  const [pendingDoc, setPendingDoc]=useState([])
  const [BProgram1, setBProgram1]=useState(0)
  const [BProgram2, setBProgram2]=useState(0)
  const [BProgram3, setBProgram3]=useState(0)
  const [validationForProgramSetion, setValidationForProgramSetion]=useState({})
  const [validationForPersonalSetion, setValidationForPersonalSetion]=useState({})
  const [validationForEducationSetion, setValidationForEducationSetion]=useState({})
  const [validationForContactSetion, setValidationForContactSetion]=useState({});
  const [validationForTextScoreSetion, setValidationForTextScoreSetion]=useState({});
  const { validationFlag, setValidationFlag } = useBehaviorSubject();
  const [programInfoUpdate, setProgramInfoUpdate] = useState(true);
  const [editTestScore, setEditTestScore] = useState(true);
  const [tabId, setTabId]=useState('Program')


  const getInstituteId = (value) => {
    setProgramId(0)
    setIntakeID(0)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setProgramList([])
    setIntakeList([])
    setCampusList([])
    setProgramSpecialization([])
    setInstituteId(value);
    getProgramList(value);
    getcountryDetails(value);
    getAssignedData(value);
  };

  const getProgramId = (id) => {
    setIntakeID(0)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setIntakeList([])
    setCampusList([])
    setProgramSpecialization([])
    setProgramId(id);
    getProgramDetails(id);
    getIntakeList(id);
    getProgramModeList(id);
    getProgramSpecialization(id);
    CheckInstitutionIntakeExists(id, studentId, instituteId, intakeId);
    getAssignedData(instituteId);
    getProgramDocumentList(applicationId, programId, countryId);
    getProgramParentDocumentList(studentId);
  };

  const getIntakeId = (id) => {
    setIntakeID(id)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setCampusList([])
    setProgramSpecialization([])
    CheckInstitutionIntakeExists(programId, studentId, instituteId, id);
    getCampusList(id);
    forApplication(id);
  };

  const addEducationDetail = ()=>{
    getCountryList();
    // getEduLevelList(); need to implemen
    getEduLevelForDDLByApplication(studentId)
    getSubjectList();
    getLanguageList();
    getDegreeList();
    getStreamList();
  }

  const getScroll = (value) => {
    setTabId(value)
    document
      .getElementById(value)
      .scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  const getProgramDetails = (programIdValue) => {
    setIsLoading(true)
    ApplicationService.getProgramDetails(programIdValue)
      .then((res) => {
        setIsLoading(false)
        setProgramDetails(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };


  const getProgramDetails1 = (programIdValue) => {
    setIsLoading(true)
    ApplicationService.getProgramDetails(programIdValue)
      .then((res) => {
        setIsLoading(false)
        setProgramDetails(res);
          getProgramList(res?.InstitutionId);
          getIntakeList(res?.ProgramId)
          getProgramModeList(res?.ProgramId)
          setProgramId(res?.ProgramId)
          setInstituteId(res?.InstitutionId)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getIntakeList = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramIntake(programId, applicationId, countryId)
      .then((res) => {
        setIsLoading(false)
        setIntakeList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramModeList = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramMode(programId)
      .then((res) => {
        setIsLoading(false)
        setProgramMode(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramSpecialization = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramSpecialization(programId)
      .then((res) => {
        setIsLoading(false)
        setProgramSpecialization(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getInstituteList = () => {
    setIsLoading(true)
    ApplicationService.getInstituteList(studentId, programTypeId)
      .then((res) => {
        setIsLoading(false)
        setInstitionList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramList = (value) => {
    let data = {
      InstitutionId: Number(value),
      CountryId: countryId,
      ProgramTypeID: programTypeId,
    };
    setIsLoading(true)
    ApplicationService.getProgramList(data)
      .then((res) => {
        setIsLoading(false)
        setProgramList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getcountryDetails = (value) => {
    ApplicationService.countryDetailsByInstitutionId(value)
      .then((res) => {})
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getStudentData = () => {
    setIsLoading(true)
    ApplicationService.getStudentData(studentId, programId)
      .then((res) => {
        setIsLoading(false)
        setStudentData(res);
        if(res?.genInfo)
            setPersonalFormData(res?.genInfo)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getStudentDataBySectionwise = () => {
    setIsLoading(true)
    ApplicationService.getStudentDataBySection(studentId)
      .then((res) => {
        setIsLoading(false)
        setStudentDataBySectionWise(res);
        setCountryId(res?.genInfo?.Country)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getRelation = () => {
    ApplicationService.getRelationList()
      .then((res) => {
        setRelationList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getCountryCode = () => {
    ApplicationService.getCountryCode()
      .then((res) => {
        setCountryCodeList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getCountryList = () => {
    setIsLoading(true)
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false)
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProvinceList = (value) => {
    setIsLoading(true)
    ApplicationService.getProvince(value)
      .then((res) => {
        setIsLoading(false)
        setProvinceList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getCityList = (value) => {
    setIsLoading(false)
    ApplicationService.getCity(value)
      .then((res) => {
        setIsLoading(false)
        setCityList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };


  const getLanguageList = () => {
    setIsLoading(true)
    ApplicationService.getLanguageList()
      .then((res) => {
        setIsLoading(false)
        setLanguageList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getSubjectList = () => {
    setIsLoading(true)
    ApplicationService.getSubjectList()
    .then((res) => {
        setIsLoading(false)
        setSubjectList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getDegreeList = () => {
    setIsLoading(true)
    ApplicationService.getDegreeList()
      .then((res) => {
        setIsLoading(false)
        setDegreeList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getStreamList = () => {
    setIsLoading(true)
    ApplicationService.getStreamList()
      .then((res) => {
        setIsLoading(false)
        setStreamList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getApplicationList = () => {
    ApplicationService.getApplicationByStudentId(studentId)
      .then((res) => {
        setApplicationList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const CheckInstitutionIntakeExists = (
    programId,
    studentId,
    instituteId,
    intakeId
  ) => {
    let data = {
      InstitutionId: Number(instituteId),
      StudentId: Number(studentId),
      ProgramId: Number(programId),
      IntakeId: Number(intakeId),
    };
    setIsLoading(true)
    ApplicationService.CheckInstitutionIntakeExists(data)
      .then((res) => {
        setIsLoading(false)
        if (res > 0 && res !== applicationId) {
          toast.error("Application already exists with same program and intake.");
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getProgramDocumentList = (ApplicationId, ProgramId, countryId) => {
    setIsLoading(true)
    ApplicationService.getProgramDocumentList(ApplicationId, ProgramId, countryId)
      .then((res) => {
        setIsLoading(false)
        setPendingDocumentList(res)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramParentDocumentList = (studentId) => {
    ApplicationService.getProgramParentDocumentList(studentId)
      .then((res) => {
        setDocumentList(res)
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };


  const getCampusList = (IntakeId) => {
    ApplicationService.getCampusList(IntakeId)
      .then((res) => {
        setCampusList(res)
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const forApplication = (IntakeId) => {
    let data = {
      IntakeId: IntakeId,
      Nationality: 1,
      ApplicationId: applicationId,
    };
    ApplicationService.forApplication(data)
      .then((res) => {
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getAssignedData = (instituteId) => {
    ApplicationService.getAssignedCounsellor(studentId, instituteId)
      .then((res) => {
        setRowData(res);
      })
      .catch((error) => {
     
      });
  };
  const getEducationLevelData = () => {
    ApplicationService.getEducationLevelasync()
      .then((res) => {
        setEducationLevelData(res);
      })
      .catch((error) => {
     
      });
  };

  const getEduLevelForDDLByApplication = (studentId) => {
    let data = {
      ParentType: 7,
      ParentId: studentId,
      SchoolId: 0,
    };
    setIsLoading(true)
    ApplicationService.getEduLevelForDDLByApplication(data)
      .then((res) => {
        setIsLoading(false)
        setEducationLevelListbyApllication(res);
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };

  const getSchoolDetailList = () => {
    let data = {
      ParentType: 6,
      ParentId: studentId,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setSchoolList(res);
      })
      .catch((error) =>{
      });
  };
  
  const getSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.getSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        setSchoolData(res);
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };

  const deleteSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.deleteSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        // toast.success(res?.message);
        toast.success("Education details have been deleted successfully!");
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };

  const addSchoolDetail = (data) => {
    setIsLoading(true)
    ApplicationService.addSchoolDetail(data)
      .then((res) => {
        setIsLoading(false)
        toast.success(res?.message);
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };


  const updateSchoolDetail = (data) => {
    setIsLoading(true)
    ApplicationService.updateSchoolDetail(data)
      .then((res) => {
        setIsLoading(false)
        // toast.success(res?.message);
        toast.success("School updated successfully!");
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
      });
  };



  const onEducationCountryChange = (value) => {
    ApplicationService.getGradingSchemeAsync(value).then((res)=>{
        setGrdSchemeData(res);
      }).catch((error)=>{
       
      })
}


    const getMode=(id)=>{
      setModeId(id)

    }
    const getCampus=(id)=>{
      setCampusId(id)

    }
    const getSpecialization=(id)=>{
      setSpecializationId(id)

    }
    const getResiding=(id)=>{
      setResidingId(id)

    }
    const getPersonalInfoData=(data)=>{
      setPersonalInfoData(data)
    }
    const getGenContectInfo=(data)=>{
      setGenContectInfoData(data)
    }

    const getTestScore=(data)=>{
      setTestScoreData(data)
    }

    const getEducationInfo=(data)=>{
      setEducationInfo(data)
    }
    const getBackupProgramId=(BProgram1Id, BProgram2Id, BProgram3Id)=>{
      setBProgram1(BProgram1Id)
      setBProgram2(BProgram2Id)
      setBProgram3(BProgram3Id)

    }

    const getValidation=(value, type)=>{
      if(type===1){
        setValidationForProgramSetion(value)
      }
      if(type===3){
        setValidationForPersonalSetion(value)
      }
      if(type===4){
        setValidationForContactSetion(value)
      }
      if(type===5){
        setValidationForEducationSetion(value)
      }
      if(type===6){
        setValidationForTextScoreSetion(value)
      }
    }
    


const addApplication=()=>{
  setValidationFlag(true); // Trigger validation across sections
  if (validationForProgramSetion.institute !== null || 
    validationForProgramSetion.program !== null ||
     ( campusList?.length > 0 && validationForProgramSetion.campus !== null) || 
    validationForProgramSetion.intake !== null || 
   (programSpecialization?.length>0 &&  validationForProgramSetion.specialization !== null) || 
    validationForProgramSetion.mode !== null || 
    validationForProgramSetion.residing !== null) {
    getScroll('Program');
     return;
   }
   else if(
    !personalInfoData.Salution || !personalInfoData.FirstName || !personalInfoData.LastName ||
    !personalInfoData.Gender || (programTypeId===1 && !personalInfoData.MaritialStatus) || !personalInfoData.DateOfBirth ||
    (!personalInfoData.Email || (validationForPersonalSetion?.Email && validationForPersonalSetion?.Email!==null))  || !personalInfoData.MobileNoCountryCode ||
    !personalInfoData.MobileNo || (validationForPersonalSetion?.MobileNo && validationForPersonalSetion?.MobileNo!==null)  || Number(personalInfoData.Citizenship)===0 || ((programTypeId===1 || Number(personalInfoData?.GovernmentIdType)===2) && (personalInfoData.PassportNo.toLowerCase()!=='passport applied' && !personalInfoData.PassportExpiryDate)) ||
    (programTypeId===1 && !personalInfoData.PassportNo) ||  (programTypeId===2 && !personalInfoData.GovernmentIdType) || 
     (programTypeId===2 && !personalInfoData.GovernmentIdNumber) || 
     (programTypeId===2 && (!personalInfoData.Addres|| Number(personalInfoData.Country)===0 || Number(personalInfoData.Province)===0 || 
      !personalInfoData.City || !personalInfoData.Pincode))){
    getScroll('Personal');
 
    return
  }else if(programTypeId===1 && (!genContectInfoData.Addres || Number(genContectInfoData.Country)===0 || Number(genContectInfoData.Province)===0 || 
    !genContectInfoData.City|| !genContectInfoData.Pincode || !genContectInfoData.MailingAddres ||
    Number(genContectInfoData.MailingCountry)===0 || Number(genContectInfoData.MailingProvince)===0 || !genContectInfoData.MailingCity  ||
    !genContectInfoData.MailingPincode || !genContectInfoData.EmrgencyName  ||  !genContectInfoData.EmergencyRelation  ||
    !genContectInfoData.EmergencyEmail || (validationForContactSetion?.EmergencyEmail && validationForContactSetion?.EmergencyEmail!==null) ||
    !genContectInfoData.EmergencyCellPhoneCountryCode || !genContectInfoData.EmergencyCellPhone || (validationForContactSetion?.EmergencyCellPhone && validationForContactSetion?.EmergencyCellPhone!==null))){
      getScroll('Contact') 
      return
  }else if(Number(educationInfo.CountryOfEducation)=== 0 || Number(educationInfo.HighestLevelOfEducation)===0 || Number(educationInfo.GradingScheme)===0 || 
    !educationInfo.GradeAverage){
    getScroll('Education') 
    return
  }
  
  // const hasProgramErrors = Object.values(validationForProgramSetion).some((value) => value !== null);
  // const hasPersonalErrors = Object.values(validationForPersonalSetion).some((value) => value !== null);
  // const hasContactErrors = Object.values(validationForContactSetion).some((value) => value !== null);
  // const hasEducationErrors = Object.values(validationForEducationSetion).some((value) => value !== null);
   const hasTextScoreErrors = Object.values(validationForTextScoreSetion).some((value) => value !== null);

  // if (hasProgramErrors) {
  //   console.log(validationForProgramSetion);
  //   getScroll("Program");
  //   return;
  // }

  // if (hasPersonalErrors) {
  //   console.log(validationForPersonalSetion);
  //   getScroll("Personal");
  //   return;
  // }

  // if (hasContactErrors) {
  //   console.log(validationForContactSetion);
  //   getScroll("Contact");
  //   return;
  // }

  // if (hasEducationErrors) {
  //   console.log(validationForEducationSetion);
  //   getScroll("Education");
  //   return;
  // }

  if (hasTextScoreErrors) {
    getScroll(programTypeId===1?"Test":'Education'); // Adjust scroll as needed
    return;
  }

 let data= {
  programInfo: {
    AppSourceCode: "",
    ApplicationId: Number(applicationId),
    ApplicationMode: Number(modeId),
    ApplicationStatus: 0,
    B1ProgramId: Number(BProgram1),
    B2ProgramId: Number(BProgram2),
    B3ProgramId: Number(BProgram3),
    Campus: campusId,
    InstitutionId: Number(instituteId),
    IntekId: Number(intakeId),
    ProgramId: Number(programId),
    ShoreType:Number(residingId),
    SpecializationId: specializationId
  },
  genInfo: {
    About: "",
    Addres: programTypeId===2 ? personalInfoData?.Addres: genContectInfoData?.Addres,
    AdmissionExecutive: studentData?.genInfo?.AdmissionExecutive,
    AgentId: Number(studentData?.genInfo?.AgentId),
    App_Password: studentData?.genInfo?.App_Password,
    ApplicationId: applicationId,
    Area: studentData?.genInfo?.Area,
    AssignedTo:Number(studentData?.genInfo?.AssignedTo)?Number(studentData?.genInfo?.AssignedTo):0,
    B2CCounsellorId: Number(studentData?.genInfo?.B2CCounsellorId)?Number(studentData?.genInfo?.B2CCounsellorId):0,
    Citizenship: Number(personalInfoData?.Citizenship),
    City: programTypeId===2 ? personalInfoData?.City: genContectInfoData?.City,
    ConnectionCount: 0,
    Country:programTypeId===2 ? Number(personalInfoData?.Country): Number(genContectInfoData?.Country),
    CountryName: "",
    DateOfBirth: personalInfoData?.DateOfBirth,
    Email: personalInfoData?.Email,
    EmergencyAddress: genContectInfoData?.EmergencyAddress,
    EmergencyAddressSame: genContectInfoData?.EmergencyAddressSame,
    EmergencyBusinessPhone: studentData?.genInfo?.EmergencyBusinessPhone,
    EmergencyBusinessPhoneCountryCode: studentData?.genInfo?.EmergencyBusinessPhoneCountryCode,
    EmergencyCellPhone:  genContectInfoData?.EmergencyCellPhone,
    EmergencyCellPhoneCountryCode: genContectInfoData?.EmergencyCellPhoneCountryCode,
    EmergencyCity:  genContectInfoData?.EmergencyCity,
    EmergencyCountry:Number(genContectInfoData?.EmergencyCountry)?Number(genContectInfoData?.EmergencyCountry):0,
    EmergencyEmail: genContectInfoData?.EmergencyEmail,
    EmergencyPincode: genContectInfoData?.EmergencyPincode,
    EmergencyProvince: Number(genContectInfoData?.EmergencyProvince)?Number(genContectInfoData?.EmergencyProvince):0,
    EmergencyRelation: genContectInfoData?.EmergencyRelation,
    EmrgencyName: genContectInfoData?.EmrgencyName,
    FirstLogin: false,
    FirstName: personalInfoData?.FirstName,
    Gender: personalInfoData?.Gender,
    GovernmentIdNumber: personalInfoData?.GovernmentIdNumber,
    GovernmentIdType: personalInfoData?.GovernmentIdType,
    HouseNumber: "  ",
    Language: personalInfoData?.Language,
    LastName: personalInfoData?.LastName,
    LeadSourceId: 0,
    LeadSourceSecondaryId:0,
    LeadSourceText: "",
    LeadSourceThirdId: 0,
    MailingAddres: genContectInfoData?.MailingAddres,
    MailingAddressSame: genContectInfoData?.MailingAddressSame,
    MailingCity: genContectInfoData?.MailingCity,
    MailingCountry:Number(genContectInfoData?.MailingCountry?genContectInfoData?.MailingCountry:0),
    MailingPincode:genContectInfoData?.MailingPincode,
    MailingProvince:  Number(genContectInfoData?.MailingProvince?genContectInfoData?.MailingProvince:0),
    MailingProvinceName: "",
    MaritialStatus:  personalInfoData?.MaritialStatus,
    MarketingManager: studentData?.genInfo?.MarketingManager,
    MiddleName: personalInfoData?.MiddleName,
    MobileNo: personalInfoData?.MobileNo,
    MobileNoCountryCode: personalInfoData?.MobileNoCountryCode,
    PassportExpiryDate: personalInfoData?.PassportExpiryDate,
    PassportNo: personalInfoData?.PassportNo,
    Pincode: programTypeId===2 ? personalInfoData?.Pincode: genContectInfoData?.Pincode,
    PreferredCountryId: studentData?.genInfo?.PreferredCountryId,
    PreferredInstitution: studentData?.genInfo?.PreferredInstitution,
    PreferredProgramId: studentData?.genInfo?.PreferredProgramId,
    PreferredProgramLevel: studentData?.genInfo?.PreferredProgramLevel,
    ProgramTypeID: programTypeId,
    Province: programTypeId===2 ? Number(personalInfoData?.Province): Number(genContectInfoData?.Province),
    ProvinceName: "",
    PunchedFrom: 0,
    Salution: personalInfoData?.Salution,
    SecLanguage: "",
    StreetName: studentData?.genInfo?.StreetName,
    StudentId: studentId,
    TagLine: ""
  },
    eduInfo: {
      ApplicationId: applicationId,
      CountryOfEducation:Number(educationInfo?.CountryOfEducation),
      HighestLevelOfEducation: Number(educationInfo?.HighestLevelOfEducation),
      GradingScheme: Number(educationInfo?.GradingScheme),
      GradeAverage: educationInfo?.GradeAverage,
      GraduatedMostRecent:0,
      EduYearEnd:educationInfo?.EduYearEnd?educationInfo?.EduYearEnd:"",
      EduYearStart: educationInfo?.EduYearStart?educationInfo?.EduYearStart:"",
      StudentId: studentId
    },
    testScore: {
      StudentId: studentId,
      EnglishExamType: Number(testScoreData?.EnglishExamType),
      EnglishExamDate: testScoreData?.EnglishExamDate,
      EnglishScoreL:Number(testScoreData?.EnglishScoreL),
      EnglishScoreR: Number(testScoreData?.EnglishScoreR),
      EnglishScoreW: Number(testScoreData?.EnglishScoreW),
      EnglishScoreS: Number(testScoreData?.EnglishScoreS),
      GREExamDate: testScoreData?.GREExamDate,
      GREScoreV: Number(testScoreData?.GREScoreV),
      GREScoreQ: Number(testScoreData?.GREScoreQ),
      GREScoreW: Number(testScoreData?.GREScoreW),
      GMATExamDate: testScoreData?.GMATExamDate,
      GMATScoreA: Number(testScoreData?.GMATScoreA),
      GMATScoreI: Number(testScoreData?.GMATScoreI),
      GMATScoreQ: Number(testScoreData?.GMATScoreQ),
      GMATScoreV:Number(testScoreData?.GMATScoreV),
      GREExam: Number(testScoreData?.GREExam),
      GMATExam: Number(testScoreData?.GMATExam),
      EnglishScoreOverall: Number(testScoreData?.EnglishScoreOverall),
      EnglishExamUser: testScoreData?.EnglishExamUser,
      EnglishExamPswd: testScoreData?.EnglishExamPswd,
      EnglishExamRemark: testScoreData?.EnglishExamRemark,
      SATScoreTotal: Number(testScoreData?.SATScoreTotal),
      SATScoreMath:Number(testScoreData?.SATScoreMath),
      SATScoreEBRW:Number(testScoreData?.SATScoreEBRW),
      ACTScoreTotal: Number(testScoreData?.ACTScoreTotal),
      ACTScoreEnglish: Number(testScoreData?.ACTScoreEnglish),
      ACTScoreMath:Number(testScoreData?.ACTScoreMath),
      ACTScoreReading:Number(testScoreData?.ACTScoreReading),
      ACTScoreScience: Number(testScoreData?.ACTScoreScience),
      ACTScoreWriting: Number(testScoreData?.ACTScoreWriting),
      EnglishExamDocPath: testScoreData?.EnglishExamDocPath,
      OtherExamDocPath: testScoreData?.OtherExamDocPath,
      OtherExamUser: testScoreData?.OtherExamUser,
      OtherExamPswd:testScoreData?.OtherExamPswd
    }
  }


setIsLoading(true)
ApplicationService.addApplication(data).then(res=>{
  setIsLoading(false)
  if(res?.message==='Program exist for application' || res?.message==='This institution is not accepting application with your citizenship'){
    toast.error(res?.message)
    return;
  }
  if(!res?.response || res?.response===''){
    toast.error('Failed to create application, please try again')
    return
  }
   setApplicationId(Number(res?.response))
  toast.success("Application created successfully.")
  updateGeninfoData(data?.genInfo);
  updateEducationData(data?.eduInfo);
  updateTestScoreData(data?.testScore)
  preferenceAdd(Number(res?.response))
  navigate('/member/application/applicationstatus/view/' + Number(res?.response))

}).catch(error=>{
  setIsLoading(false)
  toast.error(error?.message)
})

}


const updateGeninfoData=(data)=>{
  setIsLoading(true)
  ApplicationService.getUpdateGenInfo(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })

}

const updateEducationData=(data)=>{
  data.StudentId=studentId
  setIsLoading(true)
  ApplicationService.udateEducation(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })
}

const updateTestScoreData=(data)=>{
  setIsLoading(true)
  ApplicationService.udateTestScore(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })
}

const preferenceAdd=(applicationIdValue)=>{
let data={
  ApplicationId:applicationIdValue,
  ApplicationPreference:[{CampusId:campusId?campusId:0, IndexId:0, ModeId:Number(modeId),
     ProgramId:Number(programId), ShoreType: Number(residingId), SpecializationId:specializationId?specializationId:0}]
}
setIsLoading(true)
ApplicationService.preferenceAdd(data).then(res=>{
  setIsLoading(false)
  uploadBulkDoc(applicationIdValue)

}).catch(error=>{
  setIsLoading(false)
})
}

const getPendingDoc=(data)=>{
  const newArray = data.map(({ DocPath, DocumentTypeId }) => ({
    DocPath,
    DocumentTypeId,
  }));

  setPendingDoc(newArray)
}

const uploadBulkDoc=(applicationIdValue)=>{
let data={
  Documents:pendingDoc,
  ParentId:applicationIdValue,
  ParentType:7
}

StudentServices.pendingDocumentUpload(data).then(res=>{

}).catch(error=>{
  setIsLoading(false)
  toast.error(error?.message)
})
 }

useEffect(() => {
  if (!initial.current) {
    initial.current = true;
    getCountryCode();
    getCountryList();
    getInstituteList();
    getStudentData();
    getStudentDataBySectionwise();
    getRelation();
    getLanguageList();
    getApplicationList();
    getEducationLevelData();
    getSchoolDetailList();
    getProgramParentDocumentList(studentId);
    if(queryParams.get('programId')){
      setProgramId(queryParams.get('programId'))
      getProgramDetails1(queryParams.get('programId'));
    }
  }
}, []);

useEffect(()=>{
  setTabs([
    { name: "Program Info", id: "Program" },
    { name: pendingDocumentList?.length>0?"Documents":'', id: "Documents" },
    { name: "Personal Info", id: "Personal" },
    { name: programTypeId===1?"Contact Info" :'', id: "Contact" },
    { name: "Education Info", id: "Education" },
    { name: programTypeId===1?"Test Score":'', id: "Test" },
    { name: "Assigned Counsellor", id: "Assigned" },
  ])

}, [pendingDocumentList, programTypeId])

return (
  <>
    <div>
      <div>
        <div>
          <p className={styles["heading"]}>Application</p>
        </div>

          <div className={styles["tabs-section"]}>
            <div className={styles["tab-alignment"]}>
              {tabs.map((item) => (
                <>
                <div>
                {item?.name && (
                  <div
                  onClick={() => getScroll(item?.id)}
                  className={item?.id === tabId ? styles['selected-text']: styles["tab-text"]}
                >
                  <p>{item?.name}</p>
                </div>
                )}
                </div>
          
                </>
      
              ))}
            </div>
          </div>
 

          <div id="Program" className="mb-3">
            <div className={styles["header"]}>
              {/* <p>Program Information</p> */}
            </div>
            <div className={programDetails? styles['program-container'] :styles['normal-program']}>
            <ProgramSection data={programDetails}
              programTypeId={programTypeId}
              institutionData={institionList}
              programData={programList}
              intakeData={intakeList}
              programModeData={programMode}
              campusList={campusList}
              programSpecializationData={programSpecialization}
              onInstituteChnage={getInstituteId}
              onProgramChnage={getProgramId}
              onIntakeChange={getIntakeId}
              onModeChange={getMode}
              onResidingChange={getResiding}
               onSpecializationChange={getSpecialization} onCampusChange={getCampus}
               getBackupProgramId={getBackupProgramId}
               getValidation={getValidation}
               editProgramInfo={programInfoUpdate}
            />
              </div>
              {programDetails && (
            <div className={styles["program-details"]}>
              <div className="row">
                <div
                  className={`${styles["col-white-background"]} col-md-3 col-sm-12`}
                >
                  <img
                    className={styles["institue-image"]}
                    src={programDetails?.InstLogoPath ? blobURL + programDetails?.InstLogoPath: institutesImage}
                    alt=""
                  ></img>
                </div>
                <div className={`${styles["col-background"]} col-md-9 col-sm-12`}>
                  <div className="row">
               {programDetails?.DurationTime && (
                <div
                className={`${styles["col-background"]} col-md-4 col-sm-12`}
              >
                <p className={styles["sub-heading"]}>Duration</p>
                <p className={styles["sub-heading-result"]}>
                  {programDetails?.DurationTime}
                </p>
              </div>
               )}  

            {programDetails?.AverageProcessingDay &&(
                <div
                className={`${styles["col-background"]} col-md-4 col-sm-12`}
              >
                <p className={styles["sub-heading"]}>
                  Average Processing Time
                </p>
                <p className={styles["sub-heading-result"]}>{programDetails?.AverageProcessingDay}</p>
              </div>
             ) }

                {programDetails?.FeeDetail.map(fee=>(
                <div
                className={`${styles["col-background"]} col-md-4 col-sm-12`}
              >
                <p className={styles["sub-heading"]}>{fee?.FeeType}</p>
                <p className={styles["sub-heading-result"]}>
                 {programDetails?.ProgramCurrency} {fee?.FeeAmount} {fee?.FeeBasis}
                </p>
              </div>
                ))}
                </div>

              </div>
              </div>
            </div>
                      )}
          
    
          </div>
          {pendingDocumentList.length>0 && (
          <div id="Documents" className="mb-3">
            <div className={styles["header"]}>
              <p>Documents</p>
            </div>
        
            <PendingDocumentsSection getPendingDoc={getPendingDoc} applicationId={applicationId} pendingDocumentList={pendingDocumentList} documentList={documentList} />
        
            
          </div>
        )}
          <div id="Personal" className="mb-3">
            <div className={styles["header"]}>
              <p>Personal Information</p>
            </div>
            {studentData && (<PersonalInformation
              studentDataBySectionWise={studentDataBySectionWise}
              countryList={countryList}
              countryCodeList={countryCodeList}
              languageList={languageList}
              personalInfoData={getPersonalInfoData}
              formData={personalFormData}
              getValidation={getValidation}
              programTypeId={programTypeId}
            />)}
          </div>

          {programTypeId===1 && (
          <div id="Contact" className="mb-3">
          <div className={styles["header"]}>
            <p>Contact Information</p>
          </div>
          <div>
            {studentData && (
            <ContactInfo countryList={countryList} contactInfo={studentData?.genInfo}
            getGenContectInfo={getGenContectInfo} relationList={relationList} getValidation={getValidation}>
           </ContactInfo>
            )}

          </div>
         </div>
          )}

          <div id="Education" className="mb-3">
            <div className={styles["header"]}>
              <p>Education Information</p>
            </div>
            <div>
              {studentData && (
                <>
              <EducationSection
                educationLevelData={educationLevelData}
                grdSchemeData={grdSchemeData} 
                onEducationCountryChange={onEducationCountryChange}
                countryList={countryList}
                schoolList={schoolList}
                deleteSchoolDetail={deleteSchoolDetail}
                studentId={studentId}
                educationInfo={studentData?.eduInfo}
                getEducationInfo={getEducationInfo}
                getSchoolDetailList={getSchoolDetailList}
                getValidation={getValidation}
                editEducation={editEducation}
                programTypeId={programTypeId}
              ></EducationSection>
              {programTypeId===2 && (
            <div>
              <TestScores programTypeId={programTypeId} editTestScore={editTestScore} getTestScore={getTestScore} testScoreData={studentData?.testScore} getValidation={getValidation}/>
            </div>
              )}
                
                </>
            )}
            </div>
          </div>
          {programTypeId===1 && (
          <div id="Test" className="mb-3">
            <div className={styles["header"]}>
              <p>Test Scores</p>
            </div>
            {studentData && (
              <TestScores programTypeId={programTypeId} editTestScore={editTestScore} getTestScore={getTestScore} testScoreData={studentData?.testScore} getValidation={getValidation}/>
            )}
            
          </div>
          )}

          <div id="Assigned" className="mb-3">
          <div className={styles["header"]}>
            <p>Assigned Counsellor</p>
          </div>
          <AssignedCounsellor rowData={rowData}></AssignedCounsellor>
          </div>


          <div className="text-center">
            {/* <button className={styles["save-btn"]}>Save</button> */}
            <button onClick={addApplication} className={styles["save-btn"]}>Generate Application</button>
          </div>
        </div>
      </div>
      {isLoading && (
        <Loader></Loader>
      )}
    </>
  );
};
export default NewApplication;
