import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import styles from "./Rank_Button_Group.module.css";
import upperArrow from "../../../../../../Assets/Logo/ic-red-arrow-down.svg";
import downArrow from "../../../../../../Assets/Logo/ic-red-arrow-up.svg";

function Rank_Button_Group({ optionsList, optionType, selectOptionHandler }) {
  const [selectedValues, setSelectedValues] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState({}); 
  const dropdownHide = useRef();
  const handleSelectOption = (selectedOption, index) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [index]: selectedOption,
    }));
    setDropdownVisible((prevValues) => ({
      ...prevValues,
      [index]: false, 
    }));
    if (selectedOption !== "----select----") {
      selectOptionHandler(selectedOption, optionType);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutsideHandler);
    return () => {
      document.removeEventListener("mousedown", onClickOutsideHandler);
    };
  }, []);
  function onClickOutsideHandler(event) {
    if (dropdownHide.current && !dropdownHide.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  }
  const toggleDropdown = (index) => {
    setDropdownVisible((prevValues) => ({
      ...prevValues,
      [index]: !prevValues[index],
    }));
  };

  const getAvailableOptions = (index) => {
    const selectedOptions = Object.values(selectedValues);
    return ["----select----", ...optionsList.filter((option) => selectedValues[index] === option || !selectedOptions.includes(option))];
  };

  return (
    <div>
      {optionsList.map((option, index) => (
        <div
          key={index}
          className="d-flex align-items-center mb-3">
          <div className={styles["index-number"]}>#{index + 1}</div>
          <ButtonGroup
            vertical
            className={styles["buttonContainer"]}>
            <Button
              className={`${styles["group-btn"]} `}
              onClick={() => toggleDropdown(index)}>
               <div className={styles["buttonContainerFluid"]}>
               <div className="">{selectedValues[index] || "----select----"}</div>
                <div>
                <img
                className={styles["imgDropDown"]}
                src={dropdownVisible[index] ? downArrow : upperArrow}
                alt="Toggle Arrow"
                /></div>
                </div>
            </Button>
            {dropdownVisible[index] && (
              <div
                className={styles["countryContainer"]}
                ref={dropdownHide}>
                <div
                  className={styles["countryDropDown"]}
                  ref={dropdownHide}>
                  {getAvailableOptions(index).map((option, idx) => (
                    <div
                      key={idx}
                      className={styles["dropDown-aligment"]}
                      onClick={() => handleSelectOption(option, index)}>
                      <span className={styles["dropdown-text"]}>{option}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ButtonGroup>
        </div>
      ))}
    </div>
  );
}

export default Rank_Button_Group;
