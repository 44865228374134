import React, { useEffect, useRef, useState } from "react";
import classes from "./Modal.module.css";
import { Form } from "react-bootstrap";
import croseIcon from "../../../Assets/Logo/crossred.svg";
import ApplicationService from "../../../Services/ApplicationServices";
const blockFrom = [
  "Main Feed",
  "Study Group",
  "The New New",
  "Engineer’s Advice",
  "Family Chat",
  "Designers Discuss"
];
const mutedFrom = ["Ken Feldt", "Study Group", "Main Feed"];
const repostList = [
  "Study Group",
  "The New New",
  "Engineer’s Advice",
  "Family Chat",
  "Designers Discuss",
];

const ModelForMore = ({ title, onClose, confirm, cssFlag }) => {
  const [reportList, setReportList] = useState([]);
  const initial=useRef()
  const [reportId, setReportId]=useState(0)
  const [checkedItems, setCheckedItems] = useState({
    block: [],
    mute: [],
    report: "", 
    repost: [],
  });

  const getValue = (listName, value, isChecked, listItems) => {
    if (listName === "report") {
      setCheckedItems((prevState) => ({
        ...prevState,
        report: value,
      }));
    } else {
      let updatedList;

      if (value === "All") {
        updatedList = isChecked ? [...listItems, "All"] : [];
      } else {
        updatedList = isChecked
          ? [...checkedItems[listName], value]
          : checkedItems[listName].filter((item) => item !== value);
        if (!isChecked) {
          updatedList = updatedList.filter((item) => item !== "All");
        }
        if (isChecked && updatedList.length === listItems.length) {
          updatedList.push("All");
        }
      }
      setCheckedItems((prevState) => ({
        ...prevState,
        [listName]: updatedList,
      }));
    }
  };

  const getReportId=(value)=>{
    setReportId(Number(value))
  }

  const isAllChecked = (listName, listItems) => {
    return checkedItems[listName].includes("All") || listItems.every((item) => checkedItems[listName].includes(item));
  };

  const getReportReasonList = () =>{
    ApplicationService.getReportReasonList().then((res)=>{
    setReportList(res || []);
    setReportId(res[0]?.ReportId)
    }).catch((error)=>{
  
    })
  }

  const confirmAction=()=>{
    confirm(reportId)
  }
  useEffect(()=>{
    if(!initial.current){
        if(title === "Report"){
        initial.current=true
        getReportReasonList();  
      }
    }
  },[title]);

  return (
    <div className={classes["forMoreModel"]}>
      <div className={cssFlag ? classes["forModelContainer1"] : classes["forModelContainer"]}>
        <div className={classes["forMoreHeader"]}>
          <div>{title}</div>
          <div onClick={onClose}>
            <img className={classes["croseIcon"]} src={croseIcon} />
          </div>
        </div>
        <div className="mt-4">
          {title === "Block" && (
            <div className="mt-4">
              <p className={classes["modal-text"]}>
                Block content from this user for which feeds?
              </p>
              <div className="mt-4">
                <Form>
                  {blockFrom.map((feed) => (
                    <Form.Check
                      key={feed}
                      label={feed}
                      type="checkbox"
                      name="block"
                      title={feed}
                      checked={checkedItems.block.includes(feed)}
                      onChange={(e) =>
                        getValue("block", feed, e.target.checked, blockFrom)
                      }
                      className={classes["radio-btn"]}
                    />
                  ))}
                  <Form.Check
                    label="All"
                    type="checkbox"
                    name="block"
                    checked={isAllChecked("block", blockFrom)}
                    onChange={(e) =>
                      getValue("block", "All", e.target.checked, blockFrom)
                    }
                    className={classes["radio-btn"]}
                  />
                </Form>
              </div>
            </div>
          )}
          {title === "Report" && (
            <div>
              <p className={classes["modal-text"]}>
                Thanks for looking out for yourself and fellow students by
                reporting things that break the rules. Let us know what’s
                happening and we’ll look into it.
              </p>
              <div className="mt-4">
              <Form>
                  {reportList.length > 0 && reportList.map((report) => (
                    <Form.Check
                      key={report?.ReportId}
                      label={report?.ReportName}
                      type="radio"
                      name="report"
                      title={report?.ReportName}
                      value={report?.ReportId}
                      checked={report?.ReportId === reportId}
                      onChange={(e) => getReportId(e.target.value)}
                      className={classes["radio-btn"]}
                    />
                  ))}
                </Form>
              </div>
            </div>
          )}
          {title === "Repost" && (
            <div className="mt-4">
              <p className={classes["modal-text"]}>
                Where would you like to repost this content?
              </p>
              <div className="mt-4">
                <Form>
                  {repostList.map((repost) => (
                    <Form.Check
                      key={repost}
                      label={repost}
                      type="checkbox"
                      name="repost"
                      title={repost}
                      checked={checkedItems.repost.includes(repost)}
                      onChange={(e) =>
                        getValue("repost", repost, e.target.checked, repostList)
                      }
                      className={classes["radio-btn"]}
                    />
                  ))}
                  <Form.Check
                    label="All"
                    type="checkbox"
                    name="repost"
                    checked={isAllChecked("repost", repostList)}
                    onChange={(e) =>
                      getValue("repost", "All", e.target.checked, repostList)
                    }
                    className={classes["radio-btn"]}
                  />
                </Form>
              </div>
            </div>
          )}
          {title === "Mute" && (
            <div className="mt-4">
              <p className={classes["modal-text"]}>
                Mute content from this user and/oor groups?
              </p>
              <div className="mt-4">
                <Form>
                  {mutedFrom.map((group) => (
                    <Form.Check
                      key={group}
                      label={group}
                      type="checkbox"
                      name="mute"
                      checked={checkedItems.mute.includes(group)}
                      onChange={(e) =>
                        getValue("mute", group, e.target.checked, mutedFrom)
                      }
                      className={classes["radio-btn"]}
                    />
                  ))}
                  <Form.Check
                    label="All"
                    type="checkbox"
                    name="mute"
                    checked={isAllChecked("mute", mutedFrom)}
                    onChange={(e) =>
                      getValue("mute", "All", e.target.checked, mutedFrom)
                    }
                    className={classes["radio-btn"]}
                  />
                </Form>
              </div>
            </div>
          )}
        </div>

        <div className="text-center mt-4 mb-4">
          <button  onClick={confirmAction} className="primary-button">
            {title}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModelForMore;
