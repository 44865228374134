import { Modal } from 'react-bootstrap';
import classes from "./Modal_Box.module.css";
import corssIcon from "../../../Assets/Logo/red-cross-icon.svg";

function Modal_Box({ children, onShow, onClose, title, removePadding, isAboutModel }) {
    return (
        <Modal show={onShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className={classes.header}>
                <Modal.Title className={`${classes['title']} ${isAboutModel ? classes['heading-text-modal']: ''}`} id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
                <div className={classes["tooltip-container"]}>

                <img title="" className={`${classes["corss-icon"]} ${isAboutModel ? classes["iconSizeForAboutModel"] : ""}`} src={corssIcon} alt='' onClick={onClose}></img>
                {/* <span className={classes["tooltip"]}>Remove</span> */}
                </div>
            </Modal.Header>
            <Modal.Body className={ removePadding ? 'p-0':''}>
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default Modal_Box;