import React from 'react'
import classes from "./StatusLogSection.module.css";
import { Button, Form, Table } from 'react-bootstrap';

function StatusLogSection({statusLogList}) {

const tableColumns = ["Status", "Remarks", "Updated By", "Updated On"];
const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    if (isNaN(date)) {
      return 'Invalid Date'; 
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  };

    
  return (
    <div>
         <div className={classes.container}>
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {tableColumns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
            {/* <th>View File</th>
            <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
        {statusLogList && statusLogList?.length> 0 ? 
          (statusLogList.map((status, index) => (
            <tr key={index}>
              <td>{status.ActivityStatus}</td>
              <td>{status.ActivityRemark}</td>
              <td>{status.DisplayName || 'N/A'}</td>
              <td>{formatDate(status.ActivityStamp)}</td>
            </tr>
          )))
          : 
         ( <tr>
            <td colSpan={4}> <div className={classes.noData}>No Data Available</div> </td>
          </tr>) }
        </tbody>
      </Table>
    </div>
    </div>
  )
}

export default StatusLogSection